import { PartSchemas } from 'types/partStatus';
import { PartId } from 'types/part';
import CellLoadingWrapper from 'ui-component/shared/CellLoadingWrapper';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ReactNode } from 'react';

export type RenderWithLoadingWrapperProps = {
  partIds?: PartId[];
  schemas?: PartSchemas[];
  params: GridRenderCellParams;
  renderMethod?: (params: GridRenderCellParams) => ReactNode;
  loadingVariant?: 'skeleton' | 'progress';
  // isLoading can be leveraged in place of partIds and schemas
  isLoading?: boolean;
};

export function renderWithLoadingWrapper({
  partIds,
  schemas,
  params,
  renderMethod,
  loadingVariant,
  isLoading,
}: RenderWithLoadingWrapperProps) {
  return (
    <CellLoadingWrapper
      partIds={partIds}
      schemas={schemas}
      params={params}
      renderMethod={renderMethod}
      loadingVariant={loadingVariant}
      isLoading={isLoading}
    />
  );
}
