import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import EditWithOnRender from 'ui-component/DataGrid/Cell/EditWithOnRender';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { ReactNode } from 'react';

export type RenderEditWithEmptyCellProps = {
  params: GridRenderCellParams;
  onRender: ({ params }: { params: GridRenderEditCellParams }) => void;
  renderMethod?: null | ((params: GridRenderCellParams) => ReactNode);
};

/**
 * Renders a cell in edit mode
 * Accepts an onRender function, which is called when the cell is rendered
 * This is useful for launching a dialog for cell editing
 */
export function renderEditWithOnRender({
  params,
  onRender,
  renderMethod,
}: RenderEditWithEmptyCellProps) {
  return (
    <EditWithOnRender
      params={params}
      onRender={onRender}
      renderMethod={renderMethod}
    />
  );
}
