import { ALL_APPS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const DocAiInbox = Loadable(lazy(() => import('views/procurement/DocInbox')));
const DocAiExtractors = Loadable(lazy(() => import('views/docai/Extractors')));
const DocAiPreferences = Loadable(
  lazy(() => import('views/docai/Preferences'))
);

export const DocAiRoutes = [
  {
    path: `${ALL_APPS.DOCAI.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.DOCAI.id,
          }}
        >
          <Navigate to={`${ALL_VIEWS.DOC_AI_INBOX.path}`} replace />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.DOC_AI_INBOX.path}`,
    element: (
      <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.DOCAI.id }}>
        <DocAiInbox />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.DOC_AI_EXTRACTORS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.DOCAI.id }}>
          <DocAiExtractors />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.DOC_AI_PREFERENCES.path}`,
    element: (
      <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.DOCAI.id }}>
        <DocAiPreferences />
      </PermissionsRouteWrapper>
    ),
  },
];
