import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import CopyClipboard from 'ui-component/extended/CopyClipboard';

export const RenderWithCopyButton = (
  text: ReactElement | string,
  copyString: string,
  copyLabel: string
) => {
  if (text) {
    return (
      <Typography>
        {text}
        <CopyClipboard input={copyString} label={copyLabel} />
      </Typography>
    );
  }
  return '-';
};
