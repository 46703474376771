import { Tooltip, Grid, Typography, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';

export function priceBreaksRenderCell(params: GridRenderCellParams) {
  const matchingCorrection = _.get(params, ['row', 'corrections', 'prices']);
  const originalPricesWithUiSchema = matchingCorrection
    ? _.map(matchingCorrection?.originalValue, (p) => ({
        priceBreak: p.quantity,
        price: parseFloat(p.convertedPrice),
      }))
    : null;
  const currentPricesWithUiSchema = matchingCorrection
    ? _.map(params.row.prices, (p) => ({
        priceBreak: p.priceBreak,
        price: parseFloat(p.price),
      }))
    : null;
  const originalPrices =
    matchingCorrection &&
    !_.isEqual(originalPricesWithUiSchema, currentPricesWithUiSchema)
      ? originalPricesWithUiSchema
      : null;
  const formattedOriginalPrices = originalPrices
    ? `$${_.min(_.map(originalPrices, 'price'))} = ${_.max(
        _.map(originalPrices, 'price')
      )}`
    : null;

  return (
    <Tooltip
      title={
        <Grid container spacing={0} sx={{}}>
          <Grid
            container
            item
            sx={{
              p: 2,
              color: 'grey.800',
              borderBottom: '1px solid #E3F2FD',
            }}
          >
            {!!matchingCorrection && !!matchingCorrection.detail && (
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="caption">
                  {matchingCorrection.detail}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography>Quantity</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Unit Price</Typography>
            </Grid>
          </Grid>
          {params.row.prices.map(
            (
              price: {
                priceBreak: number;
                price: string;
              },
              index: number
            ) => (
              <Grid
                container
                item
                sx={{
                  p: 2,
                  borderBottom:
                    index !== params.row.prices.length - 1
                      ? '1px solid #E3F2FD'
                      : 'none',
                }}
                key={index}
              >
                <Grid item xs={6}>
                  <Typography>{price.priceBreak}+</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>${price.price}</Typography>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      }
      placement="bottom"
      disableHoverListener={!params.row.prices.length}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'white',
            boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
            borderRadius: '12px',
            color: 'grey.500',
          },
        },
      }}
    >
      {originalPrices ? (
        <span className="table-cell-trucate">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ width: '100%', height: '100%' }}
          >
            {Boolean(formattedOriginalPrices) && (
              <Typography
                variant="caption"
                sx={{
                  textDecoration: 'line-through',
                  color: 'default',
                }}
              >
                {formattedOriginalPrices}
              </Typography>
            )}
            <Typography variant="body1" sx={{ color: 'default' }}>
              {params.formattedValue}
            </Typography>
          </Stack>
        </span>
      ) : (
        <span className="table-cell-trucate">{params.formattedValue}</span>
      )}
    </Tooltip>
  );
}
