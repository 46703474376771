import { ALL_APPS, ALL_VIEW_IDS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Suppliers = Loadable(lazy(() => import('views/suppliers/Suppliers')));
const PurchaseRules = Loadable(
  lazy(() => import('views/suppliers/PurchaseRules'))
);

export const SuppliersRoutes = [
  {
    path: `${ALL_APPS.SUPPLIERS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.SUPPLIERS.id }}
        >
          <Navigate
            to={`${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}`}
            replace
          />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.SUPPLIERS.views?.SUPPLIERS?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.SUPPLIERS.id }}
        >
          <Suppliers />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.SUPPLIERS.views?.PURCHASE_RULES?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.SUPPLIERS.id,
            view: ALL_VIEW_IDS.PURCHASE_RULES,
          }}
        >
          <PurchaseRules />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
