import { useState } from 'react';
import { PermissionScope } from 'types/apps';
import SidebarActionButton from 'ui-component/DetailLayout/SidebarComponents/SidebarActionButton';
import { IconTrash } from '@tabler/icons-react';

import { DeleteMutation, GenericResource } from 'types/api';
import DeleteRecordDialog from 'ui-component/clientV2/DeleteRecordDialog';

interface SidebarDeleteButtonProps<T extends GenericResource> {
  disabled?: boolean;
  permissionScope?: PermissionScope;
  idToUpdate: string;
  recordName: string;
  message?: string;
  useClientDeleteMutation: DeleteMutation<T>;
  onSuccess?: () => void;
}

/**
 * SidebarApproveButtonClientV2
 * To be used specifically with Client V2 api
 */
const SidebarDeleteButtonClientV2 = <T extends GenericResource>({
  disabled,
  permissionScope,
  idToUpdate,
  recordName,
  message,
  useClientDeleteMutation,
  onSuccess,
}: SidebarDeleteButtonProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SidebarActionButton
        Icon={IconTrash}
        label="Delete"
        tooltip="Delete Record"
        color="error"
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}
        permissionScope={permissionScope}
      />
      <DeleteRecordDialog<T>
        id={idToUpdate}
        dialogOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        recordName={recordName}
        useClientDeleteMutation={useClientDeleteMutation}
        message={message}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default SidebarDeleteButtonClientV2;
