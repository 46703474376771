import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {
  IconArrowRight,
  IconCopy,
  IconExternalLink,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { SyntheticEvent, useState } from 'react';
import { copyClipboard } from 'utils/functions';

type InlineGoToButtonProps = {
  link: string;
  tooltip?: string;
  sx?: SxProps<Theme>;
};

const InlineGoToButton = ({
  link,
  tooltip = '',
  sx,
}: InlineGoToButtonProps) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="menu-earning-card"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => navigate(link)}>
          <ListItemIcon>
            <IconArrowRight />
          </ListItemIcon>
          <ListItemText>Go To Record</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(`${window.location.origin}${link}`, '_blank');
            handleClose();
          }}
        >
          <ListItemIcon>
            <IconExternalLink />
          </ListItemIcon>
          <ListItemText>Open Record In New Tab</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyClipboard(`${window.location.origin}${link}`);
            handleClose();
          }}
        >
          <ListItemIcon>
            <IconCopy />
          </ListItemIcon>
          <ListItemText>Copy Link to Record</ListItemText>
        </MenuItem>
      </Menu>
      <Tooltip title={tooltip} sx={{ ...sx }}>
        <IconButton
          onMouseDown={(event) => event.stopPropagation()}
          onTouchStart={(event) => event.stopPropagation()}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            handleClick(event);
          }}
          size="small"
        >
          <IconArrowRight width={20} height={20} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default InlineGoToButton;
