import { useEffect } from 'react';
import _ from 'lodash';
import { GridEventLookup, GridFilterItem } from '@mui/x-data-grid-premium';
import { dispatch, useSelector } from 'store';
import { saveDatagridState } from 'store/slices/datagrid';
import { DatagridNames, apiRefProps } from 'types/datagrid';

interface UseSaveDatagridToLocalProps {
  gridName: keyof typeof DatagridNames;
  apiRef: apiRefProps;
  shouldSaveDatagridState?: boolean;
}

const eventsNames = [
  'pageSizeChange' as keyof GridEventLookup,
  'columnOrderChange' as keyof GridEventLookup,
  'columnVisibilityModelChange' as keyof GridEventLookup,
  'columnWidthChange' as keyof GridEventLookup,
  'filterModelChange' as keyof GridEventLookup,
  'rowGroupingModelChange' as keyof GridEventLookup,
  'sortModelChange' as keyof GridEventLookup,
];

export default function useSavedDatagridState({
  gridName,
  apiRef,
  shouldSaveDatagridState,
}: UseSaveDatagridToLocalProps) {
  const datagrid = useSelector((state) => state.datagrid);

  const handleSave = () => {
    if (shouldSaveDatagridState && apiRef?.current) {
      const state = apiRef.current?.exportState();
      // overwrite the values we don't want to persist
      state.preferencePanel = { open: false };
      state.filter = {
        filterModel: {
          items: state?.filter?.filterModel?.items as GridFilterItem[],
          linkOperator: state?.filter?.filterModel?.linkOperator || undefined,
        },
      };
      dispatch(saveDatagridState({ gridName, gridState: state }));
    }
  };

  const debounceSaveLocally = _.debounce(() => {
    handleSave();
  }, 1000);

  useEffect(() => {
    if (shouldSaveDatagridState && apiRef?.current?.subscribeEvent) {
      eventsNames.forEach((event) => {
        apiRef.current.subscribeEvent(event, (params) => {
          // check if this is a filterModelChange event and the only change is to the quickFilterValues
          if (event === 'filterModelChange') {
            if (
              !_.isEqual(
                _.get(datagrid, [gridName, 'filter', 'filterModel'], {
                  items: [],
                  linkOperator: 'and',
                }),
                _.omit(params, 'quickFilterValues')
              )
            ) {
              debounceSaveLocally();
            }
          } else {
            debounceSaveLocally();
          }
        });
      });
    }
  }, [apiRef?.current?.subscribeEvent]);

  return {
    savedDatagridState: shouldSaveDatagridState
      ? datagrid[gridName] ?? null
      : null,
  };
}
