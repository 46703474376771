import {
  usePrintPrintLabelStockLocationMutation,
  usePrintPrintLabelStockLotMutation,
  usePrintPrintLabelPrinterMutation,
  usePrintPrintLabelShipmentLineMutation,
  usePrintPrintLabelShipmentMutation,
  usePrintStockDocumentMutation,
} from 'store/slices/inventory';
import { useDefaultLabelTemplates } from 'hooks/useDefaultLabelTemplates';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  mobileInventoryStorageName,
  mobileInventoryDefaults,
} from 'types/mobileInventorySettings';
import {
  PrintLabelRequest,
  LabelFormat,
  StockDocument,
  PrintStockDocumentRequest,
  LabelTemplate,
  Printer,
} from 'types/inventory';
import { usePrintPrintLabelUserMutation } from 'store/slices/user';
import useSnackbar from 'hooks/useSnackbar';

export default function usePrinting({
  shipToId,
  recordOrgId,
}: {
  shipToId?: string | null;
  recordOrgId?: string | null;
}) {
  const { dispatchSuccessSnackbar, dispatchErrorSnackbar } = useSnackbar();

  const [
    printPrintStockLocationLabel,
    { isLoading: isLoadingPrintStockLocationLabel },
  ] = usePrintPrintLabelStockLocationMutation();

  const [printPrintStockLotLabel, { isLoading: isLoadingPrintStockLotLabel }] =
    usePrintPrintLabelStockLotMutation();

  const [printPrintPrinterLabel, { isLoading: isLoadingPrintPrinterLabel }] =
    usePrintPrintLabelPrinterMutation();

  const [
    printPrintShipmentLineLabel,
    { isLoading: isLoadingPrintShipmentLineLabel },
  ] = usePrintPrintLabelShipmentLineMutation();

  const [printPrintShipmentLabel, { isLoading: isLoadingPrintShipmentLabel }] =
    usePrintPrintLabelShipmentMutation();

  const [printPrintUserLabel, { isLoading: isLoadingPrintUserLabel }] =
    usePrintPrintLabelUserMutation();

  const [printStockDocument, { isLoading: isLoadingPrintStockDocument }] =
    usePrintStockDocumentMutation();

  const { fetching: isFetchingLabelTemplates, getLabelTemplate } =
    useDefaultLabelTemplates({
      shipToId,
      recordOrgId,
    });

  const [settings] = useLocalStorage(
    mobileInventoryStorageName,
    mobileInventoryDefaults
  );

  const handlePrintStockDocument = async ({
    stockDocumentId,
  }: {
    stockDocumentId: StockDocument['id'];
  }) => {
    const printer = settings.printer8511;
    if (!printer) {
      dispatchErrorSnackbar('No printer configured');
      return;
    }

    const payload = {
      printer,
    } as PrintStockDocumentRequest;

    try {
      await printStockDocument({
        id: stockDocumentId,
        payload,
      }).unwrap();

      dispatchSuccessSnackbar('Document printed');
    } catch (err) {
      dispatchErrorSnackbar('Document printing error');
    }
  };

  const getPrintingConfiguration = ({
    recordType,
    labelTemplateName,
  }: {
    recordType:
      | 'stockLocation'
      | 'stockLot'
      | 'printer'
      | 'shipmentLine'
      | 'shipment'
      | 'user';
    labelTemplateName?:
      | 'Shipment'
      | 'Stock Location'
      | 'Stock Location Vertical'
      | 'Stock Lot'
      | 'Shipment Line'
      | 'Printer'
      | 'Shipment Bin Tag'
      | 'Customer Bin Tag'
      | 'User'
      | 'Kit Bin Tag';
  }): {
    labelTemplate: LabelTemplate | null;
    printer: Printer['id'] | null;
  } => {
    // define defaults and lookups
    const labelTemplatesByRecordType = {
      stockLocation: getLabelTemplate(LabelFormat.StockLocationDefault),
      stockLot: getLabelTemplate(LabelFormat.StockLotDefault),
      printer: getLabelTemplate(LabelFormat.PrinterDefault),
      shipmentLine: getLabelTemplate(LabelFormat.ShipmentLineDefault),
      shipment: getLabelTemplate(LabelFormat.ShipmentDefault),
      user: getLabelTemplate(LabelFormat.UserDefault),
    };

    const labelTemplatesByName = {
      'Stock Location': getLabelTemplate(LabelFormat.StockLocationDefault),
      'Stock Location Vertical': getLabelTemplate(
        LabelFormat.StockLocationVertical
      ),
      'Stock Lot': getLabelTemplate(LabelFormat.StockLotDefault),
      Printer: getLabelTemplate(LabelFormat.PrinterDefault),
      'Shipment Line': getLabelTemplate(LabelFormat.ShipmentLineDefault),
      Shipment: getLabelTemplate(LabelFormat.ShipmentDefault),
      'Shipment Bin Tag': getLabelTemplate(LabelFormat.StockLocationShipment),
      'Customer Bin Tag': getLabelTemplate(LabelFormat.StockLocationCustomer),
      User: getLabelTemplate(LabelFormat.UserDefault),
      'Kit Bin Tag': getLabelTemplate(LabelFormat.StockLocationKit),
    };

    const printerByPrinterType: { [key: string]: string } = {
      '0302_thermal': settings.printer3020,
      '0402_thermal': settings.printer4020,
      '0406_thermal': settings.printer6040,
      '8511_document': settings.printer8511,
    };

    // select correct label template
    let labelTemplate = labelTemplatesByRecordType[recordType];
    if (labelTemplateName) {
      labelTemplate = labelTemplatesByName[labelTemplateName];
    }
    if (!labelTemplate) {
      dispatchErrorSnackbar('Invalid label template');
      return {
        labelTemplate: null,
        printer: null,
      };
    }

    // select correct printer for label template
    const printer = printerByPrinterType[labelTemplate?.printerType || ''];
    if (!printer) {
      dispatchErrorSnackbar('No printer configured');
      return {
        labelTemplate: null,
        printer: null,
      };
    }

    return {
      labelTemplate,
      printer,
    };
  };

  const handlePrintLabel = async ({
    recordType,
    recordIdProps,
    labelTemplateName,
    additionalData,
  }: {
    recordType:
      | 'stockLocation'
      | 'stockLot'
      | 'printer'
      | 'shipmentLine'
      | 'shipment'
      | 'user';
    recordIdProps: {
      stockLocationId?: string;
      stockLotId?: string;
      printerId?: string;
      shipmentLineId?: string;
      shipmentId?: string;
      userId?: string;
    };
    labelTemplateName?:
      | 'Shipment'
      | 'Stock Location'
      | 'Stock Location Vertical'
      | 'Stock Lot'
      | 'Shipment Line'
      | 'Printer'
      | 'Shipment Bin Tag'
      | 'Customer Bin Tag'
      | 'Kit Bin Tag'
      | 'User';
    additionalData?: { [__: string]: unknown };
  }) => {
    const { labelTemplate, printer } = getPrintingConfiguration({
      recordType,
      labelTemplateName,
    });

    if (!labelTemplate || !printer) {
      return;
    }

    const printFunctionByRecordType = {
      stockLocation: printPrintStockLocationLabel,
      stockLot: printPrintStockLotLabel,
      printer: printPrintPrinterLabel,
      shipmentLine: printPrintShipmentLineLabel,
      shipment: printPrintShipmentLabel,
      user: printPrintUserLabel,
    };

    // check that recordIdProps are correct for recordType
    if (recordType === 'stockLocation') {
      if (!recordIdProps.stockLocationId) {
        dispatchErrorSnackbar('Invalid stock location');
        return;
      }
    }
    if (recordType === 'stockLot') {
      if (!recordIdProps.stockLotId) {
        dispatchErrorSnackbar('Invalid stock lot');
        return;
      }
    }
    if (recordType === 'printer') {
      if (!recordIdProps.printerId) {
        dispatchErrorSnackbar('Invalid printer id');
        return;
      }
    }
    if (recordType === 'user') {
      if (!recordIdProps.userId) {
        dispatchErrorSnackbar('Invalid user id');
        return;
      }
    }
    if (recordType === 'shipment') {
      if (!recordIdProps.shipmentId) {
        dispatchErrorSnackbar('Invalid shipment');
        return;
      }
    }
    if (recordType === 'shipmentLine') {
      if (!recordIdProps.shipmentId || !recordIdProps.shipmentLineId) {
        dispatchErrorSnackbar('Invalid shipment line');
        return;
      }
    }

    const payload = {
      printer,
      labelTemplate: labelTemplate.id,
      additionalData,
    } as PrintLabelRequest;

    try {
      // @ts-ignore
      await printFunctionByRecordType[recordType]({
        ...recordIdProps,
        payload,
      }).unwrap();

      dispatchSuccessSnackbar('Label printed');
    } catch (err) {
      dispatchErrorSnackbar('Label printing error');
    }
  };

  return {
    handlePrintLabel,
    handlePrintStockDocument,
    getPrintingConfiguration,
    isLoadingConfig: isFetchingLabelTemplates,
    isLoadingPrint:
      isLoadingPrintStockLocationLabel ||
      isLoadingPrintStockLotLabel ||
      isLoadingPrintPrinterLabel ||
      isLoadingPrintShipmentLineLabel ||
      isLoadingPrintShipmentLabel ||
      isLoadingPrintUserLabel ||
      isLoadingPrintStockDocument,
  };
}
