import { PriceModel } from 'types/purchasing';
import { OrgUserFull } from 'types/org';
import {
  LabelTemplate,
  Printer,
  Shipment,
  ShipmentLine,
  StockLocation,
  Supplier,
  StockLocationPickListGETSchema,
} from 'types/inventory';
import { Part, Solicitation, WatchlistSubscription } from 'types/part';
import {
  BomCRUDSchema,
  BomLine,
  BomExportSheetType,
  BomExportFileType,
} from 'types/bom';
import { Order, OrderLineLite } from 'types/order';
import { ProductionRun, ProductionRunLines } from 'types/production';
import { DateString } from 'types';
import { GetOrgIssuedGrantsQueryParams } from 'types/access';
import _ from 'lodash';
import { API_URL } from 'constants/envConstants';
import { PartSchemas } from 'types/partStatus';
import { User } from 'types/user';
import {
  CofactrPurchaseCharge,
  NoPartPurchaseLine,
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseRequest,
} from 'types/procurement';
import { TeamType } from 'types/properties';

// DO NOT ADD /client/ endpoints to this type, use ClientUrlsType instead
interface UrlsType {
  ALLOCATIONS: ({
    expand,
    shipmentId,
    partId,
    productionRunId,
  }: {
    expand?: boolean;
    shipmentId?: Shipment['id'];
    partId?: Part['id'];
    productionRunId?: string;
  }) => string;
  ALLOCATIONS_SERVER: (query: string) => string;
  ALLOCATIONS_EXPORT: (query: string) => string;
  INVENTORY(
    orgId: string | null,
    cpid?: string | null,
    bomId?: string | null,
    productionRunId?: string | null,
    expand?: boolean,
    splitByFacility?: boolean
  ): string;
  INVENTORY_SERVER: (query: string) => string;
  INVENTORY_EXPORT: (query: string) => string;
  BOM_INVENTORY: (bomId: string) => string;
  PRODUCTION_RUN_INVENTORY: (production_run_id: ProductionRun['id']) => string;
  TOKEN: string;
  REFRESH_TOKEN: string;
  VERIFY_TOKEN: string;
  SIGNOUT: string;
  USER: string;
  USER_IMAGE: string;
  USER_NOTIFICATION_AUTH: string;
  USER_NOTIFICATION_PREFERENCES: string;
  USER_NOTIFICATION_CATEGORIES: string;
  USER_ACCESS: string;
  USER_GRANTS: (userId: string) => string;
  NOTIFICATION_WORKFLOW_BREAKDOWN: string;
  RESET_PASSWORD: string;
  SIGNUP: string;
  PARTS: ({
    query,
    ids,
  }: {
    query?: string;
    ids?: string;
    mpnMatch?: boolean;
  }) => string;
  PARTS_SERVER: (query: string) => string;
  PARTS_EXPORT: (query: string) => string;
  PART: (id: string, cached?: boolean, refresh?: boolean) => string;
  PART_ALTERNATIVES: (
    id: string,
    expand?: boolean,
    fullPart?: boolean
  ) => string;
  PART_QUOTES: (orgId: string | null, partId: string | null) => string;
  PART_QUOTES_REFRESH: (quoteId: string) => string;
  PART_QUOTE: (quoteId: string) => string;
  PART_OFFERS: (id: string) => string;
  PART_QUOTE_REJECT: (id: string) => string;
  PART_CLEAR_UPDATES: (id: string) => string;
  INVENTORY_LEDGERS: (cpid: string, orgId: string | null) => string;
  STOCK_LOT_LEDGERS: (stockLotId: string) => string;
  STOCK_DOCUMENT(documentId: string): string;
  STOCK_DOCUMENTS({
    stockLotId,
    purchaseId,
    productionRunId,
    bomId,
    shipmentId,
    needsReview,
  }: {
    stockLotId: string | null;
    purchaseId: string | null;
    productionRunId: string | null;
    bomId: string | null;
    shipmentId: string | null;
    needsReview: boolean | null;
  }): string;
  STOCK_DOCUMENT_DOWNLOAD(documentId: string): string;
  STOCK_DOCUMENT_REVIEW(documentId: string): string;
  STOCK_DOCUMENT_PRINT(documentId: string): string;
  STOCK_LOTS_SERVER: (query: string) => string;
  STOCK_LOTS_EXPORT: (query: string) => string;
  STOCK_LOCATIONS_SERVER: (query: string) => string;
  STOCK_LOCATIONS_EXPORT: (query: string) => string;
  STOCK_LOTS: ({
    orgId,
    partId,
    partIds,
    facilityId,
    expand,
    includePrices,
  }: {
    orgId?: string;
    partId?: string;
    partIds?: string[];
    facilityId?: string;
    expand?: boolean;
    includePrices?: boolean;
  }) => string;
  STOCK_LOT: (id: string) => string;
  STOCK_LOT_RECEIVE: (id: string) => string;
  STOCK_LOT_UNRECEIVE: (id: string) => string;
  STOCK_LOT_SCAN: (barcode: string, expand: boolean) => string;
  STOCK_LOT_SCAN_MOVE: (barcode: string) => string;
  STOCK_LOT_PRINT_LABEL: (id: string) => string;
  STOCK_LOT_PICKED: (id: string) => string;
  STOCK_LOT_PICKED_MOVE: (id: string) => string;
  HANDLING_REQUEST_DEADLINES: string;
  HANDLING_REQUESTS: ({
    stockLotId,
    facilityId,
    sandbox,
    requestTypes,
  }: {
    stockLotId?: string | null;
    facilityId?: string;
    sandbox?: boolean;
    requestTypes?: string[];
  }) => string;
  HANDLING_REQUESTS_CANCEL: (id: string) => string;
  HANDLING_TASK_ROWS: ({
    orgId,
    stockLotId,
    stockLotIds,
    expand,
    toDate,
    fromDate,
  }: {
    orgId: string | null;
    stockLotId: string | null;
    stockLotIds: string[] | null;
    expand: boolean | null;
    toDate: DateString | null;
    fromDate: DateString | null;
  }) => string;
  SHIPMENTS_TO_CONSUME: (facilityId: StockLocation['id']) => string;
  HANDLING_REQUESTS_TO_CONSUME: (
    facilityId: StockLocation['id'],
    shipmentId: Shipment['id']
  ) => string;
  HANDLING_REQUEST_COUNTS: (
    facilityId?: StockLocation['id'],
    sandbox?: boolean,
    includeDryingMslResets?: boolean
  ) => string;
  STOCK_EVENTS: (orgId: string | null) => string;
  STOCK_LOCATIONS: (orgId: string | null, id: string | null) => string;
  FACILITY_STOCK_LOCATIONS: string;
  STOCK_LOCATION: (id: string) => string;
  STOCK_LOCATION_PRINT_LABEL: (id: string) => string;
  STOCK_LOCATION_PICK_LIST: ({
    stockLocationId,
    shipmentIds,
    handlingTypes,
    orgIds,
    sandbox,
  }: StockLocationPickListGETSchema) => string;
  STOCK_LOCATION_KITTING_OPTIONS: (stockLocationId: string) => string;
  SHIPMENT_SOLVER2: string;
  SHIPMENT_ESTIMATES: string;
  SHIPMENT_CONFLICTS: string;
  USER_LOOKUP: (email: string) => string;
  USER_PRINT_LABEL: (userId: User['id']) => string;
  ORGS: string;
  ORG: (id: string) => string;
  ORG_USERS: string;
  ORG_USER: (userId: OrgUserFull['id']) => string;
  ORG_USER_INVITE: (userId: string) => string;
  ORG_IMAGE: (orgId: string) => string;
  ORG_GRANTS: (orgId: string) => string;
  CUSTOM_PROPERTIES: string;
  SUPPLIER: (supplierId?: Supplier['id']) => string;
  SUPPLIERS: (
    autocomplete?: string | null,
    ids?: string,
    query?: string
  ) => string;
  SUPPLIERS_V2: (ids: string) => string;
  SUPPLIERS_SERVER: (query: string) => string;
  SUPPLIERS_EXPORT: (query: string) => string;
  PUBLIC_SUPPLIERS_SERVER: (query: string) => string;
  ORG_SUPPLIERS_SERVER: (query: string) => string;
  ORG_SUPPLIERS_EXPORT: (query: string) => string;
  ORG_SUPPLIERS: string;
  ORG_SUPPLIER: (orgSupplierId: string) => string;
  SOLICITATIONS: ({
    partId,
    productionRunId,
    expand,
  }: {
    partId?: string | null;
    productionRunId?: string | null;
    expand?: boolean | null;
  }) => string;
  SOLICITATION: (solicitationId: Solicitation['id']) => string;
  BOMS: (orgId: string | null, bomId: string | null) => string;
  BOMS_SERVER: (query: string) => string;
  BOMS_EXPORT: (query: string) => string;
  SOLICITATION_CANCEL: (solicitationId: Solicitation['id']) => string;
  BOM_DUPLICATE: (bomId: string) => string;
  BOMLINES_PART: (cpid: Part['id']) => string;
  BOMLINES_BOM: (bomId: BomCRUDSchema['id']) => string;
  BOMLINES_BOM_LINE: ({
    bomId,
    bomLineId,
  }: {
    bomId: BomCRUDSchema['id'];
    bomLineId: BomLine['id'];
  }) => string;
  BOM_APPROVE: (bomId: BomCRUDSchema['id']) => string;
  BOM_UNAPPROVE: (bomId: BomCRUDSchema['id']) => string;
  BOM_LINK_EXTERNAL: (bomLineId: BomCRUDSchema['id']) => string;
  BOM_UNLINK_EXTERNAL: (bomLineId: BomCRUDSchema['id']) => string;
  BOM_VALIDATE: (bomId: BomCRUDSchema['id']) => string;
  AVAILABILITIES: string;
  AVAILABILITIES_V2: string;
  AVAILABILITIES_SNAPSHOT: string;
  AVAILABILITIES_ASYNC: string;
  BOM_AVAILABILITIES: (bomId: string) => string;
  BOM_AVAILABILITIES_SNAPSHOT: (bomId: string) => string;
  BOM_WORKBOOK: (bomId: string) => string;
  BOM_SHEET: (bomId: string) => string;
  BOM_SHEET_ASYNC: (bomId: string) => string;
  BOM_EXPORT_SHEET: ({
    bomId,
    sheetType,
  }: {
    bomId: BomCRUDSchema['id'];
    sheetType: BomExportSheetType;
  }) => string;
  BOM_EXPORT_FILE: ({
    bomId,
    sheetType,
    fileType,
  }: {
    bomId: BomCRUDSchema['id'];
    sheetType: BomExportSheetType;
    fileType: BomExportFileType;
  }) => string;
  BOM_IGNORE_LINES: (bomId: BomCRUDSchema['id']) => string;
  BOM_DNP_LINES: (bomId: BomCRUDSchema['id']) => string;
  BOM_DELETE_LINES: (bomId: BomCRUDSchema['id']) => string;
  BOM_IGNORE_ISSUES: (bomId: BomCRUDSchema['id']) => string;
  BOM_RESET_WARNINGS: (bomId: BomCRUDSchema['id']) => string;
  SHIPMENTS: ({
    orgId,
    id,
    expand,
    productionRunId,
  }: {
    orgId?: string;
    id?: string;
    expand?: boolean;
    productionRunId?: string | null;
  }) => string;
  SHIPMENTS_TO_PICK: ({
    shipFromId,
    expand,
    sandbox,
  }: {
    shipFromId: StockLocation['id'];
    expand: boolean;
    sandbox: boolean;
  }) => string;
  SHIPMENTS_TO_RECEIVE: ({
    shipToId,
    sandbox,
  }: {
    shipToId: StockLocation['id'];
    sandbox: boolean;
  }) => string;
  SHIPMENT_CANCEL: (id: Shipment['id']) => string;
  SHIPMENT_GENERATE_PDF: (id: Shipment['id']) => string;
  SHIPMENT_PRINT_LABEL: (id: Shipment['id']) => string;
  SHIPMENT_LINE: ({
    shipmentId,
    lineId,
    expand,
  }: {
    shipmentId: string;
    lineId?: string;
    expand?: boolean;
  }) => string;
  SHIPMENT_LINE_PRINT_LABEL: (
    id: Shipment['id'],
    lineId: ShipmentLine['id']
  ) => string;
  SHIPMENT_APPROVE: (id: Shipment['id']) => string;
  SHIPMENT_FILL_PRICES: (id: Shipment['id']) => string;
  SHIPMENT_MARK_SHIPPED: (id: Shipment['id']) => string;
  SHIPMENT_COMPLETE_INGEST: (id: Shipment['id']) => string;
  SHIPMENT_CREATE_STOCK_LOTS: (id: Shipment['id']) => string;
  SHIPMENT_SEND_TO_SHIPSTATION: (id: Shipment['id']) => string;
  PRICE_MODEL: (priceModelId: PriceModel['id']) => string;
  ORDERS: (
    productionRunId?: ProductionRun['id'] | null,
    bomId?: BomCRUDSchema['id'] | null,
    part?: Part['id'] | null,
    fromDate?: DateString | null,
    toDate?: DateString | null
  ) => string;
  PURCHASES: string;
  PURCHASE: (purchaseId: String) => string;
  PURCHASE_SEND_TO_KB: (purchaseId: String, forceSubmit: boolean) => string;
  CONFIRMED_PURCHASE_LINES: (
    facilityId: StockLocation['id'] | null | undefined,
    sandbox?: boolean
  ) => string;
  PURCHASE_LINES: (
    facilityId: StockLocation['id'] | null | undefined,
    sandbox?: boolean
  ) => string;
  PURCHASE_LINE: (purchaseLineId: String) => string;
  PURCHASE_LINE_RECEIVE: ({
    purchaseId,
    purchaseLineId,
  }: {
    purchaseId: string;
    purchaseLineId: string;
  }) => string;
  PURCHASE_LINE_UNRECEIVE: ({
    purchaseId,
    purchaseLineId,
  }: {
    purchaseId: string;
    purchaseLineId: string;
  }) => string;
  PURCHASE_LINE_UNSHIP: (purchaseId: string, purchaseLineId: string) => string;
  ORDER: (orderId: Order['id']) => string;
  ORDER_LINES: (orderId: Order['id']) => string;
  ORDER_UPDATES: (orderId: Order['id']) => string;
  PURCHASE_REQUESTS: ({
    productionRunId,
    part,
    voided,
    open,
    requested,
    archived,
    purchased,
    fromDate,
    toDate,
  }: {
    productionRunId?: string;
    part?: string;
    voided?: boolean;
    open?: boolean;
    requested?: boolean;
    archived?: boolean;
    purchased?: boolean;
    fromDate?: DateString;
    toDate?: DateString;
  }) => string;
  PURCHASE_REQUEST: (purchaseRequestId: PurchaseRequest['id']) => string;
  PURCHASE_ORDERS: (draft: boolean) => string;
  ALL_PURCHASE_LINES: ({
    active,
    part,
    productionRunId,
  }: {
    active?: boolean;
    part?: string;
    productionRunId?: string;
  }) => string;
  PURCHASE_ORDER: (purchaseOrderId: PurchaseOrder['id']) => string;
  PURCHASE_ORDER_SEND: (purchaseOrderId: PurchaseOrder['id']) => string;
  PURCHASE_ORDER_GENERATE_PDF: (purchaseOrderId: PurchaseOrder['id']) => string;
  PURCHASE_ORDER_LINES: (purchaseOrderId: PurchaseOrder['id']) => string;
  PURCHASE_ORDER_LINE: (
    purchaseOrderId: PurchaseOrder['id'],
    purchaseOrderLineId: PurchaseOrderLine['id']
  ) => string;
  PURCHASE_ORDER_NO_PART_LINES: (
    purchaseOrderId: PurchaseOrder['id']
  ) => string;
  PURCHASE_ORDER_NO_PART_LINE: (
    purchaseOrderId: PurchaseOrder['id'],
    purchaseOrderNoPartLineId: NoPartPurchaseLine['id']
  ) => string;
  PURCHASE_ORDER_PURCHASE_CHARGES: (
    purchaseOrderId: PurchaseOrder['id']
  ) => string;
  PURCHASE_ORDER_PURCHASE_CHARGE: (
    purchaseOrderId: PurchaseOrder['id'],
    purchaseOrderPurchaseChargeId: CofactrPurchaseCharge['id']
  ) => string;
  PURCHASE_ORDER_EVENTS: (purchaseOrderId: PurchaseOrder['id']) => string;
  PURCHASE_EVENTS: string;
  ORDER_REMEDIATIONS: ({
    orderId,
    orderLineId,
  }: {
    orderId: Order['id'];
    orderLineId: OrderLineLite['id'];
  }) => string;
  ORDER_REMEDIATION_SNAPSHOT_APPROVE: ({
    orderId,
    orderLineId,
  }: {
    orderId: Order['id'];
    orderLineId: OrderLineLite['id'];
  }) => string;
  ORDER_LINE_ALTS: ({
    orderId,
    orderLineId,
  }: {
    orderId: Order['id'];
    orderLineId: OrderLineLite['id'];
  }) => string;
  ORDER_LINE_UNSHIP: ({
    orderId,
    orderLineId,
  }: {
    orderId: Order['id'];
    orderLineId: OrderLineLite['id'];
  }) => string;
  BOMLINES_PARSE_LINES: (bomId: string) => string;
  SNAPSHOT: (snapshotId: string) => string;
  SNAPSHOTS: ({
    productionRunId,
    fromDate,
    toDate,
    sandbox,
    bomId,
  }: {
    productionRunId?: ProductionRun['id'] | null;
    fromDate?: DateString | null;
    toDate?: DateString | null;
    sandbox?: boolean;
    bomId?: BomCRUDSchema['id'] | null;
  }) => string;
  BOMLINES_PARSE_LINES_ASYNC: (bomId: string) => string;
  SNAPSHOT_APPROVE: (id: string) => string;
  SNAPSHOT_REQUEST_APPROVAL: (id: string) => string;
  SNAPSHOT_REJECT: (id: string) => string;
  SNAPSHOT_SAVE_RECALCULATIONS: (id: string) => string;
  SNAPSHOT_RECALCULATE_AVAILABILITIES: (id: string) => string;
  STOCK_LOT_TRANSFER: (stockLocationId: StockLocation['id']) => string;
  WATCHLIST_SUBSCRIPTIONS: ({
    partId,
    watchlistSubscriptionId,
    expand,
  }: {
    partId?: Part['id'] | null;
    watchlistSubscriptionId?: WatchlistSubscription['id'] | null;
    expand?: boolean | null;
  }) => string;
  PRODUCTION_RUNS: ({
    status,
    productionLocationId,
    productionRunId,
    bomId,
  }: {
    status?: string | null;
    productionLocationId?: StockLocation['id'] | null;
    productionRunId?: ProductionRun['id'] | null;
    bomId?: BomCRUDSchema['id'] | null;
  }) => string;
  PRODUCTION_RUN_LINES: ({
    productionRunId,
    productionRunLineId,
  }: {
    productionRunId: ProductionRun['id'];
    productionRunLineId?: ProductionRunLines['id'] | null;
  }) => string;
  PRODUCTION_RUN_DUPLICATE: (productionRunId: string) => string;
  PRODUCTION_RUN_PARTS: (
    productionRunId: string,
    core?: boolean,
    part?: string
  ) => string;
  PRODUCTION_RUN_CONSUMED_STOCK: (productionRunId: string) => string;
  PRODUCTION_RUN_OVERRIDES: (productionRunId: string) => string;
  PRODUCTION_RUN_OVERRIDE: (overrideId: string) => string;
  PRODUCTION_RUN_AVAILABILITIES: (productionRunId: string) => string;
  PRODUCTION_RUN_AVAILABILITIES_SNAPSHOT: (productionRunId: string) => string;
  PRODUCTION_RUN_ALLOCATIONS: ({
    productionRunId,
    expand,
  }: {
    productionRunId: ProductionRun['id'];
    expand?: boolean;
  }) => string;
  PRODUCTION_RUN_APPROVE: (productionRunId: string) => string;
  PRODUCTION_RUN_UNAPPROVE: (productionRunId: string) => string;
  PRODUCTION_RUN_DATES: (productionRunId: string) => string;
  PRODUCTION_RUN_SHIPMENTS: (productionRunId: string) => string;
  PRODUCTION_RUN_ESTIMATE_CONSUMPTION: (productionRunId: string) => string;
  PRODUCTION_RUN_EXECUTE: (productionRunId: string) => string;
  PRODUCTION_RUN_UNEXECUTE: (productionRunId: string) => string;
  PRINTERS: () => string;
  PRINTER: (printerId: Printer['id']) => string;
  PRINTER_PRINT_LABEL: (printerId: Printer['id']) => string;
  LABEL_TEMPLATES: (
    facilityId?: string,
    shipToId?: string | null,
    recordOrgId?: string | null
  ) => string;
  LABEL_TEMPLATE: (labelTemplateId: LabelTemplate['id']) => string;
  LABEL_TEMPLATE_PREVIEW_ZPL: (labelTemplateId: LabelTemplate['id']) => string;
  DOCUMENT_TEMPLATES: () => string;
  OUTBOUND_EMAIL_TEMPLATES: () => string;
  OUTBOUND_EMAILS: (purchaseId?: PurchaseOrder['id']) => string;
  MANUFACTURERS: (autocomplete?: string | null) => string;
  CLASSIFICATIONS: (autocomplete?: string | null) => string;
  INVOICES: (fromDate: DateString, toDate: DateString) => string;
  INVOICE: (invoiceNumber: string) => string;
  INVOICED_LINK: string;
  APPS: string;
  GRANTS: (
    grantId?: string,
    queryParams?: GetOrgIssuedGrantsQueryParams
  ) => string;
  DESCRIBE_PARTS: ({
    parts,
    schemas,
  }: {
    parts: string[];
    schemas: PartSchemas[];
  }) => string;
  PURCHASE_RULES: (purchaseRuleId?: string) => string;
  BUG_REPORT: string;
  PAIN_POINT: string;
  JOBS_ASYNC: (jobId: string, statusOnly?: boolean) => string;
  PRODUCTION_RUN_PREVIEW_ALLOCATIONS: (productionRunId: string) => string;
  PRODUCTION_RUN_ALLOCATIONS_FOR_PARTS: (productionRunId: string) => string;
  PART_RULE_SETS: string;
  METRONOME_USAGE: (fromDate: DateString, toDate: DateString) => string;
  METRONOME_PLAN: string;
  METRONOME_ENTITLEMENTS: string;
  DOCUMENT_EXTRACTORS: string;
  GLOBAL_SEARCH: (search: string) => string;
  REPORTING_QUERY: string;
  REPORTING_SCHEMAS: string;
  PRISMATIC_JWT: string;
  CUSTOMER_API_KEYS: string;
  TEAMS: string;
  TEAM: (teamId: string) => string;
  ARCHIVE_TEAM: (teamId: string) => string;
  UNARCHIVE_TEAM: (teamId: string) => string;
  TEAM_TYPES: string;
  TEAM_TYPE: (teamTypeId: TeamType['id']) => string;
}

interface ClientV2Urls {
  SUPPLIER_BILLS: (query?: string) => string;
  SUPPLIER_BILL_LINES: (query?: string) => string;
  KIT_REQUESTS: (query?: string) => string;
  KIT_REQUESTS_APPROVE: () => string;
  KIT_REQUESTS_UNAPPROVE: () => string;
  KIT_REQUESTS_ARCHIVE: () => string;
  KIT_REQUESTS_UNARCHIVE: () => string;
  KIT_REQUEST_LINES: (query?: string) => string;
  KIT_REQUEST_LINES_LOCK: () => string;
  KIT_REQUEST_LINES_UNLOCK: () => string;
  HANDLING_REQUESTS: (query?: string) => string;
  PICKED_HANDLING_REQUESTS: string;
  STOCK_EVENTS: (query?: string) => string;
  CANCEL_HANDLING_REQUEST: (handling_request_id: string) => string;
  CANCEL_HANDLING_REQUESTS: string;
  CREATE_SPLICE_COMBINE_HANDLING_REQUESTS: string;
  CREATE_STOCK_EVENTS: string;
  CREATE_SPLIT_STOCK_EVENT: string;
  CREATE_SPLICE_COMBINE_STOCK_EVENT: string;
  STOCK_LOT: (id: string) => string;
  RECEIVE_STOCK_LOT_BY_PURCHASE_LINE: (id: string) => string;
  RECEIVE_STOCK_LOT_BY_SHIPMENT_LINE: (id: string) => string;
  CREATE_RECEIVE_SHIPMENT_LINE: string;
  ALLOCATIONS: (query?: string) => string;
  SHIPMENTS: (query?: string) => string;
  SHIPMENT_LINES: (query?: string) => string;
  STOCK_LOCATIONS: (query?: string) => string;
}

// URLS must terminate with / due to Django configuration
// DO NOT ADD /client/ endpoints to this constant, use CLIENT_URLS instead
export const URLS: UrlsType = {
  TOKEN: `${API_URL}/token/`,
  REFRESH_TOKEN: `${API_URL}/token/refresh/`,
  VERIFY_TOKEN: `${API_URL}/token/verify/`,
  SIGNOUT: `/signout/`,
  USER: `${API_URL}/me/`,
  USER_IMAGE: `${API_URL}/me/image/`,
  USER_NOTIFICATION_AUTH: `${API_URL}/notification/auth/`,
  USER_NOTIFICATION_PREFERENCES: `${API_URL}/notification/preferences/`,
  USER_NOTIFICATION_CATEGORIES: `${API_URL}/notification/categories/`,
  USER_ACCESS: `${API_URL}/access/`,
  USER_GRANTS: (userId) => `${API_URL}/users/${userId}/grants/`,
  USER_PRINT_LABEL: (userId) => `${API_URL}/users/${userId}/print_label/`,
  RESET_PASSWORD: `${API_URL}/password-reset/`,
  SIGNUP: `${API_URL}/signup/`,
  NOTIFICATION_WORKFLOW_BREAKDOWN: `${API_URL}/notification/workflows/`,
  PARTS: ({ query, ids, mpnMatch }) => {
    const url = new URL(`${API_URL}/parts/`);
    if (query) {
      url.searchParams.set('query', encodeURIComponent(query));
    }
    if (ids) {
      url.searchParams.set('ids', ids);
    }
    if (mpnMatch) {
      url.searchParams.set('mpn_match', 'true');
    }
    return url.toString();
  },
  PARTS_SERVER: (query: string) => `${API_URL}/query_parts/${query}`,
  PARTS_EXPORT: (query: string) => `${API_URL}/export_parts/${query}`,
  PART: (id, cached, refresh) => {
    const url = new URL(`${API_URL}/parts/${id}/`);
    if (cached) {
      url.searchParams.set('cached', String(cached));
    }
    if (refresh) {
      url.searchParams.set('refresh', String(refresh));
    }

    return url.toString();
  },
  PART_ALTERNATIVES: (id, expand, fullPart) => {
    const url = new URL(`${API_URL}/parts/${id}/alternatives/`);
    if (expand) {
      url.searchParams.set('expand', String(expand));
    }
    if (fullPart) {
      url.searchParams.set('full_part', String(fullPart));
    }

    return url.toString();
  },
  PART_QUOTES: (orgId, partId) => {
    const url = new URL(`${API_URL}/quotes/`);
    if (orgId) {
      url.searchParams.set('org_id', orgId);
    }
    if (partId) {
      url.searchParams.set('part', partId);
    }
    url.searchParams.set('expand', 'true');
    return url.toString();
  },
  PART_QUOTE: (quoteId) => `${API_URL}/quotes/${quoteId}/`,
  PART_QUOTES_REFRESH: (partQuoteId) =>
    `${API_URL}/quotes/${partQuoteId}/refresh/?expand=true`,
  PART_OFFERS: (id) => `${API_URL}/parts/${id}/offers/`,
  PART_QUOTE_REJECT: (id) => `${API_URL}/quotes/${id}/reject/`,
  PART_CLEAR_UPDATES: (id) => `${API_URL}/parts/${id}/clear/`,
  ALLOCATIONS_SERVER: (query) => `${API_URL}/query_allocations/${query}`,
  ALLOCATIONS_EXPORT: (query) => `${API_URL}/export_allocations/${query}`,
  ALLOCATIONS: ({ expand = false, partId, shipmentId, productionRunId }) => {
    const url = new URL(`${API_URL}/allocations/`);
    if (partId) {
      url.searchParams.set('part', partId);
    }
    if (shipmentId) {
      url.searchParams.set('shipment_id', shipmentId);
    }
    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
    }
    if (expand) {
      url.searchParams.set('expand', String(expand));
    }
    return url.toString();
  },
  INVENTORY: (
    orgId,
    cpid = null,
    bomId = null,
    productionRunId = null,
    expand = false,
    splitByFacility = false
  ) => {
    let url = cpid
      ? `${API_URL}/inventory/${cpid}/?expand=${expand}`
      : `${API_URL}/inventory/?expand=${expand}`;
    if (orgId) url += `&org_id=${orgId}`;
    if (bomId) url += `&bom_id=${bomId}`;
    if (productionRunId) url += `&production_run_id=${productionRunId}`;
    if (splitByFacility) url += '&split_by_facility=true';
    return url;
  },
  INVENTORY_SERVER: (query) => `${API_URL}/query_inventory/${query}`,
  INVENTORY_EXPORT: (query) => `${API_URL}/export_inventory/${query}`,
  BOM_INVENTORY: (bomId) => `${API_URL}/boms/${bomId}/inventory/`,
  PRODUCTION_RUN_INVENTORY: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/inventory/`,
  INVENTORY_LEDGERS: (cpid, orgId) =>
    orgId
      ? `${API_URL}/inventory/${cpid}/ledgers/?org_id=${orgId}`
      : `${API_URL}/inventory/${cpid}/ledgers/`,
  STOCK_LOT_LEDGERS: (stockLotId) =>
    `${API_URL}/stock_lots/${stockLotId}/ledgers/`,
  STOCK_DOCUMENT: (documentId) => `${API_URL}/stock_documents/${documentId}/`,
  STOCK_DOCUMENTS: ({
    stockLotId,
    purchaseId,
    needsReview,
    bomId,
    productionRunId,
    shipmentId,
  }) => {
    const url = new URL(`${API_URL}/stock_documents/`);
    if (stockLotId) {
      url.searchParams.set('stock_lot_id', stockLotId);
    }
    if (purchaseId) {
      url.searchParams.set('purchase_id', purchaseId);
    }
    if (bomId) {
      url.searchParams.set('bom_id', bomId);
    }
    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
    }
    if (shipmentId) {
      url.searchParams.set('shipment_id', shipmentId);
    }
    if (needsReview) {
      url.searchParams.set('needs_review', 'true');
    }
    return url.toString();
  },
  STOCK_DOCUMENT_DOWNLOAD: (documentId) =>
    `${API_URL}/stock_documents/${documentId}/document`,
  STOCK_DOCUMENT_REVIEW: (documentId) =>
    `${API_URL}/stock_documents/${documentId}/review/`,
  STOCK_DOCUMENT_PRINT: (documentId) =>
    `${API_URL}/stock_documents/${documentId}/print/`,
  STOCK_LOTS_SERVER: (query) => `${API_URL}/query_stock_lots/${query}`,
  STOCK_LOTS_EXPORT: (query) => `${API_URL}/export_stock_lots/${query}`,
  STOCK_LOCATIONS_SERVER: (query) =>
    `${API_URL}/query_stock_locations/${query}`,
  STOCK_LOCATIONS_EXPORT: (query) =>
    `${API_URL}/export_stock_locations/${query}`,
  STOCK_LOTS: ({
    orgId,
    partId,
    partIds,
    facilityId,
    expand,
    includePrices,
  }) => {
    const url = new URL(`${API_URL}/stock_lots/`);
    if (partId) {
      url.searchParams.set('part', partId);
    }
    if (partIds) {
      url.searchParams.set('parts', JSON.stringify(partIds));
    }
    if (orgId) {
      url.searchParams.set('org_id', orgId);
    }
    if (facilityId) {
      url.searchParams.set('facility_id', facilityId);
    }
    if (expand) {
      url.searchParams.set('expand', String(expand));
    }
    if (includePrices) {
      url.searchParams.set('include_prices', String(includePrices));
    }
    return url.toString();
  },
  STOCK_LOT: (id) => `${API_URL}/stock_lots/${id}/?expand=true`,
  STOCK_LOT_RECEIVE: (id) => `${API_URL}/stock_lots/${id}/receive/`,
  STOCK_LOT_UNRECEIVE: (id) => `${API_URL}/stock_lots/${id}/unreceive/`,
  STOCK_LOT_SCAN: (barcode, expand) =>
    `${API_URL}/stock_lots/${barcode}/scan/?expand=${
      expand ? 'true' : 'false'
    }`,
  STOCK_LOT_SCAN_MOVE: (barcode) =>
    `${API_URL}/stock_lots/${barcode}/scan_move/`,
  STOCK_LOT_PRINT_LABEL: (id) => `${API_URL}/stock_lots/${id}/print_label/`,
  STOCK_LOT_PICKED: (id) => `${API_URL}/stock_lots/${id}/picked/`,
  STOCK_LOT_PICKED_MOVE: (id) => `${API_URL}/stock_lots/${id}/picked_move/`,
  STOCK_LOCATIONS: (orgId, id) => {
    if (orgId) return `${API_URL}/stock_locations/?org_id=${orgId}`;
    if (id) return `${API_URL}/stock_locations/${id}/`;
    return `${API_URL}/stock_locations/`;
  },
  FACILITY_STOCK_LOCATIONS: `${API_URL}/facilities/`,
  STOCK_LOCATION: (id) => `${API_URL}/stock_locations/${id}/`,
  STOCK_LOCATION_PRINT_LABEL: (id) =>
    `${API_URL}/stock_locations/${id}/print_label/`,
  STOCK_LOCATION_PICK_LIST: ({
    stockLocationId,
    shipmentIds,
    handlingTypes,
    orgIds,
    sandbox,
  }) => {
    const url = new URL(
      `${API_URL}/stock_locations/${stockLocationId}/pick_list/`
    );
    if (shipmentIds) {
      url.searchParams.set('shipment_ids', JSON.stringify(shipmentIds));
    }
    if (handlingTypes) {
      url.searchParams.set('handling_types', JSON.stringify(handlingTypes));
    }
    if (orgIds) {
      url.searchParams.set('org_ids', JSON.stringify(orgIds));
    }
    if (sandbox) {
      url.searchParams.set('sandbox', String(sandbox));
    }
    return url.toString();
  },
  STOCK_LOCATION_KITTING_OPTIONS: (stockLocationId) =>
    `${API_URL}/stock_locations/${stockLocationId}/kitting_options/`,
  SHIPMENT_SOLVER2: `${API_URL}/shipment_solver2/`,
  SHIPMENT_ESTIMATES: `${API_URL}/shipment_estimates/`,
  HANDLING_REQUESTS: ({ stockLotId, facilityId, sandbox, requestTypes }) => {
    const url = new URL(`${API_URL}/handling_requests/`);

    url.searchParams.set('sandbox', sandbox ? 'true' : 'false');

    if (requestTypes) {
      url.searchParams.set('request_types', requestTypes.join(','));
    }

    if (stockLotId) {
      url.searchParams.set('stock_lot_id', stockLotId);
    }

    if (facilityId) {
      url.searchParams.set('facility_id', facilityId);
    }

    return url.toString();
  },
  SHIPMENT_CONFLICTS: `${API_URL}/shipment_conflicts/`,
  HANDLING_REQUEST_DEADLINES: `${API_URL}/stock_lots/handling_requests_deadlines/`,
  HANDLING_REQUESTS_CANCEL: (id) =>
    `${API_URL}/handling_requests/${id}/cancel/`,
  HANDLING_TASK_ROWS: ({
    orgId,
    stockLotId,
    stockLotIds,
    expand = false,
    toDate,
    fromDate,
  }) => {
    const url = new URL(`${API_URL}/handling_task_rows/`);
    if (orgId) {
      url.searchParams.set('org_id', orgId);
    }
    if (stockLotId) {
      url.searchParams.set('stock_lot_id', stockLotId);
    }
    if (stockLotIds) {
      url.searchParams.set('stock_lot_ids', stockLotIds.join(','));
    }
    if (expand) {
      url.searchParams.set('expand', 'true');
    }
    if (toDate) {
      url.searchParams.set('to_date', toDate);
    }
    if (fromDate) {
      url.searchParams.set('from_date', fromDate);
    }
    return url.toString();
  },
  SHIPMENTS_TO_CONSUME: (facilityId) =>
    `${API_URL}/shipments_to_consume/?facility_id=${facilityId}`,
  HANDLING_REQUESTS_TO_CONSUME: (facilityId, shipmentId) =>
    `${API_URL}/handling_requests_to_consume/?facility_id=${facilityId}&shipment_id=${shipmentId}`,
  HANDLING_REQUEST_COUNTS: (facilityId, sandbox, includeDryingMslResets) => {
    const url = new URL(`${API_URL}/handling_request_counts/`);
    url.searchParams.set('sandbox', sandbox ? 'true' : 'false');
    if (includeDryingMslResets) {
      url.searchParams.set('include_drying_msl_resets', 'true');
    }
    if (facilityId) {
      url.searchParams.set('facility_id', facilityId);
    }
    return url.toString();
  },
  STOCK_EVENTS: (orgId) => {
    if (orgId) return `${API_URL}/stock_events/?org_id=${orgId}`;
    return `${API_URL}/stock_events/`;
  },
  USER_LOOKUP: (email) => `${API_URL}/user_lookup/?email=${email}`,
  ORGS: `${API_URL}/orgs/`,
  ORG: (id) => `${API_URL}/orgs/${id}/`,
  ORG_USERS: `${API_URL}/users/`,
  ORG_USER: (userId) => `${API_URL}/users/${userId}/`,
  ORG_USER_INVITE: (userId) => `${API_URL}/users/${userId}/reinvite/`,
  ORG_IMAGE: (orgId) => `${API_URL}/orgs/${orgId}/image/`,
  ORG_GRANTS: (orgId) => `${API_URL}/orgs/${orgId}/grants/`,
  CUSTOM_PROPERTIES: `${API_URL}/custom_properties/`,
  SUPPLIER: (supplierId) =>
    `${API_URL}/suppliers/${supplierId ? `${supplierId}/` : ''}`,
  SUPPLIERS: (autocomplete, ids, query) => {
    const url = new URL(`${API_URL}/suppliers/`);
    autocomplete && url.searchParams.set('autocomplete', autocomplete);
    ids && url.searchParams.set('ids', ids);
    query && url.searchParams.set('query', query);
    return url.toString();
  },
  SUPPLIERS_V2: (ids) => `${API_URL}/suppliers/?v2${ids ? `&ids=${ids}` : ''}`,
  SUPPLIERS_SERVER: (query) => `${API_URL}/query_suppliers/${query}`,
  SUPPLIERS_EXPORT: (query) => `${API_URL}/export_suppliers/${query}`,
  PUBLIC_SUPPLIERS_SERVER: (query) =>
    `${API_URL}/query_public_suppliers/${query}`,
  ORG_SUPPLIERS_SERVER: (query) => `${API_URL}/query_org_suppliers/${query}`,
  ORG_SUPPLIERS_EXPORT: (query) => `${API_URL}/export_org_suppliers/${query}`,
  ORG_SUPPLIERS: `${API_URL}/org_suppliers/`,
  ORG_SUPPLIER: (orgSupplierId) => `${API_URL}/org_suppliers/${orgSupplierId}/`,
  SOLICITATIONS: ({ partId, productionRunId, expand }) => {
    const url = new URL(`${API_URL}/solicitations/`);
    if (partId) {
      url.searchParams.set('part', partId);
    }
    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
    }
    if (expand) {
      url.searchParams.set('expand', String(expand));
    }
    return url.toString();
  },
  SOLICITATION: (solicitationId) =>
    `${API_URL}/solicitations/${solicitationId}/`,
  SOLICITATION_CANCEL: (solicitationId) =>
    `${API_URL}/solicitations/${solicitationId}/cancel/`,
  BOMS: (orgId, bomId) => {
    const url = new URL(`${API_URL}/boms/`);
    if (orgId) {
      url.searchParams.set('org_id', orgId);
      return url.toString();
    }
    if (bomId) {
      url.pathname += `${bomId}/`;
    }
    return url.toString();
  },
  BOMS_SERVER: (query) => `${API_URL}/query_boms/${query}`,
  BOMS_EXPORT: (query) => `${API_URL}/export_boms/${query}`,
  BOM_DUPLICATE: (bomId) => `${API_URL}/boms/${bomId}/duplicate/`,
  BOMLINES_PART: (cpid: Part['id']) => `${API_URL}/bom_lines/?part=${cpid}`,
  BOMLINES_BOM: (bomId) => `${API_URL}/boms/${bomId}/lines/`,
  BOMLINES_BOM_LINE: ({ bomId, bomLineId }) =>
    `${API_URL}/boms/${bomId}/lines/${bomLineId}/`,
  BOM_APPROVE: (bomId) => `${API_URL}/boms/${bomId}/approve/`,
  BOM_UNAPPROVE: (bomId) => `${API_URL}/boms/${bomId}/unapprove/`,
  BOM_LINK_EXTERNAL: (bomId) => `${API_URL}/boms/${bomId}/link_external/`,
  BOM_UNLINK_EXTERNAL: (bomId) => `${API_URL}/boms/${bomId}/unlink_external/`,
  BOM_VALIDATE: (bomId) => `${API_URL}/boms/${bomId}/validate/`,
  SHIPMENTS: ({ orgId, id, expand, productionRunId }) => {
    const url = new URL(`${API_URL}/shipments/`);

    if (expand) {
      url.searchParams.set('expand', 'true');
    }

    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
      return url.toString();
    }

    if (orgId) {
      url.searchParams.set('org_id', orgId);
      return url.toString();
    }

    if (id) {
      url.pathname += `${id}/`;
      return url.toString();
    }

    return url.toString();
  },
  SHIPMENTS_TO_PICK: ({ shipFromId, expand, sandbox }) =>
    `${API_URL}/shipments_to_pick/?ship_from_id=${shipFromId}&expand=${expand}&sandbox=${sandbox}`,
  SHIPMENTS_TO_RECEIVE: ({ shipToId, sandbox }) =>
    `${API_URL}/shipments_to_receive/?ship_to_id=${shipToId}&sandbox=${sandbox}`,
  SHIPMENT_APPROVE: (id) => `${API_URL}/shipments/${id}/approve/`,
  SHIPMENT_FILL_PRICES: (id) => `${API_URL}/shipments/${id}/fill_prices/`,
  SHIPMENT_MARK_SHIPPED: (id) => `${API_URL}/shipments/${id}/mark_as_shipped/`,
  SHIPMENT_COMPLETE_INGEST: (id) =>
    `${API_URL}/shipments/${id}/complete_ingest/`,
  SHIPMENT_CREATE_STOCK_LOTS: (id) =>
    `${API_URL}/shipments/${id}/create_stock_lots/`,
  SHIPMENT_CANCEL: (id: string) => `${API_URL}/shipments/${id}/cancel/`,
  SHIPMENT_GENERATE_PDF: (id: string) =>
    `${API_URL}/shipments/${id}/generate_pdf/`,
  SHIPMENT_PRINT_LABEL: (id: string) =>
    `${API_URL}/shipments/${id}/print_label/`,
  SHIPMENT_SEND_TO_SHIPSTATION: (id) =>
    `${API_URL}/shipments/${id}/send_to_ship_station/`,
  SHIPMENT_LINE: ({ shipmentId, lineId }) => {
    const url = new URL(`${API_URL}/shipments/${shipmentId}/lines/`);
    if (lineId) {
      url.pathname += `${lineId}/`;
    }
    return url.toString();
  },
  SHIPMENT_LINE_PRINT_LABEL: (id, lineId) =>
    `${API_URL}/shipments/${id}/lines/${lineId}/print_label/`,
  AVAILABILITIES: `${API_URL}/availabilities/`,
  AVAILABILITIES_V2: `${API_URL}/availabilities_v2/`,
  AVAILABILITIES_SNAPSHOT: `${API_URL}/availabilities_snapshot/`,
  AVAILABILITIES_ASYNC: `${API_URL}/async_availabilities/`,
  BOM_AVAILABILITIES: (bomId) => `${API_URL}/boms/${bomId}/availabilities/`,
  BOM_AVAILABILITIES_SNAPSHOT: (bomId) =>
    `${API_URL}/boms/${bomId}/availabilities_snapshot/`,
  BOM_WORKBOOK: (bomId) => `${API_URL}/boms/${bomId}/workbook/`,
  BOM_SHEET: (bomId) => `${API_URL}/boms/${bomId}/sheet/`,
  BOM_SHEET_ASYNC: (bomId) => `${API_URL}/boms/${bomId}/sheet_async/`,
  BOM_EXPORT_SHEET: ({ bomId, sheetType }) =>
    `${API_URL}/boms/${bomId}/sheet/?sheet_type=${sheetType}`,
  BOM_EXPORT_FILE: ({ bomId, sheetType, fileType }) =>
    `${API_URL}/boms/${bomId}/export_sheet/?sheet_type=${sheetType}&file_type=${fileType}`,
  BOM_IGNORE_LINES: (bomId) => `${API_URL}/boms/${bomId}/ignore_lines/`,
  BOM_DNP_LINES: (bomId) => `${API_URL}/boms/${bomId}/dnp_lines/`,
  BOM_DELETE_LINES: (bomId) => `${API_URL}/boms/${bomId}/delete_lines/`,
  BOM_IGNORE_ISSUES: (bomId) => `${API_URL}/boms/${bomId}/ignore_issues/`,
  BOM_RESET_WARNINGS: (bomId) => `${API_URL}/boms/${bomId}/reset_warnings/`,
  PRICE_MODEL: (priceModelId) => `${API_URL}/price_models/${priceModelId}/`,
  CONFIRMED_PURCHASE_LINES: (facilityId, sandbox) => {
    const url = new URL(`${API_URL}/confirmed_purchase_lines/`);
    if (facilityId) {
      url.searchParams.set('facility_id', facilityId);
    }
    if (sandbox) {
      url.searchParams.set('sandbox', 'true');
    } else {
      url.searchParams.set('sandbox', 'false');
    }
    return url.toString();
  },
  PURCHASE_LINES: (facilityId, sandbox) => {
    const url = new URL(`${API_URL}/purchase_lines/`);
    if (facilityId) {
      url.searchParams.set('facility_id', facilityId);
    }
    if (sandbox) {
      url.searchParams.set('sandbox', 'true');
    } else {
      url.searchParams.set('sandbox', 'false');
    }
    return url.toString();
  },
  PURCHASES: `${API_URL}/purchases/`,
  PURCHASE: (purchaseId) => `${API_URL}/purchases/${purchaseId}/`,
  PURCHASE_SEND_TO_KB: (purchaseId, forceSubmit) =>
    `${API_URL}/purchases/${purchaseId}/send_to_kb/?force_submit=${forceSubmit}`,
  PURCHASE_LINE: (purchaseLineId) =>
    `${API_URL}/purchase_lines/${purchaseLineId}/`,
  PURCHASE_LINE_RECEIVE: ({ purchaseId, purchaseLineId }) =>
    `${API_URL}/purchase_orders/${purchaseId}/lines/${purchaseLineId}/receive/`,
  PURCHASE_LINE_UNRECEIVE: ({ purchaseId, purchaseLineId }) =>
    `${API_URL}/purchase_orders/${purchaseId}/lines/${purchaseLineId}/unreceive/`,
  PURCHASE_LINE_UNSHIP: (purchaseId, purchaseLineId) =>
    `${API_URL}/purchase_orders/${purchaseId}/lines/${purchaseLineId}/unship/`,
  ORDERS: (productionRunId, bomId, part, fromDate, toDate) => {
    const url = new URL(`${API_URL}/orders/?expand=true`);
    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
    }
    if (bomId) {
      url.searchParams.set('bom_id', bomId);
    }
    if (part) {
      url.searchParams.set('part', part);
    }
    if (fromDate) {
      url.searchParams.set('from_date', fromDate);
    }
    if (toDate) {
      url.searchParams.set('to_date', toDate);
    }
    url.searchParams.set('expand', 'true');
    return url.toString();
  },
  ORDER: (orderId) => `${API_URL}/orders/${orderId}/?expand=true`,
  ORDER_LINES: (orderId) => `${API_URL}/orders/${orderId}/lines/?expand=true`,
  ORDER_UPDATES: (orderId) => `${API_URL}/orders/${orderId}/updates/`,
  PURCHASE_REQUESTS: ({
    productionRunId,
    part,
    voided,
    open,
    requested,
    archived,
    purchased,
    fromDate,
    toDate,
  }) => {
    const url = new URL(`${API_URL}/purchase_requests/`);
    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
    }
    if (part) {
      url.searchParams.set('part', part);
    }
    if (voided) {
      url.searchParams.set('voided', 'true');
    } else {
      url.searchParams.set('voided', 'false');
    }
    if (open) {
      url.searchParams.set('open', 'true');
    }
    if (requested) {
      url.searchParams.set('requested', 'true');
    }
    if (archived) {
      url.searchParams.set('archived', 'true');
    }
    if (purchased) {
      url.searchParams.set('purchased', 'true');
    }
    if (toDate) {
      url.searchParams.set('to_date', toDate);
    }
    if (fromDate) {
      url.searchParams.set('from_date', fromDate);
    }
    return url.toString();
  },
  PURCHASE_REQUEST: (purchaseRequestId) =>
    `${API_URL}/purchase_requests/${purchaseRequestId}/`,
  PURCHASE_ORDERS: (draft) =>
    `${API_URL}/purchase_orders/?draft=${draft ? 'true' : 'false'}`,
  ALL_PURCHASE_LINES: ({ productionRunId, part, active }) => {
    const url = new URL(`${API_URL}/all_purchase_order_lines/`);
    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
    }
    if (part) {
      url.searchParams.set('part', part);
    }
    if (active) {
      url.searchParams.set('active', 'true');
    }
    return url.toString();
  },
  PURCHASE_ORDER: (purchaseOrderId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/`,
  PURCHASE_ORDER_SEND: (purchaseOrderId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/send/`,
  PURCHASE_ORDER_GENERATE_PDF: (purchaseOrderId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/generate_pdf/`,
  PURCHASE_ORDER_LINES: (purchaseOrderId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/lines/`,
  PURCHASE_ORDER_LINE: (purchaseOrderId, purchaseOrderLineId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/lines/${purchaseOrderLineId}/`,
  PURCHASE_ORDER_NO_PART_LINES: (purchaseOrderId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/no_part_lines/`,
  PURCHASE_ORDER_NO_PART_LINE: (purchaseOrderId, purchaseOrderNoPartLineId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/no_part_lines/${purchaseOrderNoPartLineId}/`,
  PURCHASE_ORDER_PURCHASE_CHARGES: (purchaseOrderId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/cofactr_purchase_charge/`,
  PURCHASE_ORDER_PURCHASE_CHARGE: (
    purchaseOrderId,
    purchaseOrderPurchaseChargeId
  ) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/cofactr_purchase_charge/${purchaseOrderPurchaseChargeId}/`,
  PURCHASE_ORDER_EVENTS: (purchaseOrderId) =>
    `${API_URL}/purchase_orders/${purchaseOrderId}/purchase_events/`,
  PURCHASE_EVENTS: `${API_URL}/purchase_events/`,
  ORDER_REMEDIATIONS: ({ orderId, orderLineId }) =>
    `${API_URL}/orders/${orderId}/lines/${orderLineId}/remediations/`,
  ORDER_REMEDIATION_SNAPSHOT_APPROVE: ({ orderId, orderLineId }) =>
    `${API_URL}/orders/${orderId}/lines/${orderLineId}/remediations_snapshot/`,
  ORDER_LINE_ALTS: ({ orderId, orderLineId }) =>
    `${API_URL}/orders/${orderId}/lines/${orderLineId}/alts/`,
  ORDER_LINE_UNSHIP: ({ orderId, orderLineId }) =>
    `${API_URL}/orders/${orderId}/lines/${orderLineId}/unship/`,
  BOMLINES_PARSE_LINES: (bomId) => `${API_URL}/boms/${bomId}/parse_lines/`,
  SNAPSHOT: (snapshotId) => `${API_URL}/snapshots/${snapshotId}/`,
  SNAPSHOTS: ({ productionRunId, fromDate, toDate, sandbox, bomId }) => {
    const url = new URL(`${API_URL}/snapshots/`);
    if (productionRunId) {
      url.searchParams.set('production_run_id', productionRunId);
    }
    if (bomId) {
      url.searchParams.set('bom_id', bomId);
    }
    if (fromDate) {
      url.searchParams.set('from_date', fromDate);
    }
    if (toDate) {
      url.searchParams.set('to_date', toDate);
    }
    if (sandbox) {
      url.searchParams.set('sandbox', sandbox ? 'true' : 'false');
    }
    return url.toString();
  },
  BOMLINES_PARSE_LINES_ASYNC: (bomId) =>
    `${API_URL}/boms/${bomId}/parse_lines_async/`,
  SNAPSHOT_APPROVE: (id) => `${API_URL}/snapshots/${id}/approve/`,
  SNAPSHOT_REQUEST_APPROVAL: (id) =>
    `${API_URL}/snapshots/${id}/request_approval/`,
  SNAPSHOT_REJECT: (id) => `${API_URL}/snapshots/${id}/reject_request/`,
  SNAPSHOT_SAVE_RECALCULATIONS: (id) =>
    `${API_URL}/snapshots/${id}/recalculate/`,
  SNAPSHOT_RECALCULATE_AVAILABILITIES: (id) =>
    `${API_URL}/snapshots/${id}/recalculate_availabilities/`,
  STOCK_LOT_TRANSFER: (stockLocationId) =>
    `${API_URL}/stock_locations/${stockLocationId}/move_lots_to/`,
  WATCHLIST_SUBSCRIPTIONS: ({ partId, watchlistSubscriptionId, expand }) => {
    const url = watchlistSubscriptionId
      ? new URL(`${API_URL}/watchlist/${watchlistSubscriptionId}/`)
      : new URL(`${API_URL}/watchlist/`);
    if (expand) {
      url.searchParams.set('expand', String(expand || false));
    }
    if (partId) {
      url.searchParams.set('part', partId);
    }
    return url.toString();
  },
  PRODUCTION_RUNS: ({
    status,
    productionLocationId,
    productionRunId,
    bomId,
  }) => {
    const url = productionRunId
      ? new URL(`${API_URL}/production_runs/${productionRunId}/`)
      : new URL(`${API_URL}/production_runs/`);
    if (status) {
      url.searchParams.set('status', status);
    }
    if (productionLocationId) {
      url.searchParams.set('production_location_id', productionLocationId);
    }
    if (bomId) {
      url.searchParams.set('bom_id', bomId);
    }
    return url.toString();
  },
  PRODUCTION_RUN_DUPLICATE: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/duplicate/`,
  PRODUCTION_RUN_PARTS: (productionRunId, core = false, part = undefined) => {
    const url = new URL(`${API_URL}/production_runs/${productionRunId}/parts/`);
    if (core) {
      url.searchParams.set('core', 'true');
    }
    if (part) {
      url.searchParams.set('part', part);
    }
    return url.toString();
  },
  PRODUCTION_RUN_CONSUMED_STOCK: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/consumed_stock/`,
  PRODUCTION_RUN_OVERRIDES: (productionRunId) =>
    `${API_URL}/part_overrides/?production_run_id=${productionRunId}`,
  PRODUCTION_RUN_OVERRIDE: (overrideId) =>
    `${API_URL}/part_overrides/${overrideId}/`,
  PRODUCTION_RUN_LINES: ({ productionRunId, productionRunLineId }) => {
    const url = productionRunLineId
      ? new URL(
          `${API_URL}/production_runs/${productionRunId}/lines/${productionRunLineId}/`
        )
      : new URL(`${API_URL}/production_runs/${productionRunId}/lines/`);
    return url.toString();
  },
  PRODUCTION_RUN_AVAILABILITIES: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/availabilities/`,
  PRODUCTION_RUN_AVAILABILITIES_SNAPSHOT: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/availabilities_snapshot/`,
  PRODUCTION_RUN_ALLOCATIONS: ({ productionRunId, expand }) =>
    `${API_URL}/production_runs/${productionRunId}/allocations/?expand=${expand}`,
  PRODUCTION_RUN_APPROVE: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/approve/`,
  PRODUCTION_RUN_UNAPPROVE: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/unapprove/`,
  PRODUCTION_RUN_DATES: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/dates/`,
  // TODO: Add expanded
  PRODUCTION_RUN_SHIPMENTS: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/shipments/`,
  PRODUCTION_RUN_ESTIMATE_CONSUMPTION: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/consumption/`,
  PRODUCTION_RUN_EXECUTE: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/execute/`,
  PRODUCTION_RUN_UNEXECUTE: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/unexecute/`,
  PRINTERS: () => `${API_URL}/printers/`,
  PRINTER: (printerId) => `${API_URL}/printers/${printerId}/`,
  PRINTER_PRINT_LABEL: (printerId) =>
    `${API_URL}/printers/${printerId}/print_label/`,
  LABEL_TEMPLATES: (facilityId, shipToId, recordOrgId) => {
    const url = new URL(`${API_URL}/label_templates/`);
    facilityId && url.searchParams.set('facility_id', facilityId);
    shipToId && url.searchParams.set('ship_to_id', shipToId);
    recordOrgId && url.searchParams.set('record_org_id', recordOrgId);
    return url.toString();
  },
  LABEL_TEMPLATE: (labelTemplateId) =>
    `${API_URL}/label_templates/${labelTemplateId}/`,
  LABEL_TEMPLATE_PREVIEW_ZPL: (labelTemplateId) =>
    `${API_URL}/label_templates/${labelTemplateId}/preview_zpl/`,
  DOCUMENT_TEMPLATES: () => `${API_URL}/document_templates/`,
  OUTBOUND_EMAIL_TEMPLATES: () => `${API_URL}/outbound_email_templates/`,
  OUTBOUND_EMAILS: (purchaseId) => {
    const url = new URL(`${API_URL}/outbound_emails/`);
    purchaseId && url.searchParams.set('purchase_id', purchaseId);
    return url.toString();
  },
  MANUFACTURERS: (autocomplete) => {
    const url = new URL(`${API_URL}/manufacturers/`);
    autocomplete && url.searchParams.set('autocomplete', autocomplete);
    return url.toString();
  },
  CLASSIFICATIONS: (autocomplete) => {
    const url = new URL(`${API_URL}/classifications/`);
    autocomplete && url.searchParams.set('autocomplete', autocomplete);
    return url.toString();
  },
  APPS: `${API_URL}/apps/`,
  GRANTS: (grantId?: string, queryParams?: GetOrgIssuedGrantsQueryParams) => {
    const url = grantId
      ? new URL(`${API_URL}/grants/${grantId}/`)
      : new URL(`${API_URL}/grants/`);
    if (queryParams) {
      _.forEach(queryParams, (value, key) => {
        value !== undefined &&
          url.searchParams.set(_.snakeCase(key), value as string);
      });
    }
    return url.toString();
  },
  INVOICES: (fromDate, toDate) => {
    const url = new URL(`${API_URL}/billing/invoices/`);
    fromDate && url.searchParams.set('from_date', fromDate);
    toDate && url.searchParams.set('to_date', toDate);
    return url.toString();
  },
  INVOICE: (invoiceNumber) => `${API_URL}/billing/invoices/${invoiceNumber}/`,
  INVOICED_LINK: `${API_URL}/billing/invoiced_link/`,
  DESCRIBE_PARTS: ({
    parts,
    schemas,
  }: {
    parts: string[];
    schemas: PartSchemas[];
  }) => {
    const url = new URL(`${API_URL}/describe_parts/`);
    url.searchParams.set('schemas', String(schemas));
    url.searchParams.set('parts', String(parts));
    return url.toString();
  },
  PURCHASE_RULES: (purchaseRuleId) =>
    `${API_URL}/purchase_rules/${purchaseRuleId ? `${purchaseRuleId}/` : ''}`,
  BUG_REPORT: `${API_URL}/bug_report/`,
  PAIN_POINT: `${API_URL}/pain_point/`,
  JOBS_ASYNC: (id, keepJobData = false) =>
    `${API_URL}/jobs/${id}/${keepJobData ? '?status_only=true' : ''}`,
  PRODUCTION_RUN_PREVIEW_ALLOCATIONS: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/preview_allocations/`,
  PRODUCTION_RUN_ALLOCATIONS_FOR_PARTS: (productionRunId) =>
    `${API_URL}/production_runs/${productionRunId}/allocations/`,
  PART_RULE_SETS: `${API_URL}/part_rule_sets/`,
  METRONOME_USAGE: (fromDate, toDate) =>
    `${API_URL}/integrations/metronome/usage/?from_date=${fromDate}&to_date=${toDate}`,
  METRONOME_PLAN: `${API_URL}/integrations/metronome/plan/`,
  METRONOME_ENTITLEMENTS: `${API_URL}/integrations/metronome/entitlements/`,
  DOCUMENT_EXTRACTORS: `${API_URL}/document_extractors/`,
  GLOBAL_SEARCH: (search) => `${API_URL}/search/?q=${search}`,
  REPORTING_QUERY: `${API_URL}/reporting/query/`,
  REPORTING_SCHEMAS: `${API_URL}/reporting/schemas/`,
  PRISMATIC_JWT: `${API_URL}/integrations/prismatic/jwt/`,
  CUSTOMER_API_KEYS: `${API_URL}/customer_api_keys/`,
  TEAMS: `${API_URL}/teams/`,
  TEAM: (teamId) => `${API_URL}/teams/${teamId}/`,
  ARCHIVE_TEAM: (teamId) => `${API_URL}/teams/${teamId}/archive/`,
  UNARCHIVE_TEAM: (teamId) => `${API_URL}/teams/${teamId}/unarchive/`,
  TEAM_TYPES: `${API_URL}/team_types/`,
  TEAM_TYPE: (teamTypeId) => `${API_URL}/team_types/${teamTypeId}/`,
};

const API_URL_CLIENT_V2 = `${API_URL}/client`;

export const CLIENT_V2_URLS: ClientV2Urls = {
  SUPPLIER_BILLS: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bills/${query}`,
  SUPPLIER_BILL_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/supplier_bill_lines/${query}`,
  KIT_REQUESTS: (query = '') => `${API_URL_CLIENT_V2}/kit_requests/${query}`,
  KIT_REQUESTS_APPROVE: () => `${API_URL_CLIENT_V2}/kit_requests/approve/`,
  KIT_REQUESTS_UNAPPROVE: () => `${API_URL_CLIENT_V2}/kit_requests/unapprove/`,
  KIT_REQUESTS_ARCHIVE: () => `${API_URL_CLIENT_V2}/kit_requests/archive/`,
  KIT_REQUESTS_UNARCHIVE: () => `${API_URL_CLIENT_V2}/kit_requests/unarchive/`,
  KIT_REQUEST_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/kit_request_lines/${query}`,
  KIT_REQUEST_LINES_LOCK: () => `${API_URL_CLIENT_V2}/kit_request_lines/lock/`,
  KIT_REQUEST_LINES_UNLOCK: () =>
    `${API_URL_CLIENT_V2}/kit_request_lines/unlock/`,
  HANDLING_REQUESTS: (query = '') =>
    `${API_URL_CLIENT_V2}/handling_requests/${query}`,
  PICKED_HANDLING_REQUESTS: `${API_URL_CLIENT_V2}/handling_requests/picked/`,
  STOCK_EVENTS: (query = '') => `${API_URL_CLIENT_V2}/stock_events/${query}`,
  CANCEL_HANDLING_REQUEST: (handling_request_id) =>
    `${API_URL_CLIENT_V2}/handling_requests/${handling_request_id}/cancel/`,
  CANCEL_HANDLING_REQUESTS: `${API_URL_CLIENT_V2}/handling_requests/cancel/`,
  CREATE_SPLICE_COMBINE_HANDLING_REQUESTS: `${API_URL_CLIENT_V2}/handling_requests/create_splice_combine/`,
  CREATE_STOCK_EVENTS: `${API_URL_CLIENT_V2}/stock_lots/create_stock_events/`,
  CREATE_SPLIT_STOCK_EVENT: `${API_URL_CLIENT_V2}/stock_lots/create_split/`,
  CREATE_SPLICE_COMBINE_STOCK_EVENT: `${API_URL_CLIENT_V2}/stock_lots/create_splice_combine/`,
  STOCK_LOT: (id) => `${API_URL_CLIENT_V2}/stock_lots/${id}/`,
  RECEIVE_STOCK_LOT_BY_PURCHASE_LINE: (id) =>
    `${API_URL_CLIENT_V2}/stock_lots/${id}/receive_by_purchase_line/`,
  RECEIVE_STOCK_LOT_BY_SHIPMENT_LINE: (id) =>
    `${API_URL_CLIENT_V2}/stock_lots/${id}/receive_by_shipment_line/`,
  CREATE_RECEIVE_SHIPMENT_LINE: `${API_URL_CLIENT_V2}/stock/create_and_receive_shipment_line/`,
  ALLOCATIONS: (query = '') => `${API_URL_CLIENT_V2}/allocations/${query}`,
  SHIPMENTS: (query = '') => `${API_URL_CLIENT_V2}/shipments/${query}`,
  SHIPMENT_LINES: (query = '') =>
    `${API_URL_CLIENT_V2}/shipment_lines/${query}`,
  STOCK_LOCATIONS: (query = '') =>
    `${API_URL_CLIENT_V2}/stock_locations/${query}`,
};
