import {
  MarketAvailability,
  Part,
  PartMarket,
  historicalProcurabilityStatus,
  historicalProcurabilityStatusFormats,
} from 'types/part';
import { PartSchemas } from 'types/partStatus';
import { usePartContext } from 'views/parts/PartProvider';
import Chart, { Props as ChartProps } from 'react-apexcharts';
import { CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { formatToShortDate } from 'utils/dates';
import _ from 'lodash';
import spectraLogo from 'assets/images/Spectra_logo_horz.png';
import {
  formatHistoricalProcurability,
  getHistoricalProcurabilityStatus,
} from 'views/parts/utils';

export const marketAvailabilitiesFromPartData = (data: PartMarket[]) =>
  _.chain(data)
    .map('marketAvailabilities')
    .compact()
    .flatten()
    .groupBy('observedAt')
    .values()
    .map((a) => ({
      observedAt: a[0].observedAt,
      inventoryLevel: _.sum(_.map(a, 'inventoryLevel')),
    }))
    .value();

export const calcHistoricalProcurability = (
  marketAvailabilities: MarketAvailability[],
  targetQuantity: number
) =>
  _.chain(marketAvailabilities)
    .map('inventoryLevel')
    .filter((l) => l >= targetQuantity)
    .value().length / (marketAvailabilities || []).length;

export const PartsMarketDataGraph = ({
  partIds,
  targetQuantity = 5000,
  inlineMode = false,
  partMarketData,
}: {
  partIds: Part['id'][];
  targetQuantity?: number;
  inlineMode?: boolean;
  partMarketData?: PartMarket[];
}) => {
  const theme = useTheme();
  const { partData, isSuccessDescribePart, partDataIsLoading } =
    usePartContext();

  const marketAvailabilities = marketAvailabilitiesFromPartData(
    partMarketData ||
      _.chain(partIds)
        .map((id) => partData[id]?.[PartSchemas.market])
        .compact()
        .value()
  );

  // calculate historical procurability at targetQuantity
  const historicalProcurability = calcHistoricalProcurability(
    marketAvailabilities || [],
    targetQuantity
  );

  const currentHistoricalProcurabilityStatus = getHistoricalProcurabilityStatus(
    historicalProcurability
  );
  const historicalProcurabilityFormat =
    historicalProcurabilityStatusFormats[currentHistoricalProcurabilityStatus];
  const historicalProcurabilityColor = _.get(theme.palette, [
    historicalProcurabilityFormat.color,
    'main',
  ]);

  const lineChart: ChartProps = {
    type: 'area',
    options: {
      chart: {
        id: 'market-data-line-chart',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      annotations: {
        yaxis: [
          {
            y: targetQuantity,
            borderColor: historicalProcurabilityColor,
            label: inlineMode
              ? {}
              : {
                  borderColor: historicalProcurabilityColor,
                  style: {
                    color: '#fff',
                    background: historicalProcurabilityColor,
                  },
                  text: `${formatHistoricalProcurability(
                    historicalProcurability
                  )} Historical Procurability at ${targetQuantity.toLocaleString(
                    'en-US'
                  )}pc`,
                },
          },
        ],
      },
      colors: [
        currentHistoricalProcurabilityStatus ===
        historicalProcurabilityStatus.HIGH
          ? theme.palette.primary.main
          : historicalProcurabilityColor,
      ],
      stroke: {
        curve: 'stepline',
        width: 2,
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter(value: string) {
            return formatToShortDate(value) || '';
          },
        },
      },
      yaxis: {
        labels: {
          formatter(value: number) {
            return `${value.toLocaleString()}`;
          },
        },
      },
    },
    series: [
      {
        name: 'Market Availability',
        data: _.sortBy(marketAvailabilities || [], 'observedAt').map(
          (availability) => ({
            x: availability.observedAt,
            y: availability.inventoryLevel,
          })
        ),
      },
    ],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  const ChartContents = () => (
    <Grid item container spacing={gridSpacing}>
      <Grid container item xs={12}>
        {!partMarketData && partDataIsLoading[PartSchemas.market] && (
          <Skeleton variant="rectangular" width="100%" height={300} />
        )}
        {(!!partMarketData ||
          (isSuccessDescribePart &&
            !partDataIsLoading[PartSchemas.market])) && (
          <div style={{ width: '100%', height: 300 }}>
            <Chart {...lineChart} width="100%" height="100%" />
          </div>
        )}
      </Grid>
      <Grid
        container
        item
        justifyContent="flex-end"
        alignItems="center"
        xs={12}
        columnSpacing={actionButtonSpacing}
      >
        <Grid item xs="auto">
          <Typography variant="caption">Powered By</Typography>
        </Grid>
        <Grid item xs="auto">
          <img
            src={spectraLogo}
            alt="Altium Spectra Logo"
            width="100"
            height="24"
            style={{
              objectFit: 'contain',
              maxWidth: 'none',
              height: '24px',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  if (inlineMode)
    return (
      <Grid container spacing={gridSpacing}>
        <ChartContents />
      </Grid>
    );

  return (
    <MainCard content={false}>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <Typography variant="h4">
                  12-Month Historical Market Availability
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth />
              <Grid item>
                <Typography variant="h5" color={historicalProcurabilityColor}>
                  {`${formatHistoricalProcurability(
                    historicalProcurability
                  )} Historical Procurability at ${targetQuantity.toLocaleString(
                    'en-US'
                  )}pc`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <ChartContents />
        </Grid>
      </CardContent>
    </MainCard>
  );
};
