import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetOrgUserQuery } from 'store/slices/org';
import { User } from 'types/user';
import UserInfoSection from 'ui-component/UserInfoSection';

const UserInfoSectionWithLookup = ({
  userId,
  subtitle,
  automation,
}: {
  userId: User['id'] | null;
  subtitle?: string;
  automation?: boolean;
}) => {
  const { data: user } = useGetOrgUserQuery(userId ? { userId } : skipToken);
  if (!user && !automation) return <></>;
  return (
    <UserInfoSection user={user} subtitle={subtitle} automation={automation} />
  );
};

export function RenderUserLookupCell(
  userId: User['id'] | null,
  subtitle?: string,
  automation?: boolean
) {
  return (
    <UserInfoSectionWithLookup
      userId={userId}
      subtitle={subtitle}
      automation={automation}
    />
  );
}
