import _ from 'lodash';
import { snakeCase } from 'utils/functions';
import {
  QueryParams,
  OperatorMap,
  NullOperatorValues,
  OrderDirectionValues,
  FilterLinkOperatorsValues,
  FieldFilter,
  ALL,
  ListResponse,
} from 'types/api';
import { GenericObject } from 'types';

export function isNullOperator(operator: string) {
  return Object.keys(NullOperatorValues).includes(operator);
}

export function isFilterLinkOperator<T extends GenericObject>(
  filter: FieldFilter<T> | keyof typeof FilterLinkOperatorsValues
) {
  return Object.keys(FilterLinkOperatorsValues).includes(filter as string);
}

export function isFieldFilter<T extends GenericObject>(
  filter: FieldFilter<T> | keyof typeof FilterLinkOperatorsValues
): filter is FieldFilter<T> {
  return (filter as FieldFilter<T>).field !== undefined;
}

export function getNullOperatorValue(operator: string) {
  return NullOperatorValues[operator as keyof typeof NullOperatorValues];
}

export function getOperator(operator: string) {
  return OperatorMap[operator as keyof typeof OperatorMap];
}

export function parseNestedField(field: string | symbol | number) {
  return field
    .toString()
    .split('.')
    .reduce(
      (acc, fieldPart, index) =>
        `${acc}${index !== 0 ? '__' : ''}${_.snakeCase(fieldPart)}`,
      ''
    );
}

export function getFilterExpression<T extends GenericObject>(
  filterItem: FieldFilter<T>
) {
  const { field, operator, value } = filterItem;
  return `${parseNestedField(field)}${getOperator(operator)}:${
    isNullOperator(operator) ? getNullOperatorValue(operator) : getValue(value)
  }`;
}

export function getFilterLinkOperator(
  operator: keyof typeof FilterLinkOperatorsValues
) {
  return FilterLinkOperatorsValues[operator];
}

export function getValue(value: any) {
  if (Array.isArray(value)) {
    return value.join(';');
  }
  return value;
}

export function getFilterParam<T extends GenericObject>(
  filters: QueryParams<T>['filters'] | QueryParams<T>['excludes']
) {
  return (
    filters
      // item is either a Filter or a Link operator, or undefined and not a null operator
      ?.filter(
        (item) =>
          isFilterLinkOperator(item) ||
          (isFieldFilter(item) &&
            (!_.isNull(item.value) || isNullOperator(item.operator)) &&
            (isNullOperator(item.operator) || !_.isUndefined(item.value)) &&
            (_.isArray(item.value) ? item.value.length > 0 : true))
      )
      ?.reduce((acc: any, item: any, index: number, originalArray) => {
        if (isFilterLinkOperator(item)) {
          // if the item is a link operator, update the previous element in the array with its character
          acc[acc.length - 1] = `${acc[acc.length - 1]}${getFilterLinkOperator(
            item
          )}`;
          return acc;
        }

        if (acc.length === 0) {
          acc.push(getFilterExpression<T>(item));
          return acc;
        }
        // if the item is a filter, and the previous item is a link operator, update the previous element in the array with the filter expression
        if (
          originalArray[index - 1] &&
          isFilterLinkOperator(originalArray[index - 1])
        ) {
          acc[acc.length - 1] = `${acc[acc.length - 1]}${getFilterExpression<T>(
            item
          )}`;
          return acc;
        }

        // if the item is a filter, and the previous item is not a link operator, add the filter expression to the array
        acc.push(getFilterExpression<T>(item));
        return acc;
      }, [])
      ?.join(',')
  );
}

// TODO: properly type this
export function buildSchemaString(subSchema: any) {
  return subSchema
    .map((item: any) => {
      if (typeof item === 'string' && _.includes(item, 'custom_properties')) {
        return `custom_properties__${_.chain(item)
          .replace('custom_properties__', '')
          .snakeCase()
          .value()}`;
      }
      if (typeof item === 'string') {
        return item === ALL ? item : _.snakeCase(item);
      }
      return Object.keys(item)
        .map((key) => `${_.snakeCase(key)}{${buildSchemaString(item[key])}}`)
        .join(',');
    })
    .join(',');
}

export function getSchemaParam<T extends GenericObject>(
  schema: QueryParams<T>['schema']
) {
  if (!schema) {
    return 'schema=__all__';
  }

  if (typeof schema === 'string') {
    return `schema=${schema}`;
  }

  return `schema=${buildSchemaString(schema)}`;
}

export function getSearchSchemaParam<T extends GenericObject>(
  schema: QueryParams<T>['searchSchema']
) {
  if (!schema) {
    return 'search_schema=__all__';
  }

  if (typeof schema === 'string') {
    return `search_schema=${schema}`;
  }

  return `search_schema=${buildSchemaString(schema)}`;
}

// TODO: probably handle negative page number
export function getPageNumberParam<T extends GenericObject>(
  pageNumber: QueryParams<T>['pageNumber']
) {
  return `page_number=${pageNumber}`;
}

// TODO: probably handle negative page size
export function getPageSizeParam<T extends GenericObject>(
  pageSize: QueryParams<T>['pageSize']
) {
  return `page_size=${pageSize}`;
}

export function getSortParam<T extends GenericObject>(
  sort: QueryParams<T>['sort']
) {
  return `order_by=${sort?.map(
    (item) =>
      `${
        OrderDirectionValues[
          item.orderDirection as keyof typeof OrderDirectionValues
        ]
      }${parseNestedField(item.orderBy)}`
  )}`;
}

export function getNoCacheParam<T extends GenericObject>(
  noCache: QueryParams<T>['noCache']
) {
  return noCache ? 'no_cache' : '';
}

export function getNewVersionParam<T extends GenericObject>(
  newVersion: QueryParams<T>['newVersion']
) {
  return newVersion ? 'new' : '';
}

export function getExportParam<T extends GenericObject>(
  exportBlob: QueryParams<T>['exportBlob']
) {
  return exportBlob ? 'export' : '';
}

export function parseQueryParams<T extends GenericObject>({
  pageSize,
  pageNumber = 1,
  sort,
  filters,
  excludes,
  schema,
  searchSchema,
  search,
  noCache = false,
  newVersion = false,
  exportBlob = false,
}: QueryParams<T>) {
  const queryParams = [];
  if (pageSize !== undefined) {
    queryParams.push(getPageSizeParam<T>(pageSize));
  }
  if (pageNumber) {
    queryParams.push(getPageNumberParam<T>(pageNumber));
  }
  if (sort && sort.length > 0) {
    queryParams.push(getSortParam<T>(sort));
  }
  if (excludes && excludes?.length > 0) {
    queryParams.push(
      `excludes=${getFilterParam<T>(
        snakeCase(excludes) as QueryParams<T>['excludes']
      )}`
    );
  }
  if (filters && filters?.length > 0) {
    queryParams.push(
      `filters=${getFilterParam<T>(
        snakeCase(filters) as QueryParams<T>['filters']
      )}`
    );
  }
  if (searchSchema) {
    queryParams.push(getSearchSchemaParam<T>(searchSchema));
  }
  if (search) {
    queryParams.push(`search=${encodeURIComponent(search)}`);
  }
  if (noCache) {
    queryParams.push(getNoCacheParam<T>(noCache));
  }
  if (newVersion) {
    queryParams.push(getNewVersionParam<T>(newVersion));
  }
  if (exportBlob) {
    queryParams.push(getExportParam<T>(exportBlob));
  }

  queryParams.push(getSchemaParam<T>(schema));

  return `?${queryParams.join('&')}`;
}

export const transformResponseToFallbackNullOrgPartToPart = <
  T extends GenericObject
>(
  response: ListResponse<T>
) =>
  ({
    ...response,
    data: _.map(response.data, (item) => {
      if (!item.orgPart && item.part) {
        return { ...item, orgPart: item.part };
      }
      return item;
    }),
  } as ListResponse<T>);
