import * as yup from 'yup';

export const formConstants = {
  name: { id: 'name', label: 'Name' },
  referenceNumber: { id: 'referenceNumber', label: 'Reference Number' },
  productionDate: { id: 'productionDate', label: 'Production Date' },
  notes: { id: 'notes', label: 'Notes' },
  productionLocation: {
    id: 'productionLocation',
    label: 'Production Location',
  },
  limitToFacilities: {
    id: 'limitToFacilities',
    label: 'Source Stock From Locations',
  },
  defaultShippingMethod: {
    id: 'defaultShippingMethod',
    label: 'Default Shipping Method',
  },
  defaultShippingCarrier: 'defaultShippingCarrier',
  defaultShippingService: 'defaultShippingService',
  quant: { id: 'quant', label: 'Quantity of BOM' },
  team: { id: 'team', label: 'Team' },
  addOverage: {
    id: 'addOverage',
    label: 'Add Overages for Assembly Attrition',
  },
  addOveragePerBom: { id: 'addOveragePerBom', label: 'Add Overages Per BOM' },
  addMultiKitOverage: {
    id: 'addMultiKitOverage',
    label: 'Add Overages for Multiple Kits',
  },
};

export const validationSchema = yup.object({
  [formConstants.name.id]: yup.string().required('Name is required'),
  [formConstants.referenceNumber.id]: yup.string(),
  [formConstants.productionDate.id]: yup
    .date()
    .nullable()
    .typeError('Production Date must be a valid date'),
  [formConstants.notes.id]: yup.string(),
  [formConstants.productionLocation.id]: yup
    .string()
    .required('Production location is required'),
  [formConstants.limitToFacilities.id]: yup.array().nullable(),
  [formConstants.defaultShippingMethod.id]: yup.string(),
  [formConstants.quant.id]: yup
    .number()
    .integer('Quantity must be an integer')
    .moreThan(0, 'Quantity must be greater than 0'),
  [formConstants.team.id]: yup.string(),
});
