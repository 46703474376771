import {
  ALL_APP_IDS,
  ALL_APPS,
  ALL_VIEW_IDS,
  ALL_VIEWS,
} from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Redirect } from 'react-router6-redirect';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';
import Kits from 'views/kitting/Kits';
import Kit from 'views/kitting/Kit/KitRequestWrapper';

const InventoryShipments = Loadable(
  lazy(() => import('views/inventory/InventoryShipments'))
);
const InventoryShipment = Loadable(
  lazy(() => import('views/inventory/InventoryShipment'))
);
const InventoryAddresses = Loadable(
  lazy(() => import('views/kitting/Addresses'))
);
const ShipmentPreferences = Loadable(
  lazy(() => import('views/inventory/ShippingPreferences'))
);

export const KittingRoutes = [
  {
    path: `${ALL_VIEWS.SHIPMENTS.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.SHIPMENTS.path}`} />,
  },
  {
    path: `${ALL_APPS.KITTING.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.KITTING.id,
          }}
        >
          <Navigate to={`${ALL_VIEWS.SHIPMENTS.path}`} replace />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.SHIPMENTS.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.KITTING.id,
            view: ALL_VIEW_IDS.SHIPMENTS,
          }}
        >
          <InventoryShipments />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.SHIPMENTS.deprecatedPath}/:shipmentId`,
    element: <Redirect to={`${ALL_VIEWS.SHIPMENTS.path}/:shipmentId`} />,
  },
  {
    path: `${ALL_VIEWS.SHIPMENTS.path}/:shipmentId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.KITTING.id,
          view: ALL_VIEW_IDS.SHIPMENTS,
        }}
      >
        <InventoryShipment />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.ADDRESSES.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.ADDRESSES.path}`} />,
  },
  {
    path: `${ALL_VIEWS.ADDRESSES.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.KITTING.id,
          view: ALL_VIEW_IDS.ADDRESSES,
        }}
      >
        <InventoryAddresses />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.KITTING.views?.KITTING_PREFERENCES?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.KITTING.id,
          view: ALL_VIEW_IDS.KITTING_PREFERENCES,
        }}
      >
        <ShipmentPreferences />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: ALL_VIEWS.KITS.path as string,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APP_IDS.KITTING,
          view: ALL_VIEW_IDS.KITS,
        }}
      >
        <FullHeightMainCardWrapper>
          <Kits />
        </FullHeightMainCardWrapper>
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.KITS.path}/:kitRequestId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APP_IDS.KITTING,
          view: ALL_VIEW_IDS.KITS,
        }}
      >
        <Kit />
      </PermissionsRouteWrapper>
    ),
  },
];
