import { Grid } from '@mui/material';
import blank from 'assets/images/blank.png';
import Avatar from 'ui-component/extended/Avatar';
import Chip from 'ui-component/extended/Chip';
import { statusColorCallback } from 'ui-component/DataGrid/GridColTypeDef';
import { statusTextMapper } from 'constants/datagrid';
import { FC } from 'react';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import { IconCpu } from '@tabler/icons-react';
import HeroSection from 'ui-component/Hero/HeroSection';
import { PermissionScope } from 'types/apps';

export type TextDetails = {
  value?: string;
  clipboard?: string;
  clipboardValue?: string;
  children?: React.ReactNode;
  editInline?: React.ReactElement;
  disabled?: boolean;
  tooltip?: string;
};

interface HeroProps {
  isLoading: boolean;
  options: {
    top?: TextDetails;
    middle?: TextDetails;
    bottom?: TextDetails;
    image?: string;
    status?: string;
  };
  permissionScope?: PermissionScope;
}

const Hero: FC<HeroProps> = ({ isLoading, options, permissionScope }) => (
  <>
    {'image' in options && (
      <Grid item xs="auto">
        <WithSkeleton isLoading={isLoading}>
          <Avatar
            src={options?.image || blank}
            size="xl"
            variant="rounded"
            alt="Product image"
            sx={{}}
          >
            <IconCpu />
          </Avatar>
        </WithSkeleton>
      </Grid>
    )}
    <Grid item xs>
      {'status' in options && (
        <WithSkeleton isLoading={isLoading} width={100}>
          <Chip
            label={statusTextMapper[options?.status as string]}
            chipcolor={statusColorCallback(
              statusTextMapper[options?.status as string]
            )}
            style={{ textTransform: 'uppercase' }}
            sx={{ mb: 2 }}
          />
        </WithSkeleton>
      )}
      {'top' in options && (
        <HeroSection
          isLoading={isLoading}
          width={100}
          variant="body1"
          permissionScope={permissionScope}
          {...options.top}
        />
      )}
      {'middle' in options && (
        <HeroSection
          isLoading={isLoading}
          width={150}
          sx={!options?.middle?.clipboard ? { mb: 1 } : {}}
          variant="h3"
          permissionScope={permissionScope}
          {...options.middle}
        />
      )}
      {'bottom' in options && (
        <HeroSection
          isLoading={isLoading}
          width={100}
          variant="body1"
          permissionScope={permissionScope}
          {...options.bottom}
        />
      )}
    </Grid>
  </>
);
export default Hero;
