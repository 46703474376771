import { CLIENT_V2_URLS } from 'store/constant';
import { apiSlice } from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  ClientV2PATCHRequest,
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ListResponse,
  QueryParams,
} from 'types/api';
import { ShipmentLineSchema, ShipmentSchema } from 'types/clientV2/shipments';

// NOTE: Non GET endpoints are implemented but may not be fully fleshed out this is fine and will be updated as needed with flagship
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShipments: builder.query<
      ListResponse<ShipmentSchema>,
      QueryParams<ShipmentSchema>
    >({
      query: (params) =>
        CLIENT_V2_URLS.SHIPMENTS(parseQueryParams<ShipmentSchema>(params)),
    }),
    createShipments: builder.mutation<
      ClientV2POSTResponse<ShipmentSchema>,
      ClientV2POSTRequest<ShipmentSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENTS(),
        method: 'POST',
        body: data,
      }),
    }),
    updateShipments: builder.mutation<
      ClientV2PATCHResponse<ShipmentSchema>,
      ClientV2PATCHRequest<ShipmentSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENTS(),
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteShipments: builder.mutation<
      ClientV2DELETEResponse<ShipmentSchema>,
      ClientV2DELETERequest<ShipmentSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENTS(),
        method: 'DELETE',
        body: data,
      }),
    }),
    getShipmentLines: builder.query<
      ListResponse<ShipmentLineSchema>,
      QueryParams<ShipmentLineSchema>
    >({
      query: (params) =>
        CLIENT_V2_URLS.SHIPMENT_LINES(
          parseQueryParams<ShipmentLineSchema>(params)
        ),
    }),
    createShipmentLines: builder.mutation<
      ClientV2POSTResponse<ShipmentLineSchema>,
      ClientV2POSTRequest<ShipmentLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENT_LINES(),
        method: 'POST',
        body: data,
      }),
    }),
    updateShipmentLines: builder.mutation<
      ClientV2PATCHResponse<ShipmentLineSchema>,
      ClientV2PATCHRequest<ShipmentLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENT_LINES(),
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteShipmentLines: builder.mutation<
      ClientV2DELETEResponse<ShipmentLineSchema>,
      ClientV2DELETERequest<ShipmentLineSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.SHIPMENT_LINES(),
        method: 'DELETE',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetShipmentLinesQuery,
  useCreateShipmentLinesMutation,
  useUpdateShipmentLinesMutation,
  useDeleteShipmentLinesMutation,
  useGetShipmentsQuery,
  useCreateShipmentsMutation,
  useUpdateShipmentsMutation,
  useDeleteShipmentsMutation,
} = extendedApiSlice;
