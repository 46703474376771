import { ALL_APPS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const Users = Loadable(lazy(() => import('views/org/Users')));

export const UsersRoutes = [
  {
    path: `${ALL_APPS.USERS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.USERS.id }}>
          <Users />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
