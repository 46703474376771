import { useState } from 'react';

/**
 * Manages the state of opening/closing multiple dialogs.
 * @param dialogNames - An array of dialog names. Use an enum to define the dialog names.
 * @returns An object with functions to open, close, and toggle dialogs, plus the name of the current dialog.
 * @example
 * enum DialogNames {
 *    create = 'create',
 *    update = 'update',
 *    archive = 'archive',
 *    }
 *
 * const { openDialog, closeDialog, toggleDialog, currentDialog } = useDialogManager(Object.values(DialogNames));
 */
export const useDialogManager = <T extends string>(dialogNames: T[]) => {
  const [currentDialog, setCurrentDialog] = useState<T | null>(null);

  const openDialog = (dialogName: T) => {
    if (dialogNames.includes(dialogName)) {
      setCurrentDialog(dialogName);
    }
  };

  const closeDialog = () => setCurrentDialog(null);

  const toggleDialog = (dialogName: T) => {
    if (currentDialog === dialogName) {
      closeDialog();
    } else {
      openDialog(dialogName);
    }
  };

  const isDialogOpen = (dialogName: T) => currentDialog === dialogName;

  return { openDialog, closeDialog, toggleDialog, currentDialog, isDialogOpen };
};
