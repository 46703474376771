import { FC, useContext } from 'react';
import InfoSectionItem, {
  InfoSectionItemProps,
} from 'ui-component/InfoSectionItem';
import { DrawerContext } from '..';
import { Icon as IconType } from '@tabler/icons-react';
import { Badge, Box } from '@mui/material';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { ColorOptions } from 'types';

export interface SidebarInfoSectionProps extends InfoSectionItemProps {
  count?: number;
  Icon?: IconType;
  color?: ColorOptions;
  visible?: boolean;
}

const SidebarInfoSection: FC<SidebarInfoSectionProps> = ({
  Icon,
  count,
  color = 'primary',
  visible = true,
  ...rest
}) => {
  const theme = useTheme();
  const open = useContext(DrawerContext);

  if (!visible) {
    return <></>;
  }

  if (!open && !!Icon && !!count) {
    return (
      <Box sx={{ pt: 1 }}>
        <Badge badgeContent={count} color="primary" max={99}>
          <Icon size={24} color={_.get(theme.palette, [color, 'main'])} />
        </Badge>
      </Box>
    );
  }
  if (!open) {
    return <></>;
  }
  return <InfoSectionItem {...rest} />;
};

export default SidebarInfoSection;
