import { apiSlice } from 'store/slices/api';
import { HOST_URL } from 'constants/envConstants';
import { URLS } from 'store/constant';
import { CreateBugReport, CreatePainPoint, LinearTicket } from 'types/utility';
import { AsyncJobResponse, AsyncJobStatus } from 'types/api';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { extendedApiSlice as bomExtendedApiSlice } from 'store/slices/bom';
import { SnackbarDispatchType } from 'hooks/useSnackbar';

const sliceMap: {
  [key: string]: any;
} = {
  bom: bomExtendedApiSlice,
  // Add other slices here
};

type UpdateStore = {
  slice: string;
  endpointName: string;
  args: any;
};

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVersion: builder.query({
      query: () => ({
        url: `${HOST_URL}/appconfig.json`,
      }),
      providesTags: (response) => [{ type: 'Version', id: response?.version }],
    }),
    createBugReport: builder.mutation<LinearTicket, CreateBugReport>({
      query: (body) => ({
        url: URLS.BUG_REPORT,
        method: 'POST',
        body,
      }),
    }),
    createPainPoint: builder.mutation<LinearTicket, CreatePainPoint>({
      query: (body) => ({
        url: URLS.PAIN_POINT,
        method: 'POST',
        body,
      }),
    }),
    getJob: builder.query<
      AsyncJobResponse<any>,
      {
        jobId: string;
        updateStore?: UpdateStore;
      }
    >({
      query: ({ jobId }) => URLS.JOBS_ASYNC(jobId),
      async onQueryStarted(
        { jobId, updateStore },
        { dispatch, queryFulfilled }
      ) {
        if (updateStore) {
          const { slice, endpointName, args } = updateStore;
          const { data: jobResponse } = await queryFulfilled;
          if (jobResponse.status === AsyncJobStatus.done) {
            dispatch(
              sliceMap[slice].util.updateQueryData(
                endpointName,
                args,
                () => jobResponse.data
              )
            );
          }
        }
      },
    }),
    getJobStatus: builder.query<
      Pick<AsyncJobResponse<any>, 'jobId' | 'status'>,
      {
        jobId: string;
      }
    >({
      query: ({ jobId }) => URLS.JOBS_ASYNC(jobId, true),
    }),
    getPrismaticJWT: builder.query<{ token: string }, void>({
      query: () => ({
        url: URLS.PRISMATIC_JWT,
      }),
    }),
    getCustomerApiKey: builder.mutation<
      {
        id: string;
        key: string;
      },
      {
        name: string;
        keyType: string;
        integrationId: string;
      }
    >({
      query: (body) => ({
        url: URLS.CUSTOMER_API_KEYS,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export type ReduxJob = {
  jobId: string;
  resolve?: (value: any) => void;
  reject?: (error: any) => void;
  options: {
    onCompleteSnackbar?: SnackbarDispatchType;
    onErrorSnackbar?: SnackbarDispatchType['message'];
    retrieveDataOnCompletion: boolean;
    updateStore?: UpdateStore;
    delayBeforePollJobStatus?: number;
    onSuccess?: () => void;
  };
};

export const initialStateUtility = {
  version: 'unset',
  jobs: {} as {
    [key: string]: ReduxJob;
  },
  showNoPlanBanner: false,
};

const utility = createSlice({
  name: 'utility',
  initialState: initialStateUtility,
  reducers: {
    updateVersion(state, action) {
      state.version = action.payload;
    },
    addJob(state, action) {
      state.jobs[action.payload.jobId] = action.payload;
    },
    removeJob(state, action: PayloadAction<string>) {
      delete state.jobs[action.payload];
    },
    setShowNoPlanBanner(state, action) {
      state.showNoPlanBanner = action.payload;
    },
  },
});

export const {
  useGetVersionQuery,
  useCreateBugReportMutation,
  useCreatePainPointMutation,
  useGetJobQuery,
  useGetJobStatusQuery,
  useLazyGetJobQuery,
  useLazyGetJobStatusQuery,
  useGetPrismaticJWTQuery,
  useLazyGetPrismaticJWTQuery,
  useGetCustomerApiKeyMutation,
} = extendedApiSlice;

export const { updateVersion, addJob, removeJob, setShowNoPlanBanner } =
  utility.actions;

export default utility.reducer;
