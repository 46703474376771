import { URLS } from 'store/constant';
import { apiSlice } from 'store/slices/api';

import {
  AvailabilitiesRequest,
  AvailabilitiesReturnV2,
  Classification,
  CreateAvailabilitiesSnapshotRequest,
  CreateCustomPartRequest,
  ExpandedAlternativePart,
  Manufacturer,
  OrgPart,
  Part,
  PartOffer,
  PartQuote,
  PartQuoteCreateUpdate,
  Solicitation,
  SolicitationCreate,
  UpdatePartRequest,
  WatchlistSubscription,
  WatchlistSubscriptionCreate,
  WatchlistSubscriptionRequest,
  WatchlistSubscriptionsRequest,
  WatchlistSubscriptionUpdate,
} from 'types/part';
import { Availabilities, Snapshot } from 'types/purchasing';
import { Org } from 'types/org';
import { ProductionRun } from 'types/production';
import { AsyncJobResponse, ListResponse, QueryParams } from 'types/api';
import { parseQueryParams } from 'store/slices/utils';
import { createOrUpdatePartsStatus } from 'store/slices/partStatus';
import { PartSchemas, partStatusRequestTypes } from 'types/partStatus';
import _ from 'lodash';
import { updatePartCoreFields } from 'store/slices/partData';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParts: builder.query<
      Part[],
      {
        query?: string;
        ids?: string[];
        mpnMatch?: boolean;
        overrideOrgId?: Org['id'];
      }
    >({
      query: ({ query, ids, mpnMatch, overrideOrgId }) => ({
        url: URLS.PARTS({ query, ids: ids?.join(','), mpnMatch }),
        method: 'GET',
        headers: {
          'x-org-id': overrideOrgId,
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }: Part) => ({ type: 'Part', id } as const)),
              { type: 'Part', id: 'LIST' },
            ]
          : [{ type: 'Part', id: 'LIST' }],
    }),
    getPart: builder.query<Part, Part['id']>({
      query: (partId) => URLS.PART(partId, false, false),
      providesTags: (result) =>
        result
          ? [{ type: 'Part', id: result.id }]
          : [{ type: 'Part', id: 'LIST' }],
    }),
    getPartAlternatives: builder.query<
      ExpandedAlternativePart[],
      { partId: Part['id'] }
    >({
      query: ({ partId }) => URLS.PART_ALTERNATIVES(partId, true, true),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ part }: ExpandedAlternativePart) =>
                  ({ type: 'AlternativePart', part } as const)
              ),
              { type: 'AlternativePart', id: 'LIST' },
            ]
          : [{ type: 'AlternativePart', id: 'LIST' }],
    }),
    getCachedPart: builder.query<Part, Part['id']>({
      query: (partId) => URLS.PART(partId, true, false),
      providesTags: (result) =>
        result
          ? [{ type: 'Part', id: result.id }]
          : [{ type: 'Part', id: 'LIST' }],
    }),
    forcePartRefresh: builder.mutation<Part, Part['id']>({
      query: (partId) => ({
        url: URLS.PART(partId, false, true),
        method: 'GET',
      }),
      invalidatesTags: (result, error, partId) => [
        { type: 'Part', id: partId },
        { type: 'PartOffer', id: partId },
      ],
    }),
    getPartQuotes: builder.query<
      PartQuote[],
      {
        orgId: Org['id'];
        partId?: Part['id'];
      }
    >({
      query: ({ orgId, partId }) => URLS.PART_QUOTES(orgId, partId || null),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: PartQuote) => ({ type: 'PartQuote', id } as const)
              ),
              { type: 'PartQuote', id: 'LIST' },
            ]
          : [{ type: 'PartQuote', id: 'LIST' }],
    }),
    createPartQuote: builder.mutation<
      PartQuote[],
      {
        payload: PartQuoteCreateUpdate[];
      }
    >({
      query: ({ payload }) => ({
        url: URLS.PART_QUOTES(null, null),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              ...result
                .filter(({ solicitation }) => solicitation)
                .map(
                  ({ solicitation }: PartQuote) =>
                    ({ type: 'Solicitation', id: solicitation?.id } as const)
                ),
              { type: 'PartQuote', id: 'LIST' },
            ]
          : [
              { type: 'Solicitation', id: 'LIST' },
              { type: 'PartQuote', id: 'LIST' },
            ],
    }),
    updatePartQuote: builder.mutation<
      PartQuote[],
      { id: PartQuote['id']; payload: PartQuoteCreateUpdate }
    >({
      query: ({ id, payload }) => ({
        url: URLS.PART_QUOTE(id),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'PartQuote', id },
        { type: 'PartQuote', id: 'LIST' },
        { type: 'Solicitation', id: 'LIST' },
      ],
    }),
    getPartQuote: builder.query<PartQuote, PartQuote['id']>({
      query: (partQuoteId) => URLS.PART_QUOTE(partQuoteId),
      providesTags: (result) =>
        result
          ? [{ type: 'PartQuote', id: result.id }]
          : [{ type: 'PartQuote', id: 'LIST' }],
    }),
    forcePartQuoteRefresh: builder.mutation<PartQuote, { id: PartQuote['id'] }>(
      {
        query: ({ id }) => ({
          url: URLS.PART_QUOTES_REFRESH(id),
          method: 'POST',
        }),
        invalidatesTags: (result, error, { id }) => [{ type: 'PartQuote', id }],
      }
    ),
    rejectPartQuote: builder.mutation({
      query: ({ id }) => ({
        url: URLS.PART_QUOTE_REJECT(id),
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'Solicitation', id: 'LIST' }],
    }),
    getPartOffers: builder.query<PartOffer[], Part['id']>({
      // PartOffers don't have IDs lol
      query: (partId) => URLS.PART_OFFERS(partId),
      providesTags: (_result, _error, partId) => [
        { type: 'PartOffer', id: partId },
      ],
    }),
    getSolicitations: builder.query<
      Solicitation[],
      {
        partId?: Part['id'];
        productionRunId?: ProductionRun['id'] | null;
        expand?: boolean;
      }
    >({
      query: ({ partId = null, productionRunId = null, expand = true }) =>
        URLS.SOLICITATIONS({
          partId,
          productionRunId,
          expand,
        }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: Solicitation) =>
                  ({ type: 'Solicitation', id } as const)
              ),
              { type: 'Solicitation', id: 'LIST' },
            ]
          : [{ type: 'Solicitation', id: 'LIST' }],
    }),
    getSolicitation: builder.query<Solicitation, Solicitation['id']>({
      query: (solicitationId) => URLS.SOLICITATION(solicitationId),
      providesTags: (result) =>
        result
          ? [{ type: 'Solicitation', id: result.id }]
          : [{ type: 'Solicitation', id: 'LIST' }],
    }),
    createSolicitation: builder.mutation<Solicitation, SolicitationCreate>({
      query: (payload) => ({
        url: URLS.SOLICITATIONS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Solicitation', id: 'LIST' }],
    }),
    createBulkSolicitations: builder.mutation<
      Solicitation[],
      SolicitationCreate[]
    >({
      query: (payload) => ({
        url: URLS.SOLICITATIONS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Solicitation', id: 'LIST' }],
    }),
    updateBatchSolicitation: builder.mutation<
      Solicitation[],
      SolicitationCreate[]
    >({
      query: (payload) => ({
        url: URLS.SOLICITATIONS({}),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result) => [
        ...(result
          ? result.map(({ id }: Solicitation) => ({
              type: 'Solicitation' as 'Solicitation',
              id,
            }))
          : []),
      ],
    }),
    cancelSolicitation: builder.mutation<
      string,
      {
        id: Solicitation['id'];
      }
    >({
      query: ({ id }) => ({
        url: URLS.SOLICITATION_CANCEL(id),
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Solicitation', id: 'LIST' }],
    }),
    getAvailabilities: builder.query<Availabilities, AvailabilitiesRequest>({
      query: (payload) => ({
        url: URLS.AVAILABILITIES,
        method: 'POST',
        body: payload,
      }),
      providesTags: [{ type: 'Availabilities', id: 'LIST' }],
    }),
    getAvailabilitiesV2: builder.query<
      AvailabilitiesReturnV2,
      AvailabilitiesRequest
    >({
      query: (payload) => ({
        url: URLS.AVAILABILITIES_V2,
        method: 'POST',
        body: payload,
      }),
      providesTags: [{ type: 'Availabilities', id: 'LIST' }],
    }),
    getAvailabilitiesAsync: builder.query<
      AsyncJobResponse<null>,
      AvailabilitiesRequest
    >({
      query: (payload) => ({
        url: URLS.AVAILABILITIES_ASYNC,
        method: 'POST',
        body: payload,
      }),
    }),
    createAvailabilitiesSnapshot: builder.mutation<
      Snapshot,
      CreateAvailabilitiesSnapshotRequest
    >({
      query: (payload) => ({
        url: URLS.AVAILABILITIES_SNAPSHOT,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Solicitation', id: 'LIST' }],
    }),
    createWatchlistSubscription: builder.mutation<
      WatchlistSubscription,
      WatchlistSubscriptionCreate
    >({
      query: (payload) => ({
        url: URLS.WATCHLIST_SUBSCRIPTIONS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'WatchlistSubscriptions', id: 'LIST' }],
    }),
    editWatchlistSubscription: builder.mutation<
      WatchlistSubscription,
      {
        watchlistSubscriptionId: WatchlistSubscription['id'];
        payload: WatchlistSubscriptionUpdate;
      }
    >({
      query: ({ watchlistSubscriptionId, payload }) => ({
        url: URLS.WATCHLIST_SUBSCRIPTIONS({ watchlistSubscriptionId }),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { watchlistSubscriptionId }) => [
        {
          type: 'WatchlistSubscriptions',
          id: watchlistSubscriptionId,
        },
      ],
    }),
    deleteWatchlistSubscription: builder.mutation<
      string,
      WatchlistSubscription['id']
    >({
      query: (watchlistSubscriptionId) => ({
        url: URLS.WATCHLIST_SUBSCRIPTIONS({ watchlistSubscriptionId }),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, watchlistSubscriptionId) => [
        {
          type: 'WatchlistSubscriptions',
          id: watchlistSubscriptionId,
        },
      ],
    }),
    // get multiple subscriptions, either for entire org,
    // or for single part if partId is provided
    getWatchlistSubscriptions: builder.query<
      WatchlistSubscription[],
      WatchlistSubscriptionsRequest
    >({
      query: ({ partId, expand }) =>
        URLS.WATCHLIST_SUBSCRIPTIONS({ partId, expand }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: WatchlistSubscription) =>
                  ({ type: 'WatchlistSubscriptions', id } as const)
              ),
              { type: 'WatchlistSubscriptions', id: 'LIST' },
            ]
          : [{ type: 'WatchlistSubscriptions', id: 'LIST' }],
    }),
    // get single watchlist subscription based on watchlistSubscriptionId
    getWatchlistSubscription: builder.query<
      WatchlistSubscription,
      WatchlistSubscriptionRequest
    >({
      query: ({ watchlistSubscriptionId, expand }) =>
        URLS.WATCHLIST_SUBSCRIPTIONS({ watchlistSubscriptionId, expand }),
      providesTags: (result) =>
        result
          ? [{ type: 'WatchlistSubscription', id: result.id }]
          : [{ type: 'WatchlistSubscriptions', id: 'LIST' }],
    }),
    createCustomPart: builder.mutation<
      Part | Part[],
      { payload: CreateCustomPartRequest | CreateCustomPartRequest[] }
    >({
      query: ({ payload }) => ({
        url: URLS.PARTS({}),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        {
          type: 'CustomPart',
          id: 'LIST',
        },
        { type: 'Part', id: 'LIST' },
        { type: 'OrgPart', id: 'PARTIAL-LIST' },
      ],
    }),
    updatePart: builder.mutation<
      Part,
      {
        id: Part['id'];
        payload: UpdatePartRequest;
        shouldInvalidateBomlines?: boolean;
      }
    >({
      query: ({ id, payload }) => ({
        url: URLS.PART(id),
        method: 'PATCH',
        body: payload,
      }),
      async onQueryStarted(partId, { dispatch, queryFulfilled }) {
        dispatch(
          createOrUpdatePartsStatus({
            partIds: [partId.id],
            schemas: [PartSchemas.core],
            statusType: partStatusRequestTypes.FETCHING,
          })
        );
        const { data } = await queryFulfilled;
        const newCorePartData = {
          id: data.id,
          customId: data.customId,
          mpn: data.mpn,
          classification: data.classification,
          description: data.description,
          lifecycleStatus: data.lifecycleStatus,
          heroImage: data.heroImage,
          mfg: data.mfg,
          package: data.package,
          terminations: data.terminations,
          terminationType: data.terminationType,
          deprecatedBy: data.deprecatedBy,
          msl: data.msl,
          isCustomizedInfo: data.isCustomizedInfo,
          altMpns: data.altMpns,
        };
        dispatch(
          createOrUpdatePartsStatus({
            partIds: [data.id],
            schemas: [PartSchemas.core],
            statusType: partStatusRequestTypes.SUCCESS,
          })
        );
        dispatch(
          updatePartCoreFields({ partId: data.id, coreData: newCorePartData })
        );
      }, // @ts-ignore weird typing error here that I coudln't figure out how to resolve, but doesn't break anything
      invalidatesTags: (result, __, { shouldInvalidateBomlines = false }) => {
        const ret = result
          ? [
              { type: 'Part', id: 'LIST' },
              { type: 'Part', id: result?.id },
              { type: 'Part', id: 'PARTIAL-LIST' },
              { type: 'OrgPart', id: 'PARTIAL-LIST' },
              ...[
                shouldInvalidateBomlines
                  ? { type: 'BomLines', id: 'LIST' }
                  : {},
              ],
              { type: 'ProductionRunLines', id: 'LIST' },
              { type: 'ProductionRunParts', id: 'LIST' },
            ]
          : [
              { type: 'Part', id: 'LIST' },
              { type: 'Part', id: 'PARTIAL-LIST' },
              { type: 'OrgPart', id: 'PARTIAL-LIST' },
              ...[
                shouldInvalidateBomlines
                  ? { type: 'BomLines', id: 'LIST' }
                  : {},
              ],
              { type: 'ProductionRunLines', id: 'LIST' },
              { type: 'ProductionRunParts', id: 'LIST' },
            ];

        return _.filter(ret, (tag) => !_.isEmpty(tag));
      },
    }),
    revertPartUpdates: builder.mutation<Part, Part['id']>({
      query: (partId) => ({
        url: URLS.PART_CLEAR_UPDATES(partId),
        method: 'POST',
      }),
      async onQueryStarted(partId, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          createOrUpdatePartsStatus({
            partIds: [partId],
            schemas: [PartSchemas.core, PartSchemas.specs],
            statusType: partStatusRequestTypes.READY,
          })
        );
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Part', id: 'LIST' },
              { type: 'Part', id: result?.id },
            ]
          : [{ type: 'Part', id: 'LIST' }],
    }),
    getManufacturersAutocomplete: builder.query<Manufacturer[], string>({
      query: (autocomplete) => URLS.MANUFACTURERS(autocomplete),
      providesTags: ['ManufacturerAutocomplete'],
    }),
    getClassificationsAutocomplete: builder.query<Classification[], string>({
      query: (autocomplete) => URLS.CLASSIFICATIONS(autocomplete),
      providesTags: ['ClassificationsAutocomplete'],
    }),
  }),
});

export const extendedApiSliceServerSide = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPartsServerSide: build.query<
      ListResponse<OrgPart>,
      QueryParams<OrgPart>
    >({
      query: (queryParams) =>
        URLS.PARTS_SERVER(parseQueryParams<OrgPart>(queryParams)),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ part }: OrgPart) =>
                  ({
                    type: 'OrgPart',
                    id: part.id,
                  } as const)
              ),
              { type: 'OrgPart', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'OrgPart', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const {
  useGetPartsQuery,
  useLazyGetPartsQuery,
  useGetPartQuery,
  useGetPartAlternativesQuery,
  useGetCachedPartQuery,
  useLazyGetCachedPartQuery,
  useForcePartRefreshMutation,
  useGetPartQuotesQuery,
  useForcePartQuoteRefreshMutation,
  useRejectPartQuoteMutation,
  useGetPartOffersQuery,
  useGetSolicitationsQuery,
  useLazyGetSolicitationQuery,
  useCreateSolicitationMutation,
  useCreateBulkSolicitationsMutation,
  useUpdateBatchSolicitationMutation,
  useCreatePartQuoteMutation,
  useUpdatePartQuoteMutation,
  useGetPartQuoteQuery,
  useCancelSolicitationMutation,
  useLazyGetAvailabilitiesQuery,
  useLazyGetAvailabilitiesV2Query,
  useCreateAvailabilitiesSnapshotMutation,
  useCreateWatchlistSubscriptionMutation,
  useEditWatchlistSubscriptionMutation,
  useDeleteWatchlistSubscriptionMutation,
  useGetWatchlistSubscriptionsQuery,
  useGetWatchlistSubscriptionQuery,
  useCreateCustomPartMutation,
  useUpdatePartMutation,
  useRevertPartUpdatesMutation,
  useGetManufacturersAutocompleteQuery,
  useGetClassificationsAutocompleteQuery,
  useLazyGetAvailabilitiesAsyncQuery,
} = extendedApiSlice;

export const { useGetPartsServerSideQuery, usePrefetch } =
  extendedApiSliceServerSide;
