import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Icon, IconCheck, IconX } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { Tooltip } from '@mui/material';

export const BooleanIconRenderCell = (
  { value }: GridRenderCellParams,
  TrueIcon?: Icon | null,
  FalseIcon?: Icon | null,
  trueColor?: string,
  falseColor?: string,
  trueTooltip?: string,
  falseTooltip?: string
) => {
  const theme = useTheme();
  if (value && TrueIcon) {
    return (
      <Tooltip title={trueTooltip}>
        <TrueIcon
          color={_.get(theme.palette, [trueColor || 'success', 'main'])}
        />
      </Tooltip>
    );
  }
  if (!value && FalseIcon) {
    return (
      <Tooltip title={falseTooltip}>
        <FalseIcon
          color={_.get(theme.palette, [falseColor || 'error', 'main'])}
        />
      </Tooltip>
    );
  }
  if (value && TrueIcon === null) {
    return <></>;
  }
  if (!value && FalseIcon === null) {
    return <></>;
  }
  if (value) {
    return (
      <Tooltip title={trueTooltip}>
        <IconCheck
          color={_.get(theme.palette, [trueColor || 'success', 'main'])}
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={falseTooltip}>
      <IconX color={_.get(theme.palette, [trueColor || 'error', 'main'])} />
    </Tooltip>
  );
};
