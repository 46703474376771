import { PriceModel } from 'types/purchasing';
import { formatPrice } from 'utils/functions';

export enum manufacturingConstantKeys {
  warehouseShippingEnabled = 'warehouseShippingEnabled',
  consumesStock = 'consumesStock',
  requiresSplits = 'requiresSplits',
  manufacturer = 'manufacturer',
  requiresMslReset = 'requiresMslReset',
  requiresLcrInspection = 'requiresLcrInspection',
  requiresDimensionalInspection = 'requiresDimensionalInspection',
  requiresReels = 'requiresReels',
  requiresMerges = 'requiresMerges',
  acceptsSmtNoPnp = 'acceptsSmtNoPnp',
  packaging = 'packaging',
}

export const manufacturingConstants = (
  priceModel: PriceModel | undefined,
  facilityName?: string
) => ({
  packaging: {
    name: manufacturingConstantKeys.packaging,
    label: 'Packaging',
    description: `Do stock lots sent to ${
      facilityName || 'this facility'
    } need to be reeled?`,
    options: [
      {
        value: { requiresMerges: false, requiresReels: false },
        label: 'No Special Packaging Required',
        description: manufacturingConstDescription(priceModel, facilityName)[
          manufacturingConstantKeys.packaging
        ]({ requiresMerges: false, requiresReels: false }),
      },
      {
        value: { requiresMerges: true, requiresReels: false },
        label: 'Merge Stock Lots',
        description: manufacturingConstDescription(priceModel, facilityName)[
          manufacturingConstantKeys.packaging
        ]({ requiresMerges: true, requiresReels: false }),
      },
      {
        value: { requiresMerges: true, requiresReels: true },
        label: 'Reel Stock Lots',
        description: manufacturingConstDescription(priceModel, facilityName)[
          manufacturingConstantKeys.packaging
        ]({ requiresMerges: true, requiresReels: true }),
      },
    ],
  },
  alwaysShow: [
    {
      name: manufacturingConstantKeys.consumesStock,
      label: 'Track Stock Lots',
      description: `Will stock sent to ${
        facilityName || 'this facility'
      } be tracked within Cofactr or should stock sent to ${
        facilityName || 'this facility'
      } be ignored in Cofactr inventory?`,
      options: [
        {
          value: false,
          label: `Track Stock at ${facilityName || 'this facility'}`,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.consumesStock
          ](false),
        },
        {
          value: true,
          label: `Ignore Stock at ${facilityName || 'this facility'}`,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.consumesStock
          ](true),
        },
      ],
    },
    {
      name: manufacturingConstantKeys.requiresSplits,
      label: 'Requires Splits',
      description: `Should stock lots sent to ${
        facilityName || 'this facility'
      } be physically split to only send the needed quantity, or should the entire stock lot be shipped?`,
      options: [
        {
          value: true,
          label: `Split Stock for ${facilityName || 'this facility'}`,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresSplits
          ](true),
        },
        {
          value: false,
          label: `Don't Split Stock for ${facilityName || 'this facility'}`,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresSplits
          ](false),
        },
      ],
    },
    {
      name: manufacturingConstantKeys.acceptsSmtNoPnp,
      label: 'Accepts SMT No PNP',
      description: `Can ${
        facilityName || 'this facility'
      } accept stock lots of SMT parts that can't be used with a pick and place machine?`,
      options: [
        {
          value: true,
          label: 'Hand Place is Okay',
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.acceptsSmtNoPnp
          ](true),
        },
        {
          value: false,
          label: 'No Hand Place',
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.acceptsSmtNoPnp
          ](false),
        },
      ],
    },
  ],
  optional: [
    {
      name: manufacturingConstantKeys.requiresMslReset,
      label: 'Requires MSL Reset',
      options: [
        {
          value: true,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresMslReset
          ](true),
        },
        {
          value: false,

          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresMslReset
          ](false),
        },
      ],
    },
    {
      name: manufacturingConstantKeys.requiresLcrInspection,
      label: 'Requires LCR Inspection',
      options: [
        {
          value: true,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresLcrInspection
          ](true),
        },
        {
          value: false,

          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresLcrInspection
          ](false),
        },
      ],
    },
    {
      name: manufacturingConstantKeys.requiresDimensionalInspection,
      label: 'Requires Dimensional Inspection',
      options: [
        {
          value: true,

          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresDimensionalInspection
          ](true),
        },
        {
          value: false,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresDimensionalInspection
          ](false),
        },
      ],
    },
    {
      name: manufacturingConstantKeys.requiresReels,
      label: 'Requires Reels',
      options: [
        {
          value: true,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresReels
          ](true),
        },
        {
          value: false,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresReels
          ](false),
        },
      ],
    },
    {
      name: manufacturingConstantKeys.requiresMerges,
      label: 'Requires Merged Stock Lots',
      options: [
        {
          value: true,

          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresMerges
          ](true),
        },
        {
          value: false,
          description: manufacturingConstDescription(priceModel, facilityName)[
            manufacturingConstantKeys.requiresMerges
          ](false),
        },
      ],
    },
  ],
});
export const manufacturingConstDescription = (
  priceModel: PriceModel | undefined,
  facilityName?: string
) => ({
  [manufacturingConstantKeys.packaging]({
    requiresMerges,
    requiresReels,
  }: {
    requiresMerges: boolean;
    requiresReels: boolean;
  }) {
    if (requiresMerges === false && requiresReels === false) {
      return 'Stock lots may be sent as tubes, trays, one or more pieces of cut tape, or reels.';
    }
    if (requiresMerges === true && requiresReels === false) {
      return 'Multiple pieces of cut tape of the same part will be spliced into a single continuous cut tape whenever possible.';
    }
    if (requiresMerges === true && requiresReels === true) {
      return `Stock lots in single or multiple pieces of cut tape will be reeled with a leader and tail whenever possible.${
        priceModel
          ? ` A fee of ${formatPrice(
              priceModel.handlingHandlingReel8,
              2
            )}-${formatPrice(
              priceModel.handlingHandlingReel24,
              2
            )} per reel will apply.`
          : ''
      }`;
    }
    return '';
  },
  [manufacturingConstantKeys.warehouseShippingEnabled](val: boolean) {
    if (val) {
      return `${
        facilityName || 'this facility'
      } uses the Cofactr Warehouse App to manage inventory.`;
    }
    return `${
      facilityName || 'this facility'
    } does not use the Cofactr Warehouse App to manage inventory.`;
  },
  [manufacturingConstantKeys.consumesStock](val: boolean) {
    if (val) {
      return `Stock shipped to ${
        facilityName || 'this facility'
      } will not be tracked in Cofactr. You will still be able to view traceability info for that stock within Cofactr, but production and purchasing calculations will ignore that stock.`;
    }
    return `Stock sent to ${
      facilityName || 'this facility'
    } will be tracked within Cofactr. You will need to manually update stock lot levels within the Cofactr platform or using an integration with a 3rd party inventory management system.`;
  },
  [manufacturingConstantKeys.requiresSplits](val: boolean) {
    if (val) {
      return `Stock sent to ${
        facilityName || 'this facility'
      } will be physically split before being shipped. We recommend this option for most contract manufacturers unless you are confident that they will return unused stock in good, usable condition.`;
    }
    return `Stock sent to ${
      facilityName || 'this facility'
    } will not be split before being shipped, the whole stock lot will be sent. We do not recommend this option unless you are confident that this location will return unused stock in good, usable condition.`;
  },
  [manufacturingConstantKeys.acceptsSmtNoPnp](val: boolean) {
    if (val) {
      return `Stock lots of SMT parts sent to ${
        facilityName || 'this facility'
      } can be too small, bulk packaged, etc to be loaded on a pick and place. ${
        facilityName || 'This facility'
      } can accommodate hand assembly.`;
    }
    return `Stock lots of SMT parts sent to ${
      facilityName || 'this facility'
    } can not be too small, bulk packaged, etc to be loaded on a pick and place. Stock lots of SMT parts that are not pick and place compatible will not be included in inventory calculations or kits for this facility.`;
  },
  [manufacturingConstantKeys.requiresMslReset](val: boolean) {
    if (val) {
      return `Before a Stock Lot is sent to this location, if it is MSL 2+ and needs to have its MSL reset, it will be automatically routed for baking. Our baking procedure is per J-STD-033 using a 40C <1% humidity baking dry cabinet with resealing in a vacuum vapor barrier ESD bag with a desiccant pouch and humidity indicator card. Applicable charges per your contract will apply. This process can take up to 2 weeks so we recommend enabling MSL Reset on Receipt under Account>Preferences>Inventory.${
        priceModel && Number(priceModel.handlingHandlingMslreset) > 0
          ? ` A ${formatPrice(
              priceModel.handlingHandlingMslreset,
              2
            )} fee will apply for each MSL reset bake.`
          : ''
      }`;
    }
    return 'Stock Lots sent to this location may not be MSL reset prior to shipment. To prevent failure of parts during assembly, only use this setting when you are sure that the parts will be hand soldered or if this manufacturer will be performing required MSL resets after receiving the parts.';
  },
  [manufacturingConstantKeys.requiresLcrInspection](val: boolean) {
    if (val) {
      return `Passive components sent to this location will be automatically routed for an LCR (inductance, capacitance, resistance) measurement of a sample part and a comparison of measurement against key value and tolerance specified in the datasheet.${
        priceModel && Number(priceModel.handlingHandlingInspectionLcr) > 0
          ? ` A ${formatPrice(
              priceModel.handlingHandlingInspectionLcr,
              2
            )} fee will apply for each inspection.`
          : ''
      }`;
    }
    return 'No LCR inspection will be performed on parts sent to this location. Even parts purchased from leading distributors are sometimes incorrect, so we recommend automatic LCR inspections unless you are confident that this manufacturer is performing one after receiving the parts.';
  },
  [manufacturingConstantKeys.requiresDimensionalInspection](val: boolean) {
    if (val) {
      return `Prior to shipping parts to this manufacturer, a Dimensional measurement of key part features as defined by the manufacturer datasheet and comparison of measurements against values specified in the datasheet will be performed.${
        priceModel && Number(priceModel.handlingHandlingInspectionLcr) > 0
          ? ` A ${formatPrice(
              priceModel.handlingHandlingInspectionDimensional,
              2
            )} fee will apply for each inspection.`
          : ''
      }`;
    }
    return 'No dimensional inspection will be performed on parts sent to this location.';
  },
  [manufacturingConstantKeys.requiresReels](val: boolean) {
    if (val) {
      return `This manufacturer cannot accept parts packaged in loose cut tape. Prior to shipment parts packaged in cut tape will automatically be routed to be mounted on a reel with a leader and tail spliced to it.${
        priceModel &&
        (Number(priceModel.handlingHandlingReel8) > 0 ||
          Number(priceModel.handlingHandlingReel24) > 0)
          ? ` A ${formatPrice(
              priceModel.handlingHandlingReel8,
              2
            )}-${formatPrice(
              priceModel.handlingHandlingReel24,
              2
            )} fee will apply depending on tape size.`
          : ''
      }`;
    }
    return 'This manufacturer can accept parts packaged in cut tape with no leader or tail.';
  },
  [manufacturingConstantKeys.requiresMerges](val: boolean) {
    if (val) {
      return `When multiple stocks of the same part are sent to this manufacturer, they will be merged into a single stock lot prior to shipment. When possible, this will be a single reel.${
        priceModel && Number(priceModel.handlingHandlingSplice) > 0
          ? ` A ${formatPrice(
              priceModel.handlingHandlingSplice,
              2
            )} fee will apply.`
          : ''
      }`;
    }
    return 'When multiple stocks of the same part are sent to this manufacturer, they will be sent as multiple separate stock lots.';
  },
});
