import { Grid, Stack, Tooltip } from '@mui/material';
import SegmentInlineFieldButton from 'ui-component/Segment/SegmentInlineFieldButton';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { ReactElement } from 'react';
import { PermissionScope } from 'types/apps';

type BaseCellWithInputProps = {
  clonedInputElement: ReactElement;
  isLoading?: boolean;
  permissionScope?: PermissionScope;
  handleStopEdit: () => void;
  tooltip?: string;
};

const BaseCellWithInput = ({
  clonedInputElement,
  isLoading,
  permissionScope,
  handleStopEdit,
  tooltip,
}: BaseCellWithInputProps) => (
  <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    sx={{ flexWrap: 'nowrap' }}
  >
    <Grid item xs="auto">
      <Stack alignItems="center" direction="row">
        {clonedInputElement}
        <SegmentInlineFieldButton
          loading={isLoading}
          type="submit"
          color="primary"
          permissionScope={permissionScope}
          style={{ backgroundColor: 'white' }}
        />
        {tooltip !== null && tooltip !== undefined && (
          <Tooltip title={tooltip}>
            <IconInfoCircle />
          </Tooltip>
        )}
      </Stack>
    </Grid>
    <Grid item xs="auto">
      <SegmentIconButton
        color="error"
        onClick={handleStopEdit}
        size="small"
        permissionScope={permissionScope}
        sx={{
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        <IconX stroke="1.5px" width={20} height={20} />
      </SegmentIconButton>
    </Grid>
  </Grid>
);

export default BaseCellWithInput;
