import { Fab, IconButton } from '@mui/material';
import { IconMessageCircle } from '@tabler/icons-react';
import { FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDeviceContext } from 'hooks/useDeviceContext';
import { useGetUserQuery } from 'store/slices/user';

interface HelpHubProps {
  variant?: boolean;
}

const HelpHub: FC<HelpHubProps> = ({ variant }) => {
  const theme = useTheme();
  const { desktopScreen } = useDeviceContext();
  const { data: user } = useGetUserQuery();

  function handleClick() {
    if (window.CommandBar) {
      window.CommandBar.toggleHelpHub();
    }
  }

  if (!user) {
    return <></>;
  }

  return variant ? (
    <IconButton
      size={desktopScreen ? 'large' : 'medium'}
      color="inherit"
      onClick={handleClick}
    >
      <IconMessageCircle size={desktopScreen ? 32 : 24} color="white" />
    </IconButton>
  ) : (
    <Fab
      aria-label="Launch HelpHub"
      onClick={handleClick}
      sx={{
        bgcolor: theme.palette.success.main,
        color: theme.palette.common.white,
        position: 'fixed',
        bottom: '25px',
        right: '20px',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          background: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
        'z-index': '2147482000',
      }}
    >
      <IconMessageCircle />
    </Fab>
  );
};

export { HelpHub };
