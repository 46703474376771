import { ALL_APP_GROUPS } from 'constants/appConstants';
import { IconDashboard } from '@tabler/icons-react';
import { NavItemType } from 'types';
import _ from 'lodash';
import { Badge } from '@mui/material';
import { appPermissionStates } from 'types/apps';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { useMemo } from 'react';
import { DASHBOARD_ROUTE } from 'routes/constants';

const useMenuItems = () => {
  const { appsWithPermissions } = useAppAccessContext();

  const items: NavItemType[] = useMemo(
    () => [
      {
        id: 'dashboard',
        type: 'item',
        icon: IconDashboard,
        title: 'Dashboard',
        url: DASHBOARD_ROUTE,
      },
      ..._.chain(appsWithPermissions)
        .values()
        .filter(
          (a) =>
            !a.appGroup &&
            (a.includeInSidebar || false) &&
            a.permissions?.state === appPermissionStates.enabled
        )
        .map((a) => ({
          id: a.id,
          type: a.views ? 'collapse' : 'item',
          titleString: a.name,
          title: a.globalBeta ? (
            <Badge
              badgeContent="BETA"
              color="primary"
              sx={{
                '.MuiBadge-anchorOriginTopRight': {
                  right: '-30px',
                  top: '10px',
                },
              }}
            >
              {a.name}
            </Badge>
          ) : (
            a.name
          ),
          icon: a.icon,
          url: a.path,
          disabled: a.permissions?.state !== appPermissionStates.enabled,
          children: a.views
            ? _.chain(a.views)
                .values()
                .filter(
                  (v) =>
                    (v?.includeInSidebar || false) &&
                    v?.permissions?.state === appPermissionStates.enabled
                )
                .map((v) => ({
                  id: v?.id,
                  type: 'item',
                  title: v?.name,
                  icon: v?.icon,
                  url: v?.path,
                  disabled:
                    v?.permissions?.state !== appPermissionStates.enabled,
                }))
                .value()
            : [],
        }))
        .value(),
      ..._.values(ALL_APP_GROUPS).map((appGroup) => ({
        id: appGroup.id,
        type: 'group',
        icon: appGroup.icon,
        title: appGroup.name,
        children: _.chain(appsWithPermissions)
          .values()
          .filter(
            (a) =>
              a.appGroup?.id === appGroup.id &&
              (a.includeInSidebar || false) &&
              a.permissions?.state === appPermissionStates.enabled
          )
          .map((a) => ({
            id: a.id,
            type: a.views ? 'collapse' : 'item',
            title: a.name,
            icon: a.icon,
            url: a.path,
            disabled: a.permissions?.state !== appPermissionStates.enabled,
            children: a.views
              ? _.chain(a.views)
                  .values()
                  .filter(
                    (v) =>
                      (v?.includeInSidebar || false) &&
                      v?.permissions?.state === appPermissionStates.enabled
                  )
                  .map((v) => ({
                    id: v?.id,
                    type: 'item',
                    title: v?.name,
                    icon: v?.icon,
                    url: v?.path,
                    disabled:
                      v?.permissions?.state !== appPermissionStates.enabled,
                  }))
                  .value()
              : [],
          }))
          .value(),
      })),
    ],
    [appsWithPermissions]
  );

  return {
    items,
  };
};

export default useMenuItems;
