import { InputAdornment, TextField } from '@mui/material';
import { IconSearch, IconX } from '@tabler/icons-react';
import React, { FC } from 'react';
import { SxProps } from '@mui/system';

export type ExtendedQuickFilterProps = {
  quickSearchFocused: boolean;
  setQuickSearchFocused: (val: boolean) => void;
  searchText: string;
  setSearchText: (val: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
};

const ExtendedQuickFilter: FC<ExtendedQuickFilterProps> = ({
  quickSearchFocused,
  setQuickSearchFocused,
  searchText,
  setSearchText,
  handleChange,
  sx,
}) => (
  <TextField
    sx={{ pt: 1, ...sx }}
    type="text"
    variant="standard"
    value={searchText}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      setQuickSearchFocused(true);
    }}
    onBlur={() => setQuickSearchFocused(false)}
    onKeyDown={(ev) => {
      if (ev.key === 'Enter') {
        setQuickSearchFocused(false);
      }
    }}
    placeholder="Search..."
    autoFocus={quickSearchFocused}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconSearch width={20} height={20} />
        </InputAdornment>
      ),
      endAdornment: searchText && (
        <InputAdornment
          sx={{ cursor: 'pointer' }}
          position="end"
          onClick={() => setSearchText('')}
        >
          <IconX width={20} height={20} />
        </InputAdornment>
      ),
    }}
  />
);

export default ExtendedQuickFilter;
