import { RenderWithEditInputProps } from 'ui-component/DataGrid/Render/RenderEditCellWithInput';
import React, { useEffect, cloneElement } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  EditInlineBoxSx,
  EditInlineButtonGroupSx,
  EditInlineSx,
} from 'ui-component/EditInline';
import useInlineEditCellHookForm from 'ui-component/DataGrid/Cell/useInlineEditCellHookForm';
import { EditInlineStringInput } from 'ui-component/HookFormComponents/InlineEditInputComponents';
import BaseCellWithInput from 'ui-component/DataGrid/Cell/BaseCellWithInput';
import { Paper } from '@mui/material';

const EditCellWithInput = ({
  params,
  InputElement = EditInlineStringInput,
  validation,
  onSubmit,
  isLoading,
  permissionScope,
  hideLabel,
  apiRef,
  defaultValue,
  additionalInputElementProps,
  tooltip,
}: RenderWithEditInputProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useInlineEditCellHookForm({ params, validation });

  useEffect(() => {
    reset({
      [params.colDef.field]: defaultValue ?? params.value,
    });
  }, [params.value]);

  const clonedInputElement = cloneElement(InputElement, {
    control,
    name: params.colDef.field,
    ...(hideLabel ? {} : { label: params.colDef.headerName }),
    errors,
    size: 'small',
    sx: { ...EditInlineSx, width: '150px' },
    boxSx: EditInlineBoxSx,
    buttonGroupSx: EditInlineButtonGroupSx,
    ...(additionalInputElementProps || []),
    tooltip,
  });

  const doOnSubmit = async (data: FieldValues) => {
    const result = onSubmit(data);

    if (result instanceof Promise) {
      await result;
    }

    apiRef.current.stopCellEditMode({ id: params.id, field: params.field });
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        zIndex: '2',
      }}
    >
      <form onSubmit={handleSubmit(doOnSubmit)}>
        <BaseCellWithInput
          clonedInputElement={clonedInputElement}
          isLoading={isLoading}
          permissionScope={permissionScope}
          handleStopEdit={() => {
            apiRef.current.stopCellEditMode({
              id: params.id,
              field: params.field,
            });
          }}
          tooltip={tooltip}
        />
      </form>
    </Paper>
  );
};

export default EditCellWithInput;
