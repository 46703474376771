import { Icon } from '@tabler/icons-react';
import {
  ALL_APP_GROUP_IDS,
  ALL_APP_IDS,
  ALL_FEATURE_IDS,
  ALL_VIEW_IDS,
} from 'constants/appConstants';

export type AppGroup = {
  id: keyof typeof ALL_APP_GROUP_IDS;
  name: string;
  icon: Icon;
};

export type AppView = {
  id: keyof typeof ALL_VIEW_IDS;
  name: string;
  icon: Icon;
  path: string | null;
  includeInSidebar?: boolean;
  globalComingSoon?: boolean;
  globalBeta?: boolean;
  permissions?: AppPermission | null;
  // `excludeInBlended` is a property that allows us to control the visibility of specific views in a blended environment.
  // Even if a parent App is included in blended (i.e., `includeInBlended` is true), we might want to prevent certain views from being accessible in the blended mode.
  // By setting `excludeInBlended` to true for a specific view, we can ensure that this view is not accessible in a blended environment, regardless of the parent App's `includeInBlended` status.
  excludeInBlended?: boolean;
  // if true, will inherit permissions from parent app; entitlement permissions will not be evaluated but growthbook will if provided
  inheritParentPermissions?: boolean;
  // Optional arg for a view's feature logic now that View entitlements are being deprecated
  // Can only be a FEATURE. If inherited permission is an APP, use inheritParentPermissions
  inheritedFeatureEntitlement?: ALL_FEATURE_IDS;
  deprecatedPath?: string | null;
};

export type AppFeature = {
  id: keyof typeof ALL_FEATURE_IDS;
  name: string;
  icon?: Icon;
  globalComingSoon?: boolean;
  globalBeta?: boolean;
  permissions?: AppPermission | null;
  // `excludeInBlended` is a property that allows us to control the visibility of specific views in blended mode.
  // Even if a parent App is included in blended (i.e., `includeInBlended` is true), we might want to prevent certain views from being accessible in the blended mode.
  // By setting `excludeInBlended` to true for a specific view, we can ensure that this view is not accessible in a blended environment, regardless of the parent App's `includeInBlended` status.
  excludeInBlended?: boolean;
  // if true, will inherit permissions from parent app; entitlement permissions will not be evaluated but growthbook will if provided
  inheritParentPermissions?: boolean;
};

type Shortcut =
  | `${keyof typeof ALL_APP_IDS}`
  | `${keyof typeof ALL_APP_IDS}.views.${keyof typeof ALL_VIEW_IDS}`;

export type App = {
  id: keyof typeof ALL_APP_IDS;
  name: string;
  icon: Icon;
  globalComingSoon?: boolean;
  globalBeta?: boolean;
  path: string | null;
  deprecatedPath?: string | null;
  appGroup?: AppGroup | null;
  includeInSidebar?: boolean;
  includeInDashboard?: boolean;
  includeInSettingsMenu?: boolean;
  includeInBlended?: boolean;
  views?: { [key in ALL_VIEW_IDS]?: AppView } | null;
  features?: { [key in ALL_FEATURE_IDS]?: AppFeature } | null;
  shortcuts?: Shortcut[] | null;
  permissions?: AppPermission | null;
  globalEnabled: boolean;
  isRequiredFor?: ALL_APP_IDS[];
  requiredPermissionLevel?: keyof typeof appPermissionAccessLevels;
};

export type AppPermission = {
  id:
    | keyof typeof ALL_APP_IDS
    | keyof typeof ALL_VIEW_IDS
    | keyof typeof ALL_FEATURE_IDS;
  state: keyof typeof appPermissionStates;
  comingSoon?: boolean;
  accessLevel?: keyof typeof appPermissionAccessLevels;
};

export type PermissionScope = {
  app: keyof typeof ALL_APP_IDS;
  view?: keyof typeof ALL_VIEW_IDS;
  feature?: keyof typeof ALL_FEATURE_IDS;
  accessLevel?: keyof typeof appPermissionAccessLevels;
};

export enum appPermissionStates {
  enabled = 'enabled',
  comingSoon = 'comingSoon',
  disabled = 'disabled', // disabled means org/user has access but chose to disable
  unavailable = 'unavailable', // unavailable means that org has no access
  noPermission = 'noPermission', // noPermission means that org has access but has disabled for specific user
  noBlended = 'noBlended', // noBlended means that this app is not available in blended views
}

export enum appPermissionAccessLevels {
  none = 'none', // user can’t see the record at all
  read = 'read', // User can view all the contents of an app or a record, but all actions they can take in an app or the record are deactivated
  edit = 'edit', // User can edit the contents of an app or record, but can’t view or change the permissions of the users for this app or record
  admin = 'admin', // Users can change the permissions (i.e. view users, add/remove users, modify the permissions of users)
}

export type AccessLevelLabel = {
  name: appPermissionAccessLevels;
  label: string;
  // used for sorting/ordering
  level: number;
};

export const appPermissionAccessLevelLabels: {
  [key in appPermissionAccessLevels]: AccessLevelLabel;
} = {
  [appPermissionAccessLevels.admin]: {
    name: appPermissionAccessLevels.admin,
    label: 'Admin access',
    level: 3,
  },
  [appPermissionAccessLevels.edit]: {
    name: appPermissionAccessLevels.edit,
    label: 'Edit access',
    level: 2,
  },
  [appPermissionAccessLevels.read]: {
    name: appPermissionAccessLevels.read,
    label: 'Read access',
    level: 1,
  },
  [appPermissionAccessLevels.none]: {
    name: appPermissionAccessLevels.none,
    label: 'No access',
    level: 0,
  },
};

export type noPermissionsReason = {
  message: string;
  action: string;
};

export enum DISABLED_TYPE {
  APP,
  VIEW,
  FEATURE,
  PARENT_APP,
}

export type DisabledReason = {
  disabledType: DISABLED_TYPE;
  name?: App['name'] | AppFeature['name'];
  permissionState?: keyof typeof appPermissionStates;
};
