import { KitRequestLineSchema } from 'types/kitting';
import {
  useDeleteKitRequestLinesMutation,
  useLockKitRequestLineMutation,
  useUnlockKitRequestLineMutation,
} from 'store/slices/clientV2/kitRequests';
import { DatagridNames, RowAction } from 'types/datagrid';
import { useMemo, useState } from 'react';
import { ALL_APP_IDS } from 'constants/appConstants';
import { appPermissionAccessLevels } from 'types/apps';
import { useDialogManager } from 'hooks/useDialogManager';
import {
  IconEdit,
  IconShoppingCart,
  IconTrash,
  IconLock,
  IconLockOpen,
} from '@tabler/icons-react';
import DeleteRecordDialog from 'ui-component/clientV2/DeleteRecordDialog';
import ToggleRecordDialog, {
  ToggleRecordActionTypes,
} from 'ui-component/clientV2/ToggleRecordDialog';
import { columns } from 'views/kitting/Kit/KitRequestLineTable/columns';
import {
  DecoratedKitRequestLine,
  KitRequestLineDataLineLite,
} from 'hooks/useGetKitRequestLineData';
import ExtendedDatagrid from 'ui-component/DataGrid/ExtendedDatagrid';
import { useIsCofactr } from 'hooks/useIsCofactr';
import KitRequestLineDetailPanel from 'views/kitting/Kit/components/KitRequestLineDetailPanel';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';

enum KIT_REQUEST_LINES_DIALOGS {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  PURCHASE = 'PURCHASE',
  LOCK = 'LOCK',
}

const KitRequestLineTable = () => {
  const { closeDialog, toggleDialog, isDialogOpen } = useDialogManager(
    Object.values(KIT_REQUEST_LINES_DIALOGS)
  );
  const [selectedKitRequestLine, setSelectedKitRequestLine] =
    useState<KitRequestLineDataLineLite | null>(null);
  const [selectedRows, setSelectedRows] = useState<
    KitRequestLineDataLineLite[]
  >([]);
  const { isCofactr } = useIsCofactr();

  const { decoratedKitRequestLines, isLoadingKitRequestLines } =
    useKitRequestContext();

  const rowActions: RowAction<DecoratedKitRequestLine>[] = [
    {
      getLabel: () => 'Edit Line',
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequestLine(row);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.EDIT);
        }
      },
      Icon: IconEdit,
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
      isRowDisabled: (row) =>
        Boolean(
          (row?.locked && !isCofactr) ||
            (row?.kitRequest?.approved && !isCofactr)
        ),
    },
    {
      getLabel: () => 'Purchase Parts',
      onRowClick: (row) => {
        if (row) {
          setSelectedRows([row]);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.PURCHASE);
        }
      },
      onBatchClick: (rows) => {
        if (rows) {
          setSelectedRows(rows);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.PURCHASE);
        }
      },
      Icon: IconShoppingCart,
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
      isRowDisabled: (row) => Boolean(row?.kitRequest?.approved),
    },
    {
      getLabel: (row) => (row?.locked ? 'Unlock Line' : 'Lock Line'),
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequestLine(row);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.LOCK);
        }
      },
      getRowIcon: (row) => (row?.locked ? IconLockOpen : IconLock),
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
    },
    {
      getLabel: () => 'Delete Line',
      onRowClick: (row) => {
        if (row) {
          setSelectedKitRequestLine(row);
          toggleDialog(KIT_REQUEST_LINES_DIALOGS.DELETE);
        }
      },
      Icon: IconTrash,
      color: 'error',
      showInRowMenu: true,
      permissionScope: {
        app: ALL_APP_IDS.KITTING,
        accessLevel: appPermissionAccessLevels.edit,
      },
      isRowDisabled: (row) => Boolean(row?.kitRequest?.approved && !isCofactr),
    },
  ];

  const memoizedColumns = useMemo(() => columns, []);

  return (
    <>
      <ExtendedDatagrid<DecoratedKitRequestLine>
        gridName={DatagridNames.kitRequestLines}
        loading={isLoadingKitRequestLines}
        rows={(decoratedKitRequestLines ?? []) as DecoratedKitRequestLine[]}
        columns={memoizedColumns}
        rowActions={rowActions}
        noRows="Click 'Add Line' to add a line to this kit request"
        detailPanel
        getDetailPanelContent={({ row }) => (
          <KitRequestLineDetailPanel kitRequestLine={row} />
        )}
        newRecordButton={{
          label: 'Add Line',
          onClick: () => {
            toggleDialog(KIT_REQUEST_LINES_DIALOGS.CREATE);
          },
          permissionScope: {
            app: ALL_APP_IDS.KITTING,
            accessLevel: appPermissionAccessLevels.edit,
          },
        }}
      />
      <DeleteRecordDialog
        id={selectedKitRequestLine?.id ?? ''}
        dialogOpen={isDialogOpen(KIT_REQUEST_LINES_DIALOGS.DELETE)}
        onClose={closeDialog}
        recordName="Kit Request Line"
        useClientDeleteMutation={useDeleteKitRequestLinesMutation}
      />
      <ToggleRecordDialog<KitRequestLineSchema>
        dialogOpen={isDialogOpen(KIT_REQUEST_LINES_DIALOGS.LOCK)}
        onClose={closeDialog}
        recordName="Kit Request Line"
        isCurrentlyTrue={Boolean(selectedKitRequestLine?.locked)}
        id={selectedKitRequestLine?.id ?? ''}
        useToggleTrueMutation={useLockKitRequestLineMutation}
        useToggleFalseMutation={useUnlockKitRequestLineMutation}
        toggleActionType={ToggleRecordActionTypes.LOCK}
      />
    </>
  );
};

export default KitRequestLineTable;
