import {
  DoneLoadingStatusTypes,
  LoadingStatusTypes,
  PartSchemas,
  partStatusRequestTypes,
} from 'types/partStatus';
import { RootState, useDispatch, useSelector } from 'store';
import {
  createOrUpdatePartsStatus,
  getPartStatusSelector,
  removePartStatusByIdAndSchema,
} from 'store/slices/partStatus';
import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import {
  partDataSelector,
  removePartDataByIdAndSchema,
  selectPartsByIds,
} from 'store/slices/partData';
import { differenceInMinutes } from 'date-fns';
import isEqual from 'react-fast-compare';

interface UseGetPartListProps {
  partIds: string[];
  schemas: PartSchemas[];
  useLazyRequest?: boolean;
  cacheKey: string;
}

export default function useRequestDecoratedPartData({
  partIds = [],
  schemas,
  useLazyRequest = false,
  cacheKey,
}: UseGetPartListProps) {
  const dispatch = useDispatch();

  const selectPartStatus = useSelector(
    (state: RootState) => getPartStatusSelector(state),
    isEqual
  );
  const selectPartData = useSelector((state: RootState) =>
    partDataSelector(state)
  );

  const partStatusForPartIds = useMemo(() => {
    if (partIds) {
      return _.pickBy(selectPartStatus, (value, key) => partIds.includes(key));
    }
    return {};
  }, [selectPartData, partIds]);

  const cachedParts = useSelector(
    (state) => selectPartsByIds(state, partIds, cacheKey),
    isEqual
  );

  const dataIsExpired = ({
    fetchedAt,
    schema,
  }: {
    fetchedAt: Date;
    schema: PartSchemas;
  }) => {
    if (!fetchedAt) {
      return true;
    }
    switch (schema) {
      case PartSchemas.market:
      case PartSchemas.supply:
        //   24 hours
        return differenceInMinutes(new Date(), fetchedAt) > 24 * 60;
      case PartSchemas.core:
      case PartSchemas.specs:
      case PartSchemas.alts:
        //   7 days
        return differenceInMinutes(new Date(), fetchedAt) > 24 * 60 * 7;
      default:
        return true;
    }
  };

  const requestParts = (schema: PartSchemas) => {
    // only request parts that haven't already been requested, or whose data is expired
    const partsToFetch = _.chain(partIds)
      .compact()
      .uniq()
      .filter((partId) => {
        const fetchedAt = cachedParts?.[partId]?.[schema]?.fetchedAt;
        const isExpired =
          fetchedAt &&
          dataIsExpired({
            fetchedAt: new Date(fetchedAt),
            schema,
          });

        if (isExpired) {
          dispatch(removePartStatusByIdAndSchema({ partId, schema }));
          dispatch(
            removePartDataByIdAndSchema({
              partIds: [partId],
              schema,
            })
          );
        }
        return ![...LoadingStatusTypes, ...DoneLoadingStatusTypes].includes(
          selectPartStatus?.[partId]?.[schema] as partStatusRequestTypes
        );
      })
      .value();

    if (partsToFetch.length > 0) {
      dispatch(
        createOrUpdatePartsStatus({
          partIds: partsToFetch,
          schemas: [schema],
          statusType: partStatusRequestTypes.READY,
        })
      );
    }
  };

  const requestForAllSchemas = () => {
    _.forEach(schemas, (schema) => {
      requestParts(schema);
    });
  };

  useEffect(() => {
    if (!useLazyRequest && partIds?.length > 0) {
      requestForAllSchemas();
    }
  }, [partIds]);

  const isSuccess = useMemo(
    () =>
      _.every(partIds, (partId) =>
        _.every(
          schemas,
          (schema) =>
            partStatusForPartIds?.[partId]?.[schema] ===
            partStatusRequestTypes.SUCCESS
        )
      ),
    [cachedParts, partStatusForPartIds]
  );

  const getSuccessBySchema = (schemasToCheck: PartSchemas[]) =>
    _.every(partIds, (partId) =>
      _.every(
        schemasToCheck,
        (schema) =>
          partStatusForPartIds?.[partId]?.[schema] ===
          partStatusRequestTypes.SUCCESS
      )
    );

  return {
    lazyRequestParts: requestForAllSchemas,
    // list of data by partId for each requested schema
    data: cachedParts,
    // memoized list of loading state for requested schema
    status: partStatusForPartIds,
    isSuccess,
    getSuccessBySchema,
  };
}
