import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const PartLibrary = Loadable(lazy(() => import('views/parts/PartLibrary')));

export const PartLibraryRoutes = [
  {
    path: `${ALL_APPS.PART_LIBRARY.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PART_LIBRARY.id,
          }}
        >
          <Navigate to={`${ALL_VIEWS.PART_ALL.path}`} replace />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PART_ALL.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PART_LIBRARY.id,
            view: ALL_VIEW_IDS.PART_ALL,
          }}
        >
          <PartLibrary />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
