import { useSelector } from 'store';
import { useGetDocumentTemplatesQuery } from 'store/slices/inventory';
import _ from 'lodash';
import { useGetUserQuery } from 'store/slices/user';

export function useDocumentTemplates() {
  const { data: user } = useGetUserQuery();
  const { activeOrgId } = useSelector((state) => state.org);

  const activeCreatedFromOrg = _.chain(user?.orgs || [])
    .find({ id: activeOrgId || '' })
    .get('createdFrom')
    .value();

  const { data: documentTemplates, isFetching: isFetchingDocumentTemplates } =
    useGetDocumentTemplatesQuery();

  // document templates grouped by document type
  const groupedDocumentTemplates = _.groupBy(
    documentTemplates || [],
    'documentType'
  );

  const defaultDocumentTemplates = _.mapValues(
    groupedDocumentTemplates,
    (v) =>
      _.find(v, (t) => t.org?.id === activeOrgId && t.default) ||
      _.find(v, (t) => t.org?.id === activeCreatedFromOrg && t.default) ||
      _.find(v, (t) => !t.org && t.default)
  );

  return {
    fetching: isFetchingDocumentTemplates,
    allDocumentTemplates: documentTemplates || [],
    groupedDocumentTemplates,
    defaultDocumentTemplates,
  };
}
