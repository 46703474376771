import { IconDeviceFloppy } from '@tabler/icons-react';
import { ForwardedRef, forwardRef, memo, ReactElement } from 'react';
import SegmentLoadingButton, {
  SegmentLoadingButtonProps,
} from 'ui-component/Segment/SegmentLoadingButton';

interface SegmentInlineFieldButtonProps extends SegmentLoadingButtonProps {
  icon?: ReactElement;
  tooltip?: string;
  middle?: boolean;
  numberOfButtons?: number;
  label?: string;
  height?: string;
}

const SegmentInlineFieldButton = forwardRef(
  (
    {
      icon,
      tooltip = 'Save Change',
      middle = false,
      numberOfButtons = 1,
      label,
      height,
      ...rest
    }: SegmentInlineFieldButtonProps,
    ref: ForwardedRef<any>
  ) => (
    <SegmentLoadingButton
      size="medium"
      variant="outlined"
      startIcon={icon || <IconDeviceFloppy />}
      tooltip={tooltip}
      sx={{
        borderRadius: '8px',
        borderBottomLeftRadius: '0px',
        borderTopLeftRadius: '0px',
        height: height || '42px',
        p: '5px',
        minWidth: '0',
        position: 'relative',
        left: `-${numberOfButtons}px`,
        '.MuiButton-startIcon': { p: 0, m: 0 },
        ...(middle ? { borderRadius: '0px' } : {}),
      }}
      {...rest}
    >
      {label}
    </SegmentLoadingButton>
  )
);

export default memo(SegmentInlineFieldButton);
