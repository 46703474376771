import { useFormContext, useWatch } from 'react-hook-form';
import {
  HookFormCheckbox,
  HookFormDatePicker,
  HookFormInput,
  HookFormSelect,
  HookFormTeamAutocomplete,
} from 'ui-component/HookFormComponents';
import { createUpdateKitRequestFormConstants } from 'views/kitting/components/constants';
import HookFormStockLocationSelect from 'ui-component/HookFormComponents/HookFormStockLocationSelect';
import { StockLocationType } from 'types/inventory';
import { HookFormProductionRunAutocomplete } from 'ui-component/HookFormComponents/HookFormProductionRunAutocomplete';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { ALL_APP_IDS } from 'constants/appConstants';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import { Stack } from '@mui/system';
import { ShippingMethods } from 'types/purchasing';
import {
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { IconPlaneDeparture } from '@tabler/icons-react';
import { useSelector } from 'store';
import { getDefaultShippingMethod } from 'utils/functions';
import { useGetOrgQuery } from 'store/slices/org';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { ProductionRun } from 'types/production';
import { useEffect, useState } from 'react';
import { useLazyGetProductionRunQuery } from 'store/slices/production';

type CreateUpdateKitRequestFormProps = {
  defaultValues: Record<string, string | boolean | string[] | null>;
};

const CreateUpdateKitRequestForm = ({
  defaultValues,
}: CreateUpdateKitRequestFormProps) => {
  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  const { activeOrgId } = useSelector((state) => state.org);
  const { data: org } = useGetOrgQuery(activeOrgId ?? skipToken);
  const defaultShippingMethod = getDefaultShippingMethod({ org });

  const [associatedProgram, setAssociatedProgram] =
    useState<ProductionRun | null>(null);

  const {
    control,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();

  const watchProgram = useWatch({
    control,
    name: createUpdateKitRequestFormConstants.productionRun.id,
  });

  const watchShipTo = useWatch({
    control,
    name: createUpdateKitRequestFormConstants.shipTo.id,
  });

  const [getProgram] = useLazyGetProductionRunQuery();

  const handleGetProgram = async (programId: ProductionRun['id']) => {
    const program = await getProgram(programId ?? skipToken).unwrap();
    if (program) {
      setAssociatedProgram(program);
    }
  };

  useEffect(() => {
    if (watchProgram) {
      handleGetProgram(watchProgram);
    }
  }, [watchProgram]);

  useEffect(() => {
    if (
      associatedProgram?.productionLocation?.id &&
      !watchShipTo &&
      // Only set the default shipTo if the user has changed the production run
      !!dirtyFields[createUpdateKitRequestFormConstants.productionRun.id]
    ) {
      setValue(
        createUpdateKitRequestFormConstants.shipTo.id,
        associatedProgram.productionLocation.id
      );
    }
  }, [
    associatedProgram?.productionLocation?.id,
    watchShipTo,
    dirtyFields[createUpdateKitRequestFormConstants.productionRun.id],
  ]);

  useEffect(() => {
    if (!watchShipTo) {
      setValue(createUpdateKitRequestFormConstants.shipMethod.id, '');
      setValue(createUpdateKitRequestFormConstants.shipPartial.id, false);
      setValue(createUpdateKitRequestFormConstants.targetShipDate.id, null);
    }
  }, [watchShipTo]);

  return (
    <Stack spacing={2}>
      <HookFormInput
        name={createUpdateKitRequestFormConstants.name.id}
        label={createUpdateKitRequestFormConstants.name.label}
        control={control}
        errors={errors}
        boxSx={{ mb: 0 }}
      />
      <HookFormStockLocationSelect
        control={control}
        name={createUpdateKitRequestFormConstants.sourceLocations.id}
        label={createUpdateKitRequestFormConstants.sourceLocations.label}
        errors={errors}
        multiple
        filters={[
          {
            field: 'locationType',
            operator: '=',
            value: StockLocationType.FACILITY,
          },
          { field: 'warehouseShippingEnabled', operator: '=', value: true },
        ]}
        mb={0}
      />
      <HookFormProductionRunAutocomplete
        label={createUpdateKitRequestFormConstants.productionRun.label}
        name={createUpdateKitRequestFormConstants.productionRun.id}
        control={control}
        errors={errors}
        setValue={setValue}
        defaultValue={
          (defaultValues[
            createUpdateKitRequestFormConstants.productionRun.id
          ] as string) ?? ''
        }
        mt={0}
      />
      {propertiesAppEnabled && (
        <HookFormTeamAutocomplete
          errors={errors}
          control={control}
          name={createUpdateKitRequestFormConstants.team.id}
          label={handleReplaceWithCustomTeamName(
            createUpdateKitRequestFormConstants.team.label
          )}
          defaultValue={
            (defaultValues[
              createUpdateKitRequestFormConstants.team.id
            ] as string) ??
            associatedProgram?.team?.id ??
            ''
          }
          setValue={setValue}
          disabled={Boolean(associatedProgram && !!associatedProgram?.team)}
        />
      )}
      <HookFormStockLocationSelect
        name={createUpdateKitRequestFormConstants.shipTo.id}
        label={createUpdateKitRequestFormConstants.shipTo.label}
        control={control}
        errors={errors}
        contextualCreate
        setValue={setValue}
        defaultValue={
          defaultValues[createUpdateKitRequestFormConstants.shipTo.id] ?? ''
        }
        mb={0}
        limitCreateLocationTypeToFacility
        filters={[
          {
            field: 'locationType',
            operator: '=',
            value: StockLocationType.FACILITY,
          },
        ]}
      />
      {watchShipTo && (
        <>
          <HookFormSelect
            control={control}
            name={createUpdateKitRequestFormConstants.shipMethod.id}
            label={createUpdateKitRequestFormConstants.shipMethod.label}
            errors={errors}
            mt={0}
          >
            {ShippingMethods.map((method) =>
              method.type === 'option' ? (
                <MenuItem value={method.value} key={method.value}>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {method.value === defaultShippingMethod && (
                      <Tooltip title="The default shipping method for your organization">
                        <ListItemIcon>
                          <IconPlaneDeparture />
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    <Typography>{method.label}</Typography>
                  </Stack>
                </MenuItem>
              ) : (
                <ListSubheader key={method.value}>{method.label}</ListSubheader>
              )
            )}
          </HookFormSelect>
          <HookFormCheckbox
            name={createUpdateKitRequestFormConstants.shipPartial.id}
            control={control}
            label={createUpdateKitRequestFormConstants.shipPartial.label}
          />
          <HookFormDatePicker
            control={control}
            name={createUpdateKitRequestFormConstants.targetShipDate.id}
            label={createUpdateKitRequestFormConstants.targetShipDate.label}
            errors={errors}
            disablePast
            clearable
          />
        </>
      )}
    </Stack>
  );
};

export default CreateUpdateKitRequestForm;
