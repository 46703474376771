import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';

// TODO: some frustrating rerendering issues with this component, but it works
function PositiveNumberFilterInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const [input, setInput] = useState(
    item.value !== undefined && item.value !== null ? item.value.toString() : ''
  );

  useEffect(() => {
    setInput(
      item.value !== undefined && item.value !== null
        ? item.value.toString()
        : ''
    );
  }, [item.value]);

  const handleDebounce = useCallback(
    _.debounce((value) => {
      applyValue({ ...item, value: value === '' ? null : value });
    }, 500),
    [applyValue, item]
  );

  const handleFilterChange = useCallback(
    (event) => {
      const rawValue = event.target.value;
      const value =
        rawValue === '' ? '' : Math.max(parseInt(rawValue, 10), 0).toString();
      setInput(value);
      handleDebounce(value);
    },
    [handleDebounce]
  );

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
      }}
    >
      <TextField
        autoFocus
        value={input}
        type="number"
        label="Value"
        size="small"
        variant="standard"
        onChange={handleFilterChange}
        inputProps={{ min: 0 }}
      />
    </Box>
  );
}

export default PositiveNumberFilterInput;
