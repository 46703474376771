import { Org } from 'types/org';
import { User } from 'types/user';
import { Part, PartOption, PartQuote } from 'types/part';
import {
  PackagingOptions,
  StockLocation,
  StockLocationLite,
  StockLotLite,
  Supplier,
} from 'types/inventory';
import { BomCRUDSchema } from 'types/bom';
import {
  ProductionRun,
  ProductionRunLite,
  ProductionRunSnapshot,
} from 'types/production';
import { DateString, DateTimeString } from 'types';
import { OrgSupplier } from 'types/suppliers';

export type PurchasingStateProps = {
  isAvailabilitiesSettingsDirty: boolean;
};

// Unsure if we should just make PriceModel have nullable fields
// or if it makes more sense to have a lite version for nested
export type PriceModelLite = {
  id: string;
  name: string;
  displayName: string;
};

export type PriceModel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  displayName: string;
  handlingReceivePurchased: string;
  handlingReceiveCustomerBarcode: string;
  handlingReceiveCustomerWritten: string;
  handlingShipmentShipment: string;
  handlingShipmentStocklot: string;
  handlingHandlingSplit: string;
  handlingHandlingSplice: string;
  handlingHandlingCombine: string;
  handlingHandlingReel8: string;
  handlingHandlingReel12: string;
  handlingHandlingReel16: string;
  handlingHandlingReel24: string;
  handlingHandlingMslreset: string;
  handlingHandlingAnticounterfeitingBasicPiece: string;
  handlingHandlingAnticounterfeitingFullStocklot: string;
  handlingHandlingAnticounterfeitingFullPiece: string;
  handlingHandlingAnticounterfeitingBasicStocklot: string;
  handlingHandlingAnticounterfeitingSurfaceStocklot: string;
  handlingHandlingAnticounterfeitingDecapStocklot: string;
  handlingHandlingAnticounterfeiting3RdpartyStocklot: string;
  handlingHandlingInspectionDimensional: string;
  handlingHandlingInspectionLcr: string;
  handlingHandlingPackagePhoto: string;
  handlingHandlingInspectionPhoto: string;
  handlingHandlingInspectionXray: string;
  storageBin201506: string;
  storageBinBigreel: string;
  storageBinSqft: string;
  customsBrokerageDeminimis: string;
  customsBrokerageStandard: string;
  customsBrokerageTemporary: string;
  protectionAnticounterfeitingBasicStocklot: string;
  protectionAnticounterfeitingBasicPiece: string;
  protectionAnticounterfeitingBasicPercentVerified: string;
  protectionAnticounterfeitingBasicPercentUnverified: string;
  protectionAnticounterfeitingFullStocklot: string;
  protectionAnticounterfeitingFullPiece: string;
  protectionAnticounterfeitingFullPercentVerified: string;
  protectionAnticounterfeitingFullPercentUnverified: string;
  protectionAnticounterfeitingSurfaceStocklot: string;
  protectionAnticounterfeitingDecapStocklot: string;
  protectionAnticounterfeiting3rdpartyStocklot: string;
  buyFeeOrder: string;
  buyReturnStocklot: string;
  brokerageFeePercentageA: string;
  brokerageFeeOrder: string;
  brokerageFeePercentageB: string;
  brokerageFeePercentageC: string;
  brokerageFeePercentageD: string;
  brokerageFeePercentageF: string;
  brokerageFeePercentageUnknown: string;
  billFromSnapshot: boolean;
  billOnKitShipment: boolean;
  orderApprovalBuffer: number | null;
  allowablePurchaseOveragePercent: number | null;
};

export type ShipToUserKittingOptions = {
  kittingDestination?: StockLocation['id'];
  kittingShippingCarrier?: string;
  kittingShippingService?: string;
  kittingShipPartial?: boolean;
  kittingGlobalNotePrefix?: string;
  kittingGlobalNoteSuffix?: string;
  kittingAddRefDes?: boolean;
  kittingShipmentNote?: string;
  kittingAddBomLineNumberNote?: boolean;
};

export type BomAvailabilitiesRequest = ShipToUserKittingOptions & {
  bomQuant?: number; // for purchasing tab in bom
  addOverage: boolean;
  subtractExpectedStock: boolean;
  subtractProcessingStock: boolean;
  subtractOnhandStock: boolean;
  maxLead: number;
  supplierIds?: Supplier['id'][]; // for purchasing tab in bom
  approvedSuppliers?: Supplier['id'][]; // for purchasing tab in Part list
  authorizedOnly: boolean;
  requiredCertifications: string[];
  noBackorder: boolean;
  avoidBackorder: boolean;
};

export type CreateBomAvailabilitiesSnapshotRequest =
  ShipToUserKittingOptions & {
    bomQuant?: number;
    authorizedOnly?: boolean;
    requiredCertifications?: string[];
    maxLead?: number;
    addOverage?: boolean;
    subtractExpectedStock?: boolean;
    subtractProcessingStock?: boolean;
    subtractOnhandStock?: boolean;
    supplierIds?: Supplier['id'][];
    selectedLead: number;
    noBackorder: boolean;
    avoidBackorder: boolean;
  };

export type Availabilities = {
  totalLines: number;
  inventoryLines: number;
  buyableLines: number;
  quotableLines: number;
  maybeLines: number;
  needToFindLines: number;
  options: AvailabilitiesOptions[];
  summary: PartOption[];
};

export type AvailabilitiesOptions = {
  lead: number;
  foreign: boolean;
  totalPrice: number;
  landedPrice: number;
  totalLines?: number;
  inventoryLines?: number;
  buyableLines?: number;
  quotableLines?: number;
  maybeLines?: number;
  needToFindLines?: number;
  lineItemsSummary: LineItem[];
  lineItemsDetailed: LineItem[];
  parts: PartOption[];
  allocatedLines?: number;
  kittingEstimate?: KittingEstimate;
};

export type KittingOptionsCreate = {
  optionDetails: {
    desiredQuant: number;
    minimumQuant: number;
  }[];
  kittingShippingCarrier: string | null;
  kittingShippingService: string | null;
  kittingShipPartial: boolean;
};

export type KittingEstimate = {
  handlingRequestEstimates: HandlingRequestCostItem[];
  handlingRequestCost: number | null;
  handlingRequestLead: number;
  shippingEstimates: ShipmentCostItem[];
  shippingCost: number | null;
  packingCost: number | null;
  shippingLead: number;
  kittingLead: number;
  kittingCost: number | null;
  validShippingEstimate: boolean;
  totalLead: number;
  shippingPartial: boolean;
  kitMarkupCost: number | null;
  includesDropship?: boolean;
};

export type HandlingRequestCostItem = {
  requestType: string;
  summaryText: string;
  cost: string;
  lead: number;
};

export type ShipmentCostItem = {
  id: string | null;
  shipFromPostalCode: string;
  shipFromCountryCode: string;
  shipToPostalCode: string;
  shipToCountryCode: string;
  carrier: string | null;
  service: string | null;
  summaryText: string;
  cost: string;
  estimatedWeight: string;
  estimatedDeliveryDays: number | null;
};

export type Snapshot = {
  id: string;
  lead: number;
  total: number;
  landedPrice: number;
  approved: boolean;
  expired: boolean;
  purchased: boolean;
  summary: LineItem[];
  details: LineItem[];
  lines: SnapshotLine['id'][] | SnapshotLine[];
  org: Org['id'] | Org;
  submittedBy: User['id'] | User;
  approvedBy: null | User['id'] | User;
  priceModel: PriceModelLite['id'] | PriceModelLite;
  bom: null | BomCRUDSchema['id'] | BomCRUDSchema;
  kittingEstimate?: KittingEstimate;
};

export type SnapshotLine = {
  id: string;
  part: Part['id'] | Part;
  quant: number;
  unitPrice: number;
  totalPrice: number;
  landedPrice: number;
  sourceName: Supplier['name'];
  supplier: Supplier['id'] | Supplier;
  snapshot: Snapshot['id'] | Snapshot;
};

export type RequestOrApproveSnapshotRequest = {
  externalPoNumber?: string;
  externalPoTotal?: number | null;
  releaseDate?: DateString | null;
  productionRun?: ProductionRun['id'] | null;
};

export type LineItem = {
  description: string;
  price: number;
  sourceId?: string;
  quant?: number;
  unitPrice?: number;
  lineType?: string;
  partId?: string;
};

export type OrderConfirmationCallback = (
  selectedLead: AvailabilitiesOptions
) => Promise<Snapshot | ProductionRunSnapshot>;

export type PurchaseLite = {
  orderNumber: string | null;
  poNumber: string | null;
  internalNotes: string | null;
  externalNotes: string | null;
  destinationLocation: StockLocation['id'] | null;
  id: string;
  dropShip: boolean;
  extSourceId: string;
  addressLine_1: string | null;
  addressLine_2: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressCountry: string | null;
  addressPostalCode: string | null;
  addressCompany: string | null;
  addressName: string | null;
  addressPhone: string | null;
};

export type PurchaseEventLite = {
  trackingNumber: string | null;
  estimatedDelivery: DateString | null;
  eventType: string;
};

export type ExpandedPurchaseLineGETSchema = {
  facilityId?: StockLocation['id'] | null;
  sandbox?: boolean;
};

export type ConfirmedPurchaseLine = {
  id: string;
  stockLots: StockLotLite[];
  shippedAt?: DateString | null;
  orgSupplier: OrgSupplier;
  events: PurchaseEventLite[];
  estimatedDelivery?: DateString | null;
  purchase: PurchaseLite | null;
  part: Part;
  sku: string;
  quant: string | number;
  org: string;
};

export type ExpandedPurchaseLine = {
  purchase: PurchaseLite | null;
  events: PurchaseEventLite[];
  stockLots: StockLotLite[];
  partQuote: PartQuote | null;
  supplier: Supplier;
  orgSupplier: OrgSupplier;
  id: string;
  part: Part;
  originalQuant: string;
  quant: string | number;
  shippedAt?: DateString | null;
  estimatedDelivery?: DateString | null;
  status: string;
  sku: string;
  dropShip: boolean;
  kittingDestination: StockLocationLite | null;
  productionRun: ProductionRunLite | null;
  processingOrg: string;
  lineTotalExpected: string | null;
  lineTotalPaid: string | null;
  salesTaxPaid: string | null;
  dutiesPaid: string | null;
  shippingPaid: string | null;
  otherTotalPaid: string | null;
  externalAdjustment: string | null;
  internalAdjustment: string | null;
  link: string | null;
  package: string | null;
  needDeliveryBy: DateString | null;
  expectedShipBy: DateString | null;
  returnableUntil: DateString | null;
  releaseDeadline: DateString | null;
  processedAt: DateTimeString | null;
  confirmedAt: DateTimeString | null;
  releaseDate: DateString | null;
  internalNotes: string | null;
  externalNotes: string | null;
  externalPoNumber: string | null;
  isBackordered: boolean;
  sourceLocked: boolean;
  expectedTransitTime: number | null;
  org: string;
};

export type PurchaseCreate = {
  purchaseLines: ExpandedPurchaseLine['id'][];
  salesTaxPaid?: number;
  dutiesPaid?: number;
  shippingPaid?: number;
  otherTotalPaid?: number;
  externalAdjustment?: number;
  internalAdjustment?: number;
  internalNotes?: string;
  externalNotes?: string;
  unfulfillable?: boolean;
  destinationLocation?: StockLocation['id'];
};

export type PurchaseUpdate = {
  status?: 'confirmed' | 'processed';
  estimatedDelivery?: DateString;
  expectedShipBy?: DateString;
  salesTaxPaid?: number;
  dutiesPaid?: number;
  shippingPaid?: number;
  otherTotalPaid?: number;
  externalAdjustment?: number;
  internalAdjustment?: number;
  orderNumber?: string;
  internalNotes?: string;
  externalNotes?: string;
  unfulfillable?: boolean;
  destinationLocation?: StockLocation['id'];
};

export type PurchaseLineUpdate = {
  suggestedAlts?: Part['id'][];
  part?: Part['id'];
  quant?: number;
  lineTotalPaid?: number;
  salesTaxPaid?: number;
  dutiesPaid?: number;
  shippingPaid?: number;
  otherTotalPaid?: number;
  externalAdjustment?: number;
  internalAdjustment?: number;
  link?: string;
  needDeliveryBy?: DateString;
  estimatedDelivery?: DateString;
  expectedShipBy?: DateString;
  returnableUntil?: DateString;
  releaseDeadline?: DateString;
  releaseDate?: DateString;
  internalNotes?: string;
  externalNotes?: string;
  externalPoNumber?: string;
  isBackordered?: boolean;
  extSourceId?: string;
  extSku?: string;
  purchase?: PurchaseLite['id'] | null;
};

export type PurchaseLineReceivePOST = {
  quant: number;
  stockLocation: StockLocation['id'];
  package?: PackagingOptions | null;
  countryOfOrigin?: string | null;
  quantCertain?: boolean;
  dateCode?: string | null;
  mfgLot?: string | null;
  quarantined?: boolean;
  smtNoPnp?: boolean;
  msl?: string | null;
  mslFloorHours?: number;
  expiresAt?: DateString | null;
  internalNotes?: string | null;
  externalNotes?: string | null;
  tapeSize?: number;
  station?: string | null;
};

export const invoiceStatusMapper: { [key: string]: string } = {
  draft: 'Draft',
  not_sent: 'Not Sent',
  sent: 'Sent',
  viewed: 'Viewed',
  past_due: 'Past Due',
  pending: 'Pending',
  paid: 'Paid',
  voided: 'Voided',
};

export type Invoice = {
  id: number;
  number: string;
  status: keyof typeof invoiceStatusMapper;
  date: DateString;
  dueDate: DateString;
  purchaseOrder: string;
  total: number;
  balance: number;
  url: string;
  paymentUrl: string;
  pdfUrl: string;
};

export type InvoicedLink = {
  url: string;
};

export const ShippingMethods = [
  {
    label: 'Most Popular',
    value: 'Most Popular',
    type: 'group',
  },
  {
    label: 'UPS Ground',
    value: 'UPS_Ground',
    type: 'option',
  },
  {
    label: 'UPS 3 Day Select',
    value: 'UPS_3 Day Select',
    type: 'option',
  },
  {
    label: 'UPS 2nd Day Air',
    value: 'UPS_2nd Day Air',
    type: 'option',
  },
  {
    label: 'UPS Next Day Air Saver',
    value: 'UPS_Next Day Air Saver',
    type: 'option',
  },
  {
    label: 'UPS - Domestic',
    value: 'UPS - Domestic',
    type: 'group',
  },
  {
    label: 'UPS Next Day Air',
    value: 'UPS_Next Day Air',
    type: 'option',
  },
  {
    label: 'UPS Next Day Air Early',
    value: 'UPS_Next Day Air Early',
    type: 'option',
  },
  {
    label: 'UPS 2nd Day Air AM',
    value: 'UPS_2nd Day Air AM',
    type: 'option',
  },
  {
    label: 'UPS - International',
    value: 'UPS - International',
    type: 'group',
  },
  {
    label: 'UPS Worldwide Express',
    value: 'UPS_Worldwide Express',
    type: 'option',
  },
  {
    label: 'UPS Worldwide Expedited',
    value: 'UPS_Worldwide Expedited',
    type: 'option',
  },
  {
    label: 'UPS Worldwide Saver',
    value: 'UPS_Worldwide Saver',
    type: 'option',
  },
  {
    label: 'UPS Standard',
    value: 'UPS_Standard',
    type: 'option',
  },
  {
    label: 'UPS Worldwide Express Plus',
    value: 'UPS_Worldwide Express Plus',
    type: 'option',
  },
  {
    label: 'UPS Ground (International)',
    value: 'UPS_Ground (International)',
    type: 'option',
  },
  {
    label: 'FedEx',
    value: 'FedEx',
    type: 'group',
  },
  {
    label: 'FedEx Ground',
    value: 'FedEx_Ground',
    type: 'option',
  },
  {
    label: 'FedEx 2Day',
    value: 'FedEx_2Day',
    type: 'option',
  },
  {
    label: 'FedEx 2Day A.M.',
    value: 'FedEx_2Day A.M.',
    type: 'option',
  },
  {
    label: 'FedEx Express Saver',
    value: 'FedEx_Express Saver',
    type: 'option',
  },
  {
    label: 'FedEx Standard Overnight',
    value: 'FedEx_Standard Overnight',
    type: 'option',
  },
  {
    label: 'FedEx Priority Overnight',
    value: 'FedEx_Priority Overnight',
    type: 'option',
  },
  {
    label: 'FedEx First Overnight',
    value: 'FedEx_First Overnight',
    type: 'option',
  },
  {
    label: 'Other',
    value: 'Other',
    type: 'group',
  },
  {
    label: 'Customer Specified In Notes',
    value: 'Customer_Customer',
    type: 'option',
  },
];
