import { skipToken } from '@reduxjs/toolkit/dist/query';
import useServerSideQuery, {
  ServerSideQueryParams,
} from 'hooks/useServerSideQuery';
import { useMemo } from 'react';
import { useGetAllocationsClientV2Query } from 'store/slices/clientV2/allocations';
import { useGetKitRequestLinesQuery } from 'store/slices/clientV2/kitRequests';
import { useGetHandlingRequestsV2Query } from 'store/slices/clientV2/handlingRequests';
import { useGetShipmentLinesQuery } from 'store/slices/clientV2/shipments';
import { ALL, Lite } from 'types/api';
import { AllocationSchema } from 'types/clientV2/allocations';
import { ShipmentLineSchema } from 'types/clientV2/shipments';
import { DatagridFilterOperatorsValues } from 'types/datagrid';
import { KitRequestLineSchema, KitRequestSchema } from 'types/kitting';
import { HandlingRequestSchema } from 'types/clientV2/stock';

export type KitRequestDataLite = Lite<
  KitRequestSchema,
  'shipTo' | 'sourceLocations' | 'kittedFor' | 'team' | 'org'
>;
export type HandlingRequestDataLite = Lite<HandlingRequestSchema>;

export type KitRequestLineDataLineLite = Lite<
  KitRequestLineSchema,
  'orgPart' | 'kitRequest'
>;
export type KitRequestLineDataAllocationLite = Lite<
  AllocationSchema,
  'stockLot'
>;
export type ShipmentLineDataLite = Lite<ShipmentLineSchema>;
export type DecoratedKitRequestLine = KitRequestLineDataLineLite & {
  allocations: KitRequestLineDataAllocationLite[];
  shipmentLines: ShipmentLineDataLite[];
  handlingRequests: HandlingRequestDataLite[];
};

type UseGetKitRequestLineDataProps = {
  selectedKitRequests: KitRequestSchema['id'][];
};

const getKitRequestLineQueryParams = (
  selectedKitRequests: KitRequestSchema['id'][]
): ServerSideQueryParams<KitRequestLineSchema, KitRequestLineDataLineLite> => ({
  schema: [
    ALL,
    {
      orgPart: [],
      kitRequest: ['id', 'approved'],
    },
  ],
  filters: [
    {
      field: 'kitRequest.id',
      operator: DatagridFilterOperatorsValues.isAnyOf,
      value: selectedKitRequests,
    },
  ],
});

const getAllocationQueryParams = (
  selectedKitRequests: KitRequestSchema['id'][]
): ServerSideQueryParams<
  AllocationSchema,
  KitRequestLineDataAllocationLite
> => ({
  schema: [
    ALL,
    {
      stockLot: [],
    },
  ],
  filters: [
    {
      field: 'kitRequestLine.kitRequest',
      operator: DatagridFilterOperatorsValues.isAnyOf,
      value: selectedKitRequests,
    },
  ],
});

const getShipmentLineQueryParams = (
  selectedKitRequests: KitRequestSchema['id'][]
): ServerSideQueryParams<ShipmentLineSchema, ShipmentLineDataLite> => ({
  schema: [ALL],
  filters: [
    {
      field: 'kitRequestLine.kitRequest',
      operator: DatagridFilterOperatorsValues.isAnyOf,
      value: selectedKitRequests,
    },
  ],
});

const getHandlingRequestQueryParams = (
  selectedKitRequests: KitRequestSchema['id'][]
): ServerSideQueryParams<HandlingRequestSchema, HandlingRequestDataLite> => ({
  schema: [ALL],
  filters: [
    {
      field: 'kitRequestLine.kitRequest',
      operator: DatagridFilterOperatorsValues.isAnyOf,
      value: selectedKitRequests,
    },
  ],
});

export const useGetKitRequestLineData = ({
  selectedKitRequests,
}: UseGetKitRequestLineDataProps) => {
  const { data: kitRequestLinesData, isLoading: isLoadingKitRequestLines } =
    useServerSideQuery<KitRequestLineSchema, KitRequestLineDataLineLite>(
      useGetKitRequestLinesQuery,
      selectedKitRequests.length > 0
        ? getKitRequestLineQueryParams(selectedKitRequests)
        : skipToken
    );

  const { data: allocationsData, isLoading: isLoadingAllocations } =
    useServerSideQuery<AllocationSchema, KitRequestLineDataAllocationLite>(
      useGetAllocationsClientV2Query,
      selectedKitRequests.length > 0
        ? getAllocationQueryParams(selectedKitRequests)
        : skipToken
    );

  const { data: shipmentLinesData, isLoading: isLoadingShipmentLines } =
    useServerSideQuery<ShipmentLineSchema, ShipmentLineDataLite>(
      useGetShipmentLinesQuery,
      selectedKitRequests.length > 0
        ? getShipmentLineQueryParams(selectedKitRequests)
        : skipToken
    );

  const { data: handlingRequestsData, isLoading: isLoadingHandlingRequests } =
    useServerSideQuery<HandlingRequestSchema, HandlingRequestDataLite>(
      useGetHandlingRequestsV2Query,
      selectedKitRequests.length > 0
        ? getHandlingRequestQueryParams(selectedKitRequests)
        : skipToken
    );

  const kitRequestLines =
    selectedKitRequests.length > 0 ? kitRequestLinesData?.data : undefined;

  const allocations =
    selectedKitRequests.length > 0 ? allocationsData?.data : undefined;

  const shipmentLines =
    selectedKitRequests.length > 0 ? shipmentLinesData?.data : undefined;

  const handlingRequests =
    selectedKitRequests.length > 0 ? handlingRequestsData?.data : undefined;

  const decoratedKitRequestLines = useMemo(
    () =>
      kitRequestLines
        ? (kitRequestLines.map((line) => ({
            ...line,
            allocations:
              allocations?.filter(
                (allocation) => allocation.kitRequestLine === line.id
              ) ?? [],
            shipmentLines:
              shipmentLines?.filter(
                (shipmentLine) => shipmentLine.kitRequestLine === line.id
              ) ?? [],
            handlingRequests:
              handlingRequests?.filter(
                (handlingRequest) => handlingRequest.kitRequestLine === line.id
              ) ?? [],
          })) as DecoratedKitRequestLine[])
        : undefined,
    [kitRequestLines, allocations, shipmentLines, handlingRequests]
  );

  return {
    kitRequestLines,
    allocations,
    decoratedKitRequestLines,
    isLoadingKitRequestLines,
    isLoadingAllocations,
    isLoadingShipmentLines,
    isLoadingHandlingRequests,
  };
};
