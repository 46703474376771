import { useParams } from 'react-router-dom';
import { FC } from 'react';
import { KitRequestProvider } from 'views/kitting/Kit/KitRequestContext';
import Kit from 'views/kitting/Kit/index';

const KitRequestWrapper: FC = () => {
  const { kitRequestId } = useParams<{ kitRequestId: string }>();

  return (
    <KitRequestProvider kitRequestId={kitRequestId}>
      <Kit />
    </KitRequestProvider>
  );
};

export default KitRequestWrapper;
