import { FC, RefAttributes, memo } from 'react';
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
} from '@mui/x-data-grid-premium';
import { PermissionScope } from 'types/apps';
import { Tooltip } from '@mui/material';
import usePermissionStateAndCopyHook from 'hooks/usePermissionStateAndCopyHook';

// RefAttributes required per issue when extending MUI props: https://github.com/mui/mui-x/issues/4654
type ExtendedGridActionsCellItemProps = {
  permissionScope?: PermissionScope;
  tooltip?: string;
} & GridActionsCellItemProps &
  RefAttributes<HTMLButtonElement>;

const ExtendedGridActionsCellItem: FC<ExtendedGridActionsCellItemProps> = ({
  permissionScope,
  tooltip,
  ...others
}) => {
  const { disabled } = others;
  const { permissionDisabled, disabledCopy } = usePermissionStateAndCopyHook({
    permissionScope,
  });

  return (
    <Tooltip title={disabledCopy || tooltip || ''}>
      <span>
        <GridActionsCellItem
          {...others}
          disabled={permissionDisabled || disabled}
        />
      </span>
    </Tooltip>
  );
};

export default memo(ExtendedGridActionsCellItem);
