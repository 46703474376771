import * as Yup from 'yup';
import { matchIsValidTel } from 'mui-tel-input';

export const locationTypes = {
  facility: {
    name: 'facility',
    label: 'Address',
  },
};

export const formConstants = {
  locationName: { id: 'locationName', label: 'Name' },
  locationType: { id: 'locationType', label: 'Type' },
  parentLocation: { id: 'parentLocation', label: 'Parent Location' },
  company: { id: 'company', label: 'Company Name' },
  name: { id: 'name', label: 'Contact Name / Attention' },
  line1: { id: 'line1', label: 'Address Line 1' },
  line2: { id: 'line2', label: 'Address Line 2' },
  city: { id: 'city', label: 'City' },
  state: { id: 'state', label: 'State' },
  postalCode: { id: 'postalCode', label: 'Postal Code' },
  country: { id: 'country', label: 'Country' },
  phone: { id: 'phone', label: 'Phone' },
  notificationEmail: { id: 'notificationEmail', label: 'Notification Email' },
  team: {
    id: 'team',
    label: 'Team',
  },
};

export const addressFieldIds = [
  formConstants.company.id,
  formConstants.name.id,
  formConstants.line1.id,
  formConstants.line2.id,
  formConstants.city.id,
  formConstants.state.id,
  formConstants.postalCode.id,
  formConstants.country.id,
  formConstants.phone.id,
];

export const validationSchema = Yup.object().shape({
  [formConstants.parentLocation.id]: Yup.string().max(
    255,
    'Parent Location must be under 255 characters'
  ),
  [formConstants.locationType.id]: Yup.string()
    .max(255, 'Type must be under 255 characters')
    .required('Type is required'),
  [formConstants.locationName.id]: Yup.string()
    .max(255, 'Location Name must be under 255 characters')
    .required('Location Name is required'),
  [formConstants.company.id]: Yup.string().max(
    255,
    'Company must be under 255 characters'
  ),
  [formConstants.name.id]: Yup.string()
    .max(255, 'Contact Name must be under 255 characters')
    .required('Contact Name is required'),
  [formConstants.country.id]: Yup.string()
    .max(255, 'Country must be under 255 characters')
    .required('Must enter country'),
  [formConstants.state.id]: Yup.string()
    .max(255, 'State must be under 255 characters')
    .required('Must enter state'),
  [formConstants.city.id]: Yup.string()
    .max(255, 'City must be under 255 characters')
    .required('Must enter city'),
  [formConstants.postalCode.id]: Yup.string()
    .max(255, 'Postal code must be under 255 characters')
    .required('Must enter postal code'),
  [formConstants.line1.id]: Yup.string()
    .max(255, 'Address line 1 must be under 255 characters')
    .required('Must enter address line 1'),
  [formConstants.line2.id]: Yup.string().max(
    255,
    'Address line 2 must be under 255 characters'
  ),
  [formConstants.phone.id]: Yup.string()
    .max(32, 'Phone number must be under 32 characters')
    .required('Must enter phone number')
    .test('is-valid-phone', 'Enter a valid phone number.', (value) =>
      matchIsValidTel(value || '')
    ),
  [formConstants.notificationEmail.id]: Yup.string().email(
    'Must be a valid email'
  ),
  consumesStock: Yup.boolean().required('Please select an option'),
  requiresSplits: Yup.boolean().required('Please select an option'),
  packaging: Yup.object().shape({
    requiresMerges: Yup.boolean(),
    requiresReels: Yup.boolean(),
  }),
  [formConstants.team.id]: Yup.string(),
});
