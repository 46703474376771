import { useState } from 'react';
import { handleErr } from 'utils/functions';
import useSnackbar from 'hooks/useSnackbar';
import {
  ClientV2ToggleRequest,
  GenericResource,
  ToggleMutation,
} from 'types/api';
import { ToggleRecordActionStrings } from 'ui-component/clientV2/ToggleRecordDialog';

export type UseToggleRecordProps<T extends GenericResource> = {
  useToggleTrueMutation: ToggleMutation<T>;
  useToggleFalseMutation: ToggleMutation<T>;
  recordName: string;
  actionStrings: Pick<
    ToggleRecordActionStrings,
    'successToggledTrue' | 'successToggledFalse'
  >;
  id: string;
  isCurrentlyTrue: boolean;
  onSuccess?: () => void;
};

/**
 * useToggleRecord
 * To be used specifically with Client V2 api & ToggleRecordDialog
 * Used for things like Approve/Unapprove, Archive/Unarchive, Lock/Unlock
 */
const useToggleRecord = <T extends GenericResource>({
  useToggleTrueMutation,
  useToggleFalseMutation,
  recordName,
  actionStrings,
  id,
  isCurrentlyTrue,
  onSuccess,
}: UseToggleRecordProps<T>) => {
  const [toggleTrue, { isLoading: isLoadingTrue }] = useToggleTrueMutation();
  const [toggleFalse, { isLoading: isLoadingFalse }] = useToggleFalseMutation();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { dispatchSuccessSnackbar } = useSnackbar();

  const handleApprove = async () => {
    try {
      const payload = { ids: [id] } as ClientV2ToggleRequest<T>;
      if (isCurrentlyTrue) {
        await toggleFalse(payload).unwrap();
        dispatchSuccessSnackbar(
          `${recordName} ${actionStrings.successToggledFalse}`
        );
      } else {
        await toggleTrue(payload).unwrap();
        dispatchSuccessSnackbar(
          `${recordName} ${actionStrings.successToggledTrue}`
        );
      }
      if (onSuccess) onSuccess();
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        setSubmitError(errMessage);
      });
    }
  };

  return {
    handleApprove,
    isLoading: isLoadingTrue || isLoadingFalse,
    submitError,
    resetSubmitError: () => setSubmitError(null),
  };
};

export default useToggleRecord;
