import { RenderEditWithEmptyCellProps } from 'ui-component/DataGrid/Render/RenderEditWithOnRender';
import { useEffect } from 'react';
import { Typography } from '@mui/material';

const EditWithOnRender = ({
  params,
  onRender,
  renderMethod,
}: RenderEditWithEmptyCellProps) => {
  useEffect(() => {
    onRender({ params });
  }, []);

  return (
    <>
      {renderMethod ? (
        <>{renderMethod(params)}</>
      ) : (
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.formattedValue ?? params.value}
        </Typography>
      )}
    </>
  );
};

export default EditWithOnRender;
