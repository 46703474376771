import { PartId } from 'types/part';
import { useSelector } from 'store';
import { selectPartStatusRequestBySchemaForList } from 'store/slices/partStatus';
import {
  PartSchemas,
  partStatusRequestTypes,
  PartSupplyRetryStatus,
} from 'types/partStatus';
import _ from 'lodash';
import { pluralizeNoun } from 'utils/functions';

export const getResourceViewFailureText = (numParts?: number) => ({
  [PartSupplyRetryStatus.RETRY_FAILED]: `Some supplier data did not load${
    numParts
      ? ` for ${numParts} ${pluralizeNoun('part', numParts)} or alt parts`
      : ''
  }. Please refresh the page, or reach out to Cofactr Success.`,
  [PartSupplyRetryStatus.KB_FAILURE]: `Some supplier data did not load${
    numParts
      ? ` for ${numParts} ${pluralizeNoun('part', numParts)} or alt parts`
      : ''
  }. Please retry later, or reach out to Cofactr Success.`,
});

const getRetryStatus = ({
  partDataRequestStatus,
}: {
  partDataRequestStatus?: partStatusRequestTypes;
}) => {
  if (!partDataRequestStatus) {
    return null;
  }
  if (partDataRequestStatus === partStatusRequestTypes.RETRY_NOW_FAILED) {
    return PartSupplyRetryStatus.RETRY_FAILED;
  }
  if (partDataRequestStatus === partStatusRequestTypes.KB_FAILURE) {
    return PartSupplyRetryStatus.KB_FAILURE;
  }
  return null;
};

const getRetryStatusForList = (retryStatusList: PartSupplyRetryStatus[]) => {
  if (retryStatusList.includes(PartSupplyRetryStatus.KB_FAILURE)) {
    return PartSupplyRetryStatus.KB_FAILURE;
  }
  if (retryStatusList.includes(PartSupplyRetryStatus.RETRY_FAILED)) {
    return PartSupplyRetryStatus.RETRY_FAILED;
  }
  return null;
};

const getRetryStatusesForList = ({
  partDataRequestStatusByPartId,
}: {
  partDataRequestStatusByPartId: { [key in PartId]?: partStatusRequestTypes };
}) => {
  const partIds = _.keys(partDataRequestStatusByPartId);
  return _.reduce(
    partIds,
    (acc, partId: PartId) => {
      if (partDataRequestStatusByPartId[partId]) {
        const status = getRetryStatus({
          partDataRequestStatus: partDataRequestStatusByPartId[partId],
        });
        if (status) {
          acc.push(status);
        }
      }
      return acc;
    },
    [] as PartSupplyRetryStatus[]
  );
};

export function useGetRetryDirectiveStatus(partIds: PartId[]) {
  const partDataSupplyStatuses = useSelector(
    (state) =>
      selectPartStatusRequestBySchemaForList(
        state,
        PartSchemas.supply,
        partIds
      ),
    _.isEqual
  );

  const retryStatusList = getRetryStatusesForList({
    partDataRequestStatusByPartId: partDataSupplyStatuses,
  });

  const retryStatus = getRetryStatusForList(retryStatusList);
  const totalPartsWithFailures = _.filter(retryStatusList, (status) =>
    [
      PartSupplyRetryStatus.RETRY_FAILED,
      PartSupplyRetryStatus.KB_FAILURE,
    ].includes(status)
  );

  return {
    retryStatus,
    totalNumberPartsWithFailures: totalPartsWithFailures?.length,
  };
}
