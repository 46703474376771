import { DateString, DateTimeString } from 'types/index';
import { Org } from 'types/org';
import { ProductionRun } from 'types/production';
import { User } from 'types/user';
import { Snapshot } from 'types/purchasing';
import { Lite, PATCHSchema, POSTSchema } from 'types/api';
import { Team } from 'types/properties';
import { OrgPart } from 'types/part';
import { StockLocationSchemaLite } from 'types/clientV2/stockLocations';

export type KitRequestSchema = {
  id: string;
  name: string | null;
  internalNotes: string | null;
  externalNotes: string | null;
  printedNotes: string | null;
  approved: boolean | null;
  fulfilledAt: DateTimeString | null;
  voidedAt: DateTimeString | null;
  approvedAt: DateTimeString | null;
  archived: DateTimeString | null;
  carrier: string | null;
  service: string | null;
  shipPartial: boolean;
  targetShipDate: DateString | null;
  org: Org;
  processingOrg: Org;
  productionRun: ProductionRun;
  createdBy: User | null;
  approvedBy: User | null;
  voidedBy: User | null;
  sourceLocations: StockLocationSchemaLite[];
  shipTo: StockLocationSchemaLite;
  kittedFor: Snapshot;
  team: Team | null;
  locked: boolean | null;
};

export type KitRequestPOSTSchema = POSTSchema<
  KitRequestSchema,
  'name',
  | 'internalNotes'
  | 'externalNotes'
  | 'printedNotes'
  | 'shipPartial'
  | 'targetShipDate'
  | 'carrier'
  | 'service'
  | 'productionRun'
  | 'shipTo'
  | 'sourceLocations'
  | 'kittedFor'
>;

export type KitRequestPATCHSchema = PATCHSchema<KitRequestPOSTSchema>;

export type KitRequestLineSchema = {
  id: string;
  part: string;
  altParts: string[];
  unapprovedAltParts: string[];
  minimumQuantity: number | null;
  targetQuantity: number | null;
  internalNotes: string | null;
  externalNotes: string | null;
  orgPart: OrgPart;
  printedNotes: string | null;
  targetPackage: string;
  fulfilledAt: DateTimeString | null;
  kitRequest: Lite<KitRequestSchema>;
  fulfilledBy: User | null;
  locked: boolean;
};

export type KitRequestLinePOSTSchema = POSTSchema<
  KitRequestLineSchema,
  'part' | 'kitRequest'
>;
export type KitRequestLinePATCHSchema = KitRequestLinePOSTSchema & {
  id: string;
};

export enum KitRequestStatus {
  DRAFT = 'draft',
  APPROVED = 'approved',
  FULFILLED = 'fulfilled',
  VOIDED = 'cancelled',
}
