import { MenuItem, FormHelperText, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { countryOptions } from 'constants/countryOptions';
import _ from 'lodash';
import { MuiTelInput } from 'mui-tel-input';
import { FC } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';
import {
  HookFormAutocomplete,
  HookFormInput,
  HookFormSelect,
  HookFormTeamAutocomplete,
} from 'ui-component/HookFormComponents';
import {
  formConstants,
  locationTypes,
} from 'views/inventory/components/CreateUpdateFacilityDialog/constants';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { ALL_APP_IDS } from 'constants/appConstants';
import { Team } from 'types/properties';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import { HookFormCustomPropertyInput } from 'ui-component/HookFormComponents/HookFormCustomPropertyInput';
import { CustomProperty } from 'types/customProperty';

export const AddressFields: FC<{
  errors: FieldErrors;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
  touchedFields: Record<string, boolean>;
  teamId?: Team['id'];
  customProperties: CustomProperty[];
}> = ({
  control,
  errors,
  setValue,
  touchedFields,
  teamId,
  customProperties,
}) => {
  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  return (
    <>
      <HookFormSelect
        control={control}
        name={formConstants.locationType.id}
        label={formConstants.locationType.label}
        errors={errors}
        sx={{ mt: 0, mb: 2 }}
        disabled
      >
        <MenuItem value={locationTypes.facility.name}>
          {locationTypes.facility.label}
        </MenuItem>
      </HookFormSelect>
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.company.id}
        label={formConstants.company.label}
      />
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.name.id}
        label={formConstants.name.label}
      />
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.line1.id}
        label={formConstants.line1.label}
      />
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.line2.id}
        label={formConstants.line2.label}
      />
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.city.id}
        label={formConstants.city.label}
      />
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.state.id}
        label={formConstants.state.label}
      />
      <HookFormInput
        errors={errors}
        control={control}
        name={formConstants.postalCode.id}
        label={formConstants.postalCode.label}
      />
      <HookFormAutocomplete<{ name: string; code: string }>
        control={control}
        getOptionLabel={(option) => option.name}
        label={formConstants.country.label}
        name={formConstants.country.id}
        options={countryOptions}
        errors={errors}
        handleChange={(option) => {
          setValue(formConstants.country.id, option.code);
        }}
        initialValueMatcher={(value) =>
          _.find(countryOptions, (country) => country.code === value) ?? null
        }
      />
      <Controller
        name={formConstants.phone.id}
        control={control}
        render={({ field: { value, onBlur } }) => (
          <Box sx={{ mb: 2 }}>
            <MuiTelInput
              fullWidth
              id={formConstants.phone.id}
              name={formConstants.phone.id}
              value={value}
              onBlur={onBlur}
              onChange={(phoneVal: string) => {
                setValue(formConstants.phone.id, phoneVal ?? '');
              }}
              label={formConstants.phone.label}
              error={
                Object.keys(touchedFields).includes(formConstants.phone.id) &&
                Boolean(errors?.phone)
              }
              preferredCountries={['US']}
              defaultCountry="US"
            />
            {errors?.[formConstants.phone.id]?.message && (
              <FormHelperText error id={`${formConstants.phone.id}Error`}>
                {errors?.[formConstants.phone.id]?.message}
              </FormHelperText>
            )}
          </Box>
        )}
      />
      <HookFormInput
        control={control}
        errors={errors}
        name={formConstants.notificationEmail.id}
        label={formConstants.notificationEmail.label}
        boxSx={{ mb: 2 }}
      />
      {propertiesAppEnabled && (
        <HookFormTeamAutocomplete
          errors={errors}
          control={control}
          name={formConstants.team.id}
          label={handleReplaceWithCustomTeamName(formConstants.team.label)}
          defaultValue={teamId ?? ''}
          setValue={setValue}
        />
      )}
      <Stack sx={{ mt: 2 }}>
        {_.map(customProperties, (property) => (
          <HookFormCustomPropertyInput
            control={control}
            errors={errors}
            key={property.key}
            customProperty={property}
            setValue={setValue}
          />
        ))}
      </Stack>
    </>
  );
};
