import { useEffect } from 'react';
import { DialogContentText } from '@mui/material';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { DeleteMutation, GenericResource } from 'types/api';
import useDeleteRecord from 'hooks/clientV2/useDeleteRecord';
import { COLOR_OPTIONS } from 'types';

interface DeleteRecordDialogProps<T extends GenericResource> {
  id: string;
  dialogOpen: boolean;
  onClose: () => void;
  recordName: string;
  message?: string;
  useClientDeleteMutation: DeleteMutation<T>;
  onSuccess?: () => void;
}

/**
 * DeleteDialog
 * To be used specifically with Client V2 api
 */
const DeleteRecordDialog = <T extends GenericResource>({
  id,
  dialogOpen,
  onClose,
  recordName,
  message,
  useClientDeleteMutation,
  onSuccess,
}: DeleteRecordDialogProps<T>) => {
  const handleSuccess = () => {
    onSuccess && onSuccess();
    onClose();
  };

  const { handleDelete, isLoading, submitError, resetSubmitError } =
    useDeleteRecord<T>({
      useClientDeleteMutation,
      recordName,
      id,
      onSuccess: handleSuccess,
    });

  useEffect(() => {
    if (!dialogOpen) {
      resetSubmitError();
    }
  }, [dialogOpen]);

  return (
    <ExtendedDialog
      title={`Delete ${recordName}`}
      open={dialogOpen}
      onCloseDialog={onClose}
      submitButtonCopy="Delete"
      submitButtonColor={COLOR_OPTIONS.error}
      onClickPrimaryButton={handleDelete}
      formSubmitError={submitError}
      isSubmitting={isLoading}
    >
      <DialogContentText>
        {message || `Are you sure you want to delete this ${recordName}?`}
      </DialogContentText>
    </ExtendedDialog>
  );
};

export default DeleteRecordDialog;
