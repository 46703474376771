import MainCard from 'ui-component/cards/MainCard';
import { ReactNode } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNewVersion } from 'contexts/NewVersionContext';
import { useSelector } from 'store';

const FullHeightMainCardWrapper = ({ children }: { children: ReactNode }) => {
  const { showNewVersion } = useNewVersion();
  const { showNoPlanBanner } = useSelector((state) => state.utility);

  const height =
    showNewVersion || showNoPlanBanner
      ? 'calc(100vh - 70px)'
      : 'calc(100vh - 40px)';

  return (
    <MainCard
      sx={{ height }}
      contentSX={{ height: '100%', p: 0, '&:last-child': { p: 0 } }}
    >
      <PerfectScrollbar component="div">{children}</PerfectScrollbar>
    </MainCard>
  );
};

export default FullHeightMainCardWrapper;
