import { useAppAccessContext } from 'contexts/AppAccessContext';
import MainCard from 'ui-component/cards/MainCard';
import { CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/constants';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { getDisabledPermissionCopy } from 'utils/permissions';
import { PermissionScope } from 'types/apps';
import { ReactElement } from 'react';
import OnboardingDialog from 'ui-component/OnboardingDialog';

const PermissionsRouteWrapper = ({
  permissionScope,
  children,
}: {
  permissionScope?: PermissionScope;
  children: ReactElement;
}) => {
  const { isPermissionDisabled, getDisabledReason, isLoading } =
    useAppAccessContext();

  if (isPermissionDisabled(permissionScope)) {
    const disabledCopy = getDisabledPermissionCopy(
      getDisabledReason(permissionScope!)
    );

    return (
      <Box>
        <OnboardingDialog />
        {isLoading ? (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs="auto"
              sx={{ marginTop: '20px', marginBottom: '20px' }}
            >
              <CircularProgress size={90} thickness={2.5} color="primary" />
            </Grid>
          </Grid>
        ) : (
          <MainCard>
            <CardContent>
              <Stack spacing={2} justifyContent="center">
                <Typography variant="h3">{disabledCopy?.message}</Typography>
                <Typography variant="subtitle1">
                  {disabledCopy?.action}
                </Typography>
                <span>
                  <SegmentButton variant="contained">
                    <Link
                      to={DASHBOARD_ROUTE}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      Go to Dashboard
                    </Link>
                  </SegmentButton>
                </span>
              </Stack>
            </CardContent>
          </MainCard>
        )}
      </Box>
    );
  }

  return children;
};

export default PermissionsRouteWrapper;
