import { ALL_APPS, ALL_VIEW_IDS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import HotglueProvider from 'contexts/HotglueProvider';

const IntegrationsLegacy = Loadable(
  lazy(() => import('views/integrations/views/Legacy'))
);
const IntegrationsMarketplace = Loadable(
  lazy(() => import('views/integrations/views/Marketplace'))
);
const IntegrationsDesigner = Loadable(
  lazy(() => import('views/integrations/views/Designer'))
);

export const IntegrationsRoutes = [
  {
    path: `${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_LEGACY?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.INTEGRATIONS.id,
            view: ALL_VIEW_IDS.INTEGRATIONS_LEGACY,
          }}
        >
          <HotglueProvider>
            <IntegrationsLegacy />
          </HotglueProvider>
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.INTEGRATIONS.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.INTEGRATIONS.id,
        }}
      >
        <Navigate
          to={`${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_MARKETPLACE?.path}`}
          replace
        />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_MARKETPLACE?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.INTEGRATIONS.id,
          view: ALL_VIEW_IDS.INTEGRATIONS_MARKETPLACE,
        }}
      >
        <IntegrationsMarketplace />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.INTEGRATIONS.views?.INTEGRATIONS_DESIGNER?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.INTEGRATIONS.id,
          view: ALL_VIEW_IDS.INTEGRATIONS_DESIGNER,
        }}
      >
        <IntegrationsDesigner />
      </PermissionsRouteWrapper>
    ),
  },
];
