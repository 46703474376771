import { ALL_APPS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Redirect } from 'react-router6-redirect';

const PartsSearch = Loadable(
  lazy(() => import('views/parts/PartsSearchTable'))
);
const PartWrapper = Loadable(lazy(() => import('views/parts/PartWrapper')));

export const PartSearchRoutes = [
  {
    path: `${ALL_APPS.PART_SEARCH.deprecatedPath}`,
    element: <Redirect to={`${ALL_APPS.PART_SEARCH.path}`} />,
  },
  {
    path: `${ALL_APPS.PART_SEARCH.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PART_SEARCH.id }}
        >
          <PartsSearch />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.PART_SEARCH.deprecatedPath}/:partId`,
    element: <Redirect to={`${ALL_APPS.PART_SEARCH.path}/:partId`} />,
  },
  {
    path: `${ALL_APPS.PART_SEARCH.path}/:partId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.PART_SEARCH.id }}
      >
        <PartWrapper />
      </PermissionsRouteWrapper>
    ),
  },
];
