import { IconFileDownload } from '@tabler/icons-react';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';

type InlineDownloadButtonProps = {
  link: string | undefined | null;
  disabled?: boolean;
};

const InlineDownloadButton = ({
  link,
  disabled,
}: InlineDownloadButtonProps) => (
  <SegmentIconButton
    disabled={disabled}
    onClick={() => {
      if (link) {
        window.open(link, '_blank', 'noopener,noreferrer');
      }
    }}
    size="small"
  >
    <IconFileDownload width={20} height={20} />
  </SegmentIconButton>
);

export default InlineDownloadButton;
