import { ALL_APPS, ALL_VIEW_IDS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import NoSubOrgRouteWrapper from 'views/pages/components/NoSubOrgRouteWrapper';

const BillingInvoices = Loadable(lazy(() => import('views/billing/invoices')));
const BillingPlan = Loadable(lazy(() => import('views/billing/plan')));
const BillingUsage = Loadable(lazy(() => import('views/billing/usage')));
const BillingPayments = Loadable(lazy(() => import('views/billing/payments')));

export const BillingRoutes = [
  {
    path: `${ALL_APPS.BILLING.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.BILLING.id,
          view: ALL_VIEW_IDS.BILLING_USAGE,
        }}
      >
        <Navigate
          to={`${ALL_APPS.BILLING.views?.BILLING_USAGE?.path}`}
          replace
        />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.BILLING.views?.BILLING_USAGE?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.BILLING.id,
          view: ALL_VIEW_IDS.BILLING_USAGE,
        }}
      >
        <NoSubOrgRouteWrapper>
          <BillingUsage />
        </NoSubOrgRouteWrapper>
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.BILLING.views?.BILLING_PLAN?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.BILLING.id,
          view: ALL_VIEW_IDS.BILLING_PLAN,
        }}
      >
        <NoSubOrgRouteWrapper>
          <BillingPlan />
        </NoSubOrgRouteWrapper>
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.BILLING.views?.BILLING_INVOICES?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.BILLING.id,
            view: ALL_VIEW_IDS.BILLING_INVOICES,
          }}
        >
          <NoSubOrgRouteWrapper>
            <BillingInvoices />
          </NoSubOrgRouteWrapper>
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.BILLING.views?.BILLING_PAYMENTS?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.BILLING.id,
          view: ALL_VIEW_IDS.BILLING_PAYMENTS,
        }}
      >
        <NoSubOrgRouteWrapper>
          <BillingPayments />
        </NoSubOrgRouteWrapper>
      </PermissionsRouteWrapper>
    ),
  },
];
