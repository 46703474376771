import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const TeamsTable = Loadable(
  lazy(() => import('views/properties/Teams/TeamsTable'))
);
const TeamsPreferences = Loadable(
  lazy(() => import('views/properties/Teams/TeamPreferences'))
);
const CustomProperties = Loadable(
  lazy(() => import('views/properties/CustomProperties/CustomPropertiesTable'))
);

export const PropertiesRoutes = [
  {
    path: `${ALL_APPS.PROPERTIES.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.PROPERTIES.id,
          view: ALL_VIEW_IDS.TEAMS_TABLE,
        }}
      >
        <Navigate to={`${ALL_VIEWS.TEAMS_TABLE.path}`} replace />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.TEAMS_TABLE.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PROPERTIES.id,
            view: ALL_VIEW_IDS.TEAMS_TABLE,
          }}
        >
          <TeamsTable />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.TEAMS_PREFERENCES.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PROPERTIES.id,
            view: ALL_VIEW_IDS.TEAMS_PREFERENCES,
          }}
        >
          <TeamsPreferences />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.CUSTOM_PROPERTIES.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.PROPERTIES.id,
            view: ALL_VIEW_IDS.CUSTOM_PROPERTIES,
          }}
        >
          <CustomProperties />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
