import { TextField, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';

function MSLFloorHoursLeftFilterInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const [input, setInput] = useState(
    item.value !== undefined && item.value !== null ? item.value.toString() : ''
  );

  useEffect(() => {
    setInput(
      item.value !== undefined && item.value !== null
        ? item.value.toString()
        : ''
    );
  }, [item.value]);

  const handleDebounce = useCallback(
    _.debounce((value) => {
      applyValue({ ...item, value });
    }, 500),
    [applyValue, item]
  );

  const handleFilterChange = useCallback(
    (event) => {
      const value = event.target.value;
      setInput(value);
      handleDebounce(value);
    },
    [handleDebounce]
  );

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
      }}
    >
      <TextField
        autoFocus
        value={input}
        type="number"
        label="Value"
        size="small"
        variant="standard"
        onChange={handleFilterChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="body1">(hours)</Typography>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default MSLFloorHoursLeftFilterInput;
