import { getTrackingStrings } from 'constants/trackingStrings';

const {
  closeCreateUpdateLocationDialog,
  cancelCreateUpdateLocation,
  createLocation,
  updateLocation,
} = getTrackingStrings();

export const getCopy = (isCreating: boolean) => ({
  trackingNames: {
    close: closeCreateUpdateLocationDialog,
    cancel: cancelCreateUpdateLocation,
    save: isCreating ? createLocation : updateLocation,
  },
  title: isCreating ? 'New Address' : 'Edit Address',
  submitButtonCopy: isCreating ? 'Submit' : 'Save',
  successMessage: isCreating ? 'Address created' : 'Address updated',
});
