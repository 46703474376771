import { Avatar, Box, Divider, Drawer, Grid, Stack } from '@mui/material';
import {
  FC,
  ReactNode,
  createContext,
  useState,
  Fragment,
  useMemo,
} from 'react';
import MainCard from 'ui-component/cards/MainCard';
import ExtendedTabs, {
  ExtendedTabOption,
} from 'ui-component/extended/ExtendedTabs';
import { useTheme } from '@mui/material/styles';
import { actionButtonSpacing } from 'constants/themeConstants';
import SidebarInfoSection, {
  SidebarInfoSectionProps,
} from 'ui-component/DetailLayout/SidebarComponents/SidebarInfoSection';
import _ from 'lodash';
import {
  IconLayoutBottombarCollapse,
  IconLayoutBottombarExpand,
  IconLayoutNavbarCollapse,
  IconLayoutNavbarExpand,
  IconLayoutSidebarRightCollapse,
  IconLayoutSidebarRightExpand,
} from '@tabler/icons-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const DrawerContext = createContext(false);

export interface DetailLayoutProps {
  headerSection?: ReactNode;
  headerBottomContent?: ReactNode;
  footerSection?: ReactNode;
  tabOptions?: ExtendedTabOption[];
  sidebarActionButtons?: ReactNode[];
  sidebarInfoSections?: SidebarInfoSectionProps[];
  additionalSidebarSections?: ReactNode[];
  tabTrackingName?: string;
}

const DetailLayout: FC<DetailLayoutProps> = ({
  headerSection,
  headerBottomContent,
  footerSection,
  tabOptions,
  sidebarActionButtons = [],
  sidebarInfoSections = [],
  additionalSidebarSections = [],
  tabTrackingName,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [headerOpen, setHeaderOpen] = useState(true);
  const [footerOpen, setFooterOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const sidebarItemsWhenCollapsed = _.filter(
    sidebarInfoSections,
    (section) => !!section.Icon && !!section.count
  );

  const showInfoSectionDivider = useMemo(
    () =>
      open
        ? sidebarInfoSections.length > 0 || additionalSidebarSections.length > 0
        : sidebarItemsWhenCollapsed.length > 0,
    [
      open,
      sidebarItemsWhenCollapsed,
      sidebarInfoSections,
      additionalSidebarSections,
    ]
  );

  const visibleSidebarInfoSections = sidebarInfoSections.filter(
    (section) => section.visible !== false
  );

  const collapsedDrawerWidth = 69;
  const drawerWidth = 260;

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          width: '100%',
          height: 'calc(100% + 40px)',
          mt: '-20px',
          mb: '-20px',
          py: 0,
          pr: `${open ? drawerWidth : collapsedDrawerWidth}px`,
        }}
      >
        {headerSection && (
          <Grid item xs="auto">
            <Stack
              direction="column"
              spacing={0}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {headerOpen && (
                <MainCard
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    width: '100%',
                  }}
                  bottomContent={headerBottomContent}
                >
                  {headerSection}
                </MainCard>
              )}
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  overflow: 'hidden',
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.brand.moss,
                  color: theme.palette.brand.steel,
                  borderTopLeftRadius: headerOpen ? undefined : 0,
                  borderTopRightRadius: headerOpen ? undefined : 0,
                  marginTop: headerOpen ? '-17px' : 0,
                  '&:hover': {
                    background: theme.palette.primary.dark,
                    color: theme.palette.common.white,
                  },
                }}
                onClick={() =>
                  setHeaderOpen((prevHeaderOpen) => !prevHeaderOpen)
                }
                color="inherit"
              >
                {headerOpen ? (
                  <IconLayoutNavbarCollapse stroke={1.5} size="1.3rem" />
                ) : (
                  <IconLayoutNavbarExpand stroke={1.5} size="1.3rem" />
                )}
              </Avatar>
            </Stack>
          </Grid>
        )}
        {tabOptions && (
          <Grid
            item
            xs
            sx={{
              mt: headerSection ? '8px' : '0px',
              mb: footerSection ? '8px' : '0px',
            }}
          >
            <MainCard contentSX={{ p: 0, '&:last-child': { p: 0 } }}>
              <ExtendedTabs
                tabOptions={tabOptions}
                queryParam="tab"
                trackingName={tabTrackingName}
              />
            </MainCard>
          </Grid>
        )}

        {footerSection && (
          <Grid item xs="auto">
            <Stack
              direction="column"
              spacing={0}
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  overflow: 'hidden',
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.brand.moss,
                  color: theme.palette.brand.steel,
                  borderBottomLeftRadius: footerOpen ? undefined : 0,
                  borderBottomRightRadius: footerOpen ? undefined : 0,
                  marginBottom: footerOpen ? '-17px' : 0,
                  '&:hover': {
                    background: theme.palette.primary.dark,
                    color: theme.palette.common.white,
                  },
                }}
                onClick={() =>
                  setFooterOpen((prevFooterOpen) => !prevFooterOpen)
                }
                color="inherit"
              >
                {footerOpen ? (
                  <IconLayoutBottombarCollapse stroke={1.5} size="1.3rem" />
                ) : (
                  <IconLayoutBottombarExpand stroke={1.5} size="1.3rem" />
                )}
              </Avatar>
              {footerOpen && (
                <MainCard
                  sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    width: '100%',
                  }}
                >
                  {footerSection}
                </MainCard>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
      {(sidebarActionButtons.length > 0 ||
        sidebarInfoSections.length > 0 ||
        additionalSidebarSections.length > 0) && (
        <Drawer
          container={container}
          variant="permanent"
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
          sx={{
            '& .MuiDrawer-paper': {
              width: open ? drawerWidth : collapsedDrawerWidth,
              background: theme.palette.background.default,
              color: theme.palette.text.primary,
              borderRight: 'none',
              height: '100%',
            },
          }}
          ModalProps={{ keepMounted: true }}
          color="inherit"
        >
          <Box
            sx={{
              position: 'fixed',
              top: '16px',
              right: `${
                (open ? drawerWidth : collapsedDrawerWidth) - 34 / 2
              }px`,
              zIndex: 100000,
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                background: theme.palette.brand.moss,
                color: theme.palette.brand.steel,
                '&:hover': {
                  background: theme.palette.primary.dark,
                  color: theme.palette.common.white,
                },
              }}
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              color="inherit"
            >
              {open ? (
                <IconLayoutSidebarRightCollapse stroke={1.5} size="1.3rem" />
              ) : (
                <IconLayoutSidebarRightExpand stroke={1.5} size="1.3rem" />
              )}
            </Avatar>
          </Box>
          <DrawerContext.Provider value={open}>
            <Grid
              container
              direction="column"
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                mb: actionButtonSpacing,
                height: '100%',
              }}
            >
              <Grid item xs="auto">
                <Box sx={{ width: '100%', height: '36px' }} />
              </Grid>
              <Grid item xs="auto" sx={{ px: '16px' }}>
                <Stack
                  direction="column"
                  spacing={actionButtonSpacing}
                  sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  {sidebarActionButtons.map((button, index) => (
                    <Box key={index}>{button}</Box>
                  ))}
                </Stack>
              </Grid>
              {showInfoSectionDivider && (
                <Divider sx={{ mt: 1, mb: 1 }} flexItem />
              )}
              <Grid item xs>
                <PerfectScrollbar component="div">
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={actionButtonSpacing}
                    sx={{ mx: '16px', mt: actionButtonSpacing }}
                  >
                    {open &&
                      _.map(visibleSidebarInfoSections, (section, index) => (
                        <Fragment key={`${section.caption}-${index}`}>
                          <SidebarInfoSection {...section} />
                          {(section.visible ||
                            section.visible === undefined) && (
                            <Divider flexItem />
                          )}
                        </Fragment>
                      ))}
                    {open &&
                      _.map(additionalSidebarSections, (section, index) => (
                        <Fragment key={index}>{section}</Fragment>
                      ))}
                    {!open &&
                      sidebarItemsWhenCollapsed.length > 0 &&
                      _.map(sidebarItemsWhenCollapsed, (section, index) => (
                        <SidebarInfoSection
                          {...section}
                          key={`${section.caption}-${index}`}
                        />
                      ))}
                  </Stack>
                </PerfectScrollbar>
              </Grid>
            </Grid>
          </DrawerContext.Provider>
        </Drawer>
      )}
    </>
  );
};

export default DetailLayout;
