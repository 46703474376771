import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import RelatedRecords from 'ui-component/shared/RelatedRecords';

interface RelatedRecordRenderCellProps extends GridRenderCellParams {
  onOpenEditRelatedRecords?: () => void;
}

export const RelatedRecordRenderCell = ({
  value,
  onOpenEditRelatedRecords,
}: RelatedRecordRenderCellProps) => (
  <RelatedRecords
    relatedRecords={value}
    onOpenEditRelatedRecords={onOpenEditRelatedRecords}
  />
);
