import { Stack, Tooltip, Typography } from '@mui/material';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { PermissionScope } from 'types/apps';

export function renderNumberWithStrikethrough(
  currentValue: number,
  strikethroughValue: number | null,
  tooltip?: string,
  onClick?: () => void,
  clickDisabled?: boolean,
  currentValueColor?: string,
  strikethroughColor?: string,
  permissionScope?: PermissionScope
) {
  if (onClick && !clickDisabled) {
    return (
      <Tooltip title={tooltip || ''}>
        <SegmentButton
          onClick={onClick}
          sx={{ width: '100%', height: '100%' }}
          permissionScope={permissionScope}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ width: '100%', height: '100%' }}
          >
            {Boolean(strikethroughValue) && (
              <Typography
                variant="caption"
                sx={{
                  textDecoration: 'line-through',
                  color: strikethroughColor ?? 'default',
                }}
              >
                {strikethroughValue?.toLocaleString('en-US')}
              </Typography>
            )}
            <Typography
              variant="body1"
              sx={{ color: currentValueColor ?? 'default' }}
            >
              {currentValue.toLocaleString('en-US')}
            </Typography>
          </Stack>
        </SegmentButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip || ''}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ width: '100%', height: '100%' }}
      >
        {Boolean(strikethroughValue) && (
          <Typography
            variant="caption"
            sx={{
              textDecoration: 'line-through',
              color: strikethroughColor ?? 'default',
            }}
          >
            {strikethroughValue?.toLocaleString('en-US')}
          </Typography>
        )}
        <Typography
          variant="body1"
          sx={{ color: currentValueColor ?? 'default' }}
        >
          {currentValue.toLocaleString('en-US')}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
