import { Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InlineDownloadButton from 'ui-component/shared/InlineDownloadButton';

export function RenderStyledText(
  value: string,
  tooltip?: string,
  color?:
    | 'warning'
    | 'success'
    | 'error'
    | 'info'
    | 'primary'
    | 'secondary'
    | 'grey',
  download?: string | undefined | null
) {
  const theme = useTheme();
  const colorMap: { [key: string]: string } = {
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    info: theme.palette.info.main,
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    grey: theme.palette.grey[500],
  };
  return (
    <Tooltip placement="top" title={tooltip || ''}>
      <Typography noWrap sx={{ ...(color ? { color: colorMap[color] } : {}) }}>
        {value}
        {download && <InlineDownloadButton link={download} />}
      </Typography>
    </Tooltip>
  );
}
