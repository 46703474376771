import { Autocomplete, Chip, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { SyntheticEvent, useState } from 'react';

function PositiveNumberFilterAutoComplete(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>(
    Array.isArray(item.value) ? item.value : []
  );
  const [currentInputValue, setCurrentInputValue] = useState('');

  const handleChange = (__: SyntheticEvent, newValues: string[]) => {
    setSelectedValues(newValues);
    applyValue({ ...item, value: newValues });
  };

  const handleInputChange = (__: SyntheticEvent, newInputValue: string) => {
    setCurrentInputValue(newInputValue);
  };

  const options = [currentInputValue];

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
      }}
    >
      <Autocomplete
        multiple
        freeSolo
        options={options}
        value={selectedValues}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            label="Value"
            placeholder="Filter value"
            InputProps={{
              ...params.InputProps,
              className: 'MuiAutoComplete-inputRoot',
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
            }}
            variant="outlined"
          />
        )}
      />
    </Box>
  );
}

export default PositiveNumberFilterAutoComplete;
