import { useEffect, useState, ReactElement } from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

// assets
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { Typography } from '@mui/material';
import { actionButtonSpacing } from 'constants/themeConstants';

type AccordionItem = {
  id: string;
  title: ReactElement | string;
  content: ReactElement | string;
  disabled?: boolean;
  expanded?: boolean;
  defaultExpand?: boolean | undefined;
};

interface accordionProps {
  data: AccordionItem[];
  defaultExpandedId?: string | boolean | null;
  square?: boolean;
  toggle?: boolean;
}

// ==============================|| ACCORDION ||============================== //

export const CustomExpandIcon = () => (
  <Box
    sx={{
      mr: actionButtonSpacing,
      '.Mui-expanded & > .collapseIconWrapper': {
        display: 'none',
      },
      '.expandIconWrapper': {
        display: 'none',
      },
      '.Mui-expanded & > .expandIconWrapper': {
        display: 'block',
      },
    }}
  >
    <div className="expandIconWrapper">
      <IconMinus />
    </div>
    <div className="collapseIconWrapper">
      <IconPlus />
    </div>
  </Box>
);

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<CustomExpandIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '&.Mui-expanded': {
    color: theme.palette.primary.main,
    '.MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
}));

const Accordion = ({
  data,
  defaultExpandedId = null,
  square,
  toggle,
}: accordionProps) => {
  const [expanded, setExpanded] = useState<string | boolean | null>(null);
  const handleChange =
    (panel: string) =>
    (event: React.SyntheticEvent<Element, Event>, newExpanded: boolean) => {
      toggle && setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    setExpanded(defaultExpandedId);
  }, [defaultExpandedId]);

  return (
    <Box sx={{ width: '100%' }}>
      {data &&
        data.map((item: AccordionItem) => (
          <MuiAccordion
            key={item.id}
            defaultExpanded={!item.disabled && item.defaultExpand}
            expanded={
              (!toggle && !item.disabled && item.expanded) ||
              (toggle && expanded === item.id)
            }
            disabled={item.disabled}
            square={square}
            onChange={handleChange(item.id)}
          >
            <AccordionSummary>
              <Typography
                sx={{
                  fontWeight: 'bold',
                }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>
            <MuiAccordionDetails>{item.content}</MuiAccordionDetails>
          </MuiAccordion>
        ))}
    </Box>
  );
};

export default Accordion;
