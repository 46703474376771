import { ALL_APPS, ALL_VIEW_IDS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import NoSubOrgRouteWrapper from 'views/pages/components/NoSubOrgRouteWrapper';

const OrgInfo = Loadable(lazy(() => import('views/org/OrgInfo')));
const OrgPreferences = Loadable(
  lazy(() => import('views/org/Preferences/GeneralPreferences'))
);
const OrgSubOrgs = Loadable(lazy(() => import('views/org/SubOrgs')));

export const OrgSettingsRoutes = [
  {
    path: `${ALL_APPS.ORG_SETTINGS.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.ORG_SETTINGS.id,
        }}
      >
        <Navigate
          to={`${ALL_APPS.ORG_SETTINGS.views?.ORG_INFO?.path}`}
          replace
        />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORG_SETTINGS.views?.ORG_INFO?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.ORG_SETTINGS.id,
          view: ALL_VIEW_IDS.ORG_INFO,
        }}
      >
        <OrgInfo />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORG_SETTINGS.views?.ORG_PREFERENCES?.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.ORG_SETTINGS.id }}
      >
        <OrgPreferences />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_APPS.ORG_SETTINGS.views?.ORG_SUBORGS?.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.ORG_SETTINGS.id,
            view: ALL_VIEW_IDS.ORG_SUBORGS,
          }}
        >
          <NoSubOrgRouteWrapper>
            <OrgSubOrgs />
          </NoSubOrgRouteWrapper>
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
