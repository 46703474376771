import { GridSelectionModel } from '@mui/x-data-grid';
import { defaultPageSize } from 'constants/datagrid';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { GenericObject } from 'types';
import { ListResponse, QueryParams } from 'types/api';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query/react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export interface useServerSideDataGridProps<
  BaseResource extends GenericObject
> {
  useResourceHook: UseQuery<
    QueryDefinition<
      QueryParams<BaseResource>,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      any,
      ListResponse<BaseResource>
    >
  >;
  usePrefetch: any;
  prefetchName: string;
  params: QueryParams<BaseResource>;
}

const useServerSideDataGrid = <
  BaseResource extends GenericObject,
  ModifiedResource = BaseResource
>({
  useResourceHook,
  usePrefetch,
  prefetchName,
  params,
}: useServerSideDataGridProps<BaseResource>) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [filterModel, setFilterModel] = useState<
    QueryParams<BaseResource>['filters']
  >([]);
  const [excludeModel, setExcludeModel] = useState<
    QueryParams<BaseResource>['excludes']
  >([]);
  const [sortModel, setSortModel] = useState<QueryParams<BaseResource>['sort']>(
    []
  );
  const [quickFilterValues, setQuickFilterValues] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
  const [searchSchema, setSearchSchema] = useState<
    QueryParams<BaseResource>['searchSchema']
  >([]);

  const queryParams: QueryParams<BaseResource> = useMemo(
    () => ({
      pageSize,
      pageNumber: page,
      sort: [...(sortModel || []), ...(params.sort ? params.sort : [])],
      filters: [...(filterModel || []), ...(params.filters || [])],
      excludes: [...(excludeModel || []), ...(params.excludes || [])],
      schema: params.schema,
      noCache: params.noCache,
      search: quickFilterValues.length
        ? quickFilterValues.join(' ').replace(/,/g, '')
        : '',
      searchSchema,
      newVersion: params.newVersion,
    }),
    [
      filterModel,
      excludeModel,
      page,
      pageSize,
      params,
      quickFilterValues,
      sortModel,
    ]
  );

  const resource = useResourceHook(queryParams);

  const prefetch = usePrefetch(prefetchName);

  const prefetchNext = useCallback(() => {
    prefetch({
      ...queryParams,
      pageNumber: page + 1,
    });
  }, [prefetch, page]);

  useEffect(() => {
    prefetchNext();
  }, [page]);

  return {
    ...resource,
    data: resource.data as ListResponse<ModifiedResource> | undefined,
    page,
    pageSize,
    filterModel,
    excludeModel,
    sortModel,
    // NOTE: this will be removed when all datagrids are updated to use rowActions
    selectedRows,
    queryParams,
    setters: {
      setPage,
      setPageSize,
      setFilterModel,
      setExcludeModel,
      setQuickFilterValues,
      setSortModel,
      setSearchSchema,
      // NOTE: this will be removed when all datagrids are updated to use rowActions
      onSelectionModelChange: setSelectedRows,
    },
  };
};

export default useServerSideDataGrid;
