import Chip, { ChipColor } from 'ui-component/extended/Chip';
import { Badge } from '@mui/material';

export function chipWithBadge(
  value: string,
  color: ChipColor,
  uppercase: boolean = true,
  tooltip?: string,
  badgeContent: string | null = null,
  badgeColor:
    | 'error'
    | 'default'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'warning'
    | undefined = 'error'
) {
  return (
    <Badge
      badgeContent={badgeContent}
      color={badgeColor}
      sx={{
        '.MuiBadge-anchorOriginTopRight': {
          top: '3px',
        },
      }}
    >
      <Chip
        title={tooltip}
        label={value}
        chipcolor={color}
        style={{ textTransform: uppercase ? 'uppercase' : 'none' }}
      />
    </Badge>
  );
}
