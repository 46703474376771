import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { BooleanIconRenderCell } from 'ui-component/DataGrid/Render';
import { IconLock, IconLockOpen } from '@tabler/icons-react';
import { ALL_APPS } from 'constants/appConstants';
import { ExtendedGridColDef } from 'types/datagrid';
import {
  statusColorCallback,
  textPill,
} from 'ui-component/DataGrid/GridColTypeDef';
import { orgPartNoStockLotColumnDef } from 'ui-component/DataGrid/PartColumnDef';

export const columns = [
  {
    field: 'status',
    headerName: 'Status',
    description: 'Indicates the status of this Kit Request Line',
    type: 'singleSelect',
    //   TODO: Add correct status options, UI-3868
    valueOptions: ['Unknown'],
    minWidth: 80,
    hideable: false,
    valueGetter: (params: GridValueGetterParams) =>
      // TODO: Add logic to get the status of the part, UI-3868
      'Unknown',
    ...textPill(statusColorCallback),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  orgPartNoStockLotColumnDef,
  {
    field: 'targetQuantity',
    headerName: 'Quantity',
    description: 'Quantity of this part in this Kit Request Line',
    type: 'number',
    flex: 0.25,
    permissionScope: {
      app: ALL_APPS.KITTING.id,
    },
  },
  {
    field: 'locked',
    headerName: 'Locked',
    description:
      'Indicates whether the system can automatically calculate allocations for this Kit Request Line',
    type: 'boolean',
    flex: 0.3,
    hideable: true,
    renderCell: (params: GridRenderCellParams) =>
      BooleanIconRenderCell(params, IconLock, IconLockOpen, 'error', 'primary'),
    permissionScope: { app: ALL_APPS.KITTING.id },
  },
] as ExtendedGridColDef[];
