import { Typography, Box } from '@mui/material';
import { useState } from 'react';
import { StockLocation } from 'types/inventory';
import { Org } from 'types/org';
import SegmentButton from 'ui-component/Segment/SegmentButton';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import CreateUpdateFacilityDialog from 'views/inventory/components/CreateUpdateFacilityDialog';
import CreateUpdateStockLocationDialog from 'views/inventory/components/CreateUpdateStockLocationDialog';

type ContextualCreateLocationDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
  orgId?: Org['id'];
  onCreateSuccess?: (location: StockLocation) => void;
};

const ContextualCreateLocationDialog = ({
  dialogOpen,
  onClose,
  orgId,
  onCreateSuccess,
}: ContextualCreateLocationDialogProps) => {
  const [openCreateFacilityDialog, setOpenCreateFacilityDialog] =
    useState(false);
  const [openCreateStockLocationDialog, setOpenCreateStockLocationDialog] =
    useState(false);

  const handleCreateFacilityClose = () => {
    setOpenCreateFacilityDialog(false);
    onClose();
  };

  const handleCreateStockLocationClose = () => {
    setOpenCreateStockLocationDialog(false);
    onClose();
  };

  return (
    <>
      <CreateUpdateFacilityDialog
        dialogOpen={openCreateFacilityDialog}
        orgId={orgId}
        onClose={handleCreateFacilityClose}
        onCreateSuccess={onCreateSuccess}
      />
      <CreateUpdateStockLocationDialog
        dialogOpen={openCreateStockLocationDialog}
        orgId={orgId}
        onClose={handleCreateStockLocationClose}
        onCreateSuccess={onCreateSuccess}
      />
      <ExtendedDialog
        open={dialogOpen}
        onCloseDialog={onClose}
        title="Create Location"
        noActions
      >
        <Typography variant="body1" sx={{ textAlign: 'center', mb: 3 }}>
          Which location are you making?
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
          <SegmentButton
            sx={{ mr: 1 }}
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateFacilityDialog(true)}
          >
            Address
          </SegmentButton>
          <SegmentButton
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateStockLocationDialog(true)}
          >
            Stock Location
          </SegmentButton>
        </Box>
      </ExtendedDialog>
    </>
  );
};

export default ContextualCreateLocationDialog;
