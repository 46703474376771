import { CLIENT_V2_URLS } from 'store/constant';
import { apiSlice } from 'store/slices/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  ClientV2DELETERequest,
  ClientV2DELETEResponse,
  ClientV2PATCHRequest,
  ClientV2PATCHResponse,
  ClientV2POSTRequest,
  ClientV2POSTResponse,
  ListResponse,
  QueryParams,
} from 'types/api';
import {
  AllocationSchema,
  AllocationPOSTSchema,
  AllocationPATCHSchema,
} from 'types/clientV2/allocations';

export const allocationsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllocationsClientV2: builder.query<
      ListResponse<AllocationSchema>,
      QueryParams<AllocationSchema>
    >({
      query: (params) =>
        CLIENT_V2_URLS.ALLOCATIONS(parseQueryParams<AllocationSchema>(params)),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }) =>
                  ({
                    type: 'ClientV2Allocations',
                    id,
                  } as const)
              ),
              { type: 'ClientV2Allocations', id: 'LIST' },
            ]
          : [{ type: 'ClientV2Allocations', id: 'LIST' }],
    }),
    createAllocations: builder.mutation<
      ClientV2POSTResponse<AllocationSchema>,
      ClientV2POSTRequest<AllocationPOSTSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.ALLOCATIONS(),
        method: 'POST',
        body: data,
      }),
    }),
    updateAllocations: builder.mutation<
      ClientV2PATCHResponse<AllocationSchema>,
      ClientV2PATCHRequest<AllocationPATCHSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.ALLOCATIONS(),
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteAllocations: builder.mutation<
      ClientV2DELETEResponse<AllocationSchema>,
      ClientV2DELETERequest<AllocationSchema>
    >({
      query: (data) => ({
        url: CLIENT_V2_URLS.ALLOCATIONS(),
        method: 'DELETE',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetAllocationsClientV2Query,
  useCreateAllocationsMutation,
  useUpdateAllocationsMutation,
  useDeleteAllocationsMutation,
} = allocationsSlice;
