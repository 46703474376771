import { ALL_APPS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const Boms = Loadable(lazy(() => import('views/boms/Boms')));
const BomWrapper = Loadable(lazy(() => import('views/boms/Bom/BomWrapper')));

export const BomRoutes = [
  {
    path: `${ALL_APPS.BOMS.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.BOMS.id }}>
          <Boms />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.BOMS.path}/:bomId`,
    element: (
      <PermissionsRouteWrapper permissionScope={{ app: ALL_APPS.BOMS.id }}>
        <BomWrapper />
      </PermissionsRouteWrapper>
    ),
  },
];
