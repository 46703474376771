import { Icon as IconType } from '@tabler/icons-react';
import { FC, useContext } from 'react';
import { PermissionScope } from 'types/apps';
import { DrawerContext } from '..';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import SegmentButton, {
  TooltipPlacement,
} from 'ui-component/Segment/SegmentButton';
import { ColorOptions } from 'types';

export interface SidebarActionButtonProps {
  Icon: IconType;
  label: string;
  tooltip?: string;
  color?: ColorOptions;
  onClick: () => void;
  disabled?: boolean;
  trackingName?: string;
  permissionScope?: PermissionScope;
}

const SidebarActionButton: FC<SidebarActionButtonProps> = ({
  Icon,
  label,
  tooltip,
  color = 'primary',
  onClick,
  disabled,
  trackingName,
  permissionScope,
}) => {
  const open = useContext(DrawerContext);
  if (open) {
    return (
      <SegmentButton
        color={color}
        onClick={onClick}
        disabled={disabled}
        tooltip={tooltip ?? label}
        permissionScope={permissionScope}
        trackingName={trackingName}
        startIcon={<Icon size={24} />}
        variant="outlined"
        tooltipPlacement={TooltipPlacement.Left}
      >
        {label}
      </SegmentButton>
    );
  }

  return (
    <SegmentIconButton
      color={color}
      size="small"
      sx={{
        border: 1,
        borderRadius: 1,
      }}
      onClick={onClick}
      disabled={disabled}
      tooltip={tooltip ?? label}
      permissionScope={permissionScope}
      trackingName={trackingName}
      tooltipPlacement={TooltipPlacement.Left}
    >
      <Icon />
    </SegmentIconButton>
  );
};

export default SidebarActionButton;
