import { PartOfferCorrection } from 'types/part';
import { Typography } from '@mui/material';
import _ from 'lodash';
import { renderStringWithStrikethrough } from 'ui-component/DataGrid/Render/RenderStringWithStrikethrough';

export function renderNumberWithCorrection(
  fieldName: string,
  value: number,
  corrections: { [key: string]: PartOfferCorrection } | undefined | null,
  formatter?: (value: number) => string
) {
  const matchingCorrection = _.get(corrections, fieldName);
  if (matchingCorrection && matchingCorrection.originalValue !== value) {
    return (
      <>
        {renderStringWithStrikethrough({
          currentValue: formatter
            ? formatter(value)
            : value?.toLocaleString('en-US'),
          strikethroughValue: formatter
            ? formatter(matchingCorrection.originalValue as number)
            : (matchingCorrection.originalValue as number)?.toLocaleString(
                'en-US'
              ),
          tooltip:
            matchingCorrection.detail ||
            'Correction for incorrect data from supplier',
        })}
      </>
    );
  }
  return (
    <Typography variant="body1">
      {value
        ? formatter
          ? formatter(value)
          : value?.toLocaleString('en-US')
        : ''}
    </Typography>
  );
}
