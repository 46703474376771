import { Tooltip, Typography, Stack } from '@mui/material';
import { Part } from 'types/part';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';

const WarningNumber = memo(({ quantity }: { quantity: number }) => {
  const theme = useTheme();
  return (
    <Tooltip title="We don't have enough information to accurately calculate the required overage for this part because the package or mount type is missing.">
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={0.5}
      >
        <Typography>{quantity.toLocaleString()}</Typography>
        <IconAlertTriangle
          color={theme.palette.warning.main}
          width={20}
          height={20}
        />
      </Stack>
    </Tooltip>
  );
});

export function renderQuantWithOverageWarning(
  quantity: number,
  part: Part,
  tooltip?: string
) {
  if (
    (!part.package && part.terminationType === 'SMT') ||
    !part.terminationType
  ) {
    return <WarningNumber quantity={quantity} />;
  }
  return (
    <Tooltip title={tooltip}>
      <Typography>{quantity.toLocaleString()}</Typography>
    </Tooltip>
  );
}
