import {
  Alert,
  AlertTitle,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import isEqual from 'react-fast-compare';
import { gridSpacing } from 'constants/themeConstants';
import { memo } from 'react';

export interface VerboseLoaderProps {
  mainVariant?: 'determinate' | 'indeterminate';
  mainStatus?: 'loading' | 'success' | 'error';
  mainValue?: number;
  message?: string;
  subLoaders?: {
    id: string;
    name: string;
    message?: string;
    variant: 'determinate' | 'indeterminate';
    status?: 'loading' | 'success' | 'error';
    value?: number;
  }[];
}

const VerboseLoader = ({
  mainVariant = 'indeterminate',
  mainStatus = 'loading',
  mainValue = 0,
  message,
  subLoaders = [],
}: VerboseLoaderProps) => (
  <Alert
    icon={
      <CircularProgress
        variant={mainVariant}
        value={mainStatus === 'loading' ? mainValue : 100}
        color="inherit"
      />
    }
    variant="outlined"
    severity={mainStatus === 'loading' ? 'info' : mainStatus}
    sx={{ '.MuiAlert-message': { overflow: 'hidden' } }}
  >
    {!!message && <AlertTitle sx={{ ml: gridSpacing }}>{message}</AlertTitle>}
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={gridSpacing}
      sx={{ mt: gridSpacing, ml: gridSpacing }}
    >
      {subLoaders.map((subloader) => (
        <Stack
          key={subloader.id}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={gridSpacing}
        >
          <CircularProgress
            variant={subloader.variant}
            value={subloader.status === 'loading' ? subloader.value : 100}
            color={
              subloader.status === 'error'
                ? 'error'
                : subloader.status === 'success'
                ? 'success'
                : 'info'
            }
          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Typography variant="h5">{subloader.name}</Typography>
            {!!subloader.message && (
              <Typography variant="caption">{subloader.message}</Typography>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  </Alert>
);

function verboseLoaderPropsAreEqual(
  prevProps: VerboseLoaderProps,
  nextProps: VerboseLoaderProps
) {
  if (prevProps.mainVariant !== nextProps.mainVariant) {
    return false;
  }
  if (prevProps.mainStatus !== nextProps.mainStatus) {
    return false;
  }
  if (prevProps.mainValue !== nextProps.mainValue) {
    return false;
  }
  if (prevProps.message !== nextProps.message) {
    return false;
  }
  if (!isEqual(prevProps.subLoaders, nextProps.subLoaders)) {
    return false;
  }
  return true;
}

export default memo(VerboseLoader, verboseLoaderPropsAreEqual);
