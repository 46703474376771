import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { User, UserLite } from 'types/user';
import {
  formatDaysToDaysOrWeeks,
  formatToDateTime,
  formatToShortDate,
  hoursToHoursOrDays,
} from 'utils/dates';
import { formatPrice, pluralizeNoun } from 'utils/functions';
import { isCofactrUser } from 'utils/userPermissions';
import _ from 'lodash';
import { terminationTypeOptionsExpanded } from 'types/part';

export const intWithSuffixDaysValueFormatter = (
  params: GridValueFormatterParams
) =>
  params.value
    ? `${params.value} business ${pluralizeNoun('day', params.value)}`
    : '';

export const businessDaysOrWeeksValueFormatter = (
  params: GridValueFormatterParams
) => formatDaysToDaysOrWeeks(params.value, 'business day');

export const daysOrWeeksValueFormatter = (params: GridValueFormatterParams) =>
  formatDaysToDaysOrWeeks(params.value, undefined, undefined, undefined, false);

export const currencyValueFormatter = ({ value }: GridValueFormatterParams) => {
  const val = Number(value);
  if (Number.isNaN(val) || val === 0) return '-';
  return `$${val.toLocaleString()}`;
};

export const priceValueFormatter = ({ value }: GridValueFormatterParams) =>
  formatPrice(value);

export const priceValueFormatterSmall = ({ value }: GridValueFormatterParams) =>
  formatPrice(value, 2);

export const priceValueFormatterLarge = ({ value }: GridValueFormatterParams) =>
  formatPrice(value, 4);

export const quantValueFormatter = ({ value }: GridValueFormatterParams) => {
  if (value === null || value === undefined) return '';
  return _.toNumber(value).toLocaleString('en-US');
};

export const numberValueFormatter = ({ value }: GridValueFormatterParams) => {
  if (value === null || value === undefined) return '-';
  return value > 0 ? `+${value}` : value;
};

export const intValueFormatter = ({ value }: GridValueFormatterParams) => {
  if (value === null || value === undefined) return '-';
  return value > 0
    ? `${value.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`
    : '-';
};

export const intValueFormatterShowZero = ({
  value,
}: GridValueFormatterParams) => {
  if (value === null || value === undefined) return '-';
  return `${value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;
};

export const percentageFormatter = ({ value }: GridValueFormatterParams) => {
  if (value === null || value === undefined) return '-';
  return `${(_.toNumber(value) * 100).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}%`;
};

export const replaceStringWithHyphenValueFormatter = ({
  value,
}: GridValueFormatterParams) =>
  value === null || value === undefined ? '-' : value;

export function offerPricesValueFormatter({ value }: GridValueFormatterParams) {
  const { min, max } = value;
  if (!min && !max) return 'Request Quote';
  return `$${min.toFixed(4)} - ${max.toFixed(4)}`;
}

export function quotePricesValueFormatter({ value }: GridValueFormatterParams) {
  const { min, max } = value;
  if (!min && !max) return '-';
  if (min === max) return `$${min.toFixed(4)}`;
  return `$${min.toFixed(4)} - ${max.toFixed(4)}`;
}

export const dateValueFormatter = ({ value }: { value: Date }) =>
  (value && formatToShortDate(value)) || '-';

export const dateTimeValueFormatter = ({ value }: { value: Date }) =>
  (value && formatToDateTime(value)) || '-';

export const mslHoursLeftValueFormatter = ({
  value,
}: GridValueFormatterParams) => {
  if (!value) {
    return 'Unlimited';
  }
  if (value === 9999) {
    return 'Unlimited';
  }
  if (value <= 23) {
    return `Bake Required`;
  }
  const timeRemaining = hoursToHoursOrDays(value || 0);
  return `${timeRemaining.value.toLocaleString()} ${pluralizeNoun(
    timeRemaining.unit,
    timeRemaining.value
  )} (${value.toLocaleString()} hours)`;
};

export const userNameValueFormatter = ({
  user,
  currentUser,
  viewAsStaff,
}: {
  user: User | UserLite | null | undefined;
  currentUser: User | undefined;
  viewAsStaff: boolean | null;
}) => {
  const obscureCofactrUser =
    user?.isStaff && !isCofactrUser({ user: currentUser, viewAsStaff });

  if (!user) return '';
  if (!!user && obscureCofactrUser) return 'Cofactr Success';
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export const terminationTypeValueFormatter = ({
  value,
}: GridValueFormatterParams) => {
  const matchedOption = _.find(
    terminationTypeOptionsExpanded,
    (option) => option.value === value
  );
  return matchedOption?.label ?? value ?? '';
};
