import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, useTheme, Theme } from '@mui/material/styles';
import Header from 'layout/MainLayout/Header';
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';
import { drawerWidth } from 'constants/themeConstants';

interface MediumStyleProps {
  theme: Theme;
}

// ==============================|| MINIMAL LAYOUT ||============================== //
const Medium = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }: MediumStyleProps) => ({
    ...theme.typography.mainContent,
    ...{
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter,
      }),
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      marginTop: '72px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
      },
    },
  })
);

const MediumLayout = () => {
  const theme = useTheme();
  const drawerOpen = true;
  const header = useMemo(
    () => (
      <Toolbar>
        <Header />
      </Toolbar>
    ),
    []
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}
      >
        {header}
      </AppBar>
      {/* main content */}
      <Medium theme={theme}>
        <Outlet />
      </Medium>
    </Box>
  );
};

export default MediumLayout;
