import { FC, useEffect, useState } from 'react';
import { ExtendedDialog } from 'ui-component/extended/ExtendedDialog';
import { FieldValues, useForm } from 'react-hook-form';
import { HookFormProductionRunAutocomplete } from 'ui-component/HookFormComponents/HookFormProductionRunAutocomplete';
import { RelatedRecord, RelatedRecordType } from 'types/inventory';
import { useCreateStockAllocationsMutation } from 'store/slices/inventory';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { HookFormToggle } from 'ui-component/HookFormComponents';
import { handleErr, pluralizeNoun } from 'utils/functions';
import { Order, OrderLine } from 'types/order';
import { PurchaseOrder, PurchaseOrderLine } from 'types/procurement';
import useSnackbar from 'hooks/useSnackbar';

const formConstants = {
  productionRun: {
    name: 'productionRun',
    label: 'Related Program',
  },
  allocateOrderLines: {
    name: 'allocateOrderLines',
    label: (numLines: number) =>
      `Allocate available stock for ${
        numLines > 1 ? 'these' : 'this'
      } ${pluralizeNoun('Order', numLines)} ${pluralizeNoun(
        'Line',
        numLines
      )} to this Program`,
  },
};

const getCopy = () => ({
  title: 'Add / Update Related Program',
});

type RelatedProductionRunDialogProps = {
  open: boolean;
  onClose: () => void;
  relatedProductionRun?: RelatedRecord;
  ids: string[];
  // TODO: Need to determine how to type this -- UI-2742
  useUpdateBatchMutation: UseMutation<any>;
  orderLines?: OrderLine[] | null;
  purchaseOrderLines?: PurchaseOrderLine[] | null;
  isLoadingLines?: boolean;
  orderId?: Order['id'];
  purchaseOrderId?: PurchaseOrder['id'];
  batch?: boolean;
};

const RelatedProductionRunDialog: FC<RelatedProductionRunDialogProps> = ({
  open,
  onClose,
  relatedProductionRun,
  ids,
  useUpdateBatchMutation,
  orderLines,
  isLoadingLines,
  orderId,
  purchaseOrderLines,
  purchaseOrderId,
  batch = true,
}) => {
  const { dispatchSuccessSnackbar } = useSnackbar();
  const copy = getCopy();
  const [submitError, setSubmitError] = useState<string | null>(null);

  const [createAllocations, { isLoading: isCreatingAllocations }] =
    useCreateStockAllocationsMutation();

  const defaultValues = {
    [formConstants.productionRun.name]: relatedProductionRun?.recordId || '',
    [formConstants.allocateOrderLines.name]: false,
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    relatedProductionRun && open && reset(defaultValues);
  }, [open, relatedProductionRun?.recordId]);

  const [updateBatch, { isLoading: isLoadingBatchUpdate }] =
    useUpdateBatchMutation();

  const onSubmit = async (data: FieldValues) => {
    try {
      if (batch) {
        const batchData = ids.map((id: string) => ({
          id,
          [formConstants.productionRun.name]:
            data[formConstants.productionRun.name] ?? null,
        }));
        await updateBatch(
          orderId
            ? { orderId, payload: batchData }
            : purchaseOrderId
            ? { purchaseOrderId, payload: batchData }
            : batchData
        ).unwrap();
      } else {
        await updateBatch({
          id: ids[0],
          payload: {
            [formConstants.productionRun.name]:
              data[formConstants.productionRun.name] ?? null,
          },
        }).unwrap();
      }

      if (data[formConstants.allocateOrderLines.name] && orderLines) {
        await createAllocations(
          orderLines.flatMap(
            (orderLine) =>
              orderLine.stockLots?.map((stockLot) => ({
                stockLot: stockLot.id,
                quant: stockLot.quant,
                productionRun: data[formConstants.productionRun.name],
                replaceFromProductionRun: orderLine.relatedRecords?.find(
                  (record) =>
                    record.recordType === RelatedRecordType.PRODUCTION_RUN
                )?.recordId,
              })) || []
          )
        ).unwrap();
      }
      if (data[formConstants.allocateOrderLines.name] && purchaseOrderLines) {
        await createAllocations(
          purchaseOrderLines.flatMap(
            (orderLine) =>
              orderLine.stockLots?.map((stockLot) => ({
                stockLot: stockLot.id,
                quant: stockLot.quant,
                productionRun: data[formConstants.productionRun.name],
                replaceFromProductionRun: orderLine.productionRun?.recordId,
              })) || []
          )
        ).unwrap();
      }
      dispatchSuccessSnackbar(`Successfully updated Related Program`);
      reset(defaultValues);
      onClose();
    } catch (err) {
      handleErr(err, setSubmitError);
    }
  };

  return (
    <>
      <ExtendedDialog
        title={copy.title}
        open={open}
        onCloseDialog={onClose}
        onSubmit={handleSubmit(onSubmit)}
        isForm
        formSubmitError={submitError}
        isSubmitting={
          isLoadingBatchUpdate || isCreatingAllocations || isLoadingLines
        }
      >
        <HookFormProductionRunAutocomplete
          control={control}
          errors={errors}
          setValue={setValue}
          defaultValue={relatedProductionRun?.recordId || ''}
          name={formConstants.productionRun.name}
          label={formConstants.productionRun.label}
          sx={{ mb: 2 }}
        />
        {(orderLines || purchaseOrderLines) && (
          <HookFormToggle
            control={control}
            name={formConstants.allocateOrderLines.name}
            label={formConstants.allocateOrderLines.label(ids.length)}
          />
        )}
      </ExtendedDialog>
    </>
  );
};

export default RelatedProductionRunDialog;
