import DetailLayout from 'ui-component/DetailLayout';
import { useNavigate } from 'react-router-dom';
import KitDetail from 'views/kitting/Kit/components/KitDetail';
import { IconFiles, IconLink, IconListDetails } from '@tabler/icons-react';
import {
  ALL_APP_IDS,
  ALL_APPS,
  ALL_FEATURE_IDS,
  ALL_VIEW_IDS,
  ALL_VIEWS,
} from 'constants/appConstants';
import KitRequestLineTable from 'views/kitting/Kit/KitRequestLineTable';
import StockLotDocumentationTabV2 from 'views/inventory/StockLot/StockLotDocumentationTabV2';
import { appPermissionAccessLevels } from 'types/apps';
import SidebarApproveButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarApproveButtonClientV2';
import {
  useApproveKitRequestMutation,
  useDeleteKitRequestsMutation,
  useUnapproveKitRequestMutation,
  useUpdateKitRequestsMutation,
} from 'store/slices/clientV2/kitRequests';
import SidebarDeleteButtonClientV2 from 'ui-component/DetailLayout/SidebarComponents/SidebarDeleteButtonClientV2';
import { InfoSectionItemFormatTypes } from 'ui-component/InfoSectionItem';
import EditInline from 'ui-component/EditInline';
import { HookFormTeamAutocomplete } from 'ui-component/HookFormComponents';
import useCustomTeamTypeName from 'hooks/useCustomTeamTypeName';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { createUpdateKitRequestFormConstants } from 'views/kitting/components/constants';
import { EditInlineDatePicker } from 'ui-component/HookFormComponents/InlineEditInputComponents';
import { convertDate, formatToUniversalDate } from 'utils/dates';
import { RelatedRecordType } from 'types/inventory';
import RelatedProductionRunDialog from 'ui-component/RelatedProductionRunDialog';
import { useState } from 'react';
import { useKitRequestContext } from 'views/kitting/Kit/KitRequestContext';

const Kit = () => {
  const navigate = useNavigate();

  const [openRelatedProductionRunDialog, setOpenRelatedProductionRunDialog] =
    useState(false);

  const { hasAppPermission } = useAppAccessContext();
  const propertiesAppEnabled = hasAppPermission(ALL_APP_IDS.PROPERTIES);
  const { handleReplaceWithCustomTeamName } = useCustomTeamTypeName();

  const { kitRequest, isLoadingKitRequest } = useKitRequestContext();

  const disableEdit = Boolean(kitRequest?.approved || kitRequest?.locked);

  const relatedProductionRun = {
    name: kitRequest?.productionRun?.name ?? '',
    recordType: RelatedRecordType.PRODUCTION_RUN,
    recordId: kitRequest?.productionRun?.id ?? '',
    orgId: kitRequest?.org?.id ?? '',
  };

  return (
    <>
      {!!kitRequest?.id && (
        <DetailLayout
          headerSection={<KitDetail kitRequestId={kitRequest.id} />}
          tabOptions={[
            {
              name: 'lines',
              label: 'Parts',
              icon: <IconListDetails />,
              loading: false,
              contents: <KitRequestLineTable />,
              permissionScope: { app: ALL_APPS.KITTING.id },
              gridPadding: true,
            },
            {
              name: 'documents',
              label: 'Documents',
              icon: <IconFiles />,
              contents: (
                <StockLotDocumentationTabV2
                  kitRequestId={kitRequest.id}
                  allowUpload
                  uploadPermissionScope={{
                    app: ALL_APP_IDS.ORG_SETTINGS,
                    feature: ALL_FEATURE_IDS.FILE_ATTACHMENTS,
                    accessLevel: appPermissionAccessLevels.edit,
                  }}
                />
              ),
              loading: isLoadingKitRequest,
              permissionScope: {
                app: ALL_APPS.ORG_SETTINGS.id,
                feature: ALL_FEATURE_IDS.FILE_ATTACHMENTS,
              },
              gridPadding: true,
            },
          ]}
          sidebarActionButtons={[
            <SidebarApproveButtonClientV2
              idToUpdate={kitRequest.id}
              recordName="Kit Request"
              approved={!!kitRequest.approved}
              useClientApproveMutation={useApproveKitRequestMutation}
              useClientUnapproveMutation={useUnapproveKitRequestMutation}
            />,
            <SidebarDeleteButtonClientV2
              idToUpdate={kitRequest.id}
              recordName="Kit Request"
              useClientDeleteMutation={useDeleteKitRequestsMutation}
              disabled={!!kitRequest.approved}
              onSuccess={() => {
                navigate(ALL_VIEWS[ALL_VIEW_IDS.KITS].path as string);
              }}
            />,
          ]}
          sidebarInfoSections={[
            {
              caption: 'Approved By',
              placeholder: ' ',
              clipboard: false,
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.user,
              userValue: kitRequest.approvedBy,
              dateValue: kitRequest.approvedAt,
              useInfoSectionAutomationOption: true,
              visible: !!kitRequest.approvedBy,
            },
            {
              caption: 'Target Ship Date',
              placeholder: ' ',
              clipboard: false,
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.dateShort,
              value: kitRequest.targetShipDate,
              useInfoSectionAutomationOption: true,
              disableEdit: disableEdit || !kitRequest.shipTo,
              editInline: (
                <EditInline
                  useMutation={useUpdateKitRequestsMutation}
                  preSubmit={(data) => ({
                    [createUpdateKitRequestFormConstants.targetShipDate.id]:
                      data ? formatToUniversalDate(data) : null,
                  })}
                  name={createUpdateKitRequestFormConstants.targetShipDate.id}
                  label={
                    createUpdateKitRequestFormConstants.targetShipDate.label
                  }
                  value={
                    kitRequest?.targetShipDate
                      ? convertDate(new Date(kitRequest.targetShipDate))
                      : undefined
                  }
                  id={kitRequest?.id}
                  submitAsClientV2Api
                >
                  {EditInlineDatePicker}
                </EditInline>
              ),
            },
            {
              caption: handleReplaceWithCustomTeamName('Team'),
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.string,
              value: kitRequest?.team?.name ?? '-',
              permissionScope: {
                app: ALL_APPS.PROPERTIES.id,
                accessLevel: appPermissionAccessLevels.edit,
              },
              disableEdit: Boolean(
                disableEdit || kitRequest?.productionRun?.team
              ),
              editInline: (
                <EditInline
                  useMutation={useUpdateKitRequestsMutation}
                  name={createUpdateKitRequestFormConstants.team.id}
                  label={handleReplaceWithCustomTeamName(
                    createUpdateKitRequestFormConstants.team.label
                  )}
                  value={kitRequest?.team?.id}
                  id={kitRequest?.id}
                  submitAsClientV2Api
                >
                  <HookFormTeamAutocomplete isInlineEdit disableRequiredProps />
                </EditInline>
              ),
              visible: propertiesAppEnabled,
            },
            {
              caption: 'Programs',
              isLoading: isLoadingKitRequest,
              formatType: InfoSectionItemFormatTypes.relatedRecords,
              Icon: IconLink,
              onOpenEditRelatedRecords: () =>
                setOpenRelatedProductionRunDialog(true),
              count: kitRequest?.productionRun ? 1 : 0,
              relatedRecordsValue: kitRequest?.productionRun
                ? [
                    {
                      name: kitRequest?.productionRun?.name ?? '',
                      recordType: RelatedRecordType.PRODUCTION_RUN,
                      recordId: kitRequest?.productionRun?.id ?? '',
                      orgId: kitRequest?.org?.id ?? '',
                    },
                  ]
                : [],
            },
          ]}
          additionalSidebarSections={[]}
        />
      )}
      <RelatedProductionRunDialog
        onClose={() => setOpenRelatedProductionRunDialog(false)}
        open={openRelatedProductionRunDialog}
        relatedProductionRun={relatedProductionRun}
        ids={[kitRequest?.id || '']}
        useUpdateBatchMutation={useUpdateKitRequestsMutation}
      />
    </>
  );
};

export default Kit;
