import { ALL_APPS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Redirect } from 'react-router6-redirect';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const PartsWatchlist = Loadable(
  lazy(() => import('views/parts/PartsWatchlist'))
);

export const PartWatchlistRoutes = [
  {
    path: `${ALL_APPS.WATCHLIST.deprecatedPath}`,
    element: <Redirect to={`${ALL_APPS.WATCHLIST.path}`} />,
  },
  {
    path: `${ALL_APPS.WATCHLIST.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.WATCHLIST.id }}
        >
          <PartsWatchlist />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
];
