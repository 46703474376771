import { useDispatch } from 'react-redux';
import { AlertProps } from '@mui/material/Alert/Alert';
import { openSnackbar } from 'store/slices/snackbar';
import { SnackbarProps } from 'types/snackbar';

export type SnackbarDispatchType = {
  message: string;
  color?: AlertProps['color'];
  navigateTo?: SnackbarProps['navigateTo'];
  customAction?: SnackbarProps['customAction'];
};

export default function useSnackbar() {
  const dispatch = useDispatch();

  const dispatchSnackbar = ({
    message,
    color,
    navigateTo,
    customAction,
  }: SnackbarDispatchType) => {
    dispatch(
      openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: {
          color,
        },
        close: true,
        navigateTo: navigateTo ?? undefined,
        customAction: customAction ?? undefined,
      })
    );
  };

  return {
    dispatchSuccessSnackbar: (
      message: SnackbarDispatchType['message'],
      navigateTo?: SnackbarDispatchType['navigateTo'],
      customAction?: SnackbarDispatchType['customAction']
    ) =>
      dispatchSnackbar({ message, color: 'success', navigateTo, customAction }),
    dispatchErrorSnackbar: (message: SnackbarDispatchType['message']) =>
      dispatchSnackbar({ message, color: 'error' }),
  };
}
