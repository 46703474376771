import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import OrgGuard from 'utils/route-guard/OrgGuard';
import { DASHBOARD_ROUTE, ERROR_ROUTE } from 'routes/constants';
import {
  BillingRoutes,
  BomRoutes,
  DocAiRoutes,
  IntegrationsRoutes,
  KittingRoutes,
  ManagedInventoryRoutes,
  OrdersRoutes,
  OrgSettingsRoutes,
  PartLibraryRoutes,
  PartSearchRoutes,
  PartWatchlistRoutes,
  ProcurementRoutes,
  ProgramRoutes,
  PropertiesRoutes,
  QuotesRoutes,
  ReportingRoutes,
  StockRoutes,
  SuppliersRoutes,
  UserSettingsRoutes,
  UsersRoutes,
} from 'routes/apps';

const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Error = Loadable(lazy(() => import('views/pages/Error')));

const AppRoutes: RouteObject = {
  path: '/',
  element: (
    <AuthGuard>
      <OrgGuard>
        <MainLayout />
      </OrgGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: DASHBOARD_ROUTE,
      element: <Dashboard />,
    },
    {
      path: ERROR_ROUTE,
      element: <Error />,
    },
    ...PartSearchRoutes,
    ...PartLibraryRoutes,
    ...PartWatchlistRoutes,
    ...BomRoutes,
    ...QuotesRoutes,
    ...ProcurementRoutes,
    ...DocAiRoutes,
    ...OrdersRoutes,
    ...SuppliersRoutes,
    ...KittingRoutes,
    ...ManagedInventoryRoutes,
    ...StockRoutes,
    ...ProgramRoutes,
    ...UsersRoutes,
    ...OrgSettingsRoutes,
    ...UserSettingsRoutes,
    ...BillingRoutes,
    ...IntegrationsRoutes,
    ...ReportingRoutes,
    ...PropertiesRoutes,
  ],
};

export default AppRoutes;
