import { FieldValues } from 'react-hook-form';
import { getShipmentCarrierAndService } from 'utils/functions';
import { createUpdateKitRequestFormConstants } from 'views/kitting/components/constants';
import _ from 'lodash';
import { formatToUniversalDate } from 'utils/dates';

export const createUpdateKitRequestPreSubmit = (data: FieldValues) => {
  const { defaultShippingCarrier, defaultShippingService } =
    getShipmentCarrierAndService(
      data[createUpdateKitRequestFormConstants.shipMethod.id] || ''
    );
  const cleanedData = _.omit(data, [
    createUpdateKitRequestFormConstants.shipMethod.id,
  ]);
  if (defaultShippingCarrier) {
    _.set(cleanedData, 'carrier', defaultShippingCarrier);
  }
  if (defaultShippingService) {
    _.set(cleanedData, 'service', defaultShippingService);
  }
  if (data[createUpdateKitRequestFormConstants.targetShipDate.id]) {
    _.set(
      cleanedData,
      createUpdateKitRequestFormConstants.targetShipDate.id,
      formatToUniversalDate(
        _.get(data, createUpdateKitRequestFormConstants.targetShipDate.id)
      )
    );
  }
  if (
    data[createUpdateKitRequestFormConstants.productionRun.id] === undefined
  ) {
    _.set(cleanedData, 'productionRun', null);
  }
  // If shipTo is not set, reset fields that require shipTo
  if (data[createUpdateKitRequestFormConstants.shipTo.id] === undefined) {
    _.set(cleanedData, 'shipTo', null);
    _.set(cleanedData, 'shipPartial', false);
    _.set(cleanedData, 'carrier', null);
    _.set(cleanedData, 'service', null);
  }
  return cleanedData;
};
