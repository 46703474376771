import { useDeviceContext } from 'hooks/useDeviceContext';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import SegmentLoadingButton from 'ui-component/Segment/SegmentLoadingButton';
import SegmentButton, {
  SegmentButtonProps,
} from 'ui-component/Segment/SegmentButton';

interface FullOrIconButtonProps extends SegmentButtonProps {
  label: string;
  variant: 'outlined' | 'contained' | undefined;
  loadingButton?: boolean;
  loading?: boolean;
}

export const FullOrIconButton = ({
  startIcon,
  label,
  variant,
  loadingButton,
  loading,
  ...rest
}: FullOrIconButtonProps) => {
  const { narrowWindow } = useDeviceContext();
  return narrowWindow ? (
    <SegmentIconButton {...rest} size="small">
      {startIcon}
    </SegmentIconButton>
  ) : loadingButton ? (
    <SegmentLoadingButton
      {...rest}
      variant={variant}
      size="small"
      startIcon={startIcon}
      loading={loading}
    >
      {label}
    </SegmentLoadingButton>
  ) : (
    <SegmentButton
      {...rest}
      variant={variant}
      size="small"
      startIcon={startIcon}
    >
      {label}
    </SegmentButton>
  );
};
