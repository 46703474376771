import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Tooltip, Typography } from '@mui/material';

export function renderTextWithTooltip(params: GridRenderCellParams) {
  return (
    <Tooltip placement="top" title={params}>
      <Typography noWrap>{params}</Typography>
    </Tooltip>
  );
}
