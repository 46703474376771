import { Typography, Stack, Tooltip } from '@mui/material';

export function renderTwoNumbers(
  primaryValue: string,
  secondaryValue: string,
  primaryTooltip?: string,
  secondaryTooltip?: string
) {
  return (
    <Stack direction="column" justifyContent="center" alignItems="flex-end">
      <Tooltip title={secondaryTooltip || ''}>
        <Typography variant="caption">{secondaryValue}</Typography>
      </Tooltip>
      <Tooltip title={primaryTooltip || ''}>
        <Typography variant="body1">{primaryValue}</Typography>
      </Tooltip>
    </Stack>
  );
}
