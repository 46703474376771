import { skipToken } from '@reduxjs/toolkit/query/react';
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'store';
import { Avatar, Tooltip } from '@mui/material';
import { IconBell } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import {
  KnockFeedProvider,
  NotificationFeedPopover,
  NotificationFeed,
} from '@knocklabs/react-notification-feed';

// Required CSS import, unless you're overriding the styling
import '@knocklabs/react-notification-feed/dist/index.css';
import 'assets/scss/knock-theme.css';

import { useGetUserQuery } from 'store/slices/user';
import { KnockUnreadBadge } from 'ui-component/third-party/KnockUnreadBadge';

import { API_URL } from 'constants/envConstants';
import { roundedButtonAvatarSx } from 'constants/themeConstants';

interface KnockNotificationWidgetProps {
  authWithKnock: boolean;
  variant?: 'icon' | 'inbox';
}

const KnockNotificationWidget = ({
  authWithKnock,
  variant = 'icon',
}: KnockNotificationWidgetProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const notifButtonRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const { activeOrgId } = useSelector((state) => state.org);
  const { authToken, isLoggedIn } = useSelector(({ session }) => session);

  const [stopLoop, setStopLoop] = useState<boolean>(false);
  const [knockToken, setKnockToken] = useState<string>('');
  const testKnockAuthUrl = (knock_user_id: string) =>
    `https://api.knock.app/v1/users/${knock_user_id}/feeds/${process.env.KNOCK_FEED_ID}`;

  const { data: userData } = useGetUserQuery(
    isLoggedIn ? undefined : skipToken
  );

  const fetchKnockToken = async () => {
    if (!activeOrgId) return null;
    const response = await fetch(`${API_URL}/notification/auth/`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'X-ORG-ID': activeOrgId,
      },
    });
    const data = await response.json();
    if (data.status && data.status === 401) {
      // TODO: refresh with refreshToken and try again
    } else if (data && data.token && data.expires) {
      window.localStorage.setItem('knockToken', data.token);
      window.localStorage.setItem('knockTokenExpiration', data.expires);
      setKnockToken(data.token);
      return data;
    }
    return null;
  };

  useEffect(() => {
    if (authWithKnock) {
      const expiration_str = window.localStorage.getItem(
        'knockTokenExpiration'
      );
      const token_str = window.localStorage.getItem('knockToken');
      if (
        !token_str ||
        !expiration_str ||
        new Date(expiration_str) < new Date()
      ) {
        fetchKnockToken();
      } else {
        setKnockToken(token_str);
      }
    }
  }, []);

  useEffect(() => {
    if (!authWithKnock || !knockToken || !userData) return;
    // Test if the token we got is valid on Knock's side.
    const checkCredsWithKnock = async () => {
      const knockHeaders = new Headers();
      knockHeaders.append('x-knock-user-token', knockToken);
      knockHeaders.append(
        'Authorization',
        `Bearer ${process.env.KNOCK_API_KEY}`
      );
      const response = await fetch(
        testKnockAuthUrl(`${userData.id}-${activeOrgId}`),
        {
          headers: knockHeaders,
        }
      );
      const data = await response.json();
      if (data.status && data.status === 403 && !stopLoop) {
        // refetch it because the token is bad
        fetchKnockToken();
        setStopLoop(true);
      }
    };
    checkCredsWithKnock();
  }, [knockToken, userData]);

  // Early returns for missing configuration peices
  if (!userData) return null;
  if (!process.env.KNOCK_API_KEY) return null;
  if (!process.env.KNOCK_FEED_ID) return null;

  // Knock does not require a JWT in development so we do not pass one
  if (!authWithKnock)
    return (
      <KnockFeedProvider
        apiKey={process.env.KNOCK_API_KEY}
        feedId={process.env.KNOCK_FEED_ID}
        userId={`${userData.id}-${activeOrgId}`}
      >
        {variant === 'inbox' ? (
          <NotificationFeed />
        ) : (
          <>
            <KnockUnreadBadge
              badgeCountType="unread"
              content={
                <Tooltip title="Notification Inbox">
                  <Avatar
                    variant="rounded"
                    sx={roundedButtonAvatarSx(theme)}
                    onClick={(__) => setIsOpen((prevIsOpen) => !prevIsOpen)}
                    color="inherit"
                    ref={notifButtonRef}
                  >
                    <IconBell stroke={1.5} size="1.3rem" />
                  </Avatar>
                </Tooltip>
              }
            />
            <NotificationFeedPopover
              buttonRef={notifButtonRef}
              isVisible={isOpen}
              onClose={() => setIsOpen(false)}
            />
          </>
        )}
      </KnockFeedProvider>
    );
  // Otherwise we are in prod and would wait until the token is set to render
  if (!knockToken) return null;
  return (
    <KnockFeedProvider
      apiKey={process.env.KNOCK_API_KEY}
      feedId={process.env.KNOCK_FEED_ID}
      userId={`${userData.id}-${activeOrgId}`}
      userToken={knockToken}
    >
      {variant === 'inbox' ? (
        <NotificationFeed />
      ) : (
        <>
          <KnockUnreadBadge
            badgeCountType="unread"
            content={
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  overflow: 'hidden',
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.brand.moss,
                  color: theme.palette.brand.steel,
                  '&:hover': {
                    background: theme.palette.primary.dark,
                    color: theme.palette.common.white,
                  },
                }}
                onClick={(__) => setIsOpen((prevIsOpen) => !prevIsOpen)}
                color="inherit"
                ref={notifButtonRef}
              >
                <IconBell stroke={1.5} size="1.3rem" />
              </Avatar>
            }
          />
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isOpen}
            onClose={() => setIsOpen(false)}
            onNotificationClick={(item) => {
              navigate(
                item.blocks[1].rendered.replace(
                  'https://platform.cofactr.com',
                  ''
                )
              );
            }}
          />
        </>
      )}
    </KnockFeedProvider>
  );
};

export default KnockNotificationWidget;
