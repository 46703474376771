import { Box } from '@mui/system';
import { Part, PartCore } from 'types/part';
import { Grid, Stack, Typography } from '@mui/material';
import PartAvatar from 'ui-component/extended/PartAvatar';
import CopyClipboard from 'ui-component/extended/CopyClipboard';

export function renderPartCellLite(
  part: Part | PartCore,
  rawMpn?: string | null
) {
  return (
    <Box
      sx={{
        width: '100%',
        my: '4px',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <PartAvatar part={part} />
        <Stack direction="column">
          {!part && (
            <Typography variant="body1">Unable to find match for:</Typography>
          )}
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid
              item
              xs
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                variant="subtitle1"
              >
                {part?.mpn ?? rawMpn ?? ''}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {Boolean(part?.mpn || rawMpn) && (
                <CopyClipboard
                  label="Part MPN"
                  input={(part?.mpn as string) ?? (rawMpn as string)}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}
