import useCreateRecordDialog from 'hooks/useCreateRecordDialog';
import { useCreateKitRequestsMutation } from 'store/slices/clientV2/kitRequests';
import {
  createUpdateKitRequestFormConstants,
  createUpdateKitRequestValidationSchema,
} from 'views/kitting/components/constants';
import CreateUpdateKitRequestForm from 'views/kitting/components/CreateUpdateKitRequestForm';
import { createUpdateKitRequestPreSubmit } from 'views/kitting/components/utils';
import { FormProvider } from 'react-hook-form';

type CreateKitRequestDialogProps = {
  dialogOpen: boolean;
  onClose: () => void;
};

const CreateKitRequestDialog = ({
  dialogOpen,
  onClose,
}: CreateKitRequestDialogProps) => {
  const formDefaults = {
    [createUpdateKitRequestFormConstants.name.id]: '',
    [createUpdateKitRequestFormConstants.sourceLocations.id]: [],
    [createUpdateKitRequestFormConstants.productionRun.id]: '',
    [createUpdateKitRequestFormConstants.team.id]: '',
    [createUpdateKitRequestFormConstants.shipTo.id]: '',
    [createUpdateKitRequestFormConstants.shipMethod.id]: '',
    [createUpdateKitRequestFormConstants.shipPartial.id]: false,
    [createUpdateKitRequestFormConstants.targetShipDate.id]: null,
  };

  const { methods, BaseCreateRecordDialog, dialogProps } =
    useCreateRecordDialog({
      formConstants: createUpdateKitRequestFormConstants,
      validationSchema: createUpdateKitRequestValidationSchema,
      newRecordDefaults: formDefaults,
      useCreateMutation: useCreateKitRequestsMutation,
      recordName: 'Kit Request',
      preSubmit: createUpdateKitRequestPreSubmit,
    });

  return (
    <BaseCreateRecordDialog
      open={dialogOpen}
      onClose={onClose}
      dialogProps={dialogProps}
    >
      <FormProvider {...methods}>
        <CreateUpdateKitRequestForm defaultValues={formDefaults} />
      </FormProvider>
    </BaseCreateRecordDialog>
  );
};

export default CreateKitRequestDialog;
