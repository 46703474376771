import {
  estimatedDaysOfSupplyStatus,
  historicalProcurabilityStatus,
  probableAvailabilityWindowStatus,
} from 'types/part';
import { formatDaysToDaysOrWeeks } from 'utils/dates';

export const getEstimatedDaysOfSupplyStatus = (estimatedDaysOfSupply: number) =>
  estimatedDaysOfSupply < 7
    ? estimatedDaysOfSupplyStatus.SHORTAGE
    : estimatedDaysOfSupply < 14
    ? estimatedDaysOfSupplyStatus.LIMITED
    : estimatedDaysOfSupply < 27
    ? estimatedDaysOfSupplyStatus.OKAY
    : estimatedDaysOfSupplyStatus.STRONG;

export const getHistoricalProcurabilityStatus = (
  historicalProcurability?: number
) => {
  const roundedHistoricalProcurability = Math.round(
    (historicalProcurability || 0) * 100
  );
  return roundedHistoricalProcurability < 35
    ? historicalProcurabilityStatus.LOW
    : roundedHistoricalProcurability < 95
    ? historicalProcurabilityStatus.MEDIUM
    : historicalProcurabilityStatus.HIGH;
};
export const formatEstimatedDaysOfSupply = (estimatedDaysOfSupply?: number) =>
  estimatedDaysOfSupply
    ? formatDaysToDaysOrWeeks(
        Number(estimatedDaysOfSupply),
        undefined,
        undefined,
        undefined,
        false
      )
    : '-';

export const formatHistoricalProcurability = (
  historicalProcurability?: number
) =>
  historicalProcurability
    ? `${(
        parseFloat(historicalProcurability as unknown as string) * 100
      ).toFixed(0)}%`
    : '-';

export const getProbableAvailabilityWindowStatus = (
  probableAvailabilityWindow: number
) =>
  probableAvailabilityWindow < 7
    ? probableAvailabilityWindowStatus.SHORTAGE
    : probableAvailabilityWindow < 14
    ? probableAvailabilityWindowStatus.LIMITED
    : probableAvailabilityWindow < 27
    ? probableAvailabilityWindowStatus.OKAY
    : probableAvailabilityWindowStatus.STRONG;

export const formatProbableAvailabilityWindow = (
  probableAvailabilityWindow?: number
) =>
  probableAvailabilityWindow
    ? formatDaysToDaysOrWeeks(
        Number(probableAvailabilityWindow),
        undefined,
        undefined,
        undefined,
        false
      )
    : '-';
