import { useCallback, useState } from 'react';
import _ from 'lodash';
import { FieldErrors } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';

type PackageAutocompleteProps = {
  handleSetValue: (option: string | null) => void;
  errors: FieldErrors;
  name: string;
  label: string;
  options: string[];
  initialValue?: string;
  inline?: boolean;
};

const PackageAutocomplete = ({
  handleSetValue,
  errors,
  name,
  label,
  options,
  initialValue,
  inline = false,
}: PackageAutocompleteProps) => {
  const [debouncedInput, setDebouncedInput] = useState('');
  const [value, setValue] = useState<string | null>(initialValue ?? '');

  const optionsWithUserInput = [...options];
  !_.find(options, (o) => o === debouncedInput) &&
    optionsWithUserInput.push(debouncedInput);

  const handleDebounce = useCallback(
    _.debounce((nextValue) => setDebouncedInput(nextValue), 1000),
    []
  );

  return (
    <Autocomplete
      options={optionsWithUserInput}
      onInputChange={(e, option) => {
        handleDebounce(option);
      }}
      onChange={(__, option) => {
        setValue(option);
        handleSetValue(option);
      }}
      sx={
        inline
          ? {
              '.MuiOutlinedInput-root': { height: '42px' },
              '.MuiAutocomplete-input': {
                minWidth: '50px !important',
              },
            }
          : undefined
      }
      autoComplete
      autoSelect
      filterSelectedOptions
      value={value}
      getOptionLabel={(option) => option}
      size={inline ? 'small' : undefined}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={Math.random()}>
          <MenuItem
            selected={selected}
            sx={{ fontStyle: option === debouncedInput ? 'italic' : 'normal' }}
          >
            {option}
          </MenuItem>
        </li>
      )}
      renderInput={(params) => (
        <Box sx={inline ? { width: '100%' } : { mb: 2 }}>
          <TextField
            {...params}
            label={label}
            fullWidth
            sx={
              inline
                ? {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderBottomRightRadius: '0px',
                      borderTopRightRadius: '0px',
                    },
                  }
                : undefined
            }
          />
          {errors?.[name]?.message && (
            <FormHelperText error id={`${name}Error`}>
              {errors?.[name]?.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default PackageAutocomplete;
