import { Box, Grid, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { isExternalSource } from 'utils/userPermissions';
import { useTheme } from '@mui/material/styles';
import { getCopy } from 'ui-component/extended/ExternalResourceDetails/copy';
import { formatToUniversalDateTime } from 'utils/dates';
import { IconPlug } from '@tabler/icons-react';

export const RenderExternalResourceCell = (params: GridRenderCellParams) => {
  const theme = useTheme();
  const { row } = params;
  const { dataSourceSyncedAt } = row;
  const isExternal = isExternalSource(row);
  const copy = getCopy();

  return (
    <Box>
      <Grid container alignItems="center" columnSpacing={1.5}>
        {isExternal ? (
          <>
            <Grid item xs="auto">
              <IconPlug
                color={theme.palette.primary.dark}
                width={20}
                height={20}
              />
            </Grid>
            <Grid item xs sx={{ overflow: 'hidden' }}>
              {dataSourceSyncedAt ? (
                <Stack>
                  <Typography noWrap variant="caption">
                    {copy.lastUpdated}
                  </Typography>
                  <Typography noWrap variant="caption">
                    {formatToUniversalDateTime(dataSourceSyncedAt)}
                  </Typography>
                </Stack>
              ) : (
                <Typography noWrap variant="caption">
                  {copy.failed}
                </Typography>
              )}
            </Grid>
          </>
        ) : (
          <Grid item xs sx={{ overflow: 'hidden' }}>
            Cofactr
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
