import React, { FC, useState } from 'react';
import { Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { TextDetails } from 'ui-component/Hero';
import WithSkeleton from 'ui-component/extended/WithSkeleton';
import CopyClipboard from 'ui-component/extended/CopyClipboard';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import { IconPencil } from '@tabler/icons-react';
import { PermissionScope } from 'types/apps';

interface HeroSectionProps extends TextDetails {
  isLoading: boolean;
  width: number;
  variant: 'body1' | 'h3';
  sx?: SxProps<Theme>;
  permissionScope?: PermissionScope;
  disabled?: boolean;
  tooltip?: string;
}

const HeroSection: FC<HeroSectionProps> = ({
  isLoading,
  width,
  variant,
  value,
  clipboard,
  clipboardValue,
  children,
  editInline,
  sx,
  permissionScope,
  disabled = false,
  tooltip,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  let clonedEditInline = null;
  if (editInline) {
    clonedEditInline = React.cloneElement(editInline, {
      closeEditing: () => setIsEditing(false),
    });
  }
  return (
    <WithSkeleton isLoading={isLoading} width={width}>
      <Stack direction="row" alignItems="center">
        {editInline && isEditing ? (
          clonedEditInline
        ) : (
          <Tooltip title={tooltip}>
            <Typography variant={variant} sx={sx}>
              {value || '-'}
              {editInline && !isEditing && (
                <SegmentIconButton
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  size="small"
                  permissionScope={permissionScope}
                  disabled={disabled}
                >
                  <IconPencil stroke="1.5px" width={20} height={20} />
                </SegmentIconButton>
              )}
            </Typography>
          </Tooltip>
        )}
        {!(editInline && isEditing) && clipboard && (
          <CopyClipboard
            input={clipboardValue || value || ''}
            label={clipboard}
          />
        )}
        {children}
      </Stack>
    </WithSkeleton>
  );
};

export default HeroSection;
