import * as Yup from 'yup';

export const createUpdateKitRequestFormConstants = {
  name: { label: 'Name', id: 'name' },
  sourceLocations: { label: 'Source From', id: 'sourceLocations' },
  productionRun: { label: 'Related Program', id: 'productionRun' },
  team: { label: 'Team', id: 'team' },
  shipTo: { label: 'Destination Facility', id: 'shipTo' },
  shipMethod: { label: 'Ship Method', id: 'shipMethod' },
  shipPartial: {
    label:
      'Send a partial shipment by the specified deadline if only some parts are available to ship',
    id: 'shipPartial',
  },
  targetShipDate: { label: 'Target Ship Date', id: 'targetShipDate' },
  internalNotes: { label: 'Internal Notes', id: 'internalNotes' },
  externalNotes: { label: 'Notes', id: 'externalNotes' },
};

export const createUpdateKitRequestValidationSchema = Yup.object().shape({
  [createUpdateKitRequestFormConstants.name.id]: Yup.string().required(
    `${createUpdateKitRequestFormConstants.name.label} is required`
  ),
  [createUpdateKitRequestFormConstants.sourceLocations.id]: Yup.array()
    .of(Yup.string())
    .min(
      1,
      `At least one ${createUpdateKitRequestFormConstants.sourceLocations.label} is required`
    )
    .required(
      `${createUpdateKitRequestFormConstants.sourceLocations.label} is required`
    ),
  [createUpdateKitRequestFormConstants.productionRun.id]:
    Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.team.id]: Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.shipTo.id]: Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.shipMethod.id]: Yup.string().nullable(),
  [createUpdateKitRequestFormConstants.shipPartial.id]:
    Yup.boolean().nullable(),
  [createUpdateKitRequestFormConstants.targetShipDate.id]: Yup.date()
    .transform((currentValue, originalValue) =>
      originalValue === '' ? null : currentValue
    )
    .nullable(),
});
