import { Box, Tooltip } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

export function longTextWithEllipsisRenderCell(params: GridRenderCellParams) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Tooltip title={params.formattedValue}>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {params.formattedValue}
        </Box>
      </Tooltip>
    </Box>
  );
}
