import { User } from 'types/user';
import UserInfoSection from 'ui-component/UserInfoSection';

export function RenderUserNameCell(
  value: User,
  subtitle?: string,
  automation?: boolean
) {
  return (
    <UserInfoSection user={value} subtitle={subtitle} automation={automation} />
  );
}
