import { ChangeEvent, FC, KeyboardEvent } from 'react';
import {
  Box,
  CircularProgress,
  FormHelperText,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  styled,
} from '@mui/material';
import { Controller, FieldError } from 'react-hook-form';
import { PermissionScope } from 'types/apps';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { getDisabledPermissionCopy } from 'utils/permissions';
import {
  CustomValidationType,
  HookFormComponentProps,
} from 'ui-component/HookFormComponents/types';
import _ from 'lodash';

const getValidationColor = (theme: Theme, type?: string | undefined) => {
  switch (type) {
    case CustomValidationType.WARNING:
      return theme.palette.warning.main;
    default:
      return theme.palette.error.main;
  }
};

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'errorType',
})<{ errorType?: string | undefined }>(({ theme, errorType }) => ({
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: getValidationColor(theme, errorType),
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: getValidationColor(theme, errorType),
  },
  '& .MuiInputBase-root': {
    resize: 'both',
  },
}));

const StyledFormHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== 'errorType',
})<{ errorType?: string | undefined }>(({ theme, errorType }) => ({
  '&.Mui-error': {
    color: getValidationColor(theme, errorType),
  },
}));

export type HookFormInputProps = {
  multiline?: boolean;
  disabled?: boolean;
  onKeyDown?: (e: KeyboardEvent) => void;
  permissionScope?: PermissionScope;
  boxSx?: SxProps<Theme>;
  additionalOnChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  resize?: boolean;
  helperText?: string;
  isLoading?: boolean;
} & TextFieldProps &
  HookFormComponentProps;

export const HookFormNumberInput: FC<HookFormInputProps> = ({
  errors,
  name = '',
  label,
  control,
  fullWidth = true,
  multiline = false,
  rows = 1,
  select = false,
  inputProps = {},
  InputProps = {},
  InputLabelProps = {},
  disabled = false,
  children,
  sx,
  boxSx,
  size = 'medium',
  defaultValue,
  onKeyDown,
  permissionScope,
  additionalOnChange,
  variant = 'outlined',
  resize = false,
  helperText,
  required = false,
  isLoading = false,
}) => {
  const { isPermissionDisabled, getDisabledReason } = useAppAccessContext();

  const permissionDisabled = isPermissionDisabled(permissionScope);
  const disabledCopy =
    permissionScope &&
    permissionDisabled &&
    getDisabledPermissionCopy(getDisabledReason(permissionScope));

  const errorType = _.get(errors, name)?.type as string | undefined;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <Tooltip
          title={
            permissionScope &&
            permissionDisabled &&
            disabledCopy &&
            `${disabledCopy?.message} ${disabledCopy?.action}`
          }
        >
          <Box sx={{ mb: 2, ...boxSx }}>
            <StyledTextField
              variant={variant}
              fullWidth={fullWidth}
              sx={sx}
              size={size}
              name={name}
              value={permissionDisabled ? '' : value ?? ''}
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e ? _.toNumber(e.target.value) : e);
                additionalOnChange && additionalOnChange(e);
              }}
              error={!!_.get(errors, name)}
              aria-describedby={`${name}Error`}
              label={label}
              multiline={multiline}
              rows={rows}
              select={select}
              type="number"
              required={required}
              errorType={errorType}
              inputProps={{
                ...inputProps,
                ...(resize ? { className: 'textarea' } : {}),
              }}
              /* eslint-disable-next-line react/jsx-no-duplicate-props */
              InputProps={{
                endAdornment: isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : undefined,
                ...InputProps,
              }}
              InputLabelProps={InputLabelProps}
              disabled={permissionDisabled || disabled}
              defaultValue={defaultValue}
              onKeyDown={onKeyDown}
            >
              {children}
            </StyledTextField>
            {helperText && (
              <StyledFormHelperText
                id={`${name}HelperText`}
                errorType={errorType}
              >
                {helperText}
              </StyledFormHelperText>
            )}
            {_.get(errors, name)?.message && (
              <StyledFormHelperText
                error
                id={`${name}Error`}
                errorType={errorType}
              >
                {(_.get(errors, name) as FieldError)?.message}
              </StyledFormHelperText>
            )}
          </Box>
        </Tooltip>
      )}
    />
  );
};
