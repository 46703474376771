import { ALL_APPS } from 'constants/appConstants';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Redirect } from 'react-router6-redirect';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const Runs = Loadable(lazy(() => import('views/production/views/Programs')));
const Program = Loadable(
  lazy(() => import('views/production/views/ProgramContainer'))
);

export const ProgramRoutes = [
  {
    path: `${ALL_APPS.PRODUCTION.deprecatedPath}`,
    element: <Redirect to={`${ALL_APPS.PRODUCTION.path}`} />,
  },
  {
    path: `${ALL_APPS.PRODUCTION.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PRODUCTION.id }}
        >
          <Runs />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_APPS.PRODUCTION.deprecatedPath}/:runId`,
    element: <Redirect to={`${ALL_APPS.PRODUCTION.path}/:runId`} />,
  },
  {
    path: `${ALL_APPS.PRODUCTION.path}/:runId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.PRODUCTION.id }}
      >
        <Program />
      </PermissionsRouteWrapper>
    ),
  },
];
