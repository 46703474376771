import Chip, { ChipColor } from 'ui-component/extended/Chip';

export function clickableChip(
  value: string,
  color: ChipColor,
  uppercase: boolean = true,
  tooltip?: string,
  onClick?: () => void,
  clickDisabled?: boolean
) {
  return (
    <Chip
      title={tooltip}
      label={value}
      chipcolor={color}
      style={{ textTransform: uppercase ? 'uppercase' : 'none' }}
      onClick={onClick}
      disabled={clickDisabled || false}
      keepColorWhenDisabled
    />
  );
}
