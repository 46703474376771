import _ from 'lodash';
import {
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import {
  renderPartCell,
  RenderStyledText,
  renderWithLoadingWrapper,
  RenderWithCopyButton,
  stockLocationDetailRenderCell,
  RenderMarketRatingIcon,
  detailTooltipRenderCell,
} from 'ui-component/DataGrid/Render';
import {
  StockLotCRUDSchema,
  StockLotQuerySchema,
  mountTypeOptions,
} from 'types/inventory';
import {
  supplyFilterOperators,
  getApplyFilterFnPart,
} from 'ui-component/DataGrid/FilterOperators';
import {
  businessDaysOrWeeksValueFormatter,
  currencyValueFormatter,
  intValueFormatter,
} from 'ui-component/DataGrid/ValueFormatters';
import { nestedValueGetter } from 'ui-component/DataGrid/ValueGetters';
import { ExtendedGridColDef } from 'types/datagrid';
import { ALL_APPS } from 'constants/appConstants';
import {
  aecStatus,
  aecStatusFormats,
  factoryLeadDayStatus,
  factoryLeadDayStatusFormats,
  lifecycleStatus,
  lifeCycleStatusFormats,
  minLeadStatus,
  minLeadStatusFormats,
  multiSourcingStatus,
  multiSourcingStatusCurrentFormats,
  multiSourcingStatusFormats,
  PartSupply,
  reachStatus,
  reachStatusFormats,
  rohsStatus,
  rohsStatusFormats,
  stockStatus,
  stockStatusFormats,
  terminationTypeOptionsExpanded,
  MarketRating,
  estimatedDaysOfSupplyFormats,
  probableAvailabilityWindowStatusFormats,
  historicalProcurabilityStatusFormats,
} from 'types/part';
import {
  buyableStockStatusComparator,
  medianFactoryLeadDaysComparator,
  multiSourcingComparator,
} from 'ui-component/DataGrid/SortComparators';
import { PartSchemas } from 'types/partStatus';
import { calcBestUnitPrice, calcMinLead } from 'utils/offerCalculations';
import {
  formatEstimatedDaysOfSupply,
  formatProbableAvailabilityWindow,
  getEstimatedDaysOfSupplyStatus,
  getHistoricalProcurabilityStatus,
  getProbableAvailabilityWindowStatus,
} from 'views/parts/utils';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import spectraLogo from 'assets/images/Spectra_logo_vert.png';
import {
  PartsMarketDataGraph,
  calcHistoricalProcurability,
  marketAvailabilitiesFromPartData,
} from 'views/parts/PartMarketDataGraph';

// THESE COLUMN DEFS PROVIDE THE PART COLUMN
// USE WHEN: row includes part and stockLot props
export const partColumnDef: ExtendedGridColDef = {
  field: 'part.mpn|part.mfg|part.description|part.id',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartCell(
      params.row.part,
      null,
      null,
      'stockLot' in params.row
        ? params.row.stockLot
        : (params.row as StockLotCRUDSchema | StockLotQuerySchema)
    ),
  valueGetter: nestedValueGetter,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.PART_SEARCH.id,
  },
};

// USE WHEN: row includes part prop and no stockLot
export const partNoStockLotColumnDef: ExtendedGridColDef = {
  field: 'part',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  hideable: false,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartCell(params.row.part, null, null),
  valueGetter: (params: GridValueGetterParams) => params.row.part?.mpn,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.PART_SEARCH.id,
  },
};

// USE WHEN: row includes part prop and no stockLot and datagrid is using the part provider
export const partNoStockLotColumnDefDescribeParts: ExtendedGridColDef = {
  field: 'part',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  hideable: false,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderWithLoadingWrapper({
      renderMethod: () => renderPartCell(params.row.part, null, null),
      params,
      isLoading: !params.row.part.mpn,
    }),
  valueGetter: (params: GridValueGetterParams) => params.row.part?.mpn,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.PART_SEARCH.id,
  },
};

// USE WHEN: row includes part and stockLot props and datagrid is using the part provider
export const partWithStockLotColumnDef: ExtendedGridColDef = {
  field: 'part.mpn|part.mfg|part.description|part.id',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  hideable: false,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartCell(
      params.row.part,
      null,
      null,
      'stockLot' in params.row
        ? params.row.stockLot
        : (params.row as StockLotCRUDSchema | StockLotQuerySchema)
    ),
  valueGetter: (params: GridValueGetterParams) => params.row?.part?.mpn,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.STOCK.id,
  },
};

// USE WHEN: row includes orgPart and stockLot props
export const orgPartColumnDef: ExtendedGridColDef = {
  field:
    'orgPart.mpn|orgPart.mfg|orgPart.description|orgPart.part.id|orgPart.customId',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartCell(
      params.row.orgPart,
      null,
      null,
      'stockLot' in params.row
        ? params.row.stockLot
        : (params.row as StockLotCRUDSchema | StockLotQuerySchema)
    ),
  valueGetter: nestedValueGetter,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.PART_SEARCH.id,
  },
  allowInBlended: true,
};

// USE WHEN: row includes orgPart prop and no stockLot
export const orgPartNoStockLotColumnDef: ExtendedGridColDef = {
  field:
    'orgPart.mpn|orgPart.mfg|orgPart.description|orgPart.part.id|orgPart.customId',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  hideable: false,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartCell(params.row.orgPart, null, null),
  valueGetter: (params: GridValueGetterParams) => params.row.orgPart?.mpn,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.PART_SEARCH.id,
  },
};

// USE WHEN: row includes orgPart prop and no stockLot and datagrid is using the part provider
export const orgPartWithStockLotColumnDef: ExtendedGridColDef = {
  field:
    'orgPart.mpn|orgPart.mfg|orgPart.description|orgPart.part.id|orgPart.customId',
  headerName: 'Part',
  description: 'Manufacturer and part number',
  type: 'string',
  minWidth: 250,
  flex: 1,
  hideable: false,
  renderCell: (params: GridRenderCellParams<string>) =>
    renderPartCell(
      params.row.orgPart,
      null,
      null,
      'stockLot' in params.row
        ? params.row.stockLot
        : (params.row as StockLotCRUDSchema | StockLotQuerySchema)
    ),
  valueGetter: nestedValueGetter,
  getApplyQuickFilterFn: getApplyFilterFnPart,
  permissionScope: {
    app: ALL_APPS.STOCK.id,
  },
};

// USE WHEN: server side datagrids
export const partCoreServerSideColumns = ({
  excludeMountPackage,
  orgName,
}: {
  excludeMountPackage?: boolean;
  orgName?: string;
}): ExtendedGridColDef[] => [
  {
    field: 'customId',
    headerName: `${orgName || 'Custom'} Part Number`,
    description: `${orgName || 'Custom'} Part Number for this part`,
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.customId,
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderWithCopyButton(
        params?.value || '-',
        params?.value || '-',
        `${orgName || 'Custom'} Part Number`
      ),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'mfg',
    headerName: 'Manufacturer',
    description: 'Manufacturer of this part',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      return params.row.part?.mfg;
    },
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description of this part.',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      return params.row.part?.description;
    },
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'part.id',
    headerName: 'Cofactr ID',
    description: 'Cofactr ID of part',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      return params.row.part?.id;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderWithCopyButton(params?.value || '-', params?.value || '-', 'ID'),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  ...(!excludeMountPackage
    ? [
        {
          field: 'terminationType',
          headerName: 'Mount Type',
          description: 'Mount type of part',
          type: 'singleSelect',
          minWidth: 80,
          flex: 0.5,
          valueOptions: terminationTypeOptionsExpanded,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.part || typeof params.row.part === 'string')
              return '-';
            return params.row.part?.terminationType;
          },
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'partPackage',
          headerName: 'Package',
          description: 'Package of part',
          type: 'string',
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.part || typeof params.row.part === 'string')
              return '-';
            return params.row.part?.package;
          },
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
      ]
    : []),
];

// USE WHEN: row IS a part rather than including the part as a field
export const partCoreColumns = (): ExtendedGridColDef[] => [
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    description: 'Manufacturer of this part.',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => params.row?.mfg ?? '-',
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row?.id],
        schemas: [PartSchemas.core],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description of this part.',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) =>
      params.row?.description ?? '-',
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row?.id],
        schemas: [PartSchemas.core],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'id',
    headerName: 'Cofactr ID',
    description: 'Cofactr ID of part',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => params.row?.id,
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderWithCopyButton(params?.value || '-', params?.value || '-', 'ID'),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'partMounttype',
    headerName: 'Mount Type',
    description: 'Mount type of part',
    type: 'singleSelect',
    minWidth: 80,
    flex: 0.5,
    valueOptions: terminationTypeOptionsExpanded,
    valueGetter: (params: GridValueGetterParams) => params.row?.terminationType,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row?.id],
        schemas: [PartSchemas.core],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'partPackage',
    headerName: 'Package',
    description: 'Package of part',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => params.row?.package,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row?.id],
        schemas: [PartSchemas.core],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
];

// USE WHEN: row is using the core part schema and is using the part provider
export const partCoreDecoratedColumns = (
  excludeMountPackage?: boolean
): ExtendedGridColDef[] => [
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    description: 'Manufacturer of this part.',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.mfg ?? '-',
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description of this part.',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.description ?? '-',
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'id',
    headerName: 'Cofactr ID',
    description: 'Cofactr ID of part',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      return params.row.part?.id;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderWithCopyButton(params?.value || '-', params?.value || '-', 'ID'),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  ...(!excludeMountPackage
    ? [
        {
          field: 'partMounttype',
          headerName: 'Mount Type',
          description: 'Mount type of part',
          type: 'singleSelect',
          minWidth: 80,
          flex: 0.5,
          valueOptions: terminationTypeOptionsExpanded,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.part || typeof params.row.part === 'string')
              return '-';
            return params.row.part?.terminationType;
          },
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.core],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'partPackage',
          headerName: 'Package',
          description: 'Package of part',
          type: 'string',
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.part || typeof params.row.part === 'string')
              return '-';
            return params.row.part?.package;
          },
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.core],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
      ]
    : []),
];

// THESE COLUMN DEFS PROVIDE PART COMPLIANCE COLUMNS
// USE WHEN: row is used in a server side datagrid
export const partComplianceServerSideColumns = (
  enableAEC?: boolean
): ExtendedGridColDef[] => [
  {
    field: 'reach',
    headerName: 'REACH',
    description: 'REACH compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(reachStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return params.row.part?.reach;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(reachStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(reachStatusFormats, [params.value || '', 'tooltip']),
        _.get(reachStatusFormats, [params.value || '', 'color'], 'grey'),
        _.find(params.row.specs?.documents, ['documentType', 'Reach Statement'])
          ?.url
      ),

    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'REACH Status',
      description: 'REACH compliance status of these parts',
      accordionCardGroupTitle: 'Compliance',
      alwaysVisible: false,
      schemas: [PartSchemas.specs],
      valueOptions: _.map(Object.values(reachStatus), (status) => ({
        value: status,
        label: reachStatusFormats[status].label,
        tooltip: reachStatusFormats[status].tooltip,
        color: reachStatusFormats[status].color,
      })),
    },
  },
  {
    field: 'rohs',
    headerName: 'RoHS',
    description: 'RoHS compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(rohsStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return params.row.part?.rohs;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(rohsStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(rohsStatusFormats, [params.value || '', 'tooltip']),
        _.get(rohsStatusFormats, [params.value || '', 'color'], 'grey'),
        _.find(params.row.specs?.documents, ['documentType', 'Rohs Statement'])
          ?.url
      ),

    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'RoHS Status',
      description: 'RoHS compliance status of these parts',
      accordionCardGroupTitle: 'Compliance',
      alwaysVisible: false,
      schemas: [PartSchemas.specs],
      valueOptions: _.map(Object.values(rohsStatus), (status) => ({
        value: status,
        label: rohsStatusFormats[status].label,
        tooltip: rohsStatusFormats[status].tooltip,
        color: rohsStatusFormats[status].color,
      })),
    },
  },
  ...(enableAEC
    ? [
        {
          field: 'aec',
          headerName: 'AEC-Q',
          description: 'AEC-Q compliance status of this part.',
          type: 'singleSelect',
          valueOptions: Object.values(aecStatus),
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row || typeof params.row.part === 'string') return '-';
            return params.row.part?.aec;
          },
          renderCell: (params: GridRenderCellParams<string>) =>
            RenderStyledText(
              _.get(aecStatusFormats, [params.value || '', 'label'], 'Unknown'),
              _.get(aecStatusFormats, [params.value || '', 'tooltip']),
              _.get(aecStatusFormats, [params.value || '', 'color'], 'grey')
            ),

          permissionScope: { app: ALL_APPS.COMPLIANCE.id },
          showChart: {
            title: 'AEC-Q',
            description: 'AEC-Q compliance status of these parts',
            accordionCardGroupTitle: 'Compliance',
            alwaysVisible: false,
            schemas: [PartSchemas.core],
            valueOptions: _.map(Object.values(aecStatus), (status) => ({
              value: status,
              label: aecStatusFormats[status].label,
              tooltip: aecStatusFormats[status].tooltip,
              color: aecStatusFormats[status].color,
            })),
          },
        },
      ]
    : []),
];

// USE WHEN: row is used with the part provider
export const partComplianceDecoratedColumns = (
  enableAEC?: boolean
): ExtendedGridColDef[] => [
  {
    field: 'reach',
    headerName: 'REACH',
    description: 'REACH compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(reachStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return params.row.part?.reach;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        renderMethod: () =>
          RenderStyledText(
            _.get(reachStatusFormats, [params.value || '', 'label'], 'Unknown'),
            _.get(reachStatusFormats, [params.value || '', 'tooltip']),
            _.get(reachStatusFormats, [params.value || '', 'color'], 'grey'),
            _.find(params.row.specs?.documents, [
              'documentType',
              'Reach Statement',
            ])?.url
          ),
        params,
      }),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'REACH Status',
      description: 'REACH compliance status of these parts',
      accordionCardGroupTitle: 'Compliance',
      alwaysVisible: false,
      schemas: [PartSchemas.specs],
      valueOptions: _.map(Object.values(reachStatus), (status) => ({
        value: status,
        label: reachStatusFormats[status].label,
        tooltip: reachStatusFormats[status].tooltip,
        color: reachStatusFormats[status].color,
      })),
    },
  },
  {
    field: 'rohs',
    headerName: 'RoHS',
    description: 'RoHS compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(rohsStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return params.row.part?.rohs;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        renderMethod: () =>
          RenderStyledText(
            _.get(rohsStatusFormats, [params.value || '', 'label'], 'Unknown'),
            _.get(rohsStatusFormats, [params.value || '', 'tooltip']),
            _.get(rohsStatusFormats, [params.value || '', 'color'], 'grey'),
            _.find(params.row.specs?.documents, [
              'documentType',
              'Rohs Statement',
            ])?.url
          ),
        params,
      }),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'RoHS Status',
      description: 'RoHS compliance status of these parts',
      accordionCardGroupTitle: 'Compliance',
      alwaysVisible: false,
      schemas: [PartSchemas.specs],
      valueOptions: _.map(Object.values(rohsStatus), (status) => ({
        value: status,
        label: rohsStatusFormats[status].label,
        tooltip: rohsStatusFormats[status].tooltip,
        color: rohsStatusFormats[status].color,
      })),
    },
  },
  ...(enableAEC
    ? [
        {
          field: 'aec',
          headerName: 'AEC-Q',
          description: 'AEC-Q compliance status of this part.',
          type: 'singleSelect',
          valueOptions: Object.values(aecStatus),
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row || typeof params.row.part === 'string') return '-';
            return params.row.part?.aec;
          },
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.core],
              renderMethod: () =>
                RenderStyledText(
                  _.get(
                    aecStatusFormats,
                    [params.value || '', 'label'],
                    'Unknown'
                  ),
                  _.get(aecStatusFormats, [params.value || '', 'tooltip']),
                  _.get(aecStatusFormats, [params.value || '', 'color'], 'grey')
                ),
              params,
            }),
          permissionScope: { app: ALL_APPS.COMPLIANCE.id },
          showChart: {
            title: 'AEC-Q',
            description: 'AEC-Q compliance status of these parts',
            accordionCardGroupTitle: 'Compliance',
            alwaysVisible: false,
            schemas: [PartSchemas.core],
            valueOptions: _.map(Object.values(aecStatus), (status) => ({
              value: status,
              label: aecStatusFormats[status].label,
              tooltip: aecStatusFormats[status].tooltip,
              color: aecStatusFormats[status].color,
            })),
          },
        },
      ]
    : []),
];

// THESE COLUMN DEFS PROVIDE PART RISK COLUMNS
// USE WHEN: row is used in a server side datagrid
export const partRiskServerSideColumns = (
  enableBuyable?: boolean
): ExtendedGridColDef[] => [
  {
    field: 'lifecycleStatus',
    headerName: 'Lifecycle',
    description: 'Lifecycle status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(lifecycleStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return params.row.part?.lifecycleStatus;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(lifeCycleStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(lifeCycleStatusFormats, [params.value || '', 'tooltip']),
        _.get(lifeCycleStatusFormats, [params.value || '', 'color'], 'grey')
      ),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'Lifecycle',
      description: 'Lifecycle status of these parts',
      accordionCardGroupTitle: 'Health',
      alwaysVisible: false,
      schemas: [PartSchemas.core],
      valueOptions: _.map(Object.values(lifecycleStatus), (status) => ({
        value: status,
        label: lifeCycleStatusFormats[status].label,
        tooltip: lifeCycleStatusFormats[status].tooltip,
        color: lifeCycleStatusFormats[status].color,
      })),
    },
  },
  {
    field: 'factoryLead',
    headerName: 'Factory Lead',
    description: 'Median distributor-reported factory lead time of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(factoryLeadDayStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      const medianFactoryLeadDays =
        _.min([
          params.row.part?.factoryLead,
          ..._.map(params.row.alts || [], 'factoryLead'),
        ]) || 0;
      if (!medianFactoryLeadDays) {
        return null;
      }
      return medianFactoryLeadDays < 90
        ? factoryLeadDayStatus.THREE_MONTHS
        : medianFactoryLeadDays < 180
        ? factoryLeadDayStatus.SIX_MONTHS
        : medianFactoryLeadDays < 365
        ? factoryLeadDayStatus.ONE_YEAR
        : factoryLeadDayStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'label'], '-'),
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'tooltip']),
        _.get(
          factoryLeadDayStatusFormats,
          [params.value || '', 'color'],
          'grey'
        )
      ),

    sortComparator: medianFactoryLeadDaysComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'minLead',
    headerName: 'Fastest Lead',
    description: 'Median distributor-reported factory lead time of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(minLeadStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      const minLead =
        _.min([
          params.row.part?.minLead,
          ..._.map(params.row.alts || [], 'minLead'),
        ]) || 0;
      if (!minLead) {
        return null;
      }
      return minLead <= 3
        ? minLeadStatus.THREE_DAYS
        : minLead < 7
        ? minLeadStatus.ONE_WEEK
        : minLead < 30
        ? minLeadStatus.ONE_MONTH
        : minLeadStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(minLeadStatusFormats, [params.value || '', 'label'], '-'),
        _.get(minLeadStatusFormats, [params.value || '', 'tooltip']),
        _.get(minLeadStatusFormats, [params.value || '', 'color'], 'grey')
      ),

    sortComparator: medianFactoryLeadDaysComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'currentMultisourcing',
    headerName: 'Multisourcing (Now)',
    description:
      'Number of authorized distributors with current stock of this part',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      const numAuthedDistributorsWithOffersCurrentStock =
        _.sum([
          params.row.part?.currentMultisourcing,
          ..._.map(params.row.alts || [], 'currentMultisourcing'),
        ]) || 0;
      return numAuthedDistributorsWithOffersCurrentStock < 2
        ? multiSourcingStatus.TWO
        : numAuthedDistributorsWithOffersCurrentStock < 5
        ? multiSourcingStatus.FIVE
        : numAuthedDistributorsWithOffersCurrentStock < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(
          multiSourcingStatusCurrentFormats,
          [params.value || '', 'label'],
          '-'
        ),
        _.get(multiSourcingStatusCurrentFormats, [
          params.value || '',
          'tooltip',
        ]),
        _.get(
          multiSourcingStatusCurrentFormats,
          [params.value || '', 'color'],
          'grey'
        )
      ),

    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'multisourcing',
    headerName: 'Multisourcing',
    description:
      'Number of authorized distributors that report stocking this part under ideal supply chain conditions',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      const numAuthedDistributorsWithOffers =
        _.sum([
          params.row.part?.multisourcing,
          ..._.map(params.row.alts || [], 'multisourcing'),
        ]) || 0;
      return numAuthedDistributorsWithOffers < 2
        ? multiSourcingStatus.TWO
        : numAuthedDistributorsWithOffers < 5
        ? multiSourcingStatus.FIVE
        : numAuthedDistributorsWithOffers < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(multiSourcingStatusFormats, [params.value || '', 'label'], '-'),
        _.get(multiSourcingStatusFormats, [params.value || '', 'tooltip']),
        _.get(multiSourcingStatusFormats, [params.value || '', 'color'], 'grey')
      ),

    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  ...(enableBuyable
    ? [
        {
          field: 'buyable',
          headerName: 'Supply',
          description:
            'Overall buyable availability of this part in distributor inventory',
          type: 'singleSelect',
          valueOptions: Object.values(stockStatus).map((status) => ({
            value: status,
            label: stockStatusFormats[status].label,
          })),
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) => {
            if (!params.row.part || typeof params.row.part === 'string')
              return '-';
            const buyableStock =
              _.sum([
                params.row.part?.buyable,
                ..._.map(params.row.alts || [], 'buyable'),
              ]) || 0;
            return buyableStock < 1000
              ? stockStatus.SHORTAGE
              : buyableStock < 10000
              ? stockStatus.LIMITED
              : buyableStock < 50000
              ? stockStatus.OKAY
              : stockStatus.STRONG;
          },
          renderCell: (params: GridRenderCellParams<string>) =>
            RenderStyledText(
              _.get(stockStatusFormats, [params.value || '', 'label'], '-'),
              _.get(stockStatusFormats, [params.value || '', 'tooltip']),
              _.get(stockStatusFormats, [params.value || '', 'color'], 'grey')
            ),

          sortComparator: buyableStockStatusComparator,
          permissionScope: { app: ALL_APPS.COMPLIANCE.id },
          showChart: {
            title: 'Supply Status',
            description: 'Supply status of the parts in this BOM',
            accordionCardGroupTitle: 'Health',
            alwaysVisible: false,
            schemas: [PartSchemas.supply],
            valueOptions: _.map(Object.values(stockStatus), (status) => ({
              value: status,
              label: stockStatusFormats[status].label,
              tooltip: stockStatusFormats[status].tooltip,
              color: stockStatusFormats[status].color,
            })),
          },
          filterOperators: supplyFilterOperators,
        },
      ]
    : []),
];

// USE WHEN: row is used with the part provider
export const partRiskDecoratedColumns = (
  enableBuyable?: boolean,
  enableMinLead?: boolean
): ExtendedGridColDef[] => [
  {
    field: 'lifecycleStatus',
    headerName: 'Lifecycle',
    description: 'Lifecycle status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(lifecycleStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return params.row.part?.lifecycleStatus;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        renderMethod: () =>
          RenderStyledText(
            _.get(
              lifeCycleStatusFormats,
              [params.value || '', 'label'],
              'Unknown'
            ),
            _.get(lifeCycleStatusFormats, [params.value || '', 'tooltip']),
            _.get(lifeCycleStatusFormats, [params.value || '', 'color'], 'grey')
          ),
        params,
      }),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'Lifecycle',
      description: 'Lifecycle status of these parts',
      accordionCardGroupTitle: 'Health',
      alwaysVisible: false,
      schemas: [PartSchemas.core],
      valueOptions: _.map(Object.values(lifecycleStatus), (status) => ({
        value: status,
        label: lifeCycleStatusFormats[status].label,
        tooltip: lifeCycleStatusFormats[status].tooltip,
        color: lifeCycleStatusFormats[status].color,
      })),
    },
  },
  {
    field: 'factoryLead',
    headerName: 'Factory Lead',
    description: 'Median distributor-reported factory lead time of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(factoryLeadDayStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      const medianFactoryLeadDays =
        _.min([
          params.row.part?.factoryLead,
          ..._.map(params.row.alts || [], 'factoryLead'),
        ]) || 0;
      if (!medianFactoryLeadDays) {
        return null;
      }
      return medianFactoryLeadDays < 90
        ? factoryLeadDayStatus.THREE_MONTHS
        : medianFactoryLeadDays < 180
        ? factoryLeadDayStatus.SIX_MONTHS
        : medianFactoryLeadDays < 365
        ? factoryLeadDayStatus.ONE_YEAR
        : factoryLeadDayStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        renderMethod: () =>
          RenderStyledText(
            _.get(
              factoryLeadDayStatusFormats,
              [params.value || '', 'label'],
              '-'
            ),
            _.get(factoryLeadDayStatusFormats, [params.value || '', 'tooltip']),
            _.get(
              factoryLeadDayStatusFormats,
              [params.value || '', 'color'],
              'grey'
            )
          ),
        params,
      }),
    sortComparator: medianFactoryLeadDaysComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'Factory Lead',
      description:
        'Median distributor-reported factory lead time of these parts',
      accordionCardGroupTitle: 'Health',
      alwaysVisible: false,
      schemas: [PartSchemas.core],
      valueOptions: _.map(Object.values(factoryLeadDayStatus), (status) => ({
        value: status,
        label: factoryLeadDayStatusFormats[status].label,
        tooltip: factoryLeadDayStatusFormats[status].tooltip,
        color: factoryLeadDayStatusFormats[status].color,
      })),
    },
  },
  ...(enableMinLead
    ? [
        {
          field: 'minLead',
          headerName: 'Fastest Lead',
          description:
            'Median distributor-reported factory lead time of this part.',
          type: 'singleSelect',
          valueOptions: Object.values(minLeadStatus),
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) => {
            const minLead = calcMinLead({
              part: params.row?.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: params.row?.currentTotal || 1,
            });
            if (!minLead) {
              return null;
            }
            return minLead <= 3
              ? minLeadStatus.THREE_DAYS
              : minLead < 7
              ? minLeadStatus.ONE_WEEK
              : minLead < 30
              ? minLeadStatus.ONE_MONTH
              : minLeadStatus.MORE;
          },
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.core],
              renderMethod: () =>
                RenderStyledText(
                  _.get(
                    minLeadStatusFormats,
                    [params.value || '', 'label'],
                    '-'
                  ),
                  _.get(minLeadStatusFormats, [params.value || '', 'tooltip']),
                  _.get(
                    minLeadStatusFormats,
                    [params.value || '', 'color'],
                    'grey'
                  )
                ),
              params,
            }),
          sortComparator: medianFactoryLeadDaysComparator,
          permissionScope: { app: ALL_APPS.COMPLIANCE.id },
        },
      ]
    : []),
  {
    field: 'currentMultisourcing',
    headerName: 'Multisourcing (Now)',
    description:
      'Number of authorized distributors with current stock of this part',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      const numAuthedDistributorsWithOffersCurrentStock =
        _.sum([
          params.row.part?.currentMultisourcing,
          ..._.map(params.row.alts || [], 'currentMultisourcing'),
        ]) || 0;
      return numAuthedDistributorsWithOffersCurrentStock < 2
        ? multiSourcingStatus.TWO
        : numAuthedDistributorsWithOffersCurrentStock < 5
        ? multiSourcingStatus.FIVE
        : numAuthedDistributorsWithOffersCurrentStock < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        renderMethod: () =>
          RenderStyledText(
            _.get(
              multiSourcingStatusCurrentFormats,
              [params.value || '', 'label'],
              '-'
            ),
            _.get(multiSourcingStatusCurrentFormats, [
              params.value || '',
              'tooltip',
            ]),
            _.get(
              multiSourcingStatusCurrentFormats,
              [params.value || '', 'color'],
              'grey'
            )
          ),
        params,
      }),
    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'Multisourcing (Now)',
      description:
        'Number of authorized distributors with current stock of these parts',
      accordionCardGroupTitle: 'Health',
      alwaysVisible: false,
      schemas: [PartSchemas.core],
      valueOptions: _.map(Object.values(multiSourcingStatus), (status) => ({
        value: status,
        label: multiSourcingStatusCurrentFormats[status].label,
        tooltip: multiSourcingStatusCurrentFormats[status].tooltip,
        color: multiSourcingStatusCurrentFormats[status].color,
      })),
    },
  },
  {
    field: 'multisourcing',
    headerName: 'Multisourcing',
    description:
      'Number of authorized distributors that report stocking this part under ideal supply chain conditions',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      const numAuthedDistributorsWithOffers =
        _.sum([
          params.row.part?.multisourcing,
          ..._.map(params.row.alts || [], 'multisourcing'),
        ]) || 0;
      return numAuthedDistributorsWithOffers < 2
        ? multiSourcingStatus.TWO
        : numAuthedDistributorsWithOffers < 5
        ? multiSourcingStatus.FIVE
        : numAuthedDistributorsWithOffers < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.core],
        renderMethod: () =>
          RenderStyledText(
            _.get(
              multiSourcingStatusFormats,
              [params.value || '', 'label'],
              '-'
            ),
            _.get(multiSourcingStatusFormats, [params.value || '', 'tooltip']),
            _.get(
              multiSourcingStatusFormats,
              [params.value || '', 'color'],
              'grey'
            )
          ),
        params,
      }),
    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'Multisourcing',
      description:
        'Number of authorized distributors that report stocking these parts under ideal supply chain conditions',
      accordionCardGroupTitle: 'Health',
      alwaysVisible: false,
      schemas: [PartSchemas.core],
      valueOptions: _.map(Object.values(multiSourcingStatus), (status) => ({
        value: status,
        label: multiSourcingStatusCurrentFormats[status].label,
        tooltip: multiSourcingStatusCurrentFormats[status].tooltip,
        color: multiSourcingStatusCurrentFormats[status].color,
      })),
    },
  },
  ...(enableBuyable
    ? [
        {
          field: 'buyableStatus',
          headerName: 'Supply',
          description:
            'Overall buyable availability of this part in distributor inventory',
          type: 'singleSelect',
          valueOptions: Object.values(stockStatus),
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) => {
            if (
              !params.row.part ||
              typeof params.row.part === 'string' ||
              !params.row.supply
            )
              return '-';
            const buyableStock =
              _.sum([
                params.row?.supply?.buyable,
                ...params.row?.altsSupply?.map((a: PartSupply) => a?.buyable),
              ]) || 0;
            return buyableStock < 1000
              ? stockStatus.SHORTAGE
              : buyableStock < 10000
              ? stockStatus.LIMITED
              : buyableStock < 50000
              ? stockStatus.OKAY
              : stockStatus.STRONG;
          },
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.core],
              renderMethod: () =>
                RenderStyledText(
                  _.get(stockStatusFormats, [params.value || '', 'label'], '-'),
                  _.get(stockStatusFormats, [params.value || '', 'tooltip']),
                  _.get(
                    stockStatusFormats,
                    [params.value || '', 'color'],
                    'grey'
                  )
                ),
              params,
            }),
          sortComparator: buyableStockStatusComparator,
          permissionScope: { app: ALL_APPS.COMPLIANCE.id },
          showChart: {
            title: 'Supply Status',
            description: 'Supply status of the parts in this BOM',
            accordionCardGroupTitle: 'Health',
            alwaysVisible: false,
            schemas: [PartSchemas.supply],
            valueOptions: _.map(Object.values(stockStatus), (status) => ({
              value: status,
              label: stockStatusFormats[status].label,
              tooltip: stockStatusFormats[status].tooltip,
              color: stockStatusFormats[status].color,
            })),
          },
        },
      ]
    : []),
];

export const partMarketDecoratedColumns: (
  enableSpectraPredictionColumns?: boolean
) => ExtendedGridColDef[] = (enableSpectraPredictionColumns) => [
  {
    field: 'historicalProcurability',
    headerName: 'Historical Procurability',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) =>
      calcHistoricalProcurability(
        marketAvailabilitiesFromPartData([
          ...(params.row?.market?.marketAvailabilities
            ? [params.row?.market]
            : []),
          ...(params.row?.altsMarket
            ? _.filter(params.row?.altsMarket, (a) => a?.marketAvailabilities)
            : []),
        ]),
        params.row.currentTotal || 5000
      ),
    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value && _.toNumber(params.value) !== 0) {
        return null;
      }

      return getHistoricalProcurabilityStatus(params.value);
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id, ..._.map(params.row.alts, 'id')],
        schemas: [PartSchemas.market],
        renderMethod: () =>
          detailTooltipRenderCell(
            params,
            <Box sx={{ width: '50vw' }}>
              <PartsMarketDataGraph
                partIds={[params.row.part?.id, ..._.map(params.row.alts, 'id')]}
                targetQuantity={params.row.currentTotal || 5000}
                partMarketData={[
                  ...(params.row?.market?.marketAvailabilities
                    ? [params.row?.market]
                    : []),
                  ...(params.row?.altsMarket
                    ? _.filter(
                        params.row?.altsMarket,
                        (a) => a?.marketAvailabilities
                      )
                    : []),
                ]}
                inlineMode
              />
            </Box>,
            RenderStyledText(
              !!params.value || _.toNumber(params.value) === 0
                ? `${(_.toNumber(params.value) * 100).toFixed(2)}%`
                : '-',
              _.get(historicalProcurabilityStatusFormats, [
                params.formattedValue || '',
                'tooltip',
              ]),
              _.get(
                historicalProcurabilityStatusFormats,
                [params.formattedValue || '', 'color'],
                'grey'
              )
            ),
            _.toNumber(params.value) === 0
          ),
        params,
      }),
    renderHeader: (params: GridColumnHeaderParams) => (
      <Tooltip title="Powered by Altium Spectra Historical Market Availability">
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <img
            src={spectraLogo}
            alt="Altium Spectra Logo"
            width="18"
            height="24"
            style={{
              objectFit: 'contain',
              maxWidth: 'none',
              height: '18px',
            }}
          />
          <Typography sx={{ fontWeight: 500 }}>
            {params.colDef.headerName}
          </Typography>
        </Stack>
      </Tooltip>
    ),
  },
  ...(enableSpectraPredictionColumns
    ? [
        {
          field: 'estimatedDaysOfSupply',
          headerName: 'Estimated Days Of Supply',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.market?.estimatedDaysOfSupply,
          valueFormatter: (params: GridValueFormatterParams) => {
            const estimatedDaysOfSupply = params.value;
            if (!estimatedDaysOfSupply) {
              return null;
            }
            return getEstimatedDaysOfSupplyStatus(estimatedDaysOfSupply);
          },
          renderCell: (params: GridRenderCellParams<number>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.market],
              renderMethod: () => {
                const daysOrWeeksValue = formatEstimatedDaysOfSupply(
                  params.value
                );
                return RenderStyledText(
                  daysOrWeeksValue,
                  _.get(
                    estimatedDaysOfSupplyFormats,
                    [params.formattedValue || '', 'tooltip'],
                    () => ''
                  )(daysOrWeeksValue),
                  _.get(
                    estimatedDaysOfSupplyFormats,
                    [params.formattedValue || '', 'color'],
                    'grey'
                  )
                );
              },
              params,
            }),
        },
        {
          field: 'probableAvailabilityWindow',
          headerName: 'Probable Availability Window',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.market?.probableAvailabilityWindow,
          valueFormatter: (params: GridValueFormatterParams) => {
            const probableAvailabilityWindow = params.value;
            if (!probableAvailabilityWindow) {
              return null;
            }
            return getProbableAvailabilityWindowStatus(
              probableAvailabilityWindow
            );
          },
          renderCell: (params: GridRenderCellParams<number>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.market],
              renderMethod: () => {
                const daysOrWeeksValue = formatProbableAvailabilityWindow(
                  params.value
                );

                return RenderStyledText(
                  daysOrWeeksValue,
                  _.get(
                    probableAvailabilityWindowStatusFormats,
                    [params.formattedValue || '', 'tooltip'],
                    () => ''
                  )(daysOrWeeksValue),
                  _.get(
                    probableAvailabilityWindowStatusFormats,
                    [params.formattedValue || '', 'color'],
                    'grey'
                  )
                );
              },
              params,
            }),
        },
        {
          field: 'rating',
          headerName: 'Rating',
          type: 'singleSelect',
          valueOptions: Object.values(MarketRating),
          minWidth: 80,
          flex: 0.5,
          valueGetter: (params: GridValueGetterParams) =>
            params.row?.market?.rating,
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds: [params.row.part?.id],
              schemas: [PartSchemas.market],
              params,
              renderMethod: () =>
                RenderMarketRatingIcon(params.value as MarketRating),
            }),
        },
      ]
    : []),
];

// THESE COLUMN DEFS PROVIDE PART SUPPLY COLUMNS
// USE WHEN: row has alts and is using the part provider
export const partWithAltSupplyColumnsDecoratedParts: (
  quantProperty: string,
  singleRefPriceColumn?: boolean
) => ExtendedGridColDef[] = (quantProperty, singleRefPriceColumn) => [
  {
    field: 'buyable',
    headerName: 'Buyable Quant',
    description:
      'Quantity of this part that can be purchased using Cofactr now',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds:
          typeof params?.row?.part === 'string'
            ? [params?.row?.part, _.map(params.row.altsSupply, 'id')]
            : [params?.row?.part?.id, _.map(params.row.altsSupply, 'id')],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'quotable',
    headerName: 'Quotable Quant',
    description:
      'Quantity of this part that is reported as available from reliable distributors currently and can be quoted',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds:
          typeof params?.row?.part === 'string'
            ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
            : [params?.row?.part?.id, ..._.map(params.row.altsSupply, 'id')],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'maybe',
    headerName: 'Maybe Quant',
    description:
      'Quantity of this part that is reported by less accurate distributors and may or may not actually be available',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds:
          typeof params?.row?.part === 'string'
            ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
            : [params?.row?.part?.id, ..._.map(params.row.altsSupply, 'id')],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  ...(singleRefPriceColumn
    ? [
        {
          field: 'refPrice',
          headerName: 'Ref Price',
          description:
            'Approximate price for this part from all suppliers with or without stock',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1),
              ignoreReportedStock: true,
            }) || 0) * _.toNumber(_.get(params.row, quantProperty) || 1),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'buyableRefPrice',
          headerName: 'Buyable Ref Price',
          description:
            'Approximate price for this part from all suppliers with buyable stock',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1),
              ignoreReportedStock: false,
            }) || 0) * _.toNumber(_.get(params.row, quantProperty) || 1),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
      ]
    : [
        {
          field: 'refPrice10',
          headerName: '10pc Ref Price',
          description:
            'Approximate price for this part at quantity 10 from all suppliers with or without stock',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 10,
              ignoreReportedStock: true,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 10),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'refPrice100',
          headerName: '100pc Ref Price',
          description:
            'Approximate price for this part at quantity 100 from all suppliers with or without stock',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 100,
              ignoreReportedStock: true,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 100),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'refPrice1000',
          headerName: '1,000pc Ref Price',
          description:
            'Approximate price for this part at quantity 1,000 from all suppliers with or without stock',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 1000,
              ignoreReportedStock: true,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 1000),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'refPrice10000',
          headerName: '10,000pc Ref Price',
          description:
            'Approximate price for this part at quantity 10,000 from all suppliers with or without stock',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 10000,
              ignoreReportedStock: true,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 10000),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'buyableRefPrice10',
          headerName: '10pc Buyable Ref Price',
          description:
            'Approximate price price for this part at quantity 10 from all buyable suppliers',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 10,
              ignoreReportedStock: false,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 10),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'buyableRefPrice100',
          headerName: '100pc Buyable Ref Price',
          description:
            'Approximate price price for this part at quantity 100 from all buyable suppliers',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 100,
              ignoreReportedStock: false,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 100),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'buyableRefPrice1000',
          headerName: '1,000pc Buyable Ref Price',
          description:
            'Approximate price price for this part at quantity 1,000 from all buyable suppliers',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 1000,
              ignoreReportedStock: false,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 1000),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, _.map(params.row.altsSupply, 'id')]
                  : [params?.row?.part?.id, _.map(params.row.altsSupply, 'id')],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
        {
          field: 'buyableRefPrice10000',
          headerName: '10,000pc Buyable Ref Price',
          description:
            'Approximate price price for this part at quantity 10,000 from all buyable suppliers',
          type: 'number',
          minWidth: 80,
          flex: 0.5,
          valueFormatter: currencyValueFormatter,
          valueGetter: (params: GridValueGetterParams) =>
            (calcBestUnitPrice({
              part: params.row.part,
              supply: params.row?.supply,
              altsSupply: params.row?.altsSupply,
              quant: _.toNumber(_.get(params.row, quantProperty) || 1) * 10000,
              ignoreReportedStock: false,
            }) || 0) *
            (_.toNumber(_.get(params.row, quantProperty) || 1) * 10000),
          renderCell: (params: GridRenderCellParams<string>) =>
            renderWithLoadingWrapper({
              partIds:
                typeof params?.row?.part === 'string'
                  ? [params?.row?.part, ..._.map(params.row.altsSupply, 'id')]
                  : [
                      params?.row?.part?.id,
                      ..._.map(params.row.altsSupply, 'id'),
                    ],
              schemas: [PartSchemas.supply],
              params,
            }),
          permissionScope: { app: ALL_APPS.PART_SEARCH.id },
        },
      ]),
  {
    field: 'minLead',
    headerName: 'Shortest Lead Time',
    description: 'Shortest lead time from buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: businessDaysOrWeeksValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      _.min([
        params.row.supply?.minLead,
        ..._.chain(params.row?.altsSupply)
          .map('minLead')
          .filter(Boolean)
          .value(),
      ]) || 0,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds:
          typeof params?.row?.part === 'string'
            ? [params?.row?.part, _.map(params.row.altsSupply, 'id')]
            : [params?.row?.part?.id, _.map(params.row.altsSupply, 'id')],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
];

// USE WHEN: row does NOT have alts and is using the part provider
export const nestedPartSupplyColumnsDecoratedParts: ExtendedGridColDef[] = [
  {
    field: 'buyable',
    headerName: 'Buyable Quant',
    description:
      'Quantity of this part that can be purchased using Cofactr now',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.buyable,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'quotable',
    headerName: 'Quotable Quant',
    description:
      'Quantity of this part that is reported as available from reliable distributors currently and can be quoted',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.quotable,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'maybe',
    headerName: 'Maybe Quant',
    description:
      'Quantity of this part that is reported by less accurate distributors and may or may not actually be available',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.maybe,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice10',
    headerName: '10pc Ref Price',
    description:
      'Approximate unit for this part at quantity 10 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.refPrice10,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice100',
    headerName: '100pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 100 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.refPrice100,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice1000',
    headerName: '1,000pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 1,000 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.refPrice1000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice10000',
    headerName: '10,000pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 10,000 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.refPrice10000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice10',
    headerName: '10pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 10 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice10,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice100',
    headerName: '100pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 100 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice100,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice1000',
    headerName: '1,000pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 1,000 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice1000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice10000',
    headerName: '10,000pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 10,000 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice10000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
];

// USE WHEN: columns are flat on the row (no nested .supply, etc)
export const supplyColumns: ExtendedGridColDef[] = [
  {
    field: 'buyable',
    headerName: 'Buyable Quant',
    description:
      'Quantity of this part that can be purchased using Cofactr now',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row?.buyable,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'quotable',
    headerName: 'Quotable Quant',
    description:
      'Quantity of this part that is reported as available from reliable distributors currently and can be quoted',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row?.quotable,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'maybe',
    headerName: 'Maybe Quant',
    description:
      'Quantity of this part that is reported by less accurate distributors and may or may not actually be available',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row?.maybe,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice10',
    headerName: '10pc Ref Price',
    description:
      'Approximate unit for this part at quantity 10 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) => params.row?.refPrice10,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice100',
    headerName: '100pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 100 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.refPrice100,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.part?.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice1000',
    headerName: '1,000pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 1,000 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) => params.row?.refPrice1000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice10000',
    headerName: '10,000pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 10,000 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) => params.row?.refPrice10000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice10',
    headerName: '10pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 10 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row?.buyableRefPrice10,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice100',
    headerName: '100pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 100 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row?.buyableRefPrice100,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice1000',
    headerName: '1,000pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 1,000 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row?.buyableRefPrice1000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice10000',
    headerName: '10,000pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 10,000 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row?.buyableRefPrice10000,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        partIds: [params.row.id],
        schemas: [PartSchemas.supply],
        params,
      }),
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
];

// THESE COLUMN DEFS PROVIDE PART INVENTORY COLUMNS

// BELOW THIS POINT SHOULDN'T BE USED FOR ANYTHING NEW

export const partInfoColumns: ExtendedGridColDef[] = [
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description of this part.',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      return params.row.part?.description;
    },
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'reachStatus',
    headerName: 'REACH',
    description: 'REACH compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(reachStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string')
        return 'Unknown';
      return (
        _.find(params.row.part?.specifications, ['id', 'reach_status'])
          ?.value ||
        _.find(params.row.part?.specifications, ['id', 'reachsvhc'])?.value ||
        'Reach unknown'
      );
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(reachStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(reachStatusFormats, [params.value || '', 'tooltip']),
        _.get(reachStatusFormats, [params.value || '', 'color']),
        _.find(params.row.part?.documents, ['documentType', 'Reach Statement'])
          ?.url
      ),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'REACH Status',
      description: 'REACH compliance status of these parts',
      accordionCardGroupTitle: 'Compliance',
      alwaysVisible: false,
      schemas: [PartSchemas.specs],
      valueOptions: _.map(Object.values(reachStatus), (status) => ({
        value: status,
        label: reachStatusFormats[status].label,
        tooltip: reachStatusFormats[status].tooltip,
        color: reachStatusFormats[status].color,
      })),
    },
  },
  {
    field: 'rohsStatus',
    headerName: 'RoHS',
    description: 'RoHS compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(rohsStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string')
        return 'Unknown';
      return (
        _.find(params.row.part?.specifications, ['id', 'rohs'])?.value ||
        'Unknown'
      );
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(rohsStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(rohsStatusFormats, [params.value || '', 'tooltip']),
        _.get(rohsStatusFormats, [params.value || '', 'color']),
        _.find(params.row.part?.documents, ['documentType', 'Rohs Statement'])
          ?.url
      ),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'RoHS Status',
      description: 'RoHS compliance status of these parts',
      accordionCardGroupTitle: 'Compliance',
      alwaysVisible: false,
      schemas: [PartSchemas.specs],
      valueOptions: _.map(Object.values(rohsStatus), (status) => ({
        value: status,
        label: rohsStatusFormats[status].label,
        tooltip: rohsStatusFormats[status].tooltip,
        color: rohsStatusFormats[status].color,
      })),
    },
  },
  {
    field: 'medianFactoryLeadDays',
    headerName: 'Factory Lead',
    description: 'Median distributor-reported factory lead time of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(factoryLeadDayStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      const medianFactoryLeadDays =
        _.min([
          params.row.part?.medianFactoryLeadDays,
          ..._.map(params.row?.alts, 'medianFactoryLeadDays'),
        ]) || 0;
      return medianFactoryLeadDays < 90
        ? factoryLeadDayStatus.THREE_MONTHS
        : medianFactoryLeadDays < 180
        ? factoryLeadDayStatus.SIX_MONTHS
        : medianFactoryLeadDays < 365
        ? factoryLeadDayStatus.ONE_YEAR
        : factoryLeadDayStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'label'], '-'),
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'tooltip']),
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'color'])
      ),
    sortComparator: medianFactoryLeadDaysComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'currentMultisourcing',
    headerName: 'Multisourcing (Now)',
    description:
      'Number of authorized distributors with current stock of this part',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      const numAuthedDistributorsWithOffersCurrentStock =
        _.sum([
          params.row.part?.numAuthedDistributorsWithOffersCurrentStock,
          ..._.map(
            params.row?.alts,
            'numAuthedDistributorsWithOffersCurrentStock'
          ),
        ]) || 0;
      return numAuthedDistributorsWithOffersCurrentStock < 2
        ? multiSourcingStatus.TWO
        : numAuthedDistributorsWithOffersCurrentStock < 5
        ? multiSourcingStatus.FIVE
        : numAuthedDistributorsWithOffersCurrentStock < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(
          multiSourcingStatusCurrentFormats,
          [params.value || '', 'label'],
          '-'
        ),
        _.get(multiSourcingStatusCurrentFormats, [
          params.value || '',
          'tooltip',
        ]),
        _.get(multiSourcingStatusCurrentFormats, [params.value || '', 'color'])
      ),
    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'multiSourcing',
    headerName: 'Multisourcing',
    description:
      'Number of authorized distributors that report stocking this part under ideal supply chain conditions',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      const numAuthedDistributorsWithOffers =
        _.sum([
          params.row.part?.numAuthedDistributorsWithOffers,
          ..._.map(params.row?.alts, 'numAuthedDistributorsWithOffers'),
        ]) || 0;
      return numAuthedDistributorsWithOffers < 2
        ? multiSourcingStatus.TWO
        : numAuthedDistributorsWithOffers < 5
        ? multiSourcingStatus.FIVE
        : numAuthedDistributorsWithOffers < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(multiSourcingStatusFormats, [params.value || '', 'label'], '-'),
        _.get(multiSourcingStatusFormats, [params.value || '', 'tooltip']),
        _.get(multiSourcingStatusFormats, [params.value || '', 'color'])
      ),
    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'buyableStockStatus',
    headerName: 'Supply',
    description:
      'Overall buyable availability of this part in distributor inventory',
    type: 'singleSelect',
    valueOptions: Object.values(stockStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      return (params.row?.buyable || 0) < 1000
        ? stockStatus.SHORTAGE
        : (params.row.buyable || 0) < 10000
        ? stockStatus.LIMITED
        : (params.row.buyable || 0) < 50000
        ? stockStatus.OKAY
        : stockStatus.STRONG;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(stockStatusFormats, [params.value || '', 'label'], '-'),
        _.get(stockStatusFormats, [params.value || '', 'tooltip']),
        _.get(stockStatusFormats, [params.value || '', 'color'])
      ),
    sortComparator: buyableStockStatusComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'Supply Status',
      description: 'General distributor availability of these parts',
      accordionCardGroupTitle: 'Health',
      alwaysVisible: false,
      schemas: [PartSchemas.supply],
      valueOptions: _.map(Object.values(stockStatus), (status) => ({
        value: status,
        label: stockStatusFormats[status].label,
        tooltip: stockStatusFormats[status].tooltip,
        color: stockStatusFormats[status].color,
      })),
    },
  },
];

Object.values(reachStatus);
_.map(Object.values(reachStatus), (status) => ({
  value: status,
  label: reachStatusFormats[status].label,
  tooltip: reachStatusFormats[status].tooltip,
  color: reachStatusFormats[status].color,
}));
Object.values(rohsStatus);
_.map(Object.values(rohsStatus), (status) => ({
  value: status,
  label: rohsStatusFormats[status].label,
  tooltip: rohsStatusFormats[status].tooltip,
  color: rohsStatusFormats[status].color,
}));
Object.values(factoryLeadDayStatus);
Object.values(multiSourcingStatus);
Object.values(multiSourcingStatus);
Object.values(stockStatus);
_.map(Object.values(stockStatus), (status) => ({
  value: status,
  label: stockStatusFormats[status].label,
  tooltip: stockStatusFormats[status].tooltip,
  color: stockStatusFormats[status].color,
}));

export const partAltInfoColumns: ExtendedGridColDef[] = [
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description of this part.',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row.part || typeof params.row.part === 'string') return '-';
      return params.row.part?.description;
    },
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'lifecycleStatus',
    headerName: 'Lifecycle',
    description: 'Lifecycle status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(lifecycleStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return params.row.specs?.specifications?.lifecycleStatus;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(lifeCycleStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(lifeCycleStatusFormats, [params.value || '', 'tooltip']),
        _.get(lifeCycleStatusFormats, [params.value || '', 'color'])
      ),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
    showChart: {
      title: 'Lifecycle',
      description: 'Lifecycle status of these parts',
      accordionCardGroupTitle: 'Health',
      alwaysVisible: false,
      schemas: [PartSchemas.core],
      valueOptions: _.map(Object.values(lifecycleStatus), (status) => ({
        value: status,
        label: lifeCycleStatusFormats[status].label,
        tooltip: lifeCycleStatusFormats[status].tooltip,
        color: lifeCycleStatusFormats[status].color,
      })),
    },
  },
  {
    field: 'reachStatus',
    headerName: 'REACH',
    description: 'REACH compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(reachStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return (
        _.find(params.row?.specifications, ['id', 'reach_status'])?.value ||
        _.find(params.row?.specifications, ['id', 'reachsvhc'])?.value ||
        'Reach unknown'
      );
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(reachStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(reachStatusFormats, [params.value || '', 'tooltip']),
        _.get(reachStatusFormats, [params.value || '', 'color']),
        _.find(params.row.documents, ['documentType', 'Reach Statement'])?.url
      ),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'rohsStatus',
    headerName: 'RoHS',
    description: 'RoHS compliance status of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(rohsStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return _.find(params.row?.specifications, ['id', 'rohs'])?.value;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(rohsStatusFormats, [params.value || '', 'label'], 'Unknown'),
        _.get(rohsStatusFormats, [params.value || '', 'tooltip']),
        _.get(rohsStatusFormats, [params.value || '', 'color']),
        _.find(params.row.documents, ['documentType', 'Rohs Statement'])?.url
      ),
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'medianFactoryLeadDays',
    headerName: 'Factory Lead',
    description: 'Median distributor-reported factory lead time of this part.',
    type: 'singleSelect',
    valueOptions: Object.values(factoryLeadDayStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return (params.row?.medianFactoryLeadDays || 0) < 90
        ? factoryLeadDayStatus.THREE_MONTHS
        : (params.row?.medianFactoryLeadDays || 0) < 180
        ? factoryLeadDayStatus.SIX_MONTHS
        : (params.row?.medianFactoryLeadDays || 0) < 365
        ? factoryLeadDayStatus.ONE_YEAR
        : factoryLeadDayStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'label'], '-'),
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'tooltip']),
        _.get(factoryLeadDayStatusFormats, [params.value || '', 'color'])
      ),
    sortComparator: medianFactoryLeadDaysComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'currentMultisourcing',
    headerName: 'Multisourcing (Now)',
    description:
      'Number of authorized distributors with current stock of this part',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return (params.row?.numAuthedDistributorsWithOffersCurrentStock || 0) < 2
        ? multiSourcingStatus.TWO
        : (params.row?.numAuthedDistributorsWithOffersCurrentStock || 0) < 5
        ? multiSourcingStatus.FIVE
        : (params.row?.numAuthedDistributorsWithOffersCurrentStock || 0) < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(
          multiSourcingStatusCurrentFormats,
          [params.value || '', 'label'],
          '-'
        ),
        _.get(multiSourcingStatusCurrentFormats, [
          params.value || '',
          'tooltip',
        ]),
        _.get(multiSourcingStatusCurrentFormats, [params.value || '', 'color'])
      ),
    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'multiSourcing',
    headerName: 'Multisourcing',
    description:
      'Number of authorized distributors that report stocking this part under ideal supply chain conditions',
    type: 'singleSelect',
    valueOptions: Object.values(multiSourcingStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return (params.row?.numAuthedDistributorsWithOffers || 0) < 2
        ? multiSourcingStatus.TWO
        : (params.row?.numAuthedDistributorsWithOffers || 0) < 5
        ? multiSourcingStatus.FIVE
        : (params.row?.numAuthedDistributorsWithOffers || 0) < 10
        ? multiSourcingStatus.TEN
        : multiSourcingStatus.MORE;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(multiSourcingStatusFormats, [params.value || '', 'label'], '-'),
        _.get(multiSourcingStatusFormats, [params.value || '', 'tooltip']),
        _.get(multiSourcingStatusFormats, [params.value || '', 'color'])
      ),
    sortComparator: multiSourcingComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
  {
    field: 'buyableStockStatus',
    headerName: 'Supply',
    description:
      'Overall buyable availability of this part in distributor inventory',
    type: 'singleSelect',
    valueOptions: Object.values(stockStatus),
    minWidth: 80,
    flex: 0.5,
    valueGetter: (params: GridValueGetterParams) => {
      if (!params.row || typeof params.row.part === 'string') return '-';
      return (params.row.totalAvailability?.buyableStock || 0) < 1000
        ? stockStatus.SHORTAGE
        : (params.row.totalAvailability?.buyableStock || 0) < 10000
        ? stockStatus.LIMITED
        : (params.row.totalAvailability?.buyableStock || 0) < 50000
        ? stockStatus.OKAY
        : stockStatus.STRONG;
    },
    renderCell: (params: GridRenderCellParams<string>) =>
      RenderStyledText(
        _.get(stockStatusFormats, [params.value || '', 'label'], '-'),
        _.get(stockStatusFormats, [params.value || '', 'tooltip']),
        _.get(stockStatusFormats, [params.value || '', 'color'])
      ),
    sortComparator: buyableStockStatusComparator,
    permissionScope: { app: ALL_APPS.COMPLIANCE.id },
  },
];

Object.values(lifecycleStatus);
Object.values(reachStatus);
Object.values(rohsStatus);
Object.values(factoryLeadDayStatus);
Object.values(multiSourcingStatus);
Object.values(multiSourcingStatus);
Object.values(stockStatus);

export const partWithAltInventoryColumns: ExtendedGridColDef[] = [
  {
    field: 'expected',
    headerName: 'Expected Inventory',
    description:
      'Stock quantity of each part that has been ordered but not yet received',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'processing',
    headerName: 'Processing Inventory',
    description:
      'Stock quantity of each part that is on hand but has open handling requests associated with it',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'onHand',
    headerName: 'On-Hand Inventory',
    description: 'Stock quantity of each part on hand and ready for use',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'allocated',
    headerName: 'Allocated Inventory',
    description:
      'Stock quantity of each part that has been allocated for a future program',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'unallocated',
    headerName: 'Available Inventory',
    description: 'Total unallocated owned stock quantity of each part',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'total',
    headerName: 'Total Inventory',
    description: 'Total unallocated owned stock quantity of each part',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
];

export const partWithAltInventoryColumnsDecoratedParts: ExtendedGridColDef[] = [
  {
    field: 'expected',
    headerName: 'Expected Inventory',
    description:
      'Stock quantity of each part that has been ordered but not yet received',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        renderMethod: () => stockLocationDetailRenderCell(params, 'expected'),
        params,
        isLoading: params.row.expected === undefined,
      }),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'processing',
    headerName: 'Processing Inventory',
    description:
      'Stock quantity of each part that is on hand but has open handling requests associated with it',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        renderMethod: () => stockLocationDetailRenderCell(params, 'processing'),
        params,
        isLoading: params.row.processing === undefined,
      }),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'onHand',
    headerName: 'On-Hand Inventory',
    description: 'Stock quantity of each part on hand and ready for use',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        renderMethod: () => stockLocationDetailRenderCell(params, 'onHand'),
        params,
        isLoading: params.row.onHand === undefined,
      }),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'allocated',
    headerName: 'Allocated Inventory',
    description:
      'Stock quantity of each part that has been allocated for a future program',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        renderMethod: () => stockLocationDetailRenderCell(params, 'allocated'),
        params,
        isLoading: params.row.allocated === undefined,
      }),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
  {
    field: 'unallocated',
    headerName: 'Available Inventory',
    description: 'Total unallocated owned stock quantity of each part',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    valueFormatter: intValueFormatter,
    renderCell: (params: GridRenderCellParams<string>) =>
      renderWithLoadingWrapper({
        renderMethod: () =>
          stockLocationDetailRenderCell(params, 'unallocated'),
        params,
        isLoading: params.row.unallocated === undefined,
      }),
    permissionScope: { app: ALL_APPS.STOCK.id },
  },
];

export const nestedPartSupplyColumns: ExtendedGridColDef[] = [
  {
    field: 'buyable',
    headerName: 'Buyable Quant',
    description:
      'Quantity of this part that can be purchased using Cofactr now',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.totalAvailability?.buyableStock,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'quotable',
    headerName: 'Quotable Quant',
    description:
      'Quantity of this part that is reported as available from reliable distributors currently and can be quoted',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.totalAvailability?.quotableStock,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'maybe',
    headerName: 'Maybe Quant',
    description:
      'Quantity of this part that is reported by less accurate distributors and may or may not actually be available',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.totalAvailability?.possibleStock,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'partId',
    headerName: 'Cofactr ID',
    description: 'Cofactr ID of part',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.id,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'terminationType',
    headerName: 'Mount Type',
    description: 'Mount type of part',
    type: 'singleSelect',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueOptions: mountTypeOptions,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.terminationType,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'package',
    headerName: 'Package',
    description: 'Package of part',
    type: 'string',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.package,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice10',
    headerName: '10pc Ref Price',
    description:
      'Approximate unit for this part at quantity 10 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) => params.row.part?.refPrice10,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice100',
    headerName: '100pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 100 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.refPrice100,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice1000',
    headerName: '1,000pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 1,000 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.refPrice1000,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'refPrice10000',
    headerName: '10,000pc Ref Price',
    description:
      'Approximate unit price for this part at quantity 10,000 from all suppliers with or without stock',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.refPrice10000,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice10',
    headerName: '10pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 10 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice10,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice100',
    headerName: '100pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 100 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice100,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice1000',
    headerName: '1,000pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 1,000 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice1000,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
  {
    field: 'buyableRefPrice10000',
    headerName: '10,000pc Buyable Ref Price',
    description:
      'Approximate unit price for this part at quantity 10,000 from all buyable suppliers',
    type: 'number',
    minWidth: 80,
    flex: 0.5,
    hideable: true,
    valueFormatter: currencyValueFormatter,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.part?.buyableRefPrice10000,
    permissionScope: { app: ALL_APPS.PART_SEARCH.id },
  },
];
