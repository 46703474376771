import { Icon } from '@tabler/icons-react';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';

export function RenderIconButton(
  ButtonIcon: Icon,
  link: string | undefined | null,
  onClick?: () => void,
  tooltip?: string,
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
) {
  return (
    <SegmentIconButton
      tooltip={tooltip}
      disabled={!link && !onClick}
      color={color}
      onClick={() => {
        if (link) {
          window.open(link, '_blank', 'noopener,noreferrer');
        }
        if (onClick) {
          onClick();
        }
      }}
      size="small"
    >
      <ButtonIcon width={20} height={20} />
    </SegmentIconButton>
  );
}
