import { Stack, Tooltip, Typography } from '@mui/material';
import { PermissionScope } from 'types/apps';
import SegmentButton from 'ui-component/Segment/SegmentButton';

export function renderStringWithStrikethrough({
  currentValue,
  strikethroughValue,
  tooltip,
  onClick,
  clickDisabled,
  currentValueColor,
  strikethroughColor,
  permissionScope,
}: {
  currentValue: string;
  strikethroughValue: string | null;
  tooltip?: string;
  onClick?: () => void;
  clickDisabled?: boolean;
  currentValueColor?: string;
  strikethroughColor?: string;
  permissionScope?: PermissionScope;
}) {
  if (onClick && !clickDisabled) {
    return (
      <Tooltip title={tooltip || ''}>
        <SegmentButton
          onClick={onClick}
          sx={{ width: '100%', height: '100%' }}
          permissionScope={permissionScope}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ width: '100%', height: '100%' }}
          >
            {Boolean(strikethroughValue) && (
              <Typography
                variant="caption"
                sx={{
                  textDecoration: 'line-through',
                  color: strikethroughColor ?? 'default',
                }}
              >
                {strikethroughValue}
              </Typography>
            )}
            <Typography
              variant="body1"
              sx={{ color: currentValueColor ?? 'default' }}
            >
              {currentValue}
            </Typography>
          </Stack>
        </SegmentButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip || ''}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ width: '100%', height: '100%' }}
      >
        {Boolean(strikethroughValue) && (
          <Typography
            variant="caption"
            sx={{
              textDecoration: 'line-through',
              color: strikethroughColor ?? 'default',
            }}
          >
            {strikethroughValue}
          </Typography>
        )}
        <Typography
          variant="body1"
          sx={{ color: currentValueColor ?? 'default' }}
        >
          {currentValue}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
