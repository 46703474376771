import { DatagridNames } from 'types/datagrid';
import { BomCRUDSchema, BomLineRow } from 'types/bom';
import { OrgPart, Part, PartCore, PartInventoryQuerySchema } from 'types/part';
import {
  Availabilities,
  AvailabilitiesOptions,
  BomAvailabilitiesRequest,
} from 'types/purchasing';
import { PartTableRowDescribeParts, ProductionRun } from 'types/production';

import { bomStatProps } from 'views/boms/utils/calcBomStats';
import { OrderSnapshot } from 'types/order';
import {
  AllocationQuerySchema,
  ConsumeStockEvent,
  Shipment,
  ShipmentLine,
  StockLocation,
  StockLotQuerySchema,
  Supplier,
} from 'types/inventory';
import {
  CofactrPurchaseCharge,
  DecoratedPurchaseOrderLine,
  DecoratedPurchaseRequest,
  NoPartPurchaseLine,
  PurchaseOrder,
  PurchaseOrderCore,
  PurchaseOrderLineWithPurchase,
} from 'types/procurement';
import { Org, OrgUserFull } from 'types/org';
import { CollatedShipmentLine } from 'views/inventory/InventoryShipment/InventoryShipmentLineGrid';
import { OrgSupplier } from 'types/suppliers';

export const ReportDataTypes = [
  DatagridNames.bomLinesLinesTableV2,
  DatagridNames.bomPurchasingSelects,
  DatagridNames.productionRunParts,
  DatagridNames.snapshotApprovalGenerateReport,
  DatagridNames.inventoryLocations,
  DatagridNames.inventoryParts,
  DatagridNames.inventoryShipments,
  DatagridNames.inventoryStockLots,
  DatagridNames.inventoryShipmentLines,
  DatagridNames.inventoryAllocations,
  DatagridNames.usersTable,
  DatagridNames.partLibraryTable,
  DatagridNames.productionRunConsumedStock,
  DatagridNames.subOrgs,
  DatagridNames.suppliers,
  DatagridNames.purchaseRequests,
  DatagridNames.purchaseOrders,
  DatagridNames.purchaseOrderLines,
  DatagridNames.purchaseOrderNoPartLines,
  DatagridNames.purchaseOrderCharges,
  DatagridNames.purchaseOrderPartHistory,
  DatagridNames.inventoryShipmentStockLotTab,
  DatagridNames.purchaseOrderProgramHistory,
  DatagridNames.queryResponse,
];

export interface BomLinesLinesTableV2Data {
  bom: BomCRUDSchema | undefined;
  rowData: BomLineRow[];
  overageWarningParts: Part[];
  duplicateParts: Part[];
  noMatchRows: BomLineRow[];
  bomStats: bomStatProps;
}

export interface BomPurchasingSelectsData {
  bom: BomCRUDSchema | undefined;
  rowData: BomLineRow[];
  bomStats: bomStatProps;
  availabilities: Availabilities | undefined;
  selectedOption: AvailabilitiesOptions | undefined | null;
  requestPayload: BomAvailabilitiesRequest;
}

export interface ProductionPurchasingPartsData {
  productionRun: ProductionRun | undefined;
  rowData: PartTableRowDescribeParts[];
}

export interface ProductionPurchasingSelectsData {
  productionRun: ProductionRun | undefined;
  rowData: PartTableRowDescribeParts[];
  availabilities: Availabilities | undefined;
  selectedOption: AvailabilitiesOptions | undefined | null;
}

export interface SnapshotApprovalGenerateReportData {
  snapshot: OrderSnapshot | undefined;
  suppliers: Supplier[] | undefined;
}

export interface ProgramPurchaseOrdersGenerateReportData {
  productionRun: ProductionRun | undefined;
  rowData: PartTableRowDescribeParts[];
  purchaseOrderLines: PurchaseOrderLineWithPurchase[];
  purchaseOrders: PurchaseOrderCore[];
}

export interface ProgramConsumedStockGenerateReportData {
  productionRun: ProductionRun | undefined;
  rowData: PartTableRowDescribeParts[];
  consumeEvents: ConsumeStockEvent[];
}

export interface UsersReportData {
  users: OrgUserFull[];
}

export interface StockLocationReportData {
  stockLocations: StockLocation[];
}

// not used yet. to be implemented with generate report support for serverside datagrids
export interface StockLotsReportData {
  serverSideData: StockLotQuerySchema[];
}

// not used yet. to be implemented with generate report support for serverside datagrids
export interface PartStockReportData {
  serverSideData: PartInventoryQuerySchema[];
}

// not used yet. to be implemented with generate report support for serverside datagrids
export interface PartLibraryReportData {
  serverSideData: OrgPart[];
}

// not used yet. to be implemented with generate report support for serverside datagrids
export interface AllocationsReportData {
  serverSideData: AllocationQuerySchema[];
}

// not used yet. to be implemented with generate report support for serverside datagrids
export interface SuppliersReportData {
  serverSideData: OrgSupplier[];
}

export interface ShipmentsReportData {
  shipments: Shipment[];
}

export interface ShipmentLinesReportData {
  shipment: Shipment;
  lines: ShipmentLine[];
}

export interface ShipmentPartsReportData {
  shipment: Shipment;
  lines: ShipmentLine[];
  linesByPart: CollatedShipmentLine[];
}

export interface PurchaseRequestsReportData {
  purchaseRequests: DecoratedPurchaseRequest[];
}

export interface PurchaseOrdersReportData {
  purchaseOrders: PurchaseOrder[];
}

export interface PurchaseOrderReportData {
  purchaseOrder: PurchaseOrder;
  lines: DecoratedPurchaseOrderLine[];
  noPartLines: NoPartPurchaseLine[];
  cofactrCharges: CofactrPurchaseCharge[];
}

export interface PartPurchaseOrdersGenerateReportData {
  part: OrgPart | PartCore | Part;
  purchaseOrderLines: PurchaseOrderLineWithPurchase[];
  purchaseOrders: PurchaseOrderCore[];
}

export interface SubOrgGenerateReportData {
  orgs: Org[];
}

export interface QueryResponseReportData {
  data: {}[];
}

export type GenerateReportDataType =
  | BomLinesLinesTableV2Data
  | BomPurchasingSelectsData
  | ProductionPurchasingPartsData
  | ProductionPurchasingSelectsData
  | ProgramPurchaseOrdersGenerateReportData
  | ProgramConsumedStockGenerateReportData
  | UsersReportData
  | StockLocationReportData
  | StockLotsReportData
  | PartStockReportData
  | PartLibraryReportData
  | AllocationsReportData
  | ShipmentsReportData
  | ShipmentLinesReportData
  | ShipmentPartsReportData
  | PurchaseRequestsReportData
  | PurchaseOrdersReportData
  | PurchaseOrderReportData
  | PartPurchaseOrdersGenerateReportData
  | SubOrgGenerateReportData
  | QueryResponseReportData;
