import { Typography, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';

export const progressBarRenderCell = ({
  current,
  total,
}: {
  current: number;
  total: number;
}) => {
  const progress = (current / total) * 100;
  if (total) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              minWidth: 35,
            }}
          >
            <Typography variant="body2" color="textSecondary">
              {current} / {total}
            </Typography>
          </Box>
          <LinearProgress
            style={{ width: '100px' }}
            color={progress === 100 ? 'success' : 'primary'}
            variant="determinate"
            value={progress}
          />
        </Box>
      </>
    );
  }
  return '';
};
