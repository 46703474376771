import { URLS, CLIENT_V2_URLS } from 'store/constant';
import { apiSlice } from 'store/slices/api';
import { QueryParams } from 'types/api';
import {
  AllocationQuerySchema,
  StockLocation,
  StockLotQuerySchema,
  Supplier,
} from 'types/inventory';
import { parseQueryParams } from 'store/slices/utils';
import { OrgPart, PartInventoryQuerySchema } from 'types/part';
import { BomQuerySchema } from 'types/bom';
import { OrgSupplier } from 'types/suppliers';
import { SupplierBillSchema, SupplierBillLineSchema } from 'types/procurement';
import { KitRequestSchema } from 'types/kitting';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExportStockLots: builder.query<Blob, QueryParams<StockLotQuerySchema>>({
      query: (params) => ({
        url: URLS.STOCK_LOTS_EXPORT(
          parseQueryParams<StockLotQuerySchema>(params)
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportStockLocations: builder.query<Blob, QueryParams<StockLocation>>({
      query: (params) => ({
        url: URLS.STOCK_LOCATIONS_EXPORT(
          parseQueryParams<StockLocation>(params)
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportInventory: builder.query<
      Blob,
      QueryParams<PartInventoryQuerySchema>
    >({
      query: (params) => ({
        url: URLS.INVENTORY_EXPORT(
          parseQueryParams<PartInventoryQuerySchema>(params)
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportParts: builder.query<Blob, QueryParams<OrgPart>>({
      query: (params) => ({
        url: URLS.PARTS_EXPORT(parseQueryParams<OrgPart>(params)),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportBoms: builder.query<Blob, QueryParams<BomQuerySchema>>({
      query: (params) => ({
        url: URLS.BOMS_EXPORT(parseQueryParams<BomQuerySchema>(params)),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportSuppliers: builder.query<Blob, QueryParams<Supplier>>({
      query: (params) => ({
        url: URLS.SUPPLIERS_EXPORT(parseQueryParams<Supplier>(params)),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportOrgSuppliers: builder.query<Blob, QueryParams<OrgSupplier>>({
      query: (params) => ({
        url: URLS.ORG_SUPPLIERS_EXPORT(parseQueryParams<OrgSupplier>(params)),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportAllocations: builder.query<
      Blob,
      QueryParams<AllocationQuerySchema>
    >({
      query: (params) => ({
        url: URLS.ALLOCATIONS_EXPORT(
          parseQueryParams<AllocationQuerySchema>(params)
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportSupplierBills: builder.query<
      Blob,
      QueryParams<SupplierBillSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILLS(
          parseQueryParams<SupplierBillSchema>({ ...params, exportBlob: true })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportSupplierBillLines: builder.query<
      Blob,
      QueryParams<SupplierBillLineSchema>
    >({
      query: (params) => ({
        url: CLIENT_V2_URLS.SUPPLIER_BILL_LINES(
          parseQueryParams<SupplierBillLineSchema>({
            ...params,
            exportBlob: true,
          })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
    getExportKitRequests: builder.query<Blob, QueryParams<KitRequestSchema>>({
      query: (params) => ({
        url: CLIENT_V2_URLS.KIT_REQUESTS(
          parseQueryParams<KitRequestSchema>({ ...params, exportBlob: true })
        ),
        method: 'GET',
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useLazyGetExportStockLotsQuery,
  useLazyGetExportStockLocationsQuery,
  useLazyGetExportInventoryQuery,
  useLazyGetExportPartsQuery,
  useLazyGetExportBomsQuery,
  useLazyGetExportSuppliersQuery,
  useLazyGetExportOrgSuppliersQuery,
  useLazyGetExportAllocationsQuery,
  useLazyGetExportSupplierBillsQuery,
  useLazyGetExportSupplierBillLinesQuery,
  useLazyGetExportKitRequestsQuery,
} = extendedApiSlice;
