import { GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import Chip, { ChipColor } from 'ui-component/extended/Chip';

const getColorForGrade = (grade: string) => {
  switch (true) {
    case grade === 'A+' || grade === 'A':
      return ChipColor.success;
    case grade === 'B' || grade === 'C' || grade === 'D':
      return ChipColor.warning;
    default:
      return ChipColor.error;
  }
};

const getTextColorForGrade = (grade: string) => {
  switch (true) {
    case grade === 'A+' || grade === 'A':
      return '#00C853'; // this is the color on figma
    /* return '#009413'; */
    case grade === 'B' || grade === 'C' || grade === 'D':
      return '#FFC107'; // this is the color on figma
    /* return '#9C9100'; */
    default:
      return '#D84315';
    /* return '#A11000'; */
  }
};

const getGrade = (score: number) => {
  // https://stackoverflow.com/a/60619074/2590119
  switch (true) {
    case score >= 95:
      return 'A+';
    case score >= 90:
      return 'A';
    case score >= 80:
      return 'B';
    case score >= 70:
      return 'C';
    case score >= 60:
      return 'D';
    default:
      return 'F';
  }
};

export const textGradeDot = {
  type: 'singleSelect',
  valueOptions: ['A+', 'A', 'B', 'C', 'D', 'F'],
  minWidth: 100,
  flex: 0.2,
  valueGetter: (params: GridValueGetterParams) => {
    if (params.row.supplier.accuracy === 0) {
      return '';
    }
    return getGrade(params.row.supplier.accuracy);
  },
  renderCell: ({ value }: GridRenderCellParams) =>
    value !== '' && (
      <Chip
        chipcolor={getColorForGrade(value)}
        label={value}
        sx={{
          color: `${getTextColorForGrade(value)}`,
          fontWeight: 600,
          fontSize: 18,
        }}
      />
    ),
};
