import { createContext, JSX, useContext } from 'react';
import { KitRequestSchema } from 'types/kitting';
import useServerSideQuery from 'hooks/useServerSideQuery';
import { useGetKitRequestsQuery } from 'store/slices/clientV2/kitRequests';
import { ALL } from 'types/api';
import {
  DecoratedKitRequestLine,
  useGetKitRequestLineData,
} from 'hooks/useGetKitRequestLineData';

type KitRequestContextType = {
  kitRequest: KitRequestSchema | null;
  isLoadingKitRequest: boolean;
  decoratedKitRequestLines?: DecoratedKitRequestLine[];
  isLoadingKitRequestLines: boolean;
};

const KitRequestContext = createContext({} as KitRequestContextType);

export const useKitRequestContext = () => {
  const context = useContext(KitRequestContext);
  if (context === undefined) {
    throw new Error(
      'useKitRequestContext must be used within a KitRequestContext'
    );
  }
  return context;
};

export const KitRequestProvider = ({
  kitRequestId,
  children,
}: {
  kitRequestId: KitRequestSchema['id'] | undefined;
  children: JSX.Element;
}) => {
  const {
    data: { data: [kitRequest] } = { data: [null] },
    isLoading: isLoadingKitRequest,
  } = useServerSideQuery<KitRequestSchema, KitRequestSchema>(
    useGetKitRequestsQuery,
    {
      filters: [
        {
          field: 'id',
          operator: 'is',
          value: kitRequestId,
        },
      ],
      schema: [
        ALL,
        {
          approvedBy: [],
          team: [],
          productionRun: [],
          org: ['id'],
        },
      ],
    }
  );

  const { decoratedKitRequestLines, isLoadingKitRequestLines } =
    useGetKitRequestLineData({
      selectedKitRequests: kitRequestId ? [kitRequestId] : [],
    });

  return (
    <KitRequestContext.Provider
      value={{
        kitRequest,
        isLoadingKitRequest,
        decoratedKitRequestLines,
        isLoadingKitRequestLines,
      }}
    >
      {children}
    </KitRequestContext.Provider>
  );
};
