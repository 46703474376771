import { FC, memo, useMemo, useState } from 'react';
import {
  Tooltip,
  Badge,
  MenuItem,
  Menu,
  IconButton,
  ListItemText,
  ListItemIcon,
  Divider,
  useTheme,
  Typography,
  Stack,
} from '@mui/material';
import {
  IconHelpTriangle,
  IconCpu,
  IconPackage,
  IconExternalLink,
  IconDotsVertical,
  IconCircleCheck,
  IconHelpCircle,
  IconPencil,
  IconCopy,
} from '@tabler/icons-react';
import { Part, PartCore } from 'types/part';
import { StockLotCRUDSchema, StockLotLite } from 'types/inventory';
import Avatar from 'ui-component/extended/Avatar';
import { useNavigate } from 'react-router-dom';
import { copyClipboard } from 'utils/functions';
import { ALL_APPS } from 'constants/appConstants';
import EditPartDialog from 'ui-component/EditPartDialog';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { appPermissionAccessLevels } from 'types/apps';
import { PropertySubsetValidator } from 'types/api';
import isEqual from 'react-fast-compare';

interface PartAvatarProps {
  part: Part | PartCore | null;
  altCount?: number | null;
  unapprovedAltCount?: number | null;
  stockLot?:
    | PropertySubsetValidator<StockLotCRUDSchema | StockLotLite, 'id'>
    | null
    | StockLotCRUDSchema['id']
    | StockLotLite['id'];
  searchParams?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'badge' | 'xxl' | undefined;
}

const PartAvatar: FC<PartAvatarProps> = ({
  part,
  altCount,
  unapprovedAltCount = undefined,
  stockLot,
  searchParams,
  size = 'md',
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const [editPartDialogOpen, setEditPartDialogOpen] = useState(false);

  const { isPermissionDisabled } = useAppAccessContext();

  const handleClick = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const partLink = useMemo(() => {
    let partLinkString = '';
    if (part && searchParams) {
      partLinkString = `${ALL_APPS.PART_SEARCH.path}/${part?.id}?${searchParams}`;
    } else if (part) {
      partLinkString = `${ALL_APPS.PART_SEARCH.path}/${part?.id}`;
    }
    return partLinkString;
  }, [part?.id]);

  const stockLotLink = useMemo(() => {
    let stockLotLinkString = '';
    if (stockLot && searchParams) {
      stockLotLinkString = `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/${
        typeof stockLot === 'string' ? stockLot : stockLot?.id
      }?${searchParams}`;
    } else if (stockLot) {
      stockLotLinkString = `${ALL_APPS.STOCK.views?.STOCK_LOTS?.path}/${
        typeof stockLot === 'string' ? stockLot : stockLot?.id
      }`;
    }
    return stockLotLinkString;
  }, [stockLot]);

  if (!part?.id) {
    return (
      <Avatar size={size} variant="rounded">
        <IconHelpTriangle color={theme.palette.error.main} />
      </Avatar>
    );
  }

  return (
    <>
      {editPartDialogOpen && (
        <EditPartDialog
          dialogOpen={editPartDialogOpen}
          onClose={() => setEditPartDialogOpen(false)}
          initialPart={part}
        />
      )}
      <Badge
        badgeContent={
          altCount ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <IconCircleCheck width="14px" height="14px" />
              <Typography variant="inherit">
                {altCount.toLocaleString()}
              </Typography>
            </Stack>
          ) : (
            0
          )
        }
        color="success"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '.MuiBadge-anchorOriginBottomLeft': {
            transform: 'none',
            bottom: '3px',
            left: '3px',
            visibility: altCount ? 'visible' : 'hidden',
          },
        }}
      >
        <Badge
          badgeContent={
            unapprovedAltCount ? (
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0.5}
              >
                <IconHelpCircle width="14px" height="14px" />
                <Typography variant="inherit">
                  {unapprovedAltCount.toLocaleString()}
                </Typography>
              </Stack>
            ) : (
              0
            )
          }
          color="secondary"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '.MuiBadge-anchorOriginBottomLeft': {
              transform: 'none',
              bottom: altCount ? '26px' : '3px',
              left: '3px',
              visibility: unapprovedAltCount ? 'visible' : 'hidden',
            },
          }}
        >
          {part?.heroImage ? (
            <Avatar src={part?.heroImage} size={size} variant="rounded">
              <IconCpu />
            </Avatar>
          ) : (
            <Avatar size={size} variant="rounded">
              <IconCpu />
            </Avatar>
          )}
          <Tooltip
            title="More options"
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',
            }}
          >
            {/* TODO: figure out how to correctly swap to SegmentIconButton -- onClicks weren't properly working when swapping over */}
            <IconButton
              onMouseDown={(event) => event.stopPropagation()}
              onTouchStart={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleClick(event);
              }}
              size="small"
              color="secondary"
              disabled={!part}
            >
              <IconDotsVertical />
            </IconButton>
          </Tooltip>
        </Badge>
      </Badge>
      {Boolean(anchorEl) && (
        <Menu
          id="menu-earning-card"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              navigate(partLink);
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconCpu />
            </ListItemIcon>
            <ListItemText>Go To Part</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              window.open(`${window.location.origin}${partLink}`, '_blank');
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconExternalLink />
            </ListItemIcon>
            <ListItemText>Open Part In New Tab</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              copyClipboard(`${window.location.origin}${partLink}`);
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconCopy />
            </ListItemIcon>
            <ListItemText>Copy Link to Part</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              copyClipboard(part.id);
              handleClose();
            }}
          >
            <ListItemIcon>
              <IconCopy />
            </ListItemIcon>
            <ListItemText>Copy Cofactr ID</ListItemText>
          </MenuItem>
          <MenuItem
            key="editPart"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setEditPartDialogOpen(true);
              handleClose();
            }}
            disabled={isPermissionDisabled({
              app: ALL_APPS.PART_LIBRARY.id,
              accessLevel: appPermissionAccessLevels.edit,
            })}
          >
            <ListItemIcon>
              <IconPencil />
            </ListItemIcon>
            <ListItemText>Edit Part</ListItemText>
          </MenuItem>
          {stockLotLink && [
            <Divider key="divider" />,
            <MenuItem
              key="goToStockLot"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                navigate(stockLotLink);
                handleClose();
              }}
            >
              <ListItemIcon>
                <IconPackage />
              </ListItemIcon>
              <ListItemText>Go To Stock Lot</ListItemText>
            </MenuItem>,
            <MenuItem
              key="openStockLot"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                window.open(
                  `${window.location.origin}${stockLotLink}`,
                  '_blank'
                );
                handleClose();
              }}
            >
              <ListItemIcon>
                <IconExternalLink />
              </ListItemIcon>
              <ListItemText>Open Stock Lot In New Tab</ListItemText>
            </MenuItem>,
            <MenuItem
              key="copyLinkToStockLot"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                copyClipboard(`${window.location.origin}${stockLotLink}`);
                handleClose();
              }}
            >
              <ListItemIcon>
                <IconCopy />
              </ListItemIcon>
              <ListItemText>Copy Link to Stock Lot</ListItemText>
            </MenuItem>,
          ]}
        </Menu>
      )}
    </>
  );
};

function partAvatarPropsAreEqual(
  prevProps: PartAvatarProps,
  nextProps: PartAvatarProps
) {
  if (prevProps.part?.mpn !== nextProps.part?.mpn) {
    return false;
  }
  if (prevProps.part?.customId !== nextProps.part?.customId) {
    return false;
  }
  if (prevProps.part?.id !== nextProps.part?.id) {
    return false;
  }
  if (prevProps.part?.mfg !== nextProps.part?.mfg) {
    return false;
  }
  if (prevProps.altCount !== nextProps.altCount) {
    return false;
  }
  if (isEqual(prevProps.stockLot, nextProps.stockLot)) {
    return false;
  }
  if (prevProps.searchParams !== nextProps.searchParams) {
    return false;
  }
  if (prevProps.unapprovedAltCount !== nextProps.unapprovedAltCount) {
    return false;
  }
  if (prevProps.size !== nextProps.size) {
    return false;
  }
  return true;
}

export default memo(PartAvatar, partAvatarPropsAreEqual);
