import { useState } from 'react';
import { PermissionScope } from 'types/apps';
import SidebarActionButton from 'ui-component/DetailLayout/SidebarComponents/SidebarActionButton';
import { IconThumbUpOff, IconThumbUp } from '@tabler/icons-react';

import { GenericResource, ToggleMutation } from 'types/api';
import ToggleRecordDialog, {
  ToggleRecordActionTypes,
} from 'ui-component/clientV2/ToggleRecordDialog';

interface SidebarApproveButtonProps<T extends GenericResource> {
  disabled?: boolean;
  permissionScope?: PermissionScope;
  approved: boolean;
  idToUpdate: string;
  recordName: string;
  message?: string;
  useClientApproveMutation: ToggleMutation<T>;
  useClientUnapproveMutation: ToggleMutation<T>;
}

/**
 * SidebarApproveButtonClientV2
 * To be used specifically with Client V2 api
 */
const SidebarApproveButtonClientV2 = <T extends GenericResource>({
  disabled,
  permissionScope,
  approved,
  idToUpdate,
  recordName,
  message,
  useClientApproveMutation,
  useClientUnapproveMutation,
}: SidebarApproveButtonProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SidebarActionButton
        Icon={approved ? IconThumbUpOff : IconThumbUp}
        label={approved ? 'Unapprove' : 'Approve'}
        tooltip={approved ? 'Unapprove Record' : 'Approve Record'}
        color={approved ? 'error' : 'success'}
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}
        permissionScope={permissionScope}
      />
      <ToggleRecordDialog<T>
        id={idToUpdate}
        isCurrentlyTrue={approved}
        dialogOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        recordName={recordName}
        useToggleTrueMutation={useClientApproveMutation}
        useToggleFalseMutation={useClientUnapproveMutation}
        toggleActionType={ToggleRecordActionTypes.APPROVE}
        message={message}
      />
    </>
  );
};

export default SidebarApproveButtonClientV2;
