import { getTrackingStrings } from 'constants/trackingStrings';

const trackingStrings = getTrackingStrings();

export const getCopy = () => ({
  trackingName: {
    cancel: trackingStrings.cancelCreateEditProductionRun,
    save: trackingStrings.saveCreateEditProductionRun,
  },
});
