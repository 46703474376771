import { FC } from 'react';
import { Typography } from '@mui/material';

import {
  ExtendedDialog,
  ExtendedDialogProps,
} from 'ui-component/extended/ExtendedDialog';
import { PermissionScope } from 'types/apps';

interface ConfirmDialogProps {
  title: string;
  dialogText: string;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
  dialogOpen: boolean;
  confirmButtonText?: string;
  confirmButtonColor?: ExtendedDialogProps['submitButtonColor'];
  error?: string | null;
  permissionScope?: PermissionScope;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  dialogText,
  onClose,
  onConfirm,
  loading = false,
  dialogOpen,
  confirmButtonText = 'Confirm',
  confirmButtonColor = 'primary',
  error,
  permissionScope,
}) => (
  <ExtendedDialog
    onCloseDialog={onClose}
    open={dialogOpen}
    fullWidth
    maxWidth="sm"
    title={title}
    isForm
    formSubmitError={error}
    onSubmit={onConfirm}
    isSubmitting={loading}
    submitButtonCopy={confirmButtonText}
    submitButtonColor={confirmButtonColor}
    permissionScope={permissionScope}
  >
    <Typography>{dialogText}</Typography>
  </ExtendedDialog>
);

export default ConfirmDialog;
