import { Tooltip } from '@mui/material';
import {
  IconAlertOctagon,
  IconAlertTriangle,
  IconCircleCheck,
} from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { MarketRating } from 'types/part';

export function RenderMarketRatingIcon(rating: MarketRating) {
  const theme = useTheme();
  switch (rating) {
    case MarketRating.GREEN:
      return (
        <Tooltip
          placement="top"
          title="This part is currently available from authorized distributors."
        >
          <div>
            <IconCircleCheck color={theme.palette.success.main} />
          </div>
        </Tooltip>
      );
    case MarketRating.AMBER:
      return (
        <Tooltip
          placement="top"
          title="This part is currently available from authorized distributors, but availability is limited."
        >
          <div>
            <IconAlertTriangle color={theme.palette.warning.main} />
          </div>
        </Tooltip>
      );
    case MarketRating.RED:
      return (
        <Tooltip
          placement="top"
          title="This part is currently not available from authorized distributors."
        >
          <div>
            <IconAlertOctagon color={theme.palette.error.main} />
          </div>
        </Tooltip>
      );
    default:
      return null;
  }
}
