import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import EditCellWithInput from 'ui-component/DataGrid/Cell/EditCellWithInput';
import * as Yup from 'yup';
import { MutableRefObject, ReactElement, ReactNode } from 'react';
import { PermissionScope } from 'types/apps';
import { FieldValues } from 'react-hook-form';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

// Used for `renderEditCell` option on editable columns
// This handles rendering edit-mode of an editable cell
// Including handling submit upon stop editing, and ending edit mode via handleStopEdit arg

export type RenderWithEditInputProps = {
  params: GridRenderCellParams;
  InputElement?: ReactElement;
  renderMethod?: () => ReactNode;
  validation?: Yup.SchemaOf<unknown, object>;
  onSubmit: (data: FieldValues) => Promise<void> | void;
  isLoading?: boolean;
  permissionScope?: PermissionScope;
  hideLabel?: boolean;
  apiRef: MutableRefObject<GridApiPremium>;
  defaultValue?: unknown;
  additionalInputElementProps?: { [key: string]: unknown };
  tooltip?: string;
};

export function renderEditCellWithInput({
  params,
  InputElement,
  validation,
  onSubmit,
  isLoading,
  permissionScope,
  hideLabel = false,
  apiRef,
  defaultValue,
  additionalInputElementProps,
  tooltip,
}: RenderWithEditInputProps) {
  return (
    <EditCellWithInput
      params={params}
      InputElement={InputElement}
      validation={validation}
      onSubmit={onSubmit}
      isLoading={isLoading}
      permissionScope={permissionScope}
      hideLabel={hideLabel}
      apiRef={apiRef}
      defaultValue={defaultValue}
      additionalInputElementProps={additionalInputElementProps}
      tooltip={tooltip}
    />
  );
}
