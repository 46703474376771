// This function is a modified version of the function found in the following link:
// https://gist.github.com/gingeleski/3a88a5286bcf98ba33b28b95f430e4ff
export const sanitizeCsv = (csvData: string) =>
  csvData
    .replace(/(^|[,\n\r"])([=@+(]+|-(?=\D|\b))(?=[\D.])/g, "$1'$2")
    .replace(/(^|[,\n\r])'-(?=,|$)/g, '$1-');

export const parseCsv = (csvData: string) =>
  csvData.split('\n').map((row) => {
    const regex = /,(?=(?:[^"]*"[^"]*")*[^"]*$)/;
    const parsedRow = row.split(regex);
    return parsedRow.map((cell, index) => cell);
  });

export const mergeCsv = (csvData: string[][]) =>
  csvData.map((row) => row.join(',')).join('\n');

export const sanitizeExcelCell = (cellValue: string) => {
  if (
    typeof cellValue === 'string' &&
    cellValue !== '-' &&
    /^[-+=@(]/.test(cellValue)
  ) {
    return `'${cellValue.replace(/^["]?/, '')}`;
  }
  return cellValue;
};
