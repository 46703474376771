import _ from 'lodash';
import { useGetPartRuleSetsQuery } from 'store/slices/inventory';
import { PartRuleSet } from 'types/partRules';

export default function usePartRuleSets({
  partRuleSetId,
}: {
  partRuleSetId?: PartRuleSet['id'];
}) {
  const { data: partRuleSets = [], isFetching } = useGetPartRuleSetsQuery();

  const partRuleSet = partRuleSetId
    ? (_.find(partRuleSets, { id: partRuleSetId }) as PartRuleSet | undefined)
    : (_.find(partRuleSets, { defaultRuleSet: true }) as
        | PartRuleSet
        | undefined);

  return {
    isFetching,
    partRuleSet,
    partRuleSets,
  };
}
