import { useTheme } from '@mui/material/styles';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {
  useGetDocumentDownloadQuery,
  useGetStockDocumentsQuery,
  useLazyGetDocumentDownloadQuery,
} from 'store/slices/inventory';
import fileDownload from 'js-file-download';
import {
  IconFileDownload,
  IconMailOpened,
  IconPaperclip,
  IconPrinter,
  IconRefresh,
} from '@tabler/icons-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { handleErr } from 'utils/functions';
import { PurchaseLite } from 'types/purchasing';
import {
  DocumentTemplate,
  ExtractorContextTypes,
  RelatedRecordType,
  Shipment,
  StockDocType,
  StockDocTypeLabels,
  StockDocument,
  StockLotCRUDSchema,
} from 'types/inventory';
import { actionButtonSpacing, gridSpacing } from 'constants/themeConstants';
import SegmentIconButton from 'ui-component/Segment/SegmentIconButton';
import ClickableMainCard from 'ui-component/cards/ClickableMainCard';
import { useSearchParamsState } from 'hooks/useSearchParamsState';
import { convertDate, formatToDateTime, formatToShortDate } from 'utils/dates';
import RelatedRecords from 'ui-component/shared/RelatedRecords';
import _ from 'lodash';
import MainCard from 'ui-component/cards/MainCard';
import ExtendedTabs from 'ui-component/extended/ExtendedTabs';
import useSnackbar from 'hooks/useSnackbar';
import { ProductionRun } from 'types/production';
import { BomLite } from 'types/bom';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { PermissionScope } from 'types/apps';
import { useFeature } from '@growthbook/growthbook-react';
import { FeatureFlags } from 'types';
import usePrinting from 'hooks/usePrinting';
import UploadGenerateDocuments from 'ui-component/UploadGenerateDocuments';
import ReactHtmlParser from 'react-html-parser';
import { useGetDocumentTempURL } from 'hooks/useGetDocumentTempURL';
import { useState } from 'react';
import { KitRequestSchema } from 'types/kitting';

const StockLotDocumentationTabV2 = ({
  stockLotId,
  purchaseId,
  bomId,
  productionRunId,
  shipmentId,
  // TODO: Figure out correct way to handle Stock Lot Documentation for Kit Requests
  kitRequestId,
  filterDocumentTypes,
  excludeDocumentTypes,
  generateDocumentType,
  defaultDocumentTemplateId,
  useGenerateMutation,
  uploadPermissionScope,
  additionalGenerateProps,
  defaultDocumentId,
  allowUpload,
  allowPrinting,
  dontUseSearchParamsState = false,
}: {
  stockLotId?: StockLotCRUDSchema['id'];
  purchaseId?: PurchaseLite['id'];
  bomId?: BomLite['id'];
  productionRunId?: ProductionRun['id'];
  shipmentId?: Shipment['id'];
  kitRequestId?: KitRequestSchema['id'];
  filterDocumentTypes?: StockDocType[];
  excludeDocumentTypes?: StockDocType[];
  generateDocumentType?: StockDocType;
  defaultDocumentTemplateId?: DocumentTemplate['id'] | null;
  useGenerateMutation?: UseMutation<any>;
  uploadPermissionScope?: PermissionScope;
  additionalGenerateProps?: object;
  defaultDocumentId?: StockDocument['id'];
  allowUpload?: boolean;
  allowPrinting?: boolean;
  dontUseSearchParamsState?: boolean;
  // we need to update so that if the override is present we use that instead of the search params value and setter
  // this is to support the doc inbox page
}) => {
  const theme = useTheme();
  const { dispatchErrorSnackbar } = useSnackbar();
  const [selectedDocumentIdState, setSelectedDocumentIdState] =
    useState<string>('');
  const [selectedDocumentIdParamState, setSelectedDocumentIdParamState] =
    useSearchParamsState('selectedDocumentId', defaultDocumentId || '');

  const selectedDocumentId = dontUseSearchParamsState
    ? selectedDocumentIdState
    : selectedDocumentIdParamState;
  const setSelectedDocumentId = dontUseSearchParamsState
    ? setSelectedDocumentIdState
    : setSelectedDocumentIdParamState;

  const tempDocumentGeneration = useFeature(
    FeatureFlags.tempDocumentGeneration
  ).on;

  const { handlePrintStockDocument: printStockDocument } = usePrinting({});

  const {
    data: stockDocuments,
    isLoading: isLoadingStockDocuments,
    isFetching: isFetchingStockDocuments,
    isError: isStockDocumentsError,
    isSuccess: isStockDocumentsSuccess,
    refetch: refetchStockDocuments,
  } = useGetStockDocumentsQuery(
    stockLotId || purchaseId || bomId || productionRunId || shipmentId
      ? { stockLotId, purchaseId, bomId, productionRunId, shipmentId }
      : skipToken
  );

  const filteredStockDocuments = _.filter(
    stockDocuments || [],
    (d) =>
      (!filterDocumentTypes ||
        _.includes(filterDocumentTypes, d.documentType)) &&
      (!excludeDocumentTypes ||
        !_.includes(excludeDocumentTypes, d.documentType))
  );

  const sortedStockDocuments = _.chain(filteredStockDocuments)
    .filter((d) => !d.needsReview)
    .orderBy('createdAt', 'desc')
    .value();

  const selectedDocument = _.find(sortedStockDocuments, {
    id: selectedDocumentId,
  }) as StockDocument | undefined;

  const { data: documentBlob } = useGetDocumentDownloadQuery(
    selectedDocumentId || skipToken
  );

  const documentTempURL = useGetDocumentTempURL({
    documentBlob,
    selectedDocument,
  });

  const [fetchDocument] = useLazyGetDocumentDownloadQuery();

  const handleFetchDocument = async (id: string, fileName: string) => {
    try {
      const document = await fetchDocument(id).unwrap();
      fileDownload(
        document,
        fileName.substring(fileName.lastIndexOf('/') + 1).split('?')[0]
      );
    } catch (err) {
      handleErr(err, (errMessage: string) => {
        dispatchErrorSnackbar(errMessage);
      });
    }
  };

  return (
    <Grid container spacing={gridSpacing}>
      {/* Upload or Generate Document */}
      <Grid item xs={12}>
        <UploadGenerateDocuments
          stockLotId={stockLotId}
          purchaseId={purchaseId}
          bomId={bomId}
          productionRunId={productionRunId}
          shipmentId={shipmentId}
          generateDocumentType={generateDocumentType}
          defaultDocumentTemplateId={defaultDocumentTemplateId}
          useGenerateMutation={useGenerateMutation}
          uploadPermissionScope={uploadPermissionScope}
          additionalGenerateProps={additionalGenerateProps}
          allowUpload={allowUpload}
          setSelectedDocumentId={setSelectedDocumentId}
        />
      </Grid>
      {/* Error State */}
      {isStockDocumentsError && (
        <Grid item xs={12}>
          <Alert severity="error">
            <AlertTitle>Error Loading Documents</AlertTitle>
            Something went wrong while loading document data. Please contact
            Cofactr Support for assistance.
          </Alert>
        </Grid>
      )}
      {/* Doc Picker Sidebar */}
      {/* Doc Picker Sidebar Header */}
      <Grid item xs={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={actionButtonSpacing}
          sx={{ mx: actionButtonSpacing, mb: actionButtonSpacing }}
        >
          <Typography variant="h6">Related Documents</Typography>
          {isFetchingStockDocuments ? (
            <CircularProgress color="inherit" size={40} />
          ) : (
            <SegmentIconButton
              onClick={() => refetchStockDocuments()}
              disabled={!isStockDocumentsSuccess}
            >
              <IconRefresh />
            </SegmentIconButton>
          )}
        </Stack>
        {/* Loading State */}
        {isLoadingStockDocuments && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={actionButtonSpacing}
          >
            <Skeleton variant="rounded" height={80} />
            <Skeleton variant="rounded" height={80} />
            <Skeleton variant="rounded" height={80} />
            <Skeleton variant="rounded" height={80} />
            <Skeleton variant="rounded" height={80} />
            <Skeleton variant="rounded" height={80} />
          </Stack>
        )}
        {/* Doc List State */}
        {!isLoadingStockDocuments && isStockDocumentsSuccess && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={actionButtonSpacing}
          >
            {sortedStockDocuments.map((doc) => (
              <ClickableMainCard
                key={doc.id}
                boxShadow
                highlight={selectedDocumentId === doc.id}
                contentSX={{ p: actionButtonSpacing }}
                onClick={() => setSelectedDocumentId(doc.id)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {_.get(StockDocTypeLabels, doc.documentType, 'Document')}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h5">
                      {doc.supplier?.name ||
                        doc.metadata?.context?.senderEmail ||
                        doc.name}
                    </Typography>
                  </Grid>
                  {!!doc.createdAt && (
                    <Grid item xs="auto">
                      <Typography
                        variant="caption"
                        sx={{ textAlign: 'right', width: '100%' }}
                      >
                        {formatToShortDate(convertDate(doc.createdAt))}
                      </Typography>
                    </Grid>
                  )}
                  {doc.metadata?.context?.type ===
                    ExtractorContextTypes.EMAIL && (
                    <Grid item xs={12}>
                      <Typography variant="body1" noWrap>
                        {doc.metadata?.context?.subject}
                      </Typography>
                    </Grid>
                  )}
                  {!!doc.documentExtractor && (
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {doc.documentExtractor}
                      </Typography>
                    </Grid>
                  )}
                  {!!doc.notes && (
                    <Grid item xs={12}>
                      <Typography variant="caption">{doc.notes}</Typography>
                    </Grid>
                  )}
                  {!purchaseId && (
                    <Grid item xs={12}>
                      <RelatedRecords
                        relatedRecords={_.filter(doc.relatedRecords, {
                          recordType: RelatedRecordType.ORDER,
                        })}
                      />
                    </Grid>
                  )}
                </Grid>
              </ClickableMainCard>
            ))}
          </Stack>
        )}
      </Grid>
      {/* Selected Doc Loading */}
      {isLoadingStockDocuments && (
        <Grid item xs={9}>
          <Skeleton variant="rounded" height="100%" />
        </Grid>
      )}
      {/* No Documents */}
      {sortedStockDocuments.length === 0 &&
        !isLoadingStockDocuments &&
        isStockDocumentsSuccess && (
          <Grid item xs={9}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', width: '100%' }}
            >
              <Typography variant="body1">
                {useGenerateMutation && allowUpload && tempDocumentGeneration
                  ? 'There are no related documents for this record. Upload or Generate Documents to add one.'
                  : !(useGenerateMutation && tempDocumentGeneration) &&
                    allowUpload
                  ? 'There are no related documents for this record. Upload Documents to add one.'
                  : 'There are no related documents for this record.'}
              </Typography>
            </Stack>
          </Grid>
        )}
      {/* No Selected Doc */}
      {sortedStockDocuments.length > 0 &&
        !selectedDocument &&
        !isLoadingStockDocuments &&
        isStockDocumentsSuccess && (
          <Grid item xs={9}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100%', width: '100%' }}
            >
              <Typography variant="h4">Select a Document</Typography>
              <Typography variant="body1">
                Select a document from the side bar to view or download.
              </Typography>
            </Stack>
          </Grid>
        )}
      {/* Selected Doc */}
      {!!selectedDocument &&
        !isLoadingStockDocuments &&
        isStockDocumentsSuccess && (
          <Grid item xs={9}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={actionButtonSpacing}
            >
              {!!selectedDocument.createdAt && (
                <Typography variant="body1">
                  {selectedDocument.metadata?.context?.type ===
                  ExtractorContextTypes.EMAIL
                    ? 'Received'
                    : selectedDocument.metadata?.context?.type ===
                      ExtractorContextTypes.SCAN
                    ? 'Scanned'
                    : 'Uploaded'}{' '}
                  at {formatToDateTime(convertDate(selectedDocument.createdAt))}
                </Typography>
              )}
              {selectedDocument.metadata?.context?.type ===
                ExtractorContextTypes.EMAIL && (
                <Typography variant="h3">
                  {selectedDocument.metadata?.context?.subject}
                </Typography>
              )}
              <MainCard>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography variant="h4">{selectedDocument?.name}</Typography>
                  <SegmentIconButton
                    onClick={() =>
                      handleFetchDocument(
                        selectedDocumentId,
                        selectedDocument?.document || ''
                      )
                    }
                  >
                    <IconFileDownload color={theme.palette.primary.main} />
                  </SegmentIconButton>
                  {allowPrinting && (
                    <SegmentIconButton
                      onClick={() =>
                        printStockDocument({
                          stockDocumentId: selectedDocumentId,
                        })
                      }
                    >
                      <IconPrinter color={theme.palette.primary.main} />
                    </SegmentIconButton>
                  )}
                </Stack>
                {selectedDocument.metadata?.context?.type !==
                  ExtractorContextTypes.EMAIL &&
                  !!documentTempURL &&
                  !!selectedDocument?.mimeType && (
                    <iframe
                      title="Document"
                      src={documentTempURL}
                      width="100%"
                      height="800px"
                    />
                  )}
                {selectedDocument.metadata?.context?.type ===
                  ExtractorContextTypes.EMAIL && (
                  <ExtendedTabs
                    tabOptions={[
                      ...(selectedDocument?.document
                        ? [
                            {
                              name: 'document',
                              label: 'Document',
                              icon: <IconPaperclip />,
                              contents: !!documentTempURL && (
                                <iframe
                                  title="Document"
                                  src={documentTempURL}
                                  width="100%"
                                  height="800px"
                                />
                              ),
                            },
                          ]
                        : []),
                      {
                        name: 'email',
                        label: 'Email',
                        icon: <IconMailOpened />,
                        contents:
                          selectedDocument.metadata?.context?.bodyHtml &&
                          selectedDocument.metadata?.context?.bodyHtml !==
                            'false' ? (
                            <div>
                              {ReactHtmlParser(
                                decodeURIComponent(
                                  selectedDocument.metadata?.context?.bodyHtml
                                )
                              )}
                            </div>
                          ) : (
                            <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                              {decodeURIComponent(
                                selectedDocument.metadata?.context?.bodyText ||
                                  ''
                              )}
                            </Typography>
                          ),
                        disabled: !selectedDocument.metadata?.context?.bodyText,
                      },
                    ]}
                  />
                )}
              </MainCard>
            </Stack>
          </Grid>
        )}
    </Grid>
  );
};
export default StockLotDocumentationTabV2;
