import { useDispatch, useSelector } from 'store';
import {
  Autocomplete,
  TextField,
  Box,
  Avatar,
  Tooltip,
  Grid,
} from '@mui/material';
import { setActiveOrgId } from 'store/slices/org';
import { useNavigate } from 'react-router-dom';
import { useGetUserQuery } from 'store/slices/user';
import {
  IconArrowsShuffle2,
  IconBeach,
  IconCornerDownRight,
} from '@tabler/icons-react';
import { handleOrgSwitch, setStoredOrg } from 'store/actions';
import { skipToken } from '@reduxjs/toolkit/query/react';
import Loader from 'ui-component/Loader';
import _ from 'lodash';
import { Org } from 'types/org';
import { useTheme } from '@mui/material/styles';
import { getCustomizedParts } from 'store/slices/partData';
import { memo, useMemo, useCallback } from 'react';
import { DASHBOARD_ROUTE } from 'routes/constants';
import { roundedButtonAvatarSx } from 'constants/themeConstants';

const ActiveOrgSwitcher = ({
  skipResetState,
  ignoreBlended,
}: {
  skipResetState?: boolean;
  ignoreBlended?: boolean;
}) => {
  const theme = useTheme();
  const { activeOrgId, blended, sandbox } = useSelector((state) => state.org);
  const { isLoggedIn } = useSelector((state) => state.session);

  const { data: user, isSuccess: isGetUserSuccess } = useGetUserQuery(
    isLoggedIn ? undefined : skipToken
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customizedParts = useSelector(getCustomizedParts);

  const options = useMemo(() => {
    if (!user || user?.orgs.length < 2) return [];
    return _.chain(user?.orgs)
      .filter((o) => !o.createdFrom)
      .sortBy('name')
      .map((o) => [
        o,
        ..._.chain(user?.orgs)
          .filter(
            (co) =>
              (typeof co.createdFrom === 'string'
                ? co.createdFrom
                : co.createdFrom?.id) === o.id
          )
          .value(),
      ])
      .flatten()
      .value();
  }, [user?.orgs]);

  const handleOptionChange = useCallback(
    (__, option) => {
      if (option) {
        dispatch(setStoredOrg((option as Org).id));
        dispatch(
          setActiveOrgId({
            orgId: (option as Org).id,
            org: option as Org,
            skipResetState,
          })
        );
        dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
        if (!skipResetState) {
          navigate(DASHBOARD_ROUTE);
        }
      }
    },
    [dispatch, navigate, customizedParts, skipResetState]
  );

  const activeOrg = useMemo(
    () => _.find(user?.orgs, { id: activeOrgId }),
    [user?.orgs, activeOrgId]
  );

  if (!isLoggedIn) {
    return <></>;
  }
  if (!isGetUserSuccess) {
    return <Loader />;
  }
  if (options.length === 0) return <></>;

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => (option as Org).name}
          onChange={handleOptionChange}
          autoComplete
          disabled={blended && !ignoreBlended}
          disableClearable
          value={activeOrg}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {!!(option as Org).createdFrom && (
                <IconCornerDownRight style={{ marginRight: '6px' }} />
              )}
              {(option as Org).sandbox && (
                <IconBeach style={{ marginRight: '6px' }} />
              )}
              {(option as Org).name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              label="Active Org"
              fullWidth
            />
          )}
        />
      </Grid>
      {!ignoreBlended && (
        <Grid item xs="auto">
          <Tooltip
            title={
              blended ? 'Disable blended view mode' : 'Enable blended view mode'
            }
          >
            <Avatar
              variant="rounded"
              sx={{
                ...roundedButtonAvatarSx(theme),
                ...{
                  background: blended
                    ? theme.palette.success.main
                    : theme.palette.brand.moss,
                  color: blended
                    ? theme.palette.common.white
                    : theme.palette.brand.steel,
                  ml: 2,
                },
              }}
              onClick={() => {
                if (blended) {
                  dispatch(setActiveOrgId({ skipResetState, blended: false }));
                } else {
                  dispatch(setActiveOrgId({ skipResetState, blended: true }));
                  dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
                }
                dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
                if (!skipResetState) {
                  navigate(DASHBOARD_ROUTE);
                }
              }}
              color="inherit"
            >
              <IconArrowsShuffle2 stroke={1.5} size="1.3rem" />
            </Avatar>
          </Tooltip>
        </Grid>
      )}
      {blended && !ignoreBlended && (
        <Grid item xs="auto">
          <Tooltip
            title={sandbox ? 'Disable sandbox mode' : 'Enable sandbox mode'}
          >
            <Avatar
              variant="rounded"
              sx={{
                ...roundedButtonAvatarSx(theme),
                ...{
                  background: sandbox
                    ? theme.palette.success.main
                    : theme.palette.brand.moss,
                  color: sandbox
                    ? theme.palette.common.white
                    : theme.palette.brand.steel,
                  ml: 2,
                },
              }}
              onClick={() => {
                dispatch(handleOrgSwitch({ customPartIds: customizedParts }));
                if (sandbox) {
                  dispatch(
                    setActiveOrgId({
                      skipResetState,
                      blended: true,
                      sandbox: false,
                    })
                  );
                } else {
                  dispatch(
                    setActiveOrgId({
                      skipResetState,
                      blended: true,
                      sandbox: true,
                    })
                  );
                }
              }}
              color="inherit"
            >
              <IconBeach stroke={1.5} size="1.3rem" />
            </Avatar>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(ActiveOrgSwitcher);
