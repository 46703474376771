import * as Yup from 'yup';

export const updatePartFormConstants = {
  customId: { name: 'customId', label: 'Custom Part Number' },
  description: { name: 'description', label: 'Description' },
  package: { name: 'package', label: 'Package' },
  terminationType: { name: 'terminationType', label: 'Mount Type' },
  lifecycleStatus: { name: 'lifecycleStatus', label: 'Lifecycle Status' },
};

export const validationSchema = Yup.object().shape({
  [updatePartFormConstants.customId.name]: Yup.string(),
  [updatePartFormConstants.description.name]: Yup.string(),
  [updatePartFormConstants.package.name]: Yup.string(),
  [updatePartFormConstants.terminationType.name]: Yup.string(),
  [updatePartFormConstants.lifecycleStatus.name]: Yup.string(),
});
