import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { GridFilterInputValueProps } from '@mui/x-data-grid';

function PriceFilterInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        value={item.value === undefined ? '' : item.value}
        type="text"
        label="Value"
        size="small"
        variant="standard"
        onChange={(e) => {
          applyValue({ ...item, value: e.target.value });
        }}
      />
    </Box>
  );
}

export default PriceFilterInput;
