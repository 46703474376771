import _ from 'lodash';
import {
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  FC,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParamsState } from 'hooks/useSearchParamsState';
import SegmentTabs from 'ui-component/Segment/SegmentTabs';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { PermissionScope } from 'types/apps';
import MainCard from 'ui-component/cards/MainCard';
import { getDisabledPermissionCopy } from 'utils/permissions';
import { gridSpacing } from 'constants/themeConstants';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type ExtendedTabOption = {
  name: string;
  label: string | ReactElement;
  icon?: ReactElement;
  disabled?: boolean;
  contents?: ReactNode;
  loading?: boolean;
  permissionScope?: PermissionScope;
  gridPadding?: boolean;
};

interface ExtendedTabsProps {
  queryParam?: string | null;
  onChange?: (tabName: string) => void;
  onClick?: (tabName: string) => void;
  displayChildren?: boolean;
  defaultTab?: string;
  tabOptions: ExtendedTabOption[];
  trackingName?: string;
  bottomSpacing?: number;
  sx?: SxProps<Theme>;
  value?: string;
}

const ExtendedTabs: FC<ExtendedTabsProps> = ({
  children,
  queryParam = null,
  onChange,
  onClick,
  displayChildren = true,
  defaultTab,
  tabOptions,
  trackingName,
  bottomSpacing = gridSpacing,
  sx,
  value,
}) => {
  const {
    isLoading: isLoadingAppAccess,
    isPermissionDisabled,
    getDisabledReason,
  } = useAppAccessContext();
  const defaultTabName = defaultTab || _.get(tabOptions, [0, 'name'], '');
  const [searchParam, setSearchParam] = useSearchParamsState(
    queryParam || '',
    ''
  );
  const [activeTab, setActiveTab] = useState(
    queryParam ? searchParam : value || defaultTabName
  );
  useEffect(() => {
    setActiveTab(queryParam ? searchParam : value || defaultTabName);
  }, [queryParam, searchParam, defaultTabName, value]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    const screenTop = window.scrollY;
    setActiveTab(newValue);
    if (queryParam) {
      setSearchParam(newValue);
    }
    if (onChange) {
      onChange(newValue);
    }
    window.scrollTo({
      top: screenTop,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!searchParam && queryParam) {
      setSearchParam(defaultTabName);
      setActiveTab(defaultTabName);
    }
  }, []);

  useEffect(() => {
    if (!searchParam && queryParam) {
      setActiveTab(defaultTabName);
    }
  }, [searchParam]);

  useEffect(() => {
    if (searchParam) {
      setActiveTab(searchParam);
    }
  }, [searchParam]);

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const currentTab = _.find(tabOptions, { name: activeTab });

  const permissionCopy = useMemo(
    () =>
      currentTab?.permissionScope &&
      isPermissionDisabled(currentTab?.permissionScope) &&
      getDisabledPermissionCopy(getDisabledReason(currentTab?.permissionScope)),
    [currentTab]
  );

  return (
    <Grid container item xs={12} spacing={bottomSpacing}>
      <Grid
        item
        xs={12}
        sx={{ ...(sx || { mb: 0 }), width: '100px' }} // width: '100px' is a fix for weird nested flexbox issues that was breaking MUI Grid
      >
        <SegmentTabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Tabs"
          variant="scrollable"
          trackingName={queryParam || trackingName || ''}
        >
          {isMounted &&
            tabOptions.map((tab, index) => {
              const permissionDisabled = isPermissionDisabled(
                tab.permissionScope
              );
              const disabledCopy =
                tab?.permissionScope &&
                `${
                  getDisabledPermissionCopy(
                    getDisabledReason(tab.permissionScope)
                  ).message
                } ${
                  getDisabledPermissionCopy(
                    getDisabledReason(tab.permissionScope)
                  ).action
                }`;
              return (
                <Tab
                  key={tab.name}
                  style={{ pointerEvents: 'auto' }}
                  onClick={() => {
                    if (onClick) {
                      onClick(tab.name);
                    }
                  }}
                  icon={
                    tab.loading ||
                    (tab?.permissionScope?.app && isLoadingAppAccess) ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <Tooltip title={permissionDisabled && disabledCopy}>
                        <span>{tab.icon}</span>
                      </Tooltip>
                    )
                  }
                  iconPosition="start"
                  label={
                    <Tooltip
                      key={tab.name}
                      title={permissionDisabled && disabledCopy}
                    >
                      <span>{tab.label}</span>
                    </Tooltip>
                  }
                  {...a11yProps(index)}
                  value={tab.name}
                  disabled={Boolean(tab.disabled) || permissionDisabled}
                />
              );
            })}
        </SegmentTabs>
      </Grid>
      {children}
      {displayChildren &&
        (!currentTab?.permissionScope ||
          !isPermissionDisabled(currentTab.permissionScope)) && (
          <Grid
            item
            xs={12}
            sx={
              currentTab?.gridPadding
                ? { mx: gridSpacing, mb: gridSpacing }
                : {}
            }
          >
            {currentTab?.contents}
          </Grid>
        )}
      {currentTab &&
        isPermissionDisabled(currentTab?.permissionScope) &&
        permissionCopy && (
          <Grid item xs={12}>
            <MainCard>
              <CardContent>
                <Stack spacing={2} justifyContent="center">
                  <Typography variant="h3">
                    {permissionCopy?.message}
                  </Typography>
                  <Typography variant="subtitle1">
                    {permissionCopy?.action}
                  </Typography>
                </Stack>
              </CardContent>
            </MainCard>
          </Grid>
        )}
    </Grid>
  );
};

export default ExtendedTabs;
