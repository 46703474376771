import {
  dateValueFormatter,
  replaceStringWithHyphenValueFormatter,
} from 'ui-component/DataGrid/ValueFormatters';
import { dateValueGetter } from 'ui-component/DataGrid/ValueGetters';
import { ShippingMethods } from 'types/purchasing';
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import { StockLocation } from 'types/inventory';

import { BooleanIconRenderCell } from 'ui-component/DataGrid/Render';
import { ExtendedGridColDef } from 'types/datagrid';
import { ALL_APPS } from 'constants/appConstants';
import {
  IconArchive,
  IconArchiveOff,
  IconCalendarEvent,
  IconShoppingCart,
} from '@tabler/icons-react';
import { RelatedRecordChip } from 'ui-component/shared/RelatedRecords';
import {
  booleanFilterOperators,
  booleanValueOptions,
} from 'ui-component/DataGrid/FilterOperators';

const getAddressString = (stockLocation: StockLocation) =>
  stockLocation?.externalName ||
  stockLocation?.name ||
  stockLocation?.address?.company ||
  stockLocation?.address?.name ||
  stockLocation?.address?.line1 ||
  '-';

export const makeColumns = (isCofactr: boolean) =>
  [
    {
      field: 'name',
      headerName: 'Name',
      description: 'Name of this Kit Request',
      type: 'string',
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      description: 'Date this kit request was created',
      type: 'date',
      valueFormatter: dateValueFormatter,
      valueGetter: dateValueGetter,
      flex: 0.5,
    },
    {
      field: 'approved',
      headerName: 'Approved',
      description: 'Indicates whether this kit request is approved',
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) =>
        BooleanIconRenderCell(params),
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: 'fulfilledAt',
      headerName: 'Fulfilled At',
      description: 'Date this kit request was fulfilled',
      type: 'date',
      valueFormatter: dateValueFormatter,
      valueGetter: dateValueGetter,
      flex: 0.5,
    },
    {
      field: 'voidedAt',
      headerName: 'Cancelled At',
      description: 'Date this kit request was cancelled',
      type: 'date',
      valueFormatter: dateValueFormatter,
      valueGetter: dateValueGetter,
      flex: 0.5,
    },
    {
      field: 'archived',
      headerName: 'Archived',
      description: 'Indicates whether this Kit Request is archived',
      type: 'boolean',
      filterOperators: booleanFilterOperators,
      valueOptions: booleanValueOptions,
      flex: 0.3,
      hideable: true,
      renderCell: (params: GridRenderCellParams) =>
        BooleanIconRenderCell(
          params,
          IconArchive,
          IconArchiveOff,
          'error',
          'primary'
        ),
      permissionScope: { app: ALL_APPS.BOMS.id },
    },
    {
      field: 'method',
      headerName: 'Shipping Method',
      description:
        'Carrier and shipping service used/specified for kit request',
      type: 'singleSelect',
      valueOptions: ShippingMethods.map((m) => m.label),
      minWidth: 100,
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.carrier || params.row.service
          ? `${params.row.carrier ?? ''} ${params.row.service ?? ''}`
          : '-',
    },
    {
      field: 'shipTo',
      headerName: 'Ship To',
      description: 'Destination Address for this kit request, if applicable',
      type: 'string',
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        getAddressString(params.row.shipTo),
    },
    {
      field: 'sourceLocations',
      headerName: 'Source Locations',
      description: 'Source locations for this kit request',
      type: 'string',
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.value.length > 0
          ? params.value
              .map((sourceLocation: StockLocation) =>
                getAddressString(sourceLocation)
              )
              .join(', ')
          : '-',
    },
    {
      field: 'kittedFor',
      headerName: 'Cart',
      description: 'Cart of the kit request',
      type: 'string',
      minWidth: 100,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '-';
        }
        return (
          <RelatedRecordChip
            id={params.value.id}
            label="Cart"
            link={`${ALL_APPS.ORDERS.views?.YOUR_ORDERS?.path}/${params.value.id}`}
            icon={<IconShoppingCart />}
            key={params.value.id}
            recordTypeLabel="Cart"
            orgId={params.value.org}
          />
        );
      },
    },
    {
      field: 'productionRun.name',
      headerName: 'Program',
      description: 'Program associated with this Kit Request',
      type: 'string',
      minWidth: 150,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.productionRun) {
          return '-';
        }
        return (
          <RelatedRecordChip
            id={params.row.productionRun.id}
            label={params.row.productionRun.name}
            link={`${ALL_APPS.PRODUCTION.path}/${params.row.productionRun.id}`}
            icon={<IconCalendarEvent />}
            key={params.row.productionRun.id}
            recordTypeLabel="Program"
            orgId={params.row.productionRun.org}
          />
        );
      },
    },
    {
      field: 'shipPartial',
      headerName: 'Allow Partial Shipment',
      description:
        'Indicates whether this kit should be partially shipped if not all parts are available for shipment by the specified target ship date',
      type: 'boolean',
      renderCell: (params: GridRenderCellParams) =>
        BooleanIconRenderCell(params),
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'targetShipDate',
      headerName: 'Target Ship Date',
      description: 'Target shipment date for this kit request',
      type: 'date',
      minWidth: 100,
      flex: 0.5,
      valueGetter: dateValueGetter,
      valueFormatter: dateValueFormatter,
    },
    ...(isCofactr
      ? [
          {
            field: 'internalNotes',
            headerName: 'Cofactr Notes',
            description: 'Internal notes for Cofactr staff only',
            type: 'string',
            flex: 0.5,
            valueFormatter: replaceStringWithHyphenValueFormatter,
          },
        ]
      : []),
    {
      field: 'externalNotes',
      headerName: 'Notes',
      description: 'Notes associated with this Kit Request',
      type: 'string',
      flex: 0.5,
      valueFormatter: replaceStringWithHyphenValueFormatter,
    },
  ] as ExtendedGridColDef[];
