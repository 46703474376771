import CellWithEditWrapper from 'ui-component/DataGrid/Cell/CellWithEditWrapper';
import { MutableRefObject, ReactNode } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { PermissionScope } from 'types/apps';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

// Used for `renderCell` option on editable columns
// Allows for rendering a cell with an edit button via handleStartEdit arg
// Used in place of a datagrid where double-clicking a cell begins editing

export type RenderCellWithEditWrapperProps = {
  params: GridRenderCellParams;
  renderMethod?: null | ((params: GridRenderCellParams) => ReactNode);
  permissionScope?: PermissionScope;
  apiRef: MutableRefObject<GridApiPremium>;
  disabled?: boolean;
  getIsDisabled?: (params: GridRenderCellParams) => boolean;
  tooltip?: string;
};

function renderCellWithEditWrapper({
  params,
  renderMethod,
  permissionScope,
  apiRef,
  disabled,
  getIsDisabled,
  tooltip,
}: RenderCellWithEditWrapperProps) {
  return (
    <CellWithEditWrapper
      params={params}
      renderMethod={renderMethod}
      permissionScope={permissionScope}
      apiRef={apiRef}
      disabled={disabled}
      getIsDisabled={getIsDisabled}
      tooltip={tooltip}
    />
  );
}

export default renderCellWithEditWrapper;
