import {
  GridExceljsProcessInput,
  GridFilterModel,
  GridLinkOperator,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import _ from 'lodash';
import { BomLineRow } from 'types/bom';
import { PartTableRowDescribeParts } from 'types/production';
import { ExtendedGridColDef } from 'types/datagrid';
import { mergeCsv, parseCsv, sanitizeCsv, sanitizeExcelCell } from 'utils/csv';

const createNestedObject = (
  parts: string[],
  index: number
): Record<string, unknown> | string => {
  if (index === parts.length - 1) {
    return parts[index];
  }

  const key = parts[index];
  return { [key]: [createNestedObject(parts, index + 1)] };
};

export const transformVisibleColumns = (columns: string[]) =>
  columns
    .map((column) => {
      let columnField = column;
      if (column.includes('customProperties')) {
        columnField = column.replace(
          'customProperties.',
          'custom_properties__'
        );
      }
      const parts = columnField.split('.');
      return createNestedObject(parts, 0);
    })
    .reduce((acc: any[], curr: any) => {
      if (typeof curr === 'string') {
        acc.push(curr);
        return acc;
      }

      const key = Object.keys(curr)[0];
      const existingIndex = acc.findIndex((item) =>
        Object.prototype.hasOwnProperty.call(item, key)
      );
      if (existingIndex !== -1) {
        acc[existingIndex][key] = _.uniq([
          ...acc[existingIndex][key],
          ...curr[key],
        ]);
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);

export const transformFilters = (
  items: GridFilterModel['items'],
  linkOperator: GridFilterModel['linkOperator']
) =>
  items
    .flatMap((item) => {
      // Split the columnField by | and create a new filterModel
      const splitFields = item.columnField.split('|');
      return splitFields.map((field, index) => ({
        field: field.trim(),
        operator: item.operatorValue,
        value: item.value,
        isLastField: index === splitFields.length - 1,
      }));
    })
    .flatMap((item) => {
      const filter = {
        field: item.field,
        operator: item.operator,
        value: item.value,
      };
      if (item.isLastField) {
        return [filter, linkOperator];
      }
      return [filter, GridLinkOperator.Or];
    })
    .slice(0, -1);

export const generateExternalMetadataColumns: (
  rowData: BomLineRow[] | PartTableRowDescribeParts[]
) => ExtendedGridColDef[] = (rowData) =>
  _.chain(rowData)
    .map('externalMetadata')
    .map((m) => _.keys(m))
    .flatten()
    .uniq()
    .map((k) => ({
      field: `externalMetadata_${k}`,
      headerName: _.startCase(k),
      description: `Metadata: ${_.startCase(k)}`,
      type: typeof _.get(rowData, [0, 'externalMetadata', k], ''),
      minWidth: 100,
      hideable: true,
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) =>
        _.get(params.row, ['externalMetadata', k], ''),
    }))
    .value();

export const applyExportFormatters = (
  parsedCsvData: string[][],
  columns: ExtendedGridColDef[]
) =>
  parsedCsvData.map((row) =>
    row.map((cell, index) => {
      const exportFormatter = columns[index]?.exportFormatter;
      return exportFormatter && typeof exportFormatter === 'function'
        ? exportFormatter(cell)
        : cell;
    })
  );

export const processCsvData = (
  csvData: string,
  columns: ExtendedGridColDef[]
) => {
  const sanitizedCsvData = sanitizeCsv(csvData);
  const parsedCsvData = parseCsv(sanitizedCsvData);
  const formattedCsvData = applyExportFormatters(parsedCsvData, columns);
  return mergeCsv(formattedCsvData);
};

export const processExcelData = (
  processInput: GridExceljsProcessInput,
  columns: ExtendedGridColDef[]
) => {
  processInput.workbook.eachSheet((worksheet) => {
    worksheet.eachRow((row) => {
      row.eachCell((cell, index) => {
        if (typeof cell.value === 'string') {
          cell.value = sanitizeExcelCell(cell.value);
          const exportFormatter = columns[index - 1]?.exportFormatter;
          if (exportFormatter) {
            cell.value = exportFormatter(cell.value);
          }
        }
      });
    });
  });
};
