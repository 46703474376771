import { KitRequestSchema, KitRequestStatus } from 'types/kitting';

export const calculateKitRequestStatus = (kitRequest: KitRequestSchema) => {
  if (kitRequest.voidedAt) {
    return KitRequestStatus.VOIDED;
  }
  if (kitRequest.fulfilledAt) {
    return KitRequestStatus.FULFILLED;
  }
  if (kitRequest.approved) {
    return KitRequestStatus.APPROVED;
  }
  return KitRequestStatus.DRAFT;
};
