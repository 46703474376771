import { Part, PartCore } from 'types/part';
import { StockLotCRUDSchema } from 'types/inventory';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import PartAvatar from 'ui-component/extended/PartAvatar';
import CopyClipboard from 'ui-component/extended/CopyClipboard';
import { useAppAccessContext } from 'contexts/AppAccessContext';
import { ALL_APPS, ALL_FEATURE_IDS } from 'constants/appConstants';
import { useSelector } from 'store';
import { memo } from 'react';
import isEqual from 'react-fast-compare';

type PartCellProps = {
  part: Part | PartCore | null;
  noPart?: string | null;
  altCount?: number | null;
  stockLot?: StockLotCRUDSchema | null | StockLotCRUDSchema['id'];
  searchParams?: string;
  unapprovedAltCount?: number | null;
};
const PartCell = ({
  part,
  noPart,
  altCount,
  stockLot,
  searchParams,
  unapprovedAltCount,
}: PartCellProps) => {
  const { isPermissionDisabled } = useAppAccessContext();
  const customPnsEnabled = !isPermissionDisabled({
    app: ALL_APPS.PART_LIBRARY.id,
    feature: ALL_FEATURE_IDS.CUSTOM_PNS,
  });
  const { activeOrg } = useSelector((state) => state.org);

  return (
    <Box
      sx={{
        width: '100%',
        my: '4px',
      }}
    >
      <Grid container columnSpacing={1.5} alignItems="center">
        <Grid item xs="auto">
          <PartAvatar
            part={part}
            altCount={altCount}
            stockLot={stockLot}
            searchParams={searchParams}
            unapprovedAltCount={unapprovedAltCount}
          />
        </Grid>
        <Grid item xs sx={{ overflow: 'hidden' }}>
          <Typography variant="body1">
            {noPart ? 'Unable to find match for:' : part?.mfg || '-'}
          </Typography>
          <Tooltip
            title={
              part?.altMpns && (part?.altMpns || []).length > 0
                ? `Other MPN Variants for this Part: ${part.altMpns.join(', ')}`
                : undefined
            }
          >
            <Typography variant="subtitle1">
              {noPart || part?.mpn}
              {part?.mpn && (
                <CopyClipboard label="Part MPN" input={noPart || part?.mpn} />
              )}
            </Typography>
          </Tooltip>
          {customPnsEnabled && part?.customId && (
            <Typography variant="subtitle1">
              {part.customId}
              <CopyClipboard
                label={`${activeOrg?.name || 'Custom'} Part Number`}
                input={part.customId}
              />
            </Typography>
          )}
          <Tooltip title={part?.description || ''}>
            <Typography noWrap variant="body1">
              {part?.description}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

function partCellPropsAreEqual(
  prevProps: PartCellProps,
  nextProps: PartCellProps
) {
  if (prevProps.part?.mpn !== nextProps.part?.mpn) {
    return false;
  }
  if (prevProps.part?.customId !== nextProps.part?.customId) {
    return false;
  }
  if (prevProps.part?.id !== nextProps.part?.id) {
    return false;
  }
  if (prevProps.part?.mfg !== nextProps.part?.mfg) {
    return false;
  }
  if (prevProps.noPart !== nextProps.noPart) {
    return false;
  }
  if (prevProps.altCount !== nextProps.altCount) {
    return false;
  }
  if (isEqual(prevProps.stockLot, nextProps.stockLot)) {
    return false;
  }
  if (prevProps.searchParams !== nextProps.searchParams) {
    return false;
  }
  if (prevProps.unapprovedAltCount !== nextProps.unapprovedAltCount) {
    return false;
  }
  return true;
}

export default memo(PartCell, partCellPropsAreEqual);
