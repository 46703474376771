import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';
import { CustomProperty, CustomPropertyDataType } from 'types/customProperty';
import { FC } from 'react';
import { HookFormCheckbox } from 'ui-component/HookFormComponents/HookFormCheckbox';
import { ISOStringHookFormDatePicker } from 'ui-component/HookFormComponents/HookFormDatePicker/ISOStringHookFormDatePicker';
import { HookFormUserAutocomplete } from 'ui-component/HookFormComponents/HookFormUserAutocomplete';
import { HookFormSelect } from 'ui-component/HookFormComponents/HookFormSelect';
import { HookFormInput } from 'ui-component/HookFormComponents/HookFormInput';
import { HookFormNumberInput } from 'ui-component/HookFormComponents/HookFormNumberInput';

export type HookFormCustomPropertyInputProps = {
  control: Control<any, object>;
  errors?: FieldErrors;
  customProperty: CustomProperty;
  setValue: UseFormSetValue<FieldValues>;
};

export const CUSTOM_PROPS_NAME_PREPEND = 'customProperties.';

export const HookFormCustomPropertyInput: FC<
  HookFormCustomPropertyInputProps
> = ({ control, errors, customProperty, setValue }) => {
  const label = customProperty.name;
  const name = `${CUSTOM_PROPS_NAME_PREPEND}${customProperty.key}`;

  if (customProperty.dataType === CustomPropertyDataType.BOOLEAN) {
    return (
      <HookFormCheckbox
        control={control}
        name={name}
        label={label}
        errors={errors}
      />
    );
  }

  if (
    customProperty.dataType === CustomPropertyDataType.DATE ||
    customProperty.dataType === CustomPropertyDataType.DATETIME
  ) {
    return (
      <ISOStringHookFormDatePicker
        control={control}
        name={name}
        label={label}
        errors={errors}
      />
    );
  }

  if (customProperty.dataType === CustomPropertyDataType.USER) {
    return (
      <HookFormUserAutocomplete
        control={control}
        name={name}
        label={label}
        errors={errors}
        setValue={setValue}
      />
    );
  }

  if (
    customProperty.dataType === CustomPropertyDataType.SINGLE_CHOICE ||
    customProperty.dataType === CustomPropertyDataType.MULTI_CHOICE
  ) {
    return (
      <HookFormSelect
        control={control}
        name={name}
        label={label}
        errors={errors}
        multiple={
          customProperty.dataType === CustomPropertyDataType.MULTI_CHOICE
        }
        options={customProperty.values}
        mt={0}
      />
    );
  }

  if (customProperty.dataType === CustomPropertyDataType.NUMBER) {
    return (
      <HookFormNumberInput
        control={control}
        name={name}
        label={label}
        errors={errors}
      />
    );
  }

  return (
    <HookFormInput
      control={control}
      name={name}
      label={label}
      errors={errors}
    />
  );
};
