import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { Redirect } from 'react-router6-redirect';

const UserProfile = Loadable(
  lazy(() => import('views/account/UserAccountProfile'))
);
const UserSecurity = Loadable(
  lazy(() => import('views/account/UserAccountSecurity'))
);
const UserNotifications = Loadable(
  lazy(() => import('views/account/UserAccountNotifications'))
);

export const UserSettingsRoutes = [
  {
    path: `${ALL_APPS.USER_SETTINGS.deprecatedPath}`,
    element: <Redirect to={`${ALL_APPS.USER_SETTINGS.path}`} />,
  },
  {
    path: `${ALL_APPS.USER_SETTINGS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.USER_SETTINGS.id }}
        >
          <Navigate to={`${ALL_VIEWS.PROFILE.path}`} replace />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PROFILE.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.PROFILE.path}`} />,
  },
  {
    path: `${ALL_VIEWS.PROFILE.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.USER_SETTINGS.id,
            view: ALL_VIEW_IDS.PROFILE,
          }}
        >
          <UserProfile />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.SECURITY.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.SECURITY.path}`} />,
  },
  {
    path: `${ALL_VIEWS.SECURITY.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.USER_SETTINGS.id,
          view: ALL_VIEW_IDS.SECURITY,
        }}
      >
        <UserSecurity />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.NOTIFICATIONS.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.NOTIFICATIONS.path}`} />,
  },
  {
    path: `${ALL_VIEWS.NOTIFICATIONS.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.USER_SETTINGS.id,
          view: ALL_VIEW_IDS.NOTIFICATIONS,
        }}
      >
        <UserNotifications />
      </PermissionsRouteWrapper>
    ),
  },
];
