import { Typography } from '@mui/material';
import InlineGoToButton from 'ui-component/shared/InlineGoToButton';

export function renderTextLink(text: string, link: string) {
  if (text) {
    return (
      <Typography>
        {text}
        <InlineGoToButton tooltip={`Go to ${text}`} link={link} />
      </Typography>
    );
  }
  return '-';
}
