import { Part, PartCore } from 'types/part';
import { StockLotCRUDSchema } from 'types/inventory';
import PartCell from 'ui-component/shared/PartCell';

export function renderPartCell(
  part: Part | PartCore,
  noPart?: string | null,
  altCount?: number | null,
  stockLot?: StockLotCRUDSchema | null | StockLotCRUDSchema['id'],
  searchParams?: string,
  unapprovedAltCount?: number | null
) {
  return (
    <PartCell
      part={part}
      noPart={noPart}
      altCount={altCount}
      stockLot={stockLot}
      searchParams={searchParams}
      unapprovedAltCount={unapprovedAltCount}
    />
  );
}
