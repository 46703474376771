import { Tooltip, Grid, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { statusTextMapper } from 'constants/datagrid';
import _ from 'lodash';
import Chip from 'ui-component/extended/Chip';
import { statusColorCallback } from 'ui-component/DataGrid/GridColTypeDef';

export function stockLocationDetailRenderCell(
  params: GridRenderCellParams,
  column:
    | 'expected'
    | 'processing'
    | 'onHand'
    | 'allocated'
    | 'unallocated'
    | 'totalFree'
    | 'allocatedToRun'
) {
  const locationDetail = _.chain(params.row.stockByFacility || [])
    .map((s) => ({
      facility: s.facility,
      quantity: _.get(s, column, 0),
    }))
    .value();

  const total = _.sumBy(locationDetail, 'quantity');

  return (
    <Tooltip
      title={
        <Grid container spacing={0} sx={{ p: 2 }}>
          <Chip
            label={statusTextMapper[column]}
            chipcolor={statusColorCallback(statusTextMapper[column])}
            style={{
              textTransform: 'uppercase',
            }}
          />
          <Grid
            container
            item
            sx={{
              py: 2,
              color: 'grey.800',
              borderBottom: '1px solid #E3F2FD',
            }}
          >
            <Grid item xs={8}>
              <Typography>Address</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Stock</Typography>
            </Grid>
          </Grid>
          {locationDetail.map((ld, index: number) => (
            <Grid
              container
              item
              sx={{
                py: 2,
                borderBottom:
                  index !== locationDetail.length - 1
                    ? '1px solid #E3F2FD'
                    : 'none',
              }}
              key={index}
            >
              <Grid item xs={8}>
                <Typography>
                  {ld.facility?.externalName || ld.facility?.name || ''}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>{ld.quantity.toLocaleString('en-US')}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      }
      placement="bottom"
      disableHoverListener={!locationDetail.length || !total}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'white',
            boxShadow: '0px 24px 38px rgba(9, 15, 37, 0.07)',
            borderRadius: '12px',
            color: 'grey.500',
          },
        },
      }}
    >
      <span className="table-cell-trucate">{params.formattedValue}</span>
    </Tooltip>
  );
}
