import { Tooltip, Typography, Stack } from '@mui/material';
import { IconAlertTriangle, Icon } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import _ from 'lodash';
import { memo } from 'react';

const OverageWarningString = memo(
  ({
    string,
    tooltip,
    IconOverride,
    color = 'warning',
    rightAlign = false,
  }: {
    string: string;
    tooltip?: string | null;
    IconOverride?: Icon;
    color?: 'error' | 'warning' | 'success' | 'info';
    rightAlign?: boolean;
  }) => {
    const theme = useTheme();
    return (
      <Tooltip title={tooltip}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={0.5}
        >
          <Typography align={rightAlign ? 'right' : undefined}>
            {string}
          </Typography>
          {IconOverride ? (
            <IconOverride
              color={_.get(theme.palette, [color, 'main'])}
              width={20}
              height={20}
            />
          ) : (
            <IconAlertTriangle
              color={_.get(theme.palette, [color, 'main'])}
              width={20}
              height={20}
            />
          )}
        </Stack>
      </Tooltip>
    );
  }
);

export function renderStringWithWarning(
  string: string,
  tooltip: string | null,
  IconOverride?: Icon,
  color?: 'error' | 'warning' | 'success' | 'info',
  rightAlign?: boolean
) {
  if (!tooltip) {
    return string;
  }
  return (
    <OverageWarningString
      string={string}
      tooltip={tooltip}
      IconOverride={IconOverride}
      color={color}
      rightAlign={rightAlign}
    />
  );
}
