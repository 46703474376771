import Chip, { ChipColor } from 'ui-component/extended/Chip';
import { Icon } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

export function IconWithNumberChip(
  value: string,
  color: ChipColor,
  uppercase: boolean = true,
  ChipIcon: Icon,
  tooltip?: string
) {
  const theme = useTheme();
  return (
    <Chip
      ChipIcon={ChipIcon}
      title={tooltip}
      label={value}
      chipcolor={color}
      style={{ textTransform: uppercase ? 'uppercase' : 'none' }}
    />
  );
}
