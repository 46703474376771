import { useRoutes } from 'react-router-dom';
import MobileInventoryRoutes from 'routes/MobileInventoryRoutes';
import LoginRoutes from 'routes/LoginRoutes';
import AuthenticationRoutes from 'routes/AuthenticationRoutes';
import AppRoutes from 'routes/AppRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    AppRoutes,
    MobileInventoryRoutes,
    LoginRoutes,
    AuthenticationRoutes,
  ]);
}
