// material-ui
import { Theme, useTheme } from '@mui/material/styles';
import MuiChip, { ChipProps } from '@mui/material/Chip';
import { SxProps } from '@mui/system';
import { ReactElement, memo, useMemo } from 'react';
import { Icon } from '@tabler/icons-react';
import _ from 'lodash';

// ==============================|| CHIP ||============================== //

export interface chipProps extends ChipProps {
  chipcolor?: ChipColor;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  label?: string;
  avatar?: ReactElement | undefined;
  onDelete?: () => void;
  keepColorWhenDisabled?: boolean;
  type?: 'chip' | 'button';
  ChipIcon?: Icon;
  outlineBgColor?: string;
}

export enum ChipColor {
  secondary = 'secondary',
  success = 'success',
  warning = 'warning',
  error = 'error',
  primary = 'primary',
  default = 'default',
  orange = 'orange',
  grey = 'grey',
}

const defaultSXProp = {};

const Chip = memo(
  ({
    chipcolor,
    disabled,
    keepColorWhenDisabled,
    sx = defaultSXProp,
    variant,
    type = 'chip',
    onClick,
    title,
    label,
    style,
    ChipIcon,
    icon,
    outlineBgColor = 'transparent',
    ...others
  }: chipProps) => {
    const theme = useTheme();
    const SX = useMemo(() => {
      let defaultSX = {
        color: theme.palette.primary.main,
        borderRadius: type === 'chip' ? undefined : '6px',
        bgcolor:
          theme.palette.mode === 'dark'
            ? theme.palette.primary.main
            : theme.palette.primary.light,
      };

      let outlineSX = {
        color: theme.palette.primary.main,
        bgcolor: outlineBgColor,
        border: '1px solid',
        borderRadius: type === 'chip' ? undefined : '6px',
        borderColor: theme.palette.primary.main,
        ':hover': {
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.light
              : theme.palette.primary.light,
          bgcolor:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.main
              : theme.palette.primary.dark,
        },
      };
      switch (chipcolor) {
        case 'secondary':
          variant === 'outlined'
            ? (outlineSX = {
                color: theme.palette.secondary.main,
                bgcolor: outlineBgColor,
                border: '1px solid',
                borderRadius: type === 'chip' ? undefined : '6px',
                borderColor: theme.palette.secondary.main,
                ':hover': {
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.secondary.light
                      : theme.palette.secondary.main,
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.secondary.dark
                      : theme.palette.secondary.light,
                },
              })
            : (defaultSX = {
                borderRadius: type === 'chip' ? undefined : '6px',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.main,
                bgcolor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.secondary.dark
                    : theme.palette.secondary.light,
              });
          break;
        case 'success':
          variant === 'outlined'
            ? (outlineSX = {
                color: theme.palette.success.dark,
                bgcolor: outlineBgColor,
                border: '1px solid',
                borderRadius: type === 'chip' ? undefined : '6px',
                borderColor: theme.palette.success.dark,
                ':hover': {
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.success.light
                      : theme.palette.success.dark,
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.success.dark
                      : theme.palette.success.light,
                },
              })
            : (defaultSX = {
                borderRadius: type === 'chip' ? undefined : '6px',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.success.light
                    : theme.palette.success.dark,
                bgcolor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.success.dark
                    : theme.palette.success.light,
              });
          break;
        case 'error':
          variant === 'outlined'
            ? (outlineSX = {
                color: theme.palette.error.main,
                bgcolor: outlineBgColor,
                border: '1px solid',
                borderRadius: type === 'chip' ? undefined : '6px',
                borderColor: theme.palette.error.main,
                ':hover': {
                  color:
                    theme.palette.mode === 'dark'
                      ? theme.palette.error.light
                      : theme.palette.error.dark,
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? theme.palette.error.dark
                      : theme.palette.error.light,
                },
              })
            : (defaultSX = {
                borderRadius: type === 'chip' ? undefined : '6px',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.error.light
                    : theme.palette.error.dark,
                bgcolor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.error.dark
                    : theme.palette.error.light,
              });
          break;
        case 'orange':
          variant === 'outlined'
            ? (outlineSX = {
                color: theme.palette.orange?.dark!,
                bgcolor: outlineBgColor,
                border: '1px solid',
                borderRadius: type === 'chip' ? undefined : '6px',
                borderColor: theme.palette.orange?.main!,
                ':hover': {
                  color: theme.palette.orange?.dark!,
                  bgcolor: theme.palette.orange?.light!,
                },
              })
            : (defaultSX = {
                borderRadius: type === 'chip' ? undefined : '6px',
                color: theme.palette.orange?.dark!,
                bgcolor: theme.palette.orange?.light!,
              });
          break;
        case 'grey':
          variant === 'outlined'
            ? (outlineSX = {
                color: theme.palette.grey?.['500'],
                bgcolor: outlineBgColor,
                border: '1px solid',
                borderRadius: type === 'chip' ? undefined : '6px',
                borderColor: theme.palette.grey?.['500'],
                ':hover': {
                  color: theme.palette.grey?.['500'],
                  bgcolor: theme.palette.grey?.['50'],
                },
              })
            : (defaultSX = {
                borderRadius: type === 'chip' ? undefined : '6px',
                color: theme.palette.grey?.['500'],
                bgcolor: theme.palette.grey?.['50'],
              });
          break;
        case 'warning':
          variant === 'outlined'
            ? (outlineSX = {
                color: theme.palette.warning.dark,
                bgcolor: outlineBgColor,
                border: '1px solid',
                borderRadius: type === 'chip' ? undefined : '6px',
                borderColor: theme.palette.warning.dark,
                ':hover': {
                  color: theme.palette.warning.dark,
                  bgcolor: theme.palette.warning.light,
                },
              })
            : (defaultSX = {
                borderRadius: type === 'chip' ? undefined : '6px',
                color: theme.palette.warning.dark,
                bgcolor: theme.palette.warning.light,
              });
          break;
        default:
      }

      if (disabled && !keepColorWhenDisabled) {
        variant === 'outlined'
          ? (outlineSX = {
              color: theme.palette.grey[500],
              bgcolor: outlineBgColor,
              border: '1px solid',
              borderRadius: type === 'chip' ? undefined : '6px',
              borderColor: theme.palette.grey[500],
              ':hover': {
                color: theme.palette.grey[500],
                bgcolor: 'transparent',
              },
            })
          : (defaultSX = {
              borderRadius: type === 'chip' ? undefined : '6px',
              color: theme.palette.grey[500],
              bgcolor: theme.palette.grey[50],
            });
      }

      let SXObject: SxProps<Theme> = defaultSX;
      if (variant === 'outlined') {
        SXObject = outlineSX;
      }
      return SXObject;
    }, [
      theme.palette,
      variant,
      type,
      chipcolor,
      disabled,
      keepColorWhenDisabled,
    ]);

    return (
      <MuiChip
        {...others}
        onClick={onClick}
        title={title}
        label={label}
        style={style}
        sx={{ ...SX, ...sx }}
        disabled={disabled}
        icon={ChipIcon ? <ChipIcon color={_.get(SX, 'color')} /> : icon}
      />
    );
  }
);

export default memo(Chip);
