import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';

export default function useInlineEditCellHookForm({
  params,
  validation,
}: {
  params: GridRenderCellParams;
  validation?: Yup.SchemaOf<unknown, object>;
}) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [params.colDef.field]: params.value,
    },
    ...(validation
      ? {
          resolver: yupResolver(
            Yup.object().shape({
              [params.colDef.field]: validation,
            })
          ),
        }
      : {}),
    mode: 'all',
  });

  return {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  };
}
