import {
  Typography,
  Accordion,
  AccordionDetails,
  CircularProgress,
  Stack,
} from '@mui/material';
import MainCard, { MainCardProps } from 'ui-component/cards/MainCard';
import { FC } from 'react';
import { AccordionSummary } from 'ui-component/extended/Accordion';
import { actionButtonSpacing } from 'constants/themeConstants';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface AccordionCardProps extends MainCardProps {
  defaultExpanded?: boolean;
  title: string;
  sx?: SxProps<Theme>;
  loading?: boolean;
  highlight?: boolean;
  unmountOnExit?: boolean;
}

const AccordionCard: FC<AccordionCardProps> = ({
  children,
  defaultExpanded = true,
  title,
  sx = {},
  loading = false,
  highlight = false,
  unmountOnExit = false,
  ...others
}) => (
  <MainCard
    content={false}
    sx={sx}
    highlight={highlight}
    boxShadow={highlight}
    {...others}
  >
    <Accordion
      defaultExpanded={defaultExpanded}
      TransitionProps={{ unmountOnExit }}
    >
      <AccordionSummary>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={actionButtonSpacing}
        >
          {loading && <CircularProgress color="inherit" size={20} />}
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  </MainCard>
);

export default AccordionCard;
