import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Box,
  BoxProps,
  StepperProps,
  StepProps,
} from '@mui/material';
import { useState, FC } from 'react';

export interface StepItem {
  label: string;
  component: React.ReactNode;
  preNext?: (preNextArg?: string) => Promise<boolean> | boolean;
  disableNext?: boolean;
}

interface VerticalStepperProps {
  steps: StepItem[];
  onValidate?: (isValid: boolean) => void;
  boxSX?: BoxProps['sx'];
  stepperSX?: StepperProps['sx'];
}

type VerticalStepperContainerProps = {
  boxProps?: BoxProps;
  stepperProps?: StepperProps;
};

export const VerticalStepperContainer: FC<VerticalStepperContainerProps> = ({
  boxProps = {},
  stepperProps = {},
  children,
}) => (
  <Box {...boxProps}>
    <Stepper {...stepperProps} orientation="vertical">
      {children}
    </Stepper>
  </Box>
);

type ExtendedStepProps = {
  label: string;
  preNext?: (preNextArg?: string) => Promise<boolean> | boolean;
  disableNext?: boolean;
} & StepProps;

export const ExtendedStep: FC<ExtendedStepProps> = ({
  label,
  preNext,
  disableNext,
  children,
  ...rest
}) => (
  <Step {...rest}>
    <StepLabel>{label}</StepLabel>
    <StepContent
      sx={{ pr: 2, mr: 1 }}
      TransitionProps={{ unmountOnExit: false }}
    >
      {children}
    </StepContent>
  </Step>
);

export const useStepper = (
  steps: StepItem[],
  onValidate?: (isValid: boolean) => void
) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async (preNextArg?: string) => {
    const preNext = steps[activeStep].preNext;
    const stepIsValid =
      typeof preNext === 'function'
        ? (await preNext(preNextArg)) !== false
        : true;

    if (onValidate) {
      onValidate(stepIsValid && activeStep === steps.length - 2);
    }

    if (stepIsValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleJumpTo = (index: number) => {
    if (index === activeStep + 1) {
      handleNext();
    } else {
      setActiveStep(index);
    }
  };

  const resetStep = () => {
    setActiveStep(0);
  };

  return { activeStep, resetStep, handleNext, handleBack, handleJumpTo };
};

const VerticalStepper: FC<VerticalStepperProps> = ({
  steps,
  onValidate,
  boxSX = {},
  stepperSX = {},
}) => {
  const { activeStep, handleNext, handleBack } = useStepper(steps, onValidate);

  return (
    <VerticalStepperContainer
      stepperProps={{ sx: stepperSX, activeStep }}
      boxProps={{ sx: boxSX }}
    >
      {steps.map((step) => (
        <ExtendedStep
          key={step.label}
          label={step.label}
          preNext={step.preNext}
          disableNext={step.disableNext}
        >
          {step.component}
          <Box sx={{ mb: 2 }}>
            <div>
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  onClick={() => handleNext()}
                  disabled={steps[activeStep].disableNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
              )}
              {activeStep > 0 && (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              )}
            </div>
          </Box>
        </ExtendedStep>
      ))}
    </VerticalStepperContainer>
  );
};

export default VerticalStepper;
