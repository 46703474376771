import { ALL_APPS, ALL_VIEW_IDS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import { Navigate } from 'react-router-dom';
import { Redirect } from 'react-router6-redirect';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const InventoryHandling = Loadable(
  lazy(() => import('views/inventory/InventoryHandling'))
);
const InventoryPreferences = Loadable(
  lazy(() => import('views/org/Preferences/InventoryPreferences'))
);
const StockLocations = Loadable(
  lazy(() => import('views/inventory/StockLocations'))
);

export const ManagedInventoryRoutes = [
  {
    path: `${ALL_APPS.MANAGED_INVENTORY.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.MANAGED_INVENTORY.id,
          }}
        >
          <Navigate to={`${ALL_VIEWS.HANDLING_TASKS.path}`} replace />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.HANDLING_TASKS.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.HANDLING_TASKS.path}`} />,
  },
  {
    path: `${ALL_VIEWS.HANDLING_TASKS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.MANAGED_INVENTORY.id,
            view: ALL_VIEW_IDS.HANDLING_TASKS,
          }}
        >
          <InventoryHandling />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.STOCK_LOCATIONS.deprecatedPath}`,
    element: <Redirect to={`${ALL_VIEWS.STOCK_LOCATIONS.path}`} />,
  },
  {
    path: `${ALL_VIEWS.STOCK_LOCATIONS.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.MANAGED_INVENTORY.id,
            view: ALL_VIEW_IDS.STOCK_LOCATIONS,
          }}
        >
          <StockLocations />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.INVENTORY_PREFERENCES.path}`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{
            app: ALL_APPS.MANAGED_INVENTORY.id,
            view: ALL_VIEW_IDS.INVENTORY_PREFERENCES,
          }}
        >
          <InventoryPreferences />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
