import { User } from 'types/user';
import { FC, useEffect, useState } from 'react';
import { useLazyGlobalSearchQuery } from 'store/slices/api';
import { Button, Stack, Box, IconButton, Tooltip } from '@mui/material';
import { GlobalSearchResponse } from 'types';
import {
  IconSquareLetterK,
  IconCommand,
  IconSparkles,
} from '@tabler/icons-react';
import { useSelector } from 'store';
import useConfig from 'hooks/useConfig';
import { useTheme } from '@mui/material/styles';
import { init } from 'commandbar';
import { useNavigate } from 'react-router-dom';
import 'assets/scss/pylon-style-override.css';
import { CommandbarRecordActions } from 'ui-component/Commandbar/constants';

enum PylonAction {
  'show' = 'show',
  'hide' = 'hide',
  'onShow' = 'onShow',
  'onHide' = 'onHide',
  'hideChatBubble' = 'hideChatBubble',
  'showChatBubble' = 'showChatBubble',
  'onChangeUnreadMessageCount' = 'onChangeUnreadMessageCount',
  'setNewIssueCustomFields' = 'setNewIssueCustomFields',
}

declare global {
  interface Window {
    pylon: {
      chat_settings: {
        app_id: string;
        email: string;
        name: string;
        avatar_url?: string;
        email_hash?: string;
      };
    };
    Pylon: (action: PylonAction) => void;
  }
}

export type CommandBarProps = {
  user: User | null;
};

const CommandBar: FC<CommandBarProps> = ({ user }) => {
  const { borderRadius } = useConfig();
  const [search] = useLazyGlobalSearchQuery();
  const [booted, setBooted] = useState(false);
  const { drawerOpen } = useSelector((state) => state.menu);
  const theme = useTheme();
  const navigate = useNavigate();

  // Pylon is a separate service but its integration is completely dependent on Commandbar
  useEffect(() => {
    window.pylon = {
      chat_settings: {
        app_id: process.env.PYLON_APP_ID || '',
        email: user?.email || '',
        name: user?.firstName || user?.lastName || user?.email || '',
        avatar_url: user?.image || undefined,
      },
    };
  }, [user?.id]);

  useEffect(() => {
    if (window && user?.id) {
      if (process.env.COMMANDBAR_KEY && !window.CommandBar) {
        init(process.env.COMMANDBAR_KEY);
      }
      if (window.CommandBar) {
        window.CommandBar.addRouter(navigate);
        window.CommandBar.boot(user.id);
        setBooted(true);
      }
    }
    return undefined;
  }, [user?.id, !!window, window.CommandBar]);

  const onInputChange = async (query: string) => {
    const fetch = async () => {
      const data = await search(query).unwrap();
      return data;
    };
    type output = {
      [key: string]: (GlobalSearchResponse & { label: string })[];
    };
    return new Promise<any>((resolve: any) =>
      fetch().then((results) => {
        const out: output = {};
        results.forEach((res) => {
          const newRes = { ...res, label: res.display };
          if (!(newRes.model in out)) {
            out[newRes.model] = [];
          }
          out[newRes.model].push(newRes);
        });
        Object.keys(CommandbarRecordActions).forEach((key) => {
          if (!out[key]) {
            out[key] = [];
          }
        });
        return resolve(out);
      })
    );
  };

  useEffect(() => {
    if (window.CommandBar) {
      Object.keys(CommandbarRecordActions).forEach((key) => {
        window.CommandBar.addRecords(key, []);
        window.CommandBar.addRecordAction(key, CommandbarRecordActions[key]);
      });
      window.CommandBar.addMultiSearch(
        onInputChange,
        Object.keys(CommandbarRecordActions)
      );
    }
  }, [window.CommandBar]);

  function handleClick() {
    window.CommandBar.open();
  }

  if (!booted || !user) {
    return <></>;
  }

  return drawerOpen ? (
    <Button
      variant="outlined"
      fullWidth
      sx={{
        borderRadius: `${borderRadius}px`,
        borderColor: '#bdbdbd',
        color: theme.palette.primary.main,
      }}
      onClick={handleClick}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <IconSparkles size={20} />
          <Box sx={{ ml: 1 }}>Find Anything</Box>
        </Box>
        <Stack direction="row" alignItems="center">
          <IconCommand size={20} />
          <IconSquareLetterK size={20} />
        </Stack>
      </Stack>
    </Button>
  ) : (
    <Tooltip title="Find Anything">
      <IconButton
        sx={{
          borderRadius: `${borderRadius}px`,
          width: '42px',
          color: theme.palette.primary.main,
        }}
        onClick={handleClick}
      >
        <IconSparkles size={22} />
      </IconButton>
    </Tooltip>
  );
};

export { CommandBar };
